import React from 'react';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import PermissionForm from './PermissionForm';

const CreatePermissions = (props) => {

  var editView = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/permissions_management/view_all">Permissions List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Create Permissions',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <PermissionForm editView={editView} />
    </FormContainer>
  );
};

export default CreatePermissions;