import React, { useState } from 'react';
import axios from 'axios';
import style from './SearchInvoice.module.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const SearchInvoice = () => {
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      MySwal.fire({
        title: <p>Searching Invoice</p>,
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      });
      // await new Promise(r => setTimeout(r, 5000));
      axios({
        url: 'http://localhost:8080/api/invoice/get_invoice',
        params: { invoice_id: invoiceId },
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        MySwal.close();
        navigate(`/invoice/view_invoice/${invoiceId}`);
        // console.log(res.data);
        // const data = new Array(res.data['invoice_data']);
        // console.log(data);
        // setInvoiceData([JSON.parse(res.data['invoice_data'])]);
        // setInvoiceData(invoiceData[0].client_data, JSON.parse(invoiceData[0].client_data));

        // Push to /
        // navigate('/');
      }).catch((err) => {
        console.log(err)
        return MySwal.fire({
          title: <strong>Invoice ID not found.</strong>,
          html: <i>Please try again</i>,
          icon: 'error'
        })
      });


      // await axios.post('', { invoiceId });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <a
        className="display-4"
        href="/invoice/home"
        rel="noopener noreferrer"
      >
        {/* <Button variant="primary" className='lead text-center'>Back to Invoicing</Button> */}
        <i className="fa-solid fa-arrow-left"></i>
      </a>
      <div className={style.pageContainer}>
        <div className={style.titleContainer}>
          <p className={style.pageTitle}>
            Search Invoice
          </p>
        </div>

        <form className={style.formContainer} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Invoice ID"
            className={style.input}
            value={invoiceId}
            onChange={(event) => setInvoiceId(event.target.value)}
          />
          <button className={style.button} type="submit">
            Search
          </button>
        </form>
      </div>
    </div>

  );
};

export default SearchInvoice;
