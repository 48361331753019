import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import PRForm from './PRForm';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';

const MySwal = withReactContent(Swal)

const ViewPR = (props) => {

  const { prID } = useParams();

  const viewEdit = true;
  // const [viewEdit, setViewEdit] = useState(true)

  const [onePRData, setonePRData] = useState([])
  const [prInfoData, setprInfoData] = useState([])
  const [prItemsInfoData, setprItemsInfoData] = useState([])

  async function fetchOnePRData() {
    await fetch(`${PROCUREMENT_LINK}/api/purchase_request/get_pr?pr_id=` + prID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setonePRData(data.pr_data)
        setprInfoData(data.pr_data.pr_info)
        setprItemsInfoData(data.pr_data.pr_items_info)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOnePRData();
  }, [])

  useEffect(() => {
    //   console.log(onePRData);
    //   console.log('prInfoData',prInfoData);
    //   console.log('prItemsInfoData',prItemsInfoData);
  }, [onePRData])

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_requests/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_requests/view_all">PR List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'PR Details',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      {/* Purchase Request Form */}
      <PRForm viewEdit={viewEdit} onePRData={onePRData} prInfoData={prInfoData} prItemsInfoData={prItemsInfoData} prID={prID} />

    </FormContainer>
  );
};

export default ViewPR;