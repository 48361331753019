import { useEffect } from "react";
import { useState } from "react";
import { decryptData } from "../../utils/encryption"
import { hasItems } from "../../utils/functions";
import axios from "axios";
import { AUTH_API_KEY, AUTH_LINK, ENCRYPTION_KEY } from "../../utils/env_config";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";

const usePermission = () => {
  const permissionHandler = [];
  const [permission, setPermission] = useState(null);
  const [storedPermission, setStoredPermission] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const getDataFromLocalStorage = () => {
    const json = localStorage.getItem("p");
    return json || false;
  };

  const getToken = () => {
    // getting stored value
    const saved = localStorage.getItem("t");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  const getTemp = () => {
    // getting stored value
    const saved = localStorage.getItem("te");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  const getAllRoles = () => {
    axios({
      url: `${AUTH_LINK}/api/roles/get_all_roles`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    })
      .then((response) => {
        setRoleData(response.data['all_roles_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };
  
  const navigate = useNavigate();

  useEffect(() => {
    getAllRoles();
    // Retrieve the array from local storage
    const retrievedArray = getDataFromLocalStorage();
    if (retrievedArray) {
      const decryptedPermissionsData = JSON.parse(decryptData(retrievedArray, ENCRYPTION_KEY))
      setPermission(decryptedPermissionsData);
    } else {
      setPermission();
    }

    const token = getToken();
    // console.log(token);
    const temp = getTemp();
    // console.log(temp);
    if (!token) {
      navigate("/login");
    } else if (temp) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    // console.log("roleData", roleData)
  }, [roleData])

  function storePermission() {
    if (permission) {
      permission.forEach((permission) => {
        const role_permission = {
          module_id: permission.module_id,
          hasCreate: permission.PRM00000037,
          hasUpdate: permission.PRM00000038,
          hasView: permission.PRM00000039,
        };
        permissionHandler.push(role_permission);
      });
      setStoredPermission(permissionHandler);
    }
  }

  useEffect(() => {
    // console.log("permission", permission)
    if (permission) {
      storePermission();
    }
  }, [permission]);

  useEffect(() => {
    if (hasItems(storedPermission)) {
      // console.log("module", storedPermission.map((item) => item.module_id));
    }
  }, [storedPermission]);

  // const getPermissionByModule = (module_id) => {
  //   return storedPermission.find((permissions) =>
  //     permissions.map((module) => module.module_id === module_id)
  //   );
  // };

  const getPermissionByModule = (module_id) => {
    return storedPermission.find((permission) => permission.module_id === module_id);
  };

  return {
    getPermissionByModule,
  };
};

export default usePermission;
