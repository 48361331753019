import React, { useState, useEffect } from 'react';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack, Modal } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BankAccount } from './accounts/bankAccount';
import { AccountsReceivable } from './accounts/accountsReceivable';
import { OtherAccountAsset } from './accounts/otherAccountAsset';
import { FixedAsset } from './accounts/fixedAsset';
import { OtherAsset } from './accounts/otherAsset';
import { AccountsPayable } from './accounts/accountsPayable';
import { CreditCard } from './accounts/creditCard';
import { OtherCurrentLiability } from './accounts/otherCurrentLiability';
import { LongTermLiability } from './accounts/longTermLiability';
import { EquityAccount } from './accounts/equity';
import { IncomeAccount } from './accounts/income';
import { CostOfGoodsSold } from './accounts/costOfGoodsSold';
import { ExpenseAccount } from "./accounts/expense";
import { OtherIncome } from './accounts/otherIncome';
import { OtherExpense } from './accounts/otherExpense';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../utils/env_config';
import data from './currency.json';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { SampleAccount } from './accounts/SampleAccount';

const AddAccounts = () => {

    const [accountTypeValue, setAccountTypeValue] = useState('Bank Account');
    const [oneAccountData, setOneAccountData] = useState([]);
    const [allAccountData, setAllAccountData] = useState([]);
    const [structuredAccountData, setStructuredAccountData] = useState([]);
    const [structuredAccountDD, setStructuredAccountDD] = useState([]);
    const [dropdownAccountData, setDropdownAccountData] = useState([]);
    const [dropdownAccountCode, setDropdownAccountCode] = useState('');
    const [allCurrencyData, setAllCurrencyData] = useState([]);
    const [subAccountCheck, setSubAccountCheck] = useState(true);
    const [subAccount, setSubAccount] = useState('');

    async function fetchAllAccountData() {
        await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts`, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'x-api-key': FINANCE_API_KEY, // Example header
                // 'Authorization': 'Bearer your-access-token' // Example header
                // Add more headers as needed
            },
        })
            .then(response => response.json())
            .then(data => {
                setAllAccountData(data.all_accounts_data)
            })
            .catch(error => {
                console.log('Error in fetching Data!');
                console.log(error);
            });
        // const response = await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts`);
        // const data = await response.json();
        // setAllAccountData(data.all_accounts_data)
    }

    async function fetchStructuredAccountData() {
        await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts_structured`, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'x-api-key': FINANCE_API_KEY, // Example header
                // 'Authorization': 'Bearer your-access-token' // Example header
                // Add more headers as needed
            },
        })
            .then(response => response.json())
            .then(data => {
                setStructuredAccountData(data.all_accounts_data)
            })
            .catch(error => {
                console.log('Error in fetching Data!');
                console.log(error);
            });
        // const response = await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts_structured`);
        // const data = await response.json();
        // setStructuredAccountData(data.all_accounts_data)
    }

    const accountType = [
        'Bank Account',
        'Accounts Receivable',
        'Other Account Asset',
        'Fixed Asset',
        'Other Asset',
        'Accounts Payable',
        'Credit Card',
        'Other Current Liability',
        'Long Term Liability',
        'Equity',
        'Income',
        'Cost of Goods Sold',
        'Expense',
        'Other Income',
        'Other Expense',
        'Sample Account',
    ]

    const onChangeSelect = (e) => {
        setDropdownAccountData([])
        setStructuredAccountDD([])
        setAccountTypeValue(e.target.value)
        setSubAccount('')
        setdropDownValue('Click Here to Select Account')
        setSubAccountCheck(true)
    }

    const onSubChangeSelect = (e) => {
        if (document.getElementById("sub").checked === true) {
            // console.log('hello');
            setSubAccountCheck(false)
        } else {
            setSubAccountCheck(true)
            setSubAccount('')
            setdropDownValue('Click Here to Select Account')
            setDropdownAccountCode('')
        }
    }

    const PaddingIcon = (e) => {
        if (e > 0) {
            return (<i className="bi bi-arrow-return-right">&nbsp;&nbsp;</i>)
        } else {
            return ''
        }
    }
    const AccountList = ({ accounts, level = 0 }) => {
        return (
            <div>
                {accounts.map((account) => (
                    <ul key={account.account_id} style={{ cursor: 'pointer' }}>
                        <li>
                            <Stack direction='horizontal' gap={4} onClick={() => { changeValue(account.account_name, account.account_id, account.account_code) }}>
                                <span style={{ width: '50%', paddingLeft: level * 10 }}>{PaddingIcon(level)}{account.account_code} - {account.account_name}</span>
                                {/* <span style={{ width: '20%' }}>{account.account_name}</span> */}
                                <span style={{ width: '25%' }}>{account.account_type}</span>
                                <span style={{ width: '25%' }}>{account.currency}</span>
                            </Stack>
                        </li>
                        {account.sub_accounts && (
                            <AccountList accounts={account.sub_accounts} level={level + 1} />
                        )}
                    </ul>
                ))}
            </div>
        );
    };

    useEffect(() => {
        //   console.log(dropdownAccountData);
    }, [dropdownAccountData])

    useEffect(() => {
        //   console.table(allCurrencyData);
    }, [allCurrencyData])

    useEffect(() => {
        setAllCurrencyData(data)
        // fetchAllAccountData();
        fetchStructuredAccountData();
    }, [])

    useEffect(() => {
        // console.log(oneAccountData);
    }, [oneAccountData])

    useEffect(() => {
        // console.log('subAccount',subAccount);
    }, [subAccount])

    useEffect(() => {
        // console.log(structuredAccountDD);
        try {
            if (structuredAccountDD.length > 0) {
                setIsEmpty(false)
                setdropDownValue('Click Here to Select Account')
            } else {
                setIsEmpty(true)
                setdropDownValue('No Account Data for ' + accountTypeValue)
            }
        } catch (err) { }
    }, [structuredAccountDD])

    useEffect(() => {
        // console.log(structuredAccountData);
        try {
            if (structuredAccountData.length > 0) {
                structuredAccountData.forEach(element => {
                    if (element.account_type === accountTypeValue) {
                        setStructuredAccountDD(structuredAccountDD => [...structuredAccountDD, element])
                    }
                });
            }
        } catch (err) { }
    }, [structuredAccountData])

    useEffect(() => {
        // console.log(accountTypeValue);
        // if (oneAccountData.length > 0) {
        //     oneAccountData.forEach(element => {
        //         if (element.account_type === accountTypeValue) {
        //             setDropdownAccountData(dropdownAccountData => [...dropdownAccountData, element])
        //         }
        //     });
        // }
        if (structuredAccountData.length > 0) {
            structuredAccountData.forEach(element => {
                if (element.account_type === accountTypeValue) {
                    setStructuredAccountDD(structuredAccountDD => [...structuredAccountDD, element])
                }
            });
        }
    }, [accountTypeValue])

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [dropDownValue, setdropDownValue] = useState('Click Here to Select Account')

    const changeValue = (account_name, account_id, account_code) => {
        setdropDownValue(account_code + ' - ' + account_name)
        setDropdownAccountCode(account_code)
        setShowAccountList(false)
        setSubAccount(account_id)
    }

    const showModal = () => {
        if (isEmpty === false) {
            setShowAccountList(true)
        } else {
            alert('No Data')
        }
    }

    const [showAccountList, setShowAccountList] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const handleClose = () => { setShowAccountList(false) }

    return (
        <FormContainer>
            <FormAction>
                <Stack direction="horizontal" gap={2}>
                    <div>
                        <Breadcrumb separator="" items={[
                            {
                                title: 'Location:',
                            },
                            {
                                title: <Link style={{ color: '#909090' }} to="../account_center">Home</Link>,
                            },
                            {
                                type: 'separator',
                            },
                            {
                                title: <Link style={{ color: '#909090' }} to="../account_center/view_all_account">Account List</Link>,
                            },
                            {
                                type: 'separator',
                            },
                            {
                                title: 'New Account',
                            }
                        ]} />
                    </div>
                </Stack>
            </FormAction>
            <FormContent>
                <Modal show={showAccountList} scrollable={true} onHide={handleClose} size="xl" >
                    <Modal.Body>
                        <Stack>
                            <div className="mb-3">
                                <AccountList accounts={structuredAccountDD} />
                            </div>
                        </Stack>
                    </Modal.Body>
                </Modal>
                <FormTitle><p className='text-uppercase'>Add New Account</p></FormTitle>
                <FormInfoFull>
                    <Stack>
                        <div className='mb-3'>
                            <select className='form-select' name='account_type' id='account_type' onChange={onChangeSelect}>
                                {
                                    accountType.map((e, i) => (
                                        <option key={i} value={e}>{e}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='mb-3'>
                            <Stack direction='horizontal' gap={2}>
                                <div>
                                    <input type='checkbox' name='sub' id='sub' checked={!subAccountCheck} onChange={() => { onSubChangeSelect(); setSubAccount(!subAccountCheck) }} disabled={isEmpty} /> <span>Subaccount of</span>
                                </div>
                                <div>
                                    <button className='btn btn-sm btn-outline-secondary' onClick={showModal} disabled={subAccountCheck}>{dropDownValue} </button>
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                </FormInfoFull>
                {/* Bank Account */}
                {
                    accountTypeValue === 'Bank Account' && (
                        <BankAccount subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Accounts Receivable */}
                {
                    accountTypeValue === 'Accounts Receivable' && (
                        <AccountsReceivable subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Sample Account */}
                {
                    accountTypeValue === 'Sample Account' && (
                        <SampleAccount subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Other Account Asset */}
                {
                    accountTypeValue === 'Other Account Asset' && (
                        <OtherAccountAsset subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Fixed Asset */}
                {
                    accountTypeValue === 'Fixed Asset' && (
                        <FixedAsset subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Other Asset */}
                {
                    accountTypeValue === 'Other Asset' && (
                        <OtherAsset subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Accounts Payable */}
                {
                    accountTypeValue === 'Accounts Payable' && (
                        <AccountsPayable subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Credit Card */}
                {
                    accountTypeValue === 'Credit Card' && (
                        <CreditCard subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Other Current Liability */}
                {
                    accountTypeValue === 'Other Current Liability' && (
                        <OtherCurrentLiability subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Long Term Liability */}
                {
                    accountTypeValue === 'Long Term Liability' && (
                        <LongTermLiability subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Equity */}
                {
                    accountTypeValue === 'Equity' && (
                        <EquityAccount subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Income */}
                {
                    accountTypeValue === 'Income' && (
                        <IncomeAccount subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Cost of Goods Sold */}
                {
                    accountTypeValue === 'Cost of Goods Sold' && (
                        <CostOfGoodsSold subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Expense */}
                {
                    accountTypeValue === 'Expense' && (
                        <ExpenseAccount subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Other Income */}
                {
                    accountTypeValue === 'Other Income' && (
                        <OtherIncome subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
                {/* Other Expense */}
                {
                    accountTypeValue === 'Other Expense' && (
                        <OtherExpense subAccount={subAccount} allCurrencyData={allCurrencyData} oneAccountData={oneAccountData} dropdownAccountCode={dropdownAccountCode} />
                    )
                }
            </FormContent>
        </FormContainer>
    )
}

export default AddAccounts;