import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, TwoDecimalNum, hasItems } from '../../../utils/functions';


const ViewAllJV = () => {

  const [allJVData, setAllJVData] = useState([]);
  const [combine, setCombine] = useState([]);

  async function getAllJV() {
    await axios({
      url: `${FINANCE_LINK}/api/journal_voucher/get_all_jv`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then((response) => {
        setAllJVData(response.data['all_jv']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All JV!');
      });
  };

  const columns = [
    {
      data: 'jv_id',
      text: 'JV ID',
      render: function (data, type) {
        return `<a href='/journal_voucher/view_jv/${data}'>${data}</a>`;
      }
    },
    {
      data: 'jv_date',
      text: 'JV Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }

      }
    },
    {
      data: 'total_amount',
      text: 'Amount',
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00'
        } else {
          return NumberToPhp(data)
          // return data
        }
      }
    },
    // {
    // 	data: 'supplier_id',
    // 	text: 'Supplier ID',
    // 	render: function(data, type, row) {
    // 		if (data === null || data === '') {
    // 			if (row['cv_type'] === 'Credit') {
    // 				return 'Credit (No Supplier)';
    // 			} else {
    // 				return 'RFP (No Supplier)';
    // 			}
    // 		} else {
    // 			return `<a target='_blank' href='/suppliers/view_supplier/${data}'>${data}</a>`;
    // 		}
    // 	}
    // },
    // {
    // 	data: 'particulars',
    // 	text: 'Memo',
    // },
    // {
    // 	data: 'total_amount_paid',
    // 	text: 'Amount',
    // 	render: function(data, type) {
    // 		if (data === null || isNaN(data)) {
    // 			return '₱ 0.00';
    // 		} else {
    // 			return NumberToPhp(data);
    // 		}
    // 	}
    // },
  ];

  useEffect(() => {
    getAllJV();
  }, [])

  useEffect(() => {
    // console.log('combine',combine);
  }, [combine])


  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#TableJVData")) {
      $("#TableJVData").DataTable({
        responsive: true,
        data: allJVData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -1,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    } else {
      $("#TableJVData").DataTable().destroy();
      $("#TableJVData").DataTable({
        responsive: true,
        data: allJVData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -1,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    }
  }, [allJVData]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal' gap={3}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: 'Journal Voucher List'
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Journal Voucher (JV) List</p></FormTitle>
        <FormInfoFull>
          <div className="container-fluid" rel="noopener noreferrer">
            <table id="TableJVData" className="display">
              <thead>
                <tr>
                  <th>JV ID</th>
                  <th>JV Date</th>
                  <th>Amount</th>
                  {/* <th>Amount</th>
									<th>Memo</th>
									<th>Amount</th> */}
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <Link className='btn btn-success btn-sm' to='/journal_voucher/addjv' >Add JV</Link>
          </div>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllJV;
