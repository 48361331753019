import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { NumberToPhp } from "../../../utils/functions";


const ViewAllPR = () => {
  const [dataPR, setDataPR] = useState();
  // const [supID, setSupID] = useState([{}]);
  // const [supName, setSupName] = useState([{}]);
  //   const [tableData, setTableData] = useState([]);

  const columns = [
    {
      data: "pr_id",
      text: "PR no.",
      render: function (data, type, row) {
        return `<a href='/purchase_requests/view_pr/${row['pr_id']}'>${data}</a>`;
      }
    },
    {
      data: "pr_date",
      text: "PR date",
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: "supplier_name",
      text: "Supplier Name",
      render: function (data, type, row) {
        return `<a href='/suppliers/view_supplier/${row['supplier_id']}'>${data}</a>`;
      }
    },
    {
      data: "notes",
      text: "Notes"
    },
    {
      data: "grand_total",
      text: "Amount",
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00';
        } else {
          return NumberToPhp(data);
        }
      }
    },
    {
      data: "is_approved",
      text: "Approval Status",
      render: function (data, type) {
        if (data === true) {
          return 'Approved'
        } else if (data === false) {
          return 'Rejected'
        } else {
          return 'Pending'
        }
      }
    },
    {
      data: "is_active",
      text: "Active Status",
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    }
  ];

  const getAllItems = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/purchase_request/get_all_pr`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then((response) => {
        setDataPR(response.data['pr_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#PRTable")) {
      // Initialize the DataTable plugin
      $("#PRTable").DataTable({
        data: dataPR,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -3,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    } else {
      $("#PRTable").DataTable().destroy();
      $("#PRTable").DataTable({
        data: dataPR,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -3,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    }
  }, [dataPR]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_requests/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'PR List',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className="text-uppercase">Purchase Requests List</p></FormTitle>
        <FormInfoFull>
          <Stack gap={2}>
            <div>
              <table id="PRTable" className="display" >
                <thead>
                  {<tr>
                    <th>PR no.</th>
                    <th>PR date</th>
                    <th>Supplier Name</th>
                    <th>Notes</th>
                    <th>Amount</th>
                    <th>Approval Status</th>
                    <th>Active Status</th>
                  </tr>}
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div>
              <Link to={'/purchase_requests/create'} className="btn btn-sm btn-success">ADD</Link>
            </div>
          </Stack>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllPR;