import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Col, Row, InputGroup, Stack } from 'react-bootstrap';
import { TextareaAutosize } from '@mui/material';
import { NumberToPhp, amountToWords, toTitleCase, swalWithBootstrapButtons, Required, hasItems } from '../../../utils/functions';
import { FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { MinusCircleOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { Switch } from 'antd';
import CurrencyInput from 'react-currency-input-field';

const MySwal = withReactContent(Swal)

const RFPForm = ({ editView, rfpID, rfpOneData }) => {

  var someDate = new Date();
  var dateNow = someDate.setDate(someDate.getDate());
  var dateToday = new Date(dateNow).toISOString().split("T")[0];

  const [SupName, setSupName] = useState('');
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [editViewText, setEditViewText] = useState('Add new Request for Payment (RFP)');
  const [editMode, setEditMode] = useState(false);
  const [isActiveTrue, setIsActiveTrue] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState('');
  const [validated, setValidated] = useState(false);
  const [isPO, setIsPO] = useState(false);
  const [is2M, setIs2M] = useState(false);

  const defaultRFPData = {
    payee: '',
    date_requested: dateToday,
    date_needed: dateToday,
    particulars_total_amount: 0,
    amount_in_words: 0,
    prepared_by: 'Jake Landwalker (Auto Change to User)',
    approved_by: 'Citry Pio (Temporary)',
    approved_by2: 'Spencer Ryss R. Tagud',
    po_id: '',
    particulars: '',
  }

  const defaultParticulars = {
    particulars_date: dateToday,
    particulars_activity: "",
    particulars_description: "",
    particulars_amount: 0
  }

  const [submitData, setSubmitData] = useState(defaultRFPData);

  const [serviceList, setServiceList] = useState([defaultParticulars]);

  const getallSuppliers = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_all_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data['suppliers_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
    // setSubmitData({ ...submitData, particulars: JSON.stringify(serviceList) });
  };

  const handleRFPRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleRFPAdd = () => {
    setServiceList([...serviceList, { particulars_date: dateToday, particulars_activity: "", particulars_description: "", particulars_amount: 0 }]);
  };

  const onChangeNumber = (e, index) => {
    const name = 'particulars_amount';
    const list = [...serviceList];
    list[index][name] = e;
    setServiceList(list);
  }

  const onChange = (e) => {
    // setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    if (e.target.name === 'particulars_date' || e.target.name === 'particulars_activity' || e.target.name === 'particulars_description' || e.target.name === 'particulars_amount') {
      setSubmitData({ ...submitData, particulars: JSON.stringify(serviceList) });
    } else {
      setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    }
    // console.log(submitData)
  };

  const addRFPPost = (event) => {

    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'Are you sure you want to Submit?',
        text: "You won't be able to revert this!",
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Submit',
        // cancelButtonText: 'Cancel',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((res) => {
        // console.log(submitData)
        if (res.isConfirmed) {
          MySwal.fire({
            title: <p>Creating RFP</p>,
            didOpen: () => {
              MySwal.showLoading()
            },
          })
          axios({
            url: `${FINANCE_LINK}/api/rfp/create_rfp`,
            data: submitData,
            method: 'POST',
            headers: {
              'x-api-key': FINANCE_API_KEY
            },
          }).then((res) => {
            console.log(res.data)
            return MySwal.fire({
              title: <strong>RFP successfully created</strong>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })

            // Push to /
            // navigate('/');
          })
            .catch((err) => {
              console.log(err)
              // console.log(submitData)
              console.log('Error in Adding RFP!');
            });
        }
      })
      // this.onCancel() 
    } else {
      setValidated(true);
      MySwal.fire({
        title: 'Please Fill out Required Fields',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }
  }

  const UpdateRFPPost = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'All updates will be saved, continue?',
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Continue',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((res) => {
        if (res.isConfirmed) {
          MySwal.fire({
            title: <p>Updating RFP</p>,
            allowOutsideClick: false,
            didOpen: () => {
              // `MySwal` is a subclass of `Swal` with all the same instance & static methods
              MySwal.showLoading()
            },
          })
          axios({
            url: `${FINANCE_LINK}/api/rfp/update_rfp`,
            data: submitData,
            method: 'PUT',
            headers: {
              'x-api-key': FINANCE_API_KEY,
              'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            },
          }).then((res) => {
            // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
            console.log(res.data)
            return MySwal.fire({
              title: <strong>RFP Updated Successfully</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })
          })
            .catch((err) => {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            });
        }
      })
    } else {
      setValidated(true);
      MySwal.fire({
        title: 'Please Fill out Required Fields',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }
  }

  const updateActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this RFP, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${FINANCE_LINK}/api/submitData/change_rfp_active_status`,
          params: { rfp_id: rfpID },
          method: 'PUT',
          headers: {
            'x-api-key': FINANCE_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            console.log(err)
            console.log('Error in Updating RFP!');
          });
      }
    })
  }

  const onClickEdit = () => {
    setEditMode(true);
    setEditViewText('Edit Request For Payment (RFP)');
  }

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        setEditViewText('View Request For Payment (RFP)');
        setEditMode(false);
        setSubmitData({
          rfp_id: rfpOneData.rfp_id,
          payee: rfpOneData.payee,
          date_requested: (rfpOneData.date_requested).toString().split("T")[0],
          date_needed: (rfpOneData.date_needed).toString().split("T")[0],
          particulars_date: rfpOneData.particulars_date ? rfpOneData.particulars_date : '',
          particulars_activity: rfpOneData.particulars_activity ? rfpOneData.particulars_activity : '',
          particulars_amount: rfpOneData.particulars_amount ? rfpOneData.particulars_amount : '',
          particulars_total_amount: rfpOneData.particulars_total_amount,
          amount_in_words: toTitleCase(rfpOneData.amount_in_words),
          prepared_by: rfpOneData.prepared_by,
          approved_by: rfpOneData.approved_by,
          po_id: rfpOneData.po_id,
          is_approved: rfpOneData.is_approved,
          created_by: rfpOneData.created_by,
          created_date: (rfpOneData.created_date).toString().split("T")[0],
          is_active: rfpOneData.is_active,
          particulars: rfpOneData.particulars,
          supplier_id: rfpOneData.supplier_id
        });
        setServiceList(JSON.parse(rfpOneData.particulars));
        // window.location.reload();
      }
    })
  };

  function totalCompute() {
    if (hasItems(serviceList)) {
      const totalamountlocalF = (serviceList.reduce((total, { particulars_amount }) => total + parseFloat(particulars_amount), 0));
      const totalamountlocal = Math.round(totalamountlocalF * 100) / 100;
      const totalnumlocal = NumberToPhp(totalamountlocal);
      if (isNaN(totalamountlocal)) {
        totalnumlocal = NumberToPhp(0);
      }
      setSubmitData({ ...submitData, particulars_total_amount: totalamountlocal, amount_in_words: toTitleCase(amountToWords(totalamountlocal)) });
    }
  }

  const clickApprove = () => {
    swalWithBootstrapButtons.fire({
      title: 'RFP will be Approved, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Approving RFP</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${FINANCE_LINK}/api/rfp/approve`,
          params: { rfp_id: rfpID },
          method: 'PUT',
          headers: {
            'x-api-key': FINANCE_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Approving RFP</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Approving RFP</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  }

  const clickReject = () => {
    swalWithBootstrapButtons.fire({
      title: 'RFP will be Rejected, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Rejecting RFP</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${FINANCE_LINK}/api/rfp/reject`,
          params: { rfp_id: rfpID },
          method: 'PUT',
          headers: {
            'x-api-key': FINANCE_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Rejecting RFP</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Rejecting RFP</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  }

  useEffect(() => {
    if (hasItems(allSuppliers)) {
      allSuppliers.forEach(e => {
        if (submitData.payee === e.supplier_id) {
          setSupName(e.supplier_name);
        }
      });
    }
  }, [allSuppliers])

  useEffect(() => {
    setSubmitData({ ...submitData, particulars: JSON.stringify(serviceList) });
    totalCompute();
    // if (hasItems(serviceList)) {
    // }
  }, [serviceList])

  useEffect(() => {
    // console.log('submitData',submitData)
  }, [submitData])

  useEffect(() => {
    setSubmitData({ ...submitData, particulars: JSON.stringify(serviceList) });
    if (submitData.particulars_total_amount > 1999999) {
      setIs2M(true);
    } else {
      setIs2M(false);
    }
  }, [submitData.particulars_total_amount])

  useEffect(() => {
    if (editView) {
      setEditViewText('View Request For Payment (RFP)');
    }
  }, [editView])

  useEffect(() => {
    if (hasItems(rfpOneData)) {
      if (rfpOneData.is_active) {
        setIsActiveTrue(true);
      }
      if (rfpOneData.is_approved === true) {
        setApprovalStatus('Approved');
      } else if (rfpOneData.is_approved === false) {
        setApprovalStatus('Rejected');
      } else {
        setApprovalStatus('');
      }
      setSubmitData({
        rfp_id: rfpOneData.rfp_id,
        payee: rfpOneData.payee,
        date_requested: (rfpOneData.date_requested).toString().split("T")[0],
        date_needed: (rfpOneData.date_needed).toString().split("T")[0],
        particulars_date: rfpOneData.particulars_date ? rfpOneData.particulars_date : '',
        particulars_activity: rfpOneData.particulars_activity ? rfpOneData.particulars_activity : '',
        particulars_amount: rfpOneData.particulars_amount ? rfpOneData.particulars_amount : '',
        particulars_total_amount: rfpOneData.particulars_total_amount,
        amount_in_words: rfpOneData.amount_in_words,
        prepared_by: rfpOneData.prepared_by,
        approved_by: rfpOneData.approved_by,
        po_id: rfpOneData.po_id,
        is_approved: rfpOneData.is_approved,
        created_by: rfpOneData.created_by,
        created_date: (rfpOneData.created_date).toString().split("T")[0],
        is_active: rfpOneData.is_active,
        particulars: rfpOneData.particulars,
        supplier_id: rfpOneData.supplier_id
      });
      setServiceList(JSON.parse(rfpOneData.particulars));
      if (rfpOneData.po_id) {
        getallSuppliers();
        setIsPO(true);
      }
    }
  }, [rfpOneData])

  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{editViewText}</p></FormTitle>
      <FormInfoFull>
        <Form noValidate validated={validated} onSubmit={editView ? UpdateRFPPost : addRFPPost} id="RFPFormPost" name="RFPFormPost">
          {
            editView ? (
              <Row className="mb-3">
                <Stack gap={2}>
                  <div>
                    <Stack direction='horizontal'>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>RFP ID: {rfpID}</span>
                      </div>
                      <div className='ms-auto'>
                        <a type='button' className="btn btn-primary btn-sm" href={`${FINANCE_LINK}/api/submitData/print_rfp?rfp_id=${rfpID}`} >
                          <PrinterOutlined style={{ verticalAlign: 'middle' }} /> PRINT
                        </a>
                      </div>
                    </Stack>
                  </div>
                  <div>
                    Status: &nbsp;
                    <Switch
                      style={{ backgroundColor: isActiveTrue ? '#237804' : '#f5222d' }}
                      checkedChildren={'Active'}
                      unCheckedChildren={'Inactive'}
                      checked={isActiveTrue}
                      onClick={updateActive}
                      size="small"
                    />
                  </div>
                </Stack>
              </Row>
            ) : ""
          }
          <Row>
            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Payee</Form.Label><Required />
              <InputGroup size="sm" hasValidation>
                {
                  isPO ? (
                    <a target='_blank' href={'/suppliers/view_supplier/' + submitData.payee}>{SupName}</a>
                  ) : (
                    <Form.Control type="text" value={submitData.payee} placeholder="Full Name" name="payee" onChange={onChange} required disabled={editView ? !editMode : false} plaintext={editView ? !editMode : false} />
                  )
                }
                <Form.Control.Feedback tooltip type="invalid">
                  Please fill up Payee Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Date Requested</Form.Label>
              <InputGroup size="sm" hasValidation>
                <Form.Control type="date" value={submitData.date_requested} name="date_requested" onChange={onChange} disabled plaintext />
                <Form.Control.Feedback tooltip type="invalid">
                  Please fill up Date.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Date Needed</Form.Label><Required />
              <InputGroup size="sm" hasValidation>
                <Form.Control type="date" value={submitData.date_needed} name="date_needed" min={dateToday} onChange={onChange} required disabled={editView ? !editMode : false} plaintext={editView ? !editMode : false} />
                <Form.Control.Feedback tooltip type="invalid">
                  Please fill up Date.
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Stack direction="horizontal" gap={2} className='border-top border-2' hidden={editView ? !editMode : false}>
            <div><p className='fw-bolder text-uppercase'>Particulars</p></div>
            <div className='ms-auto'>
              <button type="button" onClick={handleRFPAdd} className='btn btn-success btn-sm'>
                <PlusOutlined style={{ verticalAlign: 'middle' }} />
              </button>
            </div>
          </Stack>
          {
            hasItems(serviceList) ? (
              <div className="form-field mb-3 border-bottom border-2">
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className=' col-md-2'>Transaction Date</th>
                      <th scope="col" className=' col-md-4'>Activity</th>
                      <th scope="col" className=' col-md-4'>Particulars</th>
                      <th scope="col" className=' col-md-2'>Amount</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody className="first-division" htmlFor="service">
                    {
                      serviceList.map((singleService, index) => (
                        <tr key={index} className="services">
                          <td>
                            <Form.Group className="mb-3" controlId="particulars_date">
                              <InputGroup size="sm" hasValidation>
                                <Form.Control type="date" value={singleService.particulars_date} name="particulars_date" min={"2022-01-01"} aria-describedby="inputGroupPrepend" onChange={(e) => { handleServiceChange(e, index); onChange(e) }} required disabled={editView ? !editMode : false} plaintext={editView ? !editMode : false} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Date.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group className="mb-3" controlId="particulars_activity">
                              <InputGroup size="sm" hasValidation>
                                {
                                  editView ? (
                                    editMode ? (
                                      <Form.Control as={TextareaAutosize} rows={1} minRows={1} maxRows={3} value={singleService.particulars_activity} onChange={(e) => { handleServiceChange(e, index); onChange(e) }} name="particulars_activity" aria-describedby="inputGroupPrepend" required />
                                    ) : (
                                      <div className='text-wrap'>{singleService.particulars_activity}</div>
                                    )
                                  ) : (
                                    <Form.Control as={TextareaAutosize} rows={1} minRows={1} maxRows={3} value={singleService.particulars_activity} onChange={(e) => { handleServiceChange(e, index); onChange(e) }} name="particulars_activity" aria-describedby="inputGroupPrepend" required />
                                  )
                                }
                                <Form.Control.Feedback tooltip type="invalid">
                                  Required.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group className="mb-3" controlId="particulars_description">
                              <InputGroup size="sm" hasValidation>
                                {
                                  editView ? (
                                    editMode ? (
                                      <Form.Control as={TextareaAutosize} rows={1} minRows={1} maxRows={3} value={singleService.particulars_description} onChange={(e) => { handleServiceChange(e, index); onChange(e) }} name="particulars_description" aria-describedby="inputGroupPrepend" required />
                                    ) : (
                                      <div className='text-wrap'>{singleService.particulars_description}</div>
                                    )
                                  ) : (
                                    <Form.Control as={TextareaAutosize} rows={1} minRows={1} maxRows={3} value={singleService.particulars_description} onChange={(e) => { handleServiceChange(e, index); onChange(e) }} name="particulars_description" aria-describedby="inputGroupPrepend" required />
                                  )
                                }
                                <Form.Control.Feedback tooltip type="invalid">
                                  Required.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group className="mb-3" controlId="particulars_amount">
                              <InputGroup size="sm" hasValidation>
                                <Form.Control as={CurrencyInput} plaintext={editView ? !editMode : false} decimalScale={2} decimalsLimit={2} value={singleService.particulars_amount} allowNegativeValue={false} prefix={'₱ '} min={0} step={0} aria-describedby="inputGroupPrepend" placeholder="0.00" name="particulars_amount" onValueChange={(e) => { onChangeNumber(e, index) }} disabled={editView ? !editMode : false} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Required.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group className="mb-3">
                              {serviceList.length !== 1 && (
                                <button type="button" onClick={() => handleRFPRemove(index)} className='btn btn-danger btn-sm' hidden={editView ? !editMode : false} >
                                  <MinusCircleOutlined style={{ verticalAlign: 'middle' }} />
                                </button>
                              )}
                            </Form.Group>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="form-field mb-3 border-bottom border-2">
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className=' col-md-2'>Date</th>
                      <th scope="col" className=' col-md-4'>Activity</th>
                      <th scope="col" className=' col-md-4'>Particulars</th>
                      <th scope="col" className=' col-md-2'>Amount</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody className="first-division" htmlFor="service">
                    <tr>
                      <td>
                        <Form.Control plaintext type="text" value={'Placeholder'} disabled />
                      </td>
                      <td>
                        <Form.Control plaintext type="text" value={'Placeholder'} disabled />
                      </td>
                      <td>
                        <Form.Control plaintext type="text" value={'Placeholder'} disabled />
                      </td>
                      <td>
                        <Form.Control plaintext type="text" value={'Placeholder'} disabled />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
          <Row className="mb-3">
            <Col className="mb-3 col-md-2">
              <Form.Label className='fw-bolder'>Total Amount</Form.Label>
              <InputGroup size="sm" hasValidation>
                <Form.Control plaintext type="text" value={NumberToPhp(submitData.particulars_total_amount)} name="total_amount" onChange={onChange} disabled />
              </InputGroup>
            </Col>
            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Amount in words</Form.Label>
              <Form.Control plaintext className='text-capitalize' type="text" value={submitData.amount_in_words} name="amount_in_words" onChange={onChange} disabled />
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Prepared By</Form.Label>
              <Form.Control plaintext type="text" value={submitData.prepared_by} name="prepared_by" disabled />
            </Col>

            <Col className="mb-3">
              <Form.Label className='fw-bolder'>Approved By</Form.Label>
              <InputGroup size="sm" hasValidation>
                <Form.Control plaintext type="text" name="approved_by" value={submitData.approved_by} disabled />
              </InputGroup>
            </Col>
            {
              is2M && (
                <Col className="mb-3">
                  <Form.Label className='fw-bolder'>Approved By</Form.Label>
                  <InputGroup size="sm" hasValidation>
                    <Form.Control plaintext type="text" name="approved_by2" value={submitData.approved_by2} disabled />
                  </InputGroup>
                </Col>
              )
            }

          </Row>
        </Form>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <a className="btn btn-danger btn-sm" onClick={handleClickRefresh} >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button className="btn btn-primary btn-sm" type='submit' form='RFPFormPost' onClick={UpdateRFPPost}>Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal' gap={3}>
                {
                  approvalStatus === '' ? (
                    <>
                      {
                        !isPO && (
                          <div>
                            <a className="btn btn-primary btn-sm" onClick={onClickEdit} >Edit</a>
                          </div>
                        )
                      }
                      <div className='ms-auto'>
                        <a className="btn btn-success btn-sm" onClick={clickApprove} >
                          Approve
                        </a>
                      </div>
                      <div>
                        <a className="btn btn-danger btn-sm" onClick={clickReject} >
                          Reject
                        </a>
                      </div>
                    </>
                  ) : (
                    <div>
                      <button className={approvalStatus === 'Approved' ? 'btn btn-success btn-sm' : 'btn btn-danger btn-sm'} disabled >
                        RFP is already {approvalStatus}
                      </button>
                    </div>
                  )
                }


              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button className="btn btn-primary btn-sm" type='submit' form='RFPFormPost'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );

};


export default RFPForm;