import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import JVForm from './JVForm';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../utils/env_config';


const MySwal = withReactContent(Swal)

const ViewJV = (props) => {

  const viewOnly = true;

  const { jvID } = useParams();
  const [oneJVData, setOneJVData] = useState([]);

  async function getOneJV() {
    await axios({
      url: `${FINANCE_LINK}/api/journal_voucher/get_jv?jv_id=${jvID}`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then((response) => {
        setOneJVData(response.data['jv_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching JV!');
      });
  };

  useEffect(() => {
    getOneJV();
  }, [])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal">
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../journal_voucher/view_all">Journal Voucher List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View Journal Voucher',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <JVForm oneJVData={oneJVData} viewOnly={viewOnly} jvID={jvID} />

    </FormContainer>
  );
};

export default ViewJV;