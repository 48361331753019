import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, Form, Col, Row, InputGroup, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FormContent, FormTitle, FormInfo, FormInfoFull } from '../../../formLayout/FormContainer'
import { Breadcrumb, Switch } from 'antd';
import { Required, numberFormating } from '../../../../utils/functions';
import CurrencyInput from 'react-currency-input-field';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../../utils/env_config';



const MySwal = withReactContent(Swal)

const OtherAccountAsset = ({ subAccount, oneAccountData, allCurrencyData, dropdownAccountCode, structuredAccountData, accountID, flatArr }) => {

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [counter, setCounter] = useState(false);

  const [accountArr, setAccountArr] = useState({
    account_name: '',
    account_nickname: '',
    account_code: '',
    bank_name: '',
    bank_type: '',
    bank_account_number: '',
    opening_balance: 0,
    current_balance: 0,
    currency: 'PHP',
  });

  const onChange = (e) => {
    setAccountArr({ ...accountArr, [e.target.name]: e.target.value, account_type: 'Other Account Asset' });
  };

  const onChangeNumber = (value) => {
    setAccountArr({ ...accountArr, opening_balance: value, current_balance: value, account_type: 'Other Account Asset' });
    // console.log(value);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const [hasID, sethasID] = useState(false)
  const [isViewPage, setisViewPage] = useState(false)
  const [isActiveTrue, setIsActiveTrue] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('₱');

  const updateActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this Account, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${FINANCE_LINK}/api/accounts_center/change_account_active_status`,
          params: { account_id: accountID },
          method: 'PUT',
          headers: {
            'x-api-key': FINANCE_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            MySwal.fire({
              title: 'Error in Updating Account',
              text: "Please try again",
              icon: 'warning',
              cancelButtonText: 'OK',
              footer: "Click anywhere outside the box to cancel",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              showCloseButton: false
            })
            console.log(err)
            // console.log('Error in Updating Account!');
          });
      }
    })
  }

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const [isSub, setIsSub] = useState(false)

  useEffect(() => {
    // console.log(flatArr);
    try {
      if (flatArr.length > 0) {
        flatArr.forEach(element => {
          if (element.account_id === accountID) {
            if (element.sub_accounts) {
              setIsSub(true)
            } else {
              setIsSub(false)
            }
          }
        });
      }
    } catch (err) { }
  }, [flatArr])

  const handleEdit = () => {
    sethasID(!hasID);
    if (isSub) {
      document.getElementById("sub").disabled = true
    } else {
      document.getElementById("sub").disabled = false
    }
  }

  useEffect(() => {
    //   console.log(subAccount);
    if (subAccount === '') {
      setAccountArr({ ...accountArr, is_sub_account: false, account_code: '' })
    } else {
      setAccountArr({ ...accountArr, is_sub_account: true, sub_account_of: subAccount, account_code: dropdownAccountCode })
    }
  }, [subAccount])

  useEffect(() => {
    // console.log(oneAccountData);
    try {
      if (oneAccountData.account_id) {
        setisViewPage(true)
        sethasID(true)
        // console.log(oneAccountData.account_name);
        if (oneAccountData.is_active === true) {
          setIsActiveTrue(true)
        } else {
          setIsActiveTrue(false)
        }
        setAccountArr(oneAccountData);
      } else {
        sethasID(false)
        setisViewPage(false)
      }
    } catch (err) { }
  }, [oneAccountData])

  useEffect(() => {
    //   console.log(accountArr);
  }, [accountArr]);

  const updateAccountPost = (e) => {

    const form = e.currentTarget;
    // console.log(accountArr)

    // e.preventDefault();


    if (form.checkValidity() === true) {

      swalWithBootstrapButtons.fire({
        title: 'All updates will be saved, continue?',
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Continue',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: <p>Updating Account</p>,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading()
            },
          })
          axios({
            url: `${FINANCE_LINK}/api/accounts_center/update_account`,
            data: accountArr,
            method: 'PUT',
            headers: {
              'x-api-key': FINANCE_API_KEY
            },
          }).then((res) => {
            return MySwal.fire({
              title: <strong>Account successfully updated</strong>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })
          })
            .catch((err) => {
              if (err.response.status === 450) {
                MySwal.fire({
                  title: 'Account Code must be Unique',
                  icon: 'warning',
                  cancelButtonText: 'OK',
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: true,
                  showConfirmButton: false,
                  showCloseButton: false
                })
              } else {
                console.log(err.response.status)
                console.log('Error in Adding Item!');
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled'
          )
        }
      })

    }
    e.preventDefault();
  };

  const addAccountPost = (e) => {

    const form = e.currentTarget;
    console.log(accountArr)

    // e.preventDefault();


    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'All updates will be saved, continue?',
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Continue',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: <p>Creating Account</p>,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading()
            },
          })
          axios({
            url: `${FINANCE_LINK}/api/accounts_center/add_account`,
            data: accountArr,
            method: 'POST',
            headers: {
              'x-api-key': FINANCE_API_KEY
            },
          }).then((res) => {
            console.log(res.data)
            return MySwal.fire({
              title: <strong>Account successfully added</strong>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })


            // Push to /
            // navigate('/');
          })
            .catch((err) => {
              if (err.response.status === 450) {
                MySwal.fire({
                  title: 'Account Code must be Unique',
                  icon: 'warning',
                  cancelButtonText: 'OK',
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: true,
                  showConfirmButton: false,
                  showCloseButton: false
                })
              } else {
                console.log(err.response.status)
                console.log('Error in Adding Item!');
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled'
          )
        }
      })
    }
    e.preventDefault();
  };

  const handleSubmit = (event) => {

    if (subAccount === '') {
      setAccountArr({
        ...accountArr,
        account_code: event.target.elements.account_code.value,
        account_name: event.target.elements.account_name.value,
        account_number: event.target.elements.account_number.value,
        description: event.target.elements.description.value,
        account_type: 'Other Account Asset',
        is_sub_account: false,
      });
    } else {
      setAccountArr({
        ...accountArr,
        account_code: event.target.elements.account_code.value,
        account_name: event.target.elements.account_name.value,
        account_number: event.target.elements.account_number.value,
        description: event.target.elements.description.value,
        account_type: 'Other Account Asset',
        is_sub_account: true,
        sub_account_of: subAccount,
      });
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      // event.stopPropagation();
      MySwal.fire({
        title: 'Please Fill out Required Fields',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }

    if (form.checkValidity() === true) {
      // event.preventDefault();
      // event.stopPropagation();
      setCounter(true);
      if (isViewPage) {
        updateAccountPost(event);
      } else {
        addAccountPost(event);
      }
    } else {

      setValidated(true);
      // console.log(rfp)
      if (isViewPage) {
        updateAccountPost(event);
      } else {
        addAccountPost(event);
      }

    }

  };

  useEffect(() => {
    // console.log(dropdownAccountCode);
    document.getElementById('account_code').value = dropdownAccountCode
  }, [dropdownAccountCode]);

  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>Other Account Asset</p></FormTitle>
      <FormInfoFull>
        <Form noValidate validated={validated} onSubmit={handleSubmit} name="AccountForm" id='AccountForm'>
          {
            hasID ? (
              <FormInfoFull>
                <Row>
                  <div className='mb-3'>
                    <span style={{ fontWeight: 'bold' }}>Account ID: {accountID}</span>
                  </div>
                </Row>
                <Row>
                  <Col className="col-md-12">
                    Status: &nbsp;
                    <Switch
                      style={{ backgroundColor: isActiveTrue ? '#237804' : '#f5222d' }}
                      checkedChildren={'Active'}
                      unCheckedChildren={'Inactive'}
                      checked={isActiveTrue}
                      onClick={updateActive}
                      size="small"
                    />
                  </Col>
                </Row>
              </FormInfoFull>
            ) : ""
          }
          <FormInfo>
            <Row>
              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_code">
                  <Form.Label column sm="5">Number<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="number" min={0} value={accountArr.account_code} disabled={hasID} placeholder="Enter Number" name="account_code" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_name">
                  <Form.Label column sm="5">Account Name<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" defaultValue={accountArr.account_name} disabled={hasID} placeholder="Enter Account Name" name="account_name" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="description">
                  <Form.Label column sm="5">Description<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" defaultValue={accountArr.description} disabled={hasID} placeholder="Enter Description" name="description" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Description.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </FormInfo>
          <FormInfo>
            <Row>
              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_number">
                  <Form.Label column sm="5">Account no.<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" defaultValue={accountArr.account_number} disabled={hasID} placeholder="Enter Account no." name="account_number" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account no..
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="opening_balance">
                  <Form.Label column sm="5">Opening Balance</Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      {
                        hasID ? (
                          <Form.Control type="text" defaultValue={selectedCurrency + numberFormating(accountArr.opening_balance)} disabled />
                        ) : (
                          <Form.Control as={CurrencyInput} decimalsLimit={2} defaultValue={accountArr.opening_balance} allowNegativeValue={false} prefix={selectedCurrency} step={0} aria-describedby="inputGroupPrepend" placeholder="0.00" name="opening_balance" onValueChange={(value) => onChangeNumber(value)} />
                        )
                      }

                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </FormInfo>
        </Form>
        <Stack direction='horizontal'>
          {
            hasID ? (
              <div>
                <button className='btn btn-sm btn-primary' onClick={handleEdit}>Edit</button>
              </div>
            ) : (
              <>
                <div hidden={!isViewPage}>
                  <button className={"btn btn-danger btn-sm"} type='button' onClick={handleClickRefresh}>Cancel</button>
                </div>
                <div className='ms-auto'>
                  <button className={"btn btn-success btn-sm"} type='submit' form='AccountForm'>Submit</button>
                </div>
              </>
            )
          }
        </Stack>
      </FormInfoFull>
    </FormContent>
  );
};

export { OtherAccountAsset }