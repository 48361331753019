// // import React, { useState } from 'react';
// // import { Link } from 'react-router-dom';
// // import axios from 'axios';

// // import Button from 'react-bootstrap/Button';

// // import { useNavigate } from 'react-router-dom';

// // const InvoiceHome = (props) => {
// //   // Define the state with useState hook
// //   const navigate = useNavigate();
// // //   const [book, setBook] = useState({
// // //     title: '',
// // //     isbn: '',
// // //     author: '',
// // //     description: '',
// // //     published_date: '',
// // //     publisher: '',
// // //   });

// // //   const onChange = (e) => {
// // //     setBook({ ...book, [e.target.name]: e.target.value });
// // //   };

// // //   const onSubmit = (e) => {
// // //     e.preventDefault();

// // //     axios
// // //       .post('http://localhost:8082/api/books', book)
// // //       .then((res) => {
// // //         setBook({
// // //           title: '',
// // //           isbn: '',
// // //           author: '',
// // //           description: '',
// // //           published_date: '',
// // //           publisher: '',
// // //         });

// // //         // Push to /
// // //         navigate('/');
// // //       })
// // //       .catch((err) => {
// // //         console.log('Error in CreateBook!');
// // //       });
// // //   };

// //   return (
// //     <div className='CreateBook'>
// //       <div className='container'>
// //         <div className='row'>
// //           {/* <div className='col-md-8 m-auto'>
// //             <br />
// //             <Link to='/' className='btn btn-outline-warning float-left'>
// //               Show BooK List
// //             </Link>
// //           </div> */}
// //           <div className='col-md-8 m-auto'>

// //             <a
// //             className="App-link display-4 text-center"
// //             href="/"
// //             rel="noopener noreferrer"
// //             >
// // 							<Button variant="primary" className='lead text-center'>Back to Home</Button>
// // 						</a>

// //             <h1 className='display-4 text-center'>Invoicing</h1>

// //             <a
// //             className="App-link"
// //             href="/invoice/create_invoice"
// //             rel="noopener noreferrer"
// //             >
// //               <Button variant="primary" className='lead text-center mt-4'>Add new Invoice</Button>
// //             </a>
// //             <br></br>
// //             <a
// //             className="App-link"
// //             href="/invoice/view_all"
// //             rel="noopener noreferrer"
// //             >
// //               <Button variant="primary" className='lead text-center mt-4'>View all Invoices</Button>
// //             </a>
// //             {/* <form noValidate onSubmit={onSubmit}>
// //               <div className='form-group'>
// //                 <input
// //                   type='text'
// //                   placeholder='Title of the Book'
// //                   name='title'
// //                   className='form-control'
// //                   value={book.title}
// //                   onChange={onChange}
// //                 />
// //               </div>
// //               <br />

// //               <div className='form-group'>
// //                 <input
// //                   type='text'
// //                   placeholder='ISBN'
// //                   name='isbn'
// //                   className='form-control'
// //                   value={book.isbn}
// //                   onChange={onChange}
// //                 />
// //               </div>

// //               <div className='form-group'>
// //                 <input
// //                   type='text'
// //                   placeholder='Author'
// //                   name='author'
// //                   className='form-control'
// //                   value={book.author}
// //                   onChange={onChange}
// //                 />
// //               </div>

// //               <div className='form-group'>
// //                 <input
// //                   type='text'
// //                   placeholder='Describe this book'
// //                   name='description'
// //                   className='form-control'
// //                   value={book.description}
// //                   onChange={onChange}
// //                 />
// //               </div>

// //               <div className='form-group'>
// //                 <input
// //                   type='date'
// //                   placeholder='published_date'
// //                   name='published_date'
// //                   className='form-control'
// //                   value={book.published_date}
// //                   onChange={onChange}
// //                 />
// //               </div>
// //               <div className='form-group'>
// //                 <input
// //                   type='text'
// //                   placeholder='Publisher of this Book'
// //                   name='publisher'
// //                   className='form-control'
// //                   value={book.publisher}
// //                   onChange={onChange}
// //                 />
// //               </div>

// //               <input
// //                 type='submit'
// //                 className='btn btn-outline-warning btn-block mt-4'
// //               />
// //             </form> */}
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default InvoiceHome;


// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFileInvoice, faCalendarAlt, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
// import './invoice.css';
// const InvoiceHome = () => {
//   return (
//     <div className="invoicing-container">
//       <h1>Invoicing</h1>
//       <div className="grid-container">
//         <div className="grid-item card-fill">
//           <FontAwesomeIcon className="invoice-home-icon" icon={faFileInvoice} size="3x" />
//           <p>Invoices</p>
//         </div>
//         <div className="grid-item ">
//           <FontAwesomeIcon className="invoice-home-icon" icon={faCalendarAlt} size="3x" />
//           <p>Calendar</p>
//         </div>
//         <div className="grid-item">
//           <FontAwesomeIcon className="invoice-home-icon" icon={faFileExcel} size="3x" />
//           <p>Export to Excel</p>
//         </div>
//         <div className="grid-item">
//           <FontAwesomeIcon className="invoice-home-icon" icon={faFilePdf} size="3x" />
//           <p>Export to PDF</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InvoiceHome;


import React from "react";
import { Link } from "react-router-dom";
import style from "./invoiceHome.module.css";

function InvoiceHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Invoicing Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/invoice/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View All Invoices
          </div>
        </Link>
        <Link to="/invoice/create_invoice" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Create Invoice
          </div>
        </Link>
        <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Invoice
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link>
      </div>
    </div>
  );
}

export default InvoiceHome;
