import React, { useEffect, useState } from 'react';
import { FormContent, FormInfoFull, FormTitle } from '../../../formLayout/FormContainer';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { Required, swalWithBootstrapButtons } from '../../../../utils/functions';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Switch } from 'antd';

const MySwal = withReactContent(Swal);

const ModuleForm = ({ moduleID, oneModuleData, editView }) => {

  const [moduleData, setModuleData] = useState({
    module_name: '',
  })

  const [validated, setValidated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState('Create Module');
  const [isActive, setIsActive] = useState(null);

  const fetchPostSubmit = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Creating Module</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/modules/add_module`,
          data: moduleData,
          method: 'POST',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async (res) => {
          // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
          console.log(res.data)
          await MySwal.fire({
            title: <strong>Module Created Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            if (err.response.status === 450) {
              MySwal.fire({
                title: 'Module Name must be Unique',
                icon: 'warning',
                cancelButtonText: 'OK',
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: false
              })
            } else {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            }
          });

      }
    })
  }

  const fetchPutUpdate = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    MySwal.fire({
      title: <p>Updating Module</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${AUTH_LINK}/api/modules/update_module`,
      data: moduleData,
      method: 'PUT',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    }).then(async (res) => {
      // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
      console.log(res.data)
      await MySwal.fire({
        title: <strong>Module Updated Successfully</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      });
      window.location.reload();
    })
      .catch((err) => {
        if (err.response.status === 450) {
          MySwal.fire({
            title: 'Module Name must be Unique',
            icon: 'warning',
            cancelButtonText: 'OK',
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: false
          })
        } else {
          console.log(err)
          return MySwal.fire({
            title: <strong>{err.response.data.message}</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'error'
          }).then(() => {
            MySwal.close();
          })
        }
      });
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this Module, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/modules/change_module_active_status`,
          params: { module_id: moduleID },
          method: 'PUT',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Updating Active/Inactive Status!</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const onChange = (e) => {
    setModuleData({
      ...moduleData,
      [e.target.name]: e.target.value
    })
  }

  const handleEditMode = () => {
    setEditMode(true);
    setEditText('Edit Module');
  }

  useEffect(() => {
    // console.log(moduleID);
  }, [moduleID])

  useEffect(() => {
    console.log(oneModuleData);
    try {
      if (oneModuleData.module_id) {
        if (oneModuleData.is_active) {
          setIsActive(true)
        } else {
          setIsActive(false)
        }
        setModuleData({
          ...moduleData,
          module_id: oneModuleData.module_id,
          module_name: oneModuleData.module_name,
        })
      }
    } catch (err) { }
  }, [oneModuleData])

  useEffect(() => {
    // console.log(editMode);
  }, [editMode])

  useEffect(() => {
    // console.log(moduleData);
  }, [moduleData])

  useEffect(() => {
    // console.log(editView);
    if (editView) {
      setEditText('View Module');
    }
  }, [editView])

  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{editText}</p></FormTitle>
      <FormInfoFull>
        <Form onSubmit={!editView ? fetchPostSubmit : fetchPutUpdate} name="ModulePostForm" id='ModulePostForm'>
          {
            editView && (
              <Stack className="mb-3" hidden={editMode}>
                <div>
                  Status: &nbsp;
                  <Switch
                    style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                    checkedChildren={'Active'}
                    unCheckedChildren={'Inactive'}
                    checked={isActive}
                    onClick={handleIsActive}
                    size="small"
                  />
                </div>
              </Stack>
            )
          }
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3" controlId="module_name">
              <Form.Label>Module Name <Required /></Form.Label>
              {
                !editView ? (
                  <Form.Control type="text" placeholder='Module Name' value={moduleData.module_name} name="module_name" onChange={onChange} required />
                ) : (
                  <Form.Control type="text" placeholder='Module Name' value={moduleData.module_name} name="module_name" onChange={onChange} disabled={!editMode} required />
                )
              }
            </Form.Group>
          </Row>
        </Form>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-danger' onClick={handleClickRefresh} >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button type='submit' className='btn btn-sm btn-primary' form='ModulePostForm'>Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-primary' onClick={handleEditMode} >Edit</a>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button type='submit' className='btn btn-sm btn-primary' form='ModulePostForm'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default ModuleForm;