import React from 'react';
import '../../static/fontawesome-free-6.2.1-web/css/fontawesome.css';
import '../../static/fontawesome-free-6.2.1-web/css/brands.css';
import '../../static/fontawesome-free-6.2.1-web/css/solid.css';
import '../../static/fontawesome-free-6.2.1-web/css/all.css';
const AuthLayout = props => {
  const { children } = props;

  return (
    <div>
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;