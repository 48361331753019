import React from "react";
import { Link } from "react-router-dom";
import style from "./ReportsHome.module.css";

function ReportsHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Reports Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/reports/accounts_receivable" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fa-solid fa-receipt"></i></p>
            Accounts Payable Report
          </div>
        </Link>
        <Link to="/invoice/create_invoice" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fa-solid fa-receipt"></i></p>
            Accounts Receivable Report
          </div>
        </Link>
        {/* <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Invoice
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default ReportsHome;
