import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Form from 'react-bootstrap/Form';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import { FormContent, FormInfo, FormInfoFull, FormTitle } from '../../../formLayout/FormContainer';
import { Required, hasItems, swalWithBootstrapButtons } from "../../../../utils/functions";
import { InputGroup, Stack } from 'react-bootstrap';
import { Switch } from 'antd';

const MySwal = withReactContent(Swal)

const UserForm = ({ editView, oneUserData, userID, UserToken }) => {

  const [editText, setEditText] = useState('Create New User Account');
  const [editMode, setEditMode] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [isActiveStatus, setisActiveStatus] = useState(false);

  const [userDataUpdate, setUserDataUpdate] = useState([]);
  const [userData, setUserData] = useState({
    firstname: '',
    middlename: '',
    username: '',
    lastname: '',
    email: '',
    jobTitle: '',
    mobileNumber: '',
    secondaryEmail: '',
    department: '',
    roleId: '',
    isEnabled: true,
  });

  const getAllRole = () => {
    axios({
      url: `${AUTH_LINK}/api/roles/get_all_roles`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
      },
    })
      .then((response) => {
        setRoleData(response.data['all_roles_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  const updateUserPost = (e) => {
    e.preventDefault();
    // console.log('userData',userData);
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating User</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/users/update_user?user_id=${userID}`,
          data: JSON.stringify(userDataUpdate),
          method: 'PUT',
          headers: {
            'x-api-key': AUTH_API_KEY,
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${UserToken}`,
          },
        }).then(async (res) => {
          // ReactDOM.findDOMNode(this.addsupplierForm).reset();
          console.log(res.data)
          return MySwal.fire({
            title: <strong>User Updated Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch(async (err) => {
            console.log(err)
            await MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            });
            MySwal.close();
          });
      }
    })
  }

  const addUserPost = (e) => {
    e.preventDefault();
    // console.log('userData',userData);
    MySwal.fire({
      title: <p>Creating User</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${AUTH_LINK}/api/users/add_user`,
      data: JSON.stringify(userData),
      method: 'POST',
      headers: {
        'x-api-key': AUTH_API_KEY,
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${UserToken}`,
      },
    }).then(async (res) => {
      // ReactDOM.findDOMNode(this.addsupplierForm).reset();
      // console.log(res.data)
      return MySwal.fire({
        title: <strong>User Created Successfully</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })
    })
      .catch(async (err) => {
        // console.log(err)
        await MySwal.fire({
          title: <strong>{err.response.data.message}</strong>,
          // html: <i>You clicked the button!</i>,
          icon: 'error'
        });
        MySwal.close();
      });
  }

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const onEditClick = () => {
    setEditMode(true);
    setEditText('Edit User Account');
  };

  const updateActive = (e) => {
    swalWithBootstrapButtons.fire({
      // title: 'You are updating the Active/Inactive Status for this User, continue?',
      text: "You are updating the Active/Inactive Status for this User, continue?",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        setUserDataUpdate({ ...userDataUpdate, isEnabled: !isActiveStatus });
        setisActiveStatus(!isActiveStatus);
      }
    })
  };

  const onChange = (e) => {
    if (e.target.type === 'file') {
      setUserData({ ...userData, [e.target.name]: e.target.files[0] });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      setUserDataUpdate({ ...userDataUpdate, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    getAllRole();
  }, [])

  useEffect(() => {
    // console.log('editView',editView);
    if (editView) {
      setEditText('View User Account')
    } else {
      setEditMode(true);
    }
  }, [editView])

  useEffect(() => {
    // console.log('oneUserData',oneUserData);
    if (hasItems(oneUserData)) {
      setUserData(oneUserData);
      if (oneUserData.isEnabled) {
        setisActiveStatus(true);
      } else {
        setisActiveStatus(false);
      }
      console.log('oneUserData', oneUserData);
    }
  }, [oneUserData])

  useEffect(() => {
    // console.log('userData',userData);
  }, [userData])

  useEffect(() => {
    // console.log('isActiveStatus',isActiveStatus);
    if (hasItems(oneUserData)) {
      if (isActiveStatus !== oneUserData.isEnabled) {
        MySwal.fire({
          title: <p>Updating the Active/Inactive Status of {oneUserData.firstname + ' ' + oneUserData.lastname}</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/users/update_user?user_id=${userID}`,
          data: JSON.stringify(userDataUpdate),
          method: 'PUT',
          headers: {
            'x-api-key': AUTH_API_KEY,
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${UserToken}`,
          },
        }).then(async (res) => {
          // ReactDOM.findDOMNode(this.addsupplierForm).reset();
          // console.log(res.data)
          return MySwal.fire({
            title: <strong>User Active/Inactive Status Updated Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch(async (err) => {
            // console.log(err)
            await MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            });
            MySwal.close();
          });
      }
    }
  }, [isActiveStatus])

  useEffect(() => {
    // console.log('userDataUpdate',userDataUpdate);
    if (hasItems(userDataUpdate)) {
      // console.log('userDataUpdate',userDataUpdate);
    }
  }, [userDataUpdate])

  useEffect(() => {
    // console.log('UserToken',UserToken);
  }, [UserToken])

  useEffect(() => {
    console.log('roleData', roleData);
  }, [roleData])



  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{editText}</p></FormTitle>
      <Form noValidate onSubmit={editView ? updateUserPost : addUserPost} name='UserManagementForm' id='UserManagementForm'>
        <FormInfoFull>
          {
            editView && (
              <Stack className="mb-3" gap={2}>
                <div>User ID: {userID}</div>
                <div>
                  Status: &nbsp;
                  <Switch
                    style={{ backgroundColor: isActiveStatus ? '#237804' : '#f5222d' }}
                    checkedChildren={'Active'}
                    unCheckedChildren={'Inactive'}
                    checked={isActiveStatus}
                    onClick={updateActive}
                    size="small"
                  />
                </div>
              </Stack>
            )
          }
          <Stack direction='horizontal' gap={2}>
            <div>
              <Form.Group controlId="user_name">
                <Form.Label>Full Name<Required /></Form.Label>
                <InputGroup>
                  <Form.Control aria-label="Enter First Name" value={userData.firstname} placeholder='Enter First Name' name="firstname" onChange={onChange} required disabled={!editMode} />
                  <Form.Control aria-label="Enter Middle Name" value={userData.middlename} placeholder='Enter Middle Name' name="middlename" onChange={onChange} disabled={!editMode} />
                  <Form.Control aria-label="Enter Last Name" value={userData.lastname} placeholder='Enter Last Name' name="lastname" onChange={onChange} required disabled={!editMode} />
                </InputGroup>
              </Form.Group>
            </div>
            {/* <div>
                        <Form.Group controlId="username">
                            <Form.Label>Username<Required /></Form.Label>
                            <InputGroup>
                                <Form.Control value={userData.firstname} placeholder='Enter Username' name="username" onChange={onChange} required disabled={!editMode} />
                            </InputGroup>
                        </Form.Group>
                    </div> */}
          </Stack>
        </FormInfoFull>
        <FormInfo>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Work Email Address (*@barapido.com)<Required /></Form.Label>
            <InputGroup hasValidation>
              <Form.Control type="email" value={userData.email} pattern='.+@barapido\.com' placeholder="Enter Work Email Address" name="email" onChange={onChange} required disabled={!editMode} />
              <Form.Control.Feedback tooltip type="invalid">
                Email has to be Work Email (@barapido.com).
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="jobTitle">
            <Form.Label>Job Title<Required /></Form.Label>
            <Form.Control type="text" value={userData.jobTitle} placeholder="Enter Job Title" name="jobTitle" onChange={onChange} required disabled={!editMode} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="mobileNumber">
            <Form.Label>Mobile Number<Required /></Form.Label>
            <Form.Control type="text" value={userData.mobileNumber} placeholder="Enter Secondary Mobile Number" name="mobileNumber" onChange={onChange} required disabled={!editMode} />
          </Form.Group>
        </FormInfo>
        <FormInfo>
          <Form.Group className="mb-3" controlId="secondaryEmail">
            <Form.Label>Secondary Email Address<Required /></Form.Label>
            <Form.Control type="email" value={userData.secondaryEmail} placeholder="Enter Secondary Email Address" name="secondaryEmail" onChange={onChange} required disabled={!editMode} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="department">
            <Form.Label>Department<Required /></Form.Label>
            <Form.Control type="text" value={userData.department} placeholder="Enter Department" name="department" onChange={onChange} required disabled={!editMode} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="roleId">
            <Form.Label>Role<Required /></Form.Label>
            <Form.Select aria-label="Default select example" value={userData.roleId} name="roleId" onChange={onChange} required disabled={!editMode} >
              <option value='' >Select Role</option>
              {
                roleData.map((e, i) => (
                  <option key={i} value={e.role_id}>{e.role_name}</option>
                ))
              }
            </Form.Select>
          </Form.Group>
        </FormInfo>
        {/* <FormInfoFull>
                <Form.Group className="mb-3" controlId="profile_picture">
                    <Form.Label>Upload Profile Picture</Form.Label>
                    <Form.Control type="file" name="profile_picture" onChange={onChange} />
                </Form.Group>
            </FormInfoFull> */}
      </Form>
      <FormInfoFull>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <a type='button' onClick={handleClickRefresh} className='btn btn-sm btn-danger' >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button type='submit' form='UserManagementForm' className='btn btn-sm btn-primary' >Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' onClick={onEditClick} className='btn btn-sm btn-primary' >Edit</a>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button type='submit' form='UserManagementForm' className='btn btn-sm btn-primary' >Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default UserForm;