import React, { useState, useEffect } from 'react';
import style from './ClientInformationPage.module.css';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'


const ClientInformationPage = () => {
  const navigate = useNavigate();
  const { clientID } = useParams();
  const [clientData, setClientData] = useState({});
  const MySwal = withReactContent(Swal);

  const getClientData = _ => {
    axios({
      url: 'http://localhost:8080/api/client_center/get_client',
      params: { client_id: clientID },
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      setClientData(res.data['client_data']);

      // Push to /
      // navigate('/');
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting Invoice!');
    });
  };

  useEffect(() => {
    getClientData();
  }, []);

  async function deleteClient() {
    MySwal.fire({
      title: 'Are you sure you want to delete this client?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: <p>Deleting Client</p>,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        // await new Promise(r => setTimeout(r, 5000));
        axios({
          url: 'http://localhost:8080/api/client_center/delete_client',
          params: { client_id: clientID },
          method: 'DELETE',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        ).then((res) => {
          return MySwal.fire({
            title: <strong>Invoice successfully deleted</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            navigate(`/clients/view_all`);
          })
          // navigate(`/invoice/view_all`);
        })
          .catch((err) => {
            console.log(err)
            console.log('Error in Getting Invoice!');
            return MySwal.fire({
              title: <strong>Client deletion failed!</strong>,
              html: <i>Please try again</i>,
              icon: 'error'
            })
          });
      }
    })
  }

  function capitalizeFirstLetter(str) {
    str = str.replace(/_/g, ' ');
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  function toDate(date) {
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
  }


  return (
    <div className={style.clientHeader}>
      <a
        className="display-4"
        href="javascript:history.back()"
        rel="noopener noreferrer"
      >
        {/* <Button variant="primary" className='lead text-center'>Back to All Invoices</Button> */}
        <i className="fa-solid fa-arrow-left"></i>
      </a>

      {/* <Button variant="danger" className={style.deleteClientBtn} name='deleteClientBtn' onClick={deleteClient}><i className="fa-solid fa-trash"></i></Button> */}
      <BootstrapSwitchButton
        checked={true}
        onlabel='Active'
        offlabel='Inactive'
        width={100}
        onChange={(checked) => this.setState({ isUserAdmin: checked })}
      />
      <div className={style.clientInformationContainer}>
        <h1 className={style.clientInformationHeader}>Client Information</h1>
        <table className={style.clientInformation}>
          <tbody>
            {Object.keys(clientData).map(key => (
              <tr className={style.clientInformationRow}>
                <td className={style.clientInformationTitle}>{capitalizeFirstLetter(key)}:</td>
                <td className={style.clientInformationData}>{clientData[key]}</td>
              </tr>
              // <Form.Group className="mb-3" controlId={key}>
              // 	<Form.Label>{capitalizeFirstLetter(key)}</Form.Label>
              // 	<Form.Control type="text" placeholder={"Enter " + capitalizeFirstLetter(key)} name={key} onChange={onChange} />
              // </Form.Group>
            ))}

            {/* <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Client ID:</td>
              <td className={style.clientInformationData}>{clientData.client_id}</td>
            </tr>
            <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Client Name:</td>
              <td className={style.clientInformationData}>{clientData.client_name}</td>
            </tr>
            <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Address:</td>
              <td className={style.clientInformationData}>123 Main St, Anytown USA 12345</td>
            </tr>
            <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Phone Number:</td>
              <td className={style.clientInformationData}>(555) 555-5555</td>
            </tr>
            <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Email:</td>
              <td className={style.clientInformationData}>johndoe@email.com</td>
            </tr>
            <tr className={style.clientInformationRow}>
              <td className={style.clientInformationTitle}>Date of Birth:</td>
              <td className={style.clientInformationData}>01/01/1980</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default ClientInformationPage;
