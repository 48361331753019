import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Col, Row, Stack, InputGroup } from 'react-bootstrap';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, hasItems, swalWithBootstrapButtons, Required } from '../../../utils/functions.jsx';
import { FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Switch } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import { ExportToCsv } from "export-to-csv";

const MySwal = withReactContent(Swal)

const ItemForm = ({ editView, oneItemData, supID, itemID }) => {

  const defaultItemInfo = {
    supplier_id: '',
    item_name: '',
    item_description: '',
    case_barcode: '',
    item_barcode: '',
    sku_code: '',
    uom: '',
    qty_per_uom: 0,
    price: 0,
    price_per_uom: 0,
    price_per_piece: 0,
    vat_type: '12%',
  }

  const defaultMulti = {
    supplier_id: '',
    items_csv: '',
  }

  const [viewText, setViewText] = useState('New Supplier Item');
  const [editMode, setEditMode] = useState(false);
  const [submitData, setSubmitData] = useState(defaultItemInfo);
  const [multiUpload, setMultiUpload] = useState(defaultMulti);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState('');
  const [selectedTrue, setSelectedTrue] = useState(false);
  const [supSelected, setSupSelected] = useState(false);
  const [isActiveTrue, setIsActiveTrue] = useState(null);
  const [UOMText, setUOMText] = useState(null);
  const [duplicateItems, setDuplicateItems] = useState([]);

  const csvOptions = {
    filename: "Duplicate Item(s)" + new Date(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    // title: 'Duplicate Item(s)',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(csvOptions);

  async function onClickDownloadTemplate() {
    await fetch(`${PROCUREMENT_LINK}/api/item_center/batch_upload_template`, {
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.text())
      .then(data => {
        // Download the CSV file
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        downloadLink.download = 'template.csv';
        downloadLink.click();
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onClickMultiUpload = (e) => {
    e.preventDefault();
    if (multiUpload.items_csv !== '') {
      swalWithBootstrapButtons.fire({
        title: 'All updates will be saved, continue?',
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Continue',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((res) => {
        if (res.isConfirmed) {
          MySwal.fire({
            title: <p>Creating Items</p>,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading()
            },
          })
          axios({
            url: `${PROCUREMENT_LINK}/api/item_center/batch_upload_items`,
            data: multiUpload,
            method: 'POST',
            headers: {
              'x-api-key': PROCUREMENT_API_KEY,
              'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            },
          }).then((res) => {
            console.log(res.data)
            return MySwal.fire({
              title: <strong>Items successfully added</strong>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })
          })
            .catch((err) => {
              if (err.response.status === 451) {
                MySwal.fire({
                  title: 'No file uploaded.',
                  icon: 'warning',
                  cancelButtonText: 'OK',
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: true,
                  showConfirmButton: false,
                  showCloseButton: false
                })
              } else if (err.response.status === 450) {
                MySwal.fire({
                  title: 'Invalid file uploaded. Please use the template file.',
                  icon: 'warning',
                  cancelButtonText: 'OK',
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: true,
                  showConfirmButton: false,
                  showCloseButton: false
                })
              } else if (err.response.status === 452) {
                console.log('Dup Items');
                // console.log(err.response.data.duplicates_list);
                setDuplicateItems(err.response.data.duplicates_list);
                MySwal.fire({
                  // title: 'Duplicate item(s) found.',
                  html:
                    '<span style="color: #fe4658;" >Batch Adding of Items failed</span> <br/>' +
                    'Duplicate item(s) found',
                  // text: `Duplicate item(s) found. Batch Adding of Items failed.`,
                  icon: 'warning',
                  // cancelButtonText: 'OK',
                  // timer: 2000,
                  // timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  showCloseButton: false,
                  footer: (
                    <a className="btn btn-primary btn-sm" onClick={onClickDuplicate} >Download Duplicate</a>
                  )
                })
              }
              // console.log(err)
              // console.log('Error in Adding Item!');
            });

        }
      })
    } else {
      MySwal.fire({
        title: 'No File Uploaded',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }
  }

  const addItemPost = (e) => {
    // console.log(submitData)
    e.preventDefault();
    MySwal.fire({
      title: <p>Creating Item</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/add_item`,
      data: submitData,
      method: 'POST',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    }).then((res) => {
      // ReactDOM.findDOMNode(this.addInvoiceForm).reset();
      console.log(res.data)
      return MySwal.fire({
        title: <strong>Item successfully added</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })


      // Push to /
      // navigate('/');
    })
      .catch((err) => {
        console.log(err)
        console.log('Error in Adding Item!');
      });
  }

  const updateItermPost = (e) => {
    // console.log(itemInfo)
    e.preventDefault();
    MySwal.fire({
      title: <p>Updating Item</p>,
      didOpen: () => {
        MySwal.showLoading()
      },
    })
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/update_item`,
      data: submitData,
      method: 'PUT',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    }).then((res) => {
      // ReactDOM.findDOMNode(this.addInvoiceForm).reset();
      console.log(res.data)
      return MySwal.fire({
        title: <strong>Item successfully updating</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })
    })
      .catch((err) => {
        console.log(err)
        console.log('Error in Updating Item!');
      });
  }

  const updateActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active/Inactive Status, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active/Inactive Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/item_center/change_active_status`,
          params: { item_id: itemID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            MySwal.fire({
              title: 'Error in Updating Active/Inactive Status',
              text: "Please try again",
              icon: 'warning',
              cancelButtonText: 'OK',
              footer: "Click anywhere outside the box to cancel",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              showCloseButton: false
            })
            console.log(err)
          });
      }
    })
  }

  const getAllActiveSuppliers = _ => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setAllSuppliers(response.data.suppliers_data);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  }

  function priceCompute() {
    const uom = submitData.uom;
    const qtyPerUOM = submitData.qty_per_uom;
    const pricePerUOM = submitData.price_per_uom;
    if (uom === 'PC') {
      const pricePerPiece = pricePerUOM;
      setSubmitData({ ...submitData, price_per_piece: pricePerPiece });
    } else if (uom === "CS") {
      const pricePerPiece = pricePerUOM / qtyPerUOM;
      setSubmitData({ ...submitData, price_per_piece: pricePerPiece });
    }
  }

  const onPriceChange = (e) => {
    setSubmitData({ ...submitData, price_per_uom: e });
  }

  const onChange = (e) => {
    if (e.target.name === "uom") {
      if (e.target.value === "PC") {
        setSubmitData({ ...submitData, uom: 'PC', qty_per_uom: 1, case_barcode: '' });
        setUOMText('PC');
      }
      else if (e.target.value === "CS") {
        setSubmitData({ ...submitData, uom: 'CS', item_barcode: '' });
        setUOMText('CS');
      } else {
        setSubmitData({ ...submitData, uom: '', qty_per_uom: 0 });
        setUOMText('');
      }
    } else {
      setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    }
  };

  const onUploadSelect = (e) => {
    if (e.target.value === 'Single') {
      if (!supID) {
        setMultiUpload(defaultMulti);
        setSubmitData(defaultItemInfo);
      }
      setSelectedTrue(true);
      setSupSelected(false);
      setSelectedUpload('Single');
    } else if (e.target.value === 'Multiple') {
      if (supID) {
        setSupSelected(true);
      } else {
        setSubmitData(defaultItemInfo);
      }
      setSelectedTrue(true);
      setSelectedUpload('Multiple');
    } else {
      setSelectedTrue(false);
      if (!supID) {
        setMultiUpload(defaultMulti);
        setSubmitData(defaultItemInfo);
      }
      setSupSelected(false);
    }
  }

  const onSelectSupChange = (e) => {
    setMultiUpload({ ...multiUpload, supplier_id: e.target.value });
    if (e.target.value !== '') {
      setSupSelected(true);
    } else {
      setSupSelected(false);
    }
  }

  const onChangeFile = (e) => {
    setMultiUpload({ ...multiUpload, items_csv: e.target.files[0] });
  }

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        setSubmitData(oneItemData);
        setEditMode(false);
        setViewText('Supplier Item');
      }
    })
  };

  const onClickEdit = () => {
    setEditMode(true);
    setViewText('Edit Supplier Item');
  }

  const onClickDuplicate = () => {
    csvExporter.generateCsv(duplicateItems)
  }

  useEffect(() => {
    if (hasItems(oneItemData)) {
      // console.log('oneItemData',oneItemData);
      setSubmitData(oneItemData);
      setSelectedUpload('Single');
      setSelectedTrue(true);
      if (oneItemData.is_active) {
        setIsActiveTrue(true)
      } else {
        setIsActiveTrue(false)
      }
    }
  }, [oneItemData]);

  useEffect(() => {
    if (editView) {
      setViewText('Supplier Item');
    }
  }, [editView]);

  useEffect(() => {
    getAllActiveSuppliers();
  }, []);

  useEffect(() => {
    // console.log("submitData", submitData);
  }, [submitData])

  useEffect(() => {
    if (hasItems(duplicateItems)) {
      // csvExporter.generateCsv(duplicateItems);
      // console.log("duplicateItems", duplicateItems);
    }
  }, [duplicateItems])

  useEffect(() => {
    priceCompute();
  }, [submitData.price_per_uom, submitData.qty_per_uom])

  useEffect(() => {
    // console.log('multiUpload',multiUpload);
  }, [multiUpload]);

  useEffect(() => {
    if (supID) {
      // console.log('supID',supID);
      setMultiUpload({ ...multiUpload, supplier_id: supID });
      setSubmitData({ ...submitData, supplier_id: supID });
      setSelectedTrue(true);
      // setSelectedUpload('Single');
    }
  }, [supID]);

  const formRequired = true;
  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{viewText}</p></FormTitle>
      <FormInfoFull>
        {
          editView && (
            <Stack gap={2} >
              <div>
                <span style={{ fontWeight: 'bold' }}>Item ID: {itemID}</span>
              </div>
              <div className='mb-3'>
                Status: &nbsp;
                <Switch
                  style={{ backgroundColor: isActiveTrue ? '#237804' : '#f5222d' }}
                  checkedChildren={'Active'}
                  unCheckedChildren={'Inactive'}
                  checked={isActiveTrue}
                  onClick={updateActive}
                  size="small"
                />
              </div>
            </Stack>
          )
        }
        <Stack direction='horizontal' className='mb-3' gap={2}>
          {
            !editView && (
              <div>
                <Form.Group className="mb-3" controlId="select_upload">
                  <Form.Label>Choose Method of Adding Item</Form.Label>
                  <Form.Select name="select_upload" value={selectedUpload} onChange={onUploadSelect} >
                    <option value="">Select Method</option>
                    <option value="Single">Single Add</option>
                    <option value="Multiple">Batch Upload</option>
                  </Form.Select>
                </Form.Group>
              </div>
            )
          }
          {
            selectedUpload === 'Multiple' && (
              <div>
                <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                  <Form.Label>Supplier</Form.Label><Required />
                  <Form.Select value={multiUpload.supplier_id} name="supplier_id" onChange={onSelectSupChange} required={formRequired} disabled={supID} >
                    <option value="">Select Supplier</option>
                    {hasItems(allSuppliers) && (
                      allSuppliers.map(item => (
                        <option key={item.supplier_id} value={item.supplier_id}>
                          {item.supplier_name}
                        </option>
                      ))
                    )
                    }
                  </Form.Select>
                </Form.Group>
              </div>
            )
          }
        </Stack>
        {
          selectedTrue && (
            selectedUpload === 'Single' ? (
              <Form onSubmit={editView ? updateItermPost : addItemPost} name="ItemFormPost" id="ItemFormPost">
                <div className="mt-3" style={{ display: "flex" }}>
                  <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                    {/* <h4 className="mb-3">Item Information</h4> */}
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                        <Form.Label>Supplier</Form.Label><Required />
                        <Form.Select value={submitData.supplier_id} name="supplier_id" onChange={onChange} required={formRequired} disabled={editView ? oneItemData : supID} >
                          <option value="">Select Supplier</option>
                          {allSuppliers.length > 0 &&
                            allSuppliers.map(item => (
                              <option key={item.supplier_id} value={item.supplier_id}>
                                {item.supplier_name}
                              </option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="item_name">
                        <Form.Label>Item Name</Form.Label><Required />
                        <Form.Control type="text" value={submitData.item_name} placeholder="Enter Item Name" name="item_name" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" controlId="item_description">
                        <Form.Label>Item Description</Form.Label><Required />
                        <Form.Control type="text" value={submitData.item_description} placeholder="Enter Item Description" name="item_description" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="case_barcode">
                        <Form.Label>Case Barcode</Form.Label>{UOMText === 'CS' && <Required />}
                        <Form.Control type="text" value={submitData.case_barcode} placeholder="Enter Case Barcode" name="case_barcode" onChange={onChange} required={(UOMText === 'CS' ? true : false)} disabled={editView ? !editMode : (UOMText === 'PC' ? true : false)} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" controlId="item_barcode">
                        <Form.Label>Item Barcode</Form.Label>{UOMText === 'PC' && <Required />}
                        <Form.Control type="text" value={submitData.item_barcode} placeholder="Enter Item Barcode" name="item_barcode" onChange={onChange} required={(UOMText === 'PC' ? true : false)} disabled={editView ? !editMode : (UOMText === 'CS' ? true : false)} />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="sku_code">
                        <Form.Label>SKU Code</Form.Label><Required />
                        <Form.Control type="text" value={submitData.sku_code} placeholder="Enter SKU Code" name="sku_code" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" controlId="uom">
                        <Form.Label>Unit of Measurement (UOM)</Form.Label><Required />
                        <Form.Select value={(submitData.uom).toUpperCase()} name="uom" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} >
                          <option value="">Select UOM</option>
                          <option value="PC">Per Piece</option>
                          <option value="CS">Per Case</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="qty_per_uom">
                        <Form.Label>Quantity per UOM</Form.Label><Required />
                        <Form.Control type="number" value={submitData.qty_per_uom} min={1} placeholder="Enter Quantity per UOM" name="qty_per_uom" onChange={onChange} required={formRequired} disabled={editView ? !editMode : (submitData.uom === 'PC' ? true : false)} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-3" controlId="price_per_uom">
                        <Form.Label>Price Per UOM</Form.Label><Required />
                        <Form.Control as={CurrencyInput} decimalScale={2} decimalsLimit={2} allowNegativeValue={false} prefix={'₱ '} min={0} value={submitData.price_per_uom} name="price_per_uom" onValueChange={(e) => { onPriceChange(e) }} required={formRequired} disabled={editView ? !editMode : false} />
                        {/* <Form.Control type="number" value={submitData.price_per_uom} min={0} placeholder="Enter Price" name="price_per_uom" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} /> */}
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="price_per_piece">
                        <Form.Label>Price per Piece</Form.Label><Required />
                        <Form.Control type="text" value={NumberToPhp(submitData.price_per_piece)} min={0} placeholder="Enter Price" name="price_per_piece" disabled />
                      </Form.Group>
                    </Row>
                  </div>
                </div>
                {
                  editView ? (
                    editMode ? (
                      <Stack direction='horizontal' gap={2}>
                        <div>
                          <a className="btn btn-danger btn-sm" onClick={handleClickRefresh}>Cancel</a>
                        </div>
                        <div className='ms-auto'>
                          <button className="btn btn-primary btn-sm" type='submit' form='ItemFormPost'>Submit</button>
                        </div>
                      </Stack>
                    ) : (
                      <Stack direction='horizontal'>
                        <div>
                          <a className="btn btn-primary btn-sm" onClick={onClickEdit}>Edit</a>
                        </div>
                      </Stack>
                    )
                  ) : (
                    <Stack direction='horizontal'>
                      <div className='ms-auto'>
                        <button className="btn btn-primary btn-sm" type='submit' form='ItemFormPost'>Submit</button>
                      </div>
                    </Stack>
                  )
                }
              </Form>
            ) : (
              supSelected && (
                <Stack gap={2}>
                  <div>
                    <Stack direction='horizontal' gap={2}>
                      <div>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="items_csv" className="mb-3">
                            <Form.Label>Upload Template</Form.Label>
                            <Form.Control type="file" name="items_csv" onChange={onChangeFile} />
                          </Form.Group>
                        </Row>
                      </div>
                    </Stack>
                  </div>
                  <div>
                    <Stack direction='horizontal' gap={3}>
                      <div>
                        <a className="btn btn-primary btn-sm" onClick={onClickDownloadTemplate} >Download Template</a>
                      </div>
                      <div className='ms-auto'>
                        <a className="btn btn-primary btn-sm" onClick={onClickMultiUpload} >Submit</a>
                      </div>
                    </Stack>
                  </div>
                </Stack>
              )
            )
          )
        }

      </FormInfoFull>
    </FormContent>
  );
};

export default ItemForm;