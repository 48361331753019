import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, amountToWords, Required } from '../../../utils/functions';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import PRForm from './PRForm';

const MySwal = withReactContent(Swal)

const CreatePurchaseRequests = (props) => {

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_requests/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_requests/view_all">PR List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New PR',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      {/* Purchase Request Form */}
      <PRForm />

    </FormContainer>
  );
};

export default CreatePurchaseRequests;