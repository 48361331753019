// import React from "react";
// import style from "./AddClient.module.css";
// import { Form, FormGroup, Label, Input, Container, Row, Col } from "reactstrap";

// function AddClient() {
// 	return (
// 		<div>
// 			<header>
// 				<h1 className={style.header}>Add Client</h1>
// 			</header>
// 			<Container className={style.formContainer}>
// 				<Form>
// 					<Row form>
// 						<Col md={6}>
// 							<FormGroup>
// 								<Label for="client_id" className={style.label}>
// 									Client ID
// 								</Label>
// 								<Input
// 									type="text"
// 									name="client_id"
// 									id="client_id"
// 									className={style.input}
// 									controlId="client_id"
// 									placeholder="Enter Client ID"
// 								/>
// 							</FormGroup>
// 						</Col>
// 						<Col md={6}>
// 							<FormGroup>
// 								<Label for="client_name" className={style.label}>
// 									Client Name
// 								</Label>
// 								<Input
// 									type="text"
// 									name="client_name"
// 									id="client_name"
// 									className={style.input}
// 									controlId="client_name"
// 									placeholder="Enter Client Name"
// 								/>
// 							</FormGroup>
// 						</Col>
// 					</Row>
// 					<Row form>
// 						<Col md={6}>
// 							<FormGroup>
// 								<Label for="client_id" className={style.label}>
// 									Supplier ID
// 								</Label>
// 								<Input
// 									type="text"
// 									name="client_id"
// 									id="client_id"
// 									className={style.input}
// 									controlId="client_id"
// 									placeholder="Enter Phone Number"
// 								/>
// 							</FormGroup>
// 						</Col>
// 						<Col md={6}>
// 							<FormGroup>
// 								<Label for="client_name" className={style.label}>
// 									Supplier Name
// 								</Label>
// 								<Input
// 									type="text"
// 									name="client_name"
// 									id="client_name"
// 									className={style.input}
// 									controlId="client_id"
// 									placeholder="Enter Supplier Name"
// 								/>
// 							</FormGroup>
// 						</Col>
// 					</Row>
// 				</Form>
// 			</Container>
// 		</div>
// 	);
// }

// export default AddClient;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SweetAlert from 'react-bootstrap-sweetalert'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)

const AddClient = (props) => {
  const navigate = useNavigate();


  const [client, setClient] = useState({
    client_id: '',
    client_name: '',
    opening_balance: '',
    opening_balance_starting_date: '',
    billing_address: '',
    business_unit: '',
    cc_expiration_date: '',
    cc_name_on_card: '',
    cc_no: '',
    country: '',
    currency: '',
    customer_is_active: '',
    customer_type: '',
    email: '',
    fax: '',
    mobile_number: '',
    payment_account_number: '',
    payment_account_terms: '',
    payment_credit_limit: '',
    payment_method: '',
    sales_rep_name: '',
    shipping_address: '',
    vat_code: '',
    vat_registration_number: '',
    website: '',
  });

  // const [allClients, setAllClients] = useState([]);

  const onChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const addClientPost = (e) => {
    console.log(client)
    e.preventDefault();
    MySwal.fire({
      title: <p>Creating client</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    console.log(client)
    axios({
      url: 'http://localhost:8080/api/client_center/add_new_client',
      data: client,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
    }).then((res) => {
      // ReactDOM.findDOMNode(this.addclientForm).reset();
      console.log(res.data)
      return MySwal.fire({
        title: <strong>Client successfully created</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })


      // Push to /
      // navigate('/');
    })
      .catch((err) => {
        console.log(err)
        console.log('Error in Adding Client!');
      });
  }

  function capitalizeFirstLetter(str) {
    str = str.replace(/_/g, ' ');
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  // const getAllClients = _ => {
  // 	axios({
  // 		url: 'http://localhost:8080/api/client_center/get_all_clients',
  // 		method: 'GET',
  // 	})
  // 	.then((response) => {
  // 		// console.log(response)
  // 		// console.log(response.data.client_data)
  // 		setAllClients(response.data.client_data);
  // 		// allclients = response.data['client_data'];		
  // 	})
  // 	.catch((err) => {
  // 		console.log(err)
  // 		console.log('Error in Fetching All Clients!');
  // 	});
  // }

  // useEffect(() => {
  // 	getAllClients();
  // 	// console.log(allClients);
  // }, []);

  return (
    <div className='CreateBook'>
      <div className='container'>
        <a
          className="display-4"
          href="/clients/home"
          rel="noopener noreferrer"
        >
          {/* <Button variant="primary" className='lead text-center'>Back to Invoicing</Button> */}
          <i className="fa-solid fa-arrow-left"></i>
        </a>
        <div className='row'>

          <div className='col-md-8 m-auto'>
            <h1 className='display-6 text-center'>Add New Client</h1>
            <Form onSubmit={addClientPost} name="addClientForm">

              {Object.keys(client).map(key => {
                if (key === 'opening_balance_starting_date') {
                  return (
                    <Form.Group className="mb-3" controlId={key}>
                      <Form.Label>{capitalizeFirstLetter(key)}</Form.Label>
                      <Form.Control type="date" placeholder={"Enter " + capitalizeFirstLetter(key)} name={key} onChange={onChange} />
                    </Form.Group>
                  );
                } else if (key === 'customer_type') {
                  return (
                    <Form.Group className="mb-3" controlId={key}>
                      <Form.Label>{capitalizeFirstLetter(key)}</Form.Label>
                      <Form.Select aria-label="Default select example" placeholder={"Enter " + capitalizeFirstLetter(key)} name={key} onChange={onChange} >
                        <option>Select Customer Type</option>
                        <option value="Referral">Referral</option>
                        <option value="From Advertisement">From Advertisement</option>
                        <option value="Retail">Retail</option>
                        <option value="Wholesale">Wholesale</option>
                      </Form.Select>
                    </Form.Group>
                  )
                } else if (key === 'business_unit') {
                  return (
                    <Form.Group className="mb-3" controlId={key}>
                      <Form.Label>{capitalizeFirstLetter(key)}</Form.Label>
                      <Form.Select aria-label="Default select example" placeholder={"Enter " + capitalizeFirstLetter(key)} name={key} onChange={onChange} >
                        <option>Select Customer Type</option>
                        <option value="Barapido Retail (BRET)">Barapido Retail (BRET)</option>
                        <option value="Barapido Mart (BMART)">Barapido Mart (BMART)</option>
                        <option value="Barapido E-Commerce (BECOM)">Barapido E-Commerce (BECOM)</option>
                      </Form.Select>
                    </Form.Group>
                  )
                } else {
                  return (
                    <Form.Group className="mb-3" controlId={key}>
                      <Form.Label>{capitalizeFirstLetter(key)}</Form.Label>
                      <Form.Control type="text" placeholder={"Enter " + capitalizeFirstLetter(key)} name={key} onChange={onChange} />
                    </Form.Group>
                  )
                }
              })}
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;