import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import axios from 'axios';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { Stack } from "react-bootstrap";
import { NumberToPhp } from "../../../utils/functions";


const ViewAllItems = () => {
  const [allItems, setAllItems] = useState();
  //   const [tableData, setTableData] = useState([]);

  const columns = [
    {
      data: "item_id",
      text: "Item ID",
      render: function (data, type, row) {
        return `<a href='/item_center/view_item/${row['item_id']}'>${data}</a>`;
      }
    },
    {
      data: "item_name",
      text: "Item Name"
    },
    {
      data: "supplier_information.supplier_name",
      text: "Supplier Name",
      render: function (data, type, row) {
        return `<a href='/suppliers/view_supplier/${row['supplier_id']}'>${data}</a>`;
      }
    },
    {
      data: "price_per_piece",
      text: "Item Price",
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00';
        } else {
          return NumberToPhp(data);
        }
      }
    },
    {
      data: "is_active",
      text: "Active Status",
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    }
  ];

  const getAllItems = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_all_items`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllItems(response.data['items_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {
    // console.log(allItems)
    if (!$.fn.DataTable.isDataTable("#example")) {
      // Initialize the DataTable plugin
      $("#example").DataTable({
        data: allItems,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -2,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    } else {
      $("#example").DataTable().destroy();
      $("#example").DataTable({
        data: allItems,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -2,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    }
  }, [allItems]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal' gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: <span>Location:</span>,
              },
              {
                title: <Link style={{ color: '#909090' }} to="../item_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Item List',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className="text-uppercase">Item List</p></FormTitle>
        <FormInfoFull>
          <Stack gap={2}>
            <div>
              <table id="example" className="display" >
                <thead>
                  {<tr>
                    <th>Item ID</th>
                    <th>Item Name</th>
                    <th>Supplier Name</th>
                    <th>Item Price</th>
                    <th>Active Status</th>
                  </tr>}
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div>
              <a className="btn btn-sm btn-success" href="./add_item">Add</a>
            </div>
          </Stack>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllItems;