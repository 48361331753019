// // import React, { useState, useEffect } from "react";
// // import {
// //   Table,
// //   Collapse,
// //   Container,
// //   Row,
// //   Col,
// // } from "react-bootstrap";

// // const AccountsReceivable = async() => {
// //   const [open, setOpen] = useState(false);
// //   const [selectedRow, setSelectedRow] = useState(null);

// //   useEffect(() => {
// //     setOpen(selectedRow !== null);
// //   }, [selectedRow]);

// //   const handleRowClick = (index) => {
// //     setSelectedRow(selectedRow === index ? null : index);
// //   };

// //   return (
// //     <Container>
// //       <Table striped bordered hover>
// //         <thead>
// //           <tr>
// //             <th>#</th>
// //             <th>First Name</th>
// //             <th>Last Name</th>
// //             <th>Username</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {[...Array(5)].map((e, i) => (
// //             <React.Fragment key={i}>
// //               <tr onClick={() => handleRowClick(i)}>
// //                 <td>{i + 1}</td>
// //                 <td>John</td>
// //                 <td>Doe</td>
// //                 <td>@johndoe</td>
// //               </tr>
// //               <Collapse in={open && selectedRow === i}>
// //                 <tr>
// //                   <td colSpan="4">
// //                     <Container>
// //                       <Row>
// //                         <Col md={4}>Sub Column 1</Col>
// //                         <Col md={4}>Sub Column 2</Col>
// //                         <Col md={4}>Sub Column 3</Col>
// //                       </Row>
// //                     </Container>
// //                   </td>
// //                 </tr>
// //               </Collapse>
// //             </React.Fragment>
// //           ))}
// //         </tbody>
// //       </Table>
// //     </Container>
// //   );
// // };

// // export default AccountsReceivable;

import * as React from 'react';
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';

import style from "./AccountsReceivable.module.css";
import { NumberToPhp } from '../../../utils/functions.jsx';


function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}


export default function AccountsReceivable() {
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    console.log(row)
    return (
      <React.Fragment>
        <TableRow className={open ? style.openedRow : ""} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell className={style.mainRowTextExpanded}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" className={style.mainRowTextExpanded}>
            <a href={`/clients/view_client/${row.client_id}`}>{row.client_id}</a>
          </TableCell>
          <TableCell align="right" className={style.mainRowTextExpanded}>{row.client_name}</TableCell>
          <TableCell align="right" className={style.mainRowTextExpanded}>{row.business_unit}</TableCell>
          <TableCell align="right" className={style.mainRowTextExpanded}>{NumberToPhp(row.total_amount_due)}</TableCell>
          {/* <TableCell align="right">{NumberToPhp(row.total_amount_due)}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Transaction History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Invoice ID</strong></TableCell>
                      <TableCell><strong>Customer Name</strong></TableCell>
                      <TableCell align="right"><strong>Invoice Date</strong></TableCell>
                      <TableCell align="right"><strong>Total Amount</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.transactions.map((historyRow) => (
                      <TableRow key={historyRow.invoice_id}>
                        <TableCell component="th" scope="row">
                          <a href={`/invoice/view_invoice/${historyRow.invoice_id}`}>{historyRow.invoice_id}</a>
                        </TableCell>
                        <TableCell>{historyRow.invoice_to}</TableCell>
                        <TableCell align="right">{historyRow.tax_date}</TableCell>
                        <TableCell align="right">
                          {NumberToPhp(historyRow.amount_due)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  // Row.propTypes = {
  //   row: PropTypes.shape({
  //     calories: PropTypes.number.isRequired,
  //     carbs: PropTypes.number.isRequired,
  //     fat: PropTypes.number.isRequired,
  //     history: PropTypes.arrayOf(
  //       PropTypes.shape({
  //         amount: PropTypes.number.isRequired,
  //         customerId: PropTypes.string.isRequired,
  //         date: PropTypes.string.isRequired,
  //       }),
  //     ).isRequired,
  //     name: PropTypes.string.isRequired,
  //     price: PropTypes.number.isRequired,
  //     protein: PropTypes.number.isRequired,
  //   }).isRequired,
  // };

  const rows = [
    createData('Client A', 'Client A', 6.0, 24, 4.0, 3.99),
    createData('Client B', 'Client B', 9.0, 37, 4.3, 4.99),
    createData('Client C', 'Client C', 16.0, 24, 6.0, 3.79),
    createData('Client D', 'Client D', 3.7, 67, 4.3, 2.5),
    createData('Client E', 'Client E', 16.0, 49, 3.9, 1.5),
  ];

  const [ARData, setARData] = useState([]);
  const getARData = async () => {
    await axios({
      url: 'https://finance-test.fly.dev/api/reporting/accounts_receivable_summary',
      method: 'GET',
    })
      .then((response) => {
        var data = response.data['ar_summary_report_data'];
        // console.log(data)
        // data.forEach(element => {
        //   // console.log(allClients);
        //   const currClient = allClients.find(object => object.client_id === element.client_id);
        //   // console.log(currClient);
        //   element.client_data = currClient;
        // });
        setARData(data);
        // console.log(ARData)
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Invoices!');
      });
  };

  useEffect(() => {
    getARData();
    // console.log(ARData)
    // getAllInvoices();
  }, []);

  return (
    <>
      <div className={style.pageTitle}>
        Accounts Receivable Summary
      </div>
      <div className={style.mainTable}>
        <TableContainer component={Paper} className={style.tableContainer}>
          <Table aria-label="collapsible table" className={style.table}>
            <TableHead className={style.mainRowHeader}>
              <TableRow>
                <TableCell />
                <TableCell className={style.mainRowHeaderText}><strong>Client ID</strong></TableCell>
                <TableCell align="right" className={style.mainRowHeaderText}><strong>Client Name</strong></TableCell>
                <TableCell align="right" className={style.mainRowHeaderText}><strong>Business Unit</strong></TableCell>
                <TableCell align="right" className={style.mainRowHeaderText}><strong>Total Amount</strong></TableCell>
                {/* <TableCell align="right" className={style.mainRowHeaderText}><strong>Data E</strong></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {ARData.length > 0 ? (
                // console.log(ARData),
                ARData.map((row) => (
                  // console.log(row),
                  <Row key={row.client_id} row={row} />
                ))
              ) : (
                <div>Loading data...</div>
              )}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}





// import React from "react";
// import ReactDOM from "react-dom";
// import data from "./movies";
// import DataTable from "react-data-table-component";

// const columns = [
//   {
//     name: "Title",
//     selector: (row) => row.title
//   },
//   {
//     name: "Director",
//     selector: (row) => row.director
//   },
//   {
//     name: "Year",
//     selector: (row) => row.year
//   }
// ];

// // data provides access to your row data
// const ExpandedComponent = (suppData) => ({ data }) => {
//   return <DataTable columns={columns} data={suppData} />;
// };

// function MyComponent() {
//   return (
//     <DataTable
//       columns={columns}
//       data={data}
//       expandableRows
//       expandableRowsComponent={ExpandedComponent(data)}
//     />
//   );
// }
// // const rootElement = document.getElementById("root");
// // ReactDOM.render(<MyComponent />, rootElement);

// export default MyComponent;

