import style from "./LoginPage.module.css";
import logo from "../../../static/img/barapido_logo_square_blue.jpg";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { encryptData } from "../../../utils/encryption";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { AUTH_API_KEY, AUTH_LINK, ENCRYPTION_KEY } from "../../../utils/env_config";
import { Alert } from "react-bootstrap";
import ResetPassword from "./ResetPassword";

const LoginPage = ({ setToggleUI }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState("");
  const [errorMSG, setErrorMSG] = useState("");
  const [errorMSGHidden, setErrorMSGHidden] = useState(true);
  const [permissionsData, setPermissionsData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [isTemp, setIsTemp] = useState(false);

  const navigate = useNavigate();

  const getTemp = () => {
    // getting stored value
    const saved = localStorage.getItem("te");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };


  const getName = () => {
    // getting stored value
    const saved = localStorage.getItem("n");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setDisable(true);
    // console.log("clicked");
    const data = {
      email_address: email,
      password: password,
    };

    axios({
      url: `${AUTH_LINK}/api/auth/login`,
      data: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-api-key": AUTH_API_KEY,
        "Content-Type": "text/plain",
      },
    })
      .then((response) => {
        const token = response.data.data.token;
        const expirationTime = response.data.data.tokenExpiresAt;
        const name = response.data.data.firstname + ' ' + response.data.data.lastname;
        const temp = true;
        // encrypt data  
        const encryptedName = encryptData(
          name,
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        const encryptedToken = encryptData(
          token,
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        // setTokenCookie("token", encryptedToken, expirationTime); // Save the token in a cookie
        // setTokenCookie("name", encryptedName, expirationTime); // Save the token in a cookie
        localStorage.setItem("t", JSON.stringify(encryptedToken), expirationTime);
        localStorage.setItem("n", JSON.stringify(encryptedName), expirationTime);
        localStorage.setItem("time", expirationTime);
        // console.log("success response");

        if (response.data.data.isTemporary) {
          setIsTemp(true);
          setTokenCookie("temp", temp, expirationTime);
          localStorage.setItem("te", JSON.stringify(temp), expirationTime);
        } else {
          setIsTemp(false);
        }

        if (response.data.data.roleId === null) {
          setRole("ROL00000022");
          // console.log("role is set", role);
        } else {
          setRole(response.data.data.roleId);
        }
        // Redirect to another page, show a success message, etc.
      })
      .catch(async (err) => {
        console.log(err.response.status);
        console.log(err.response.data.message);
        console.log(err.response.data);
        if (err.response.status === 460) {
          setErrorMSG('Invalid login credentials');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 461) {
          setErrorMSG('Account is suspended');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 462) {
          setErrorMSG('Account is inactive');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 463) {
          setErrorMSG('Account does not exist');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 464) {
          setErrorMSG('Wrong password');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 465) {
          setErrorMSG('New password must be different from old password');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else if (err.response.status === 500) {
          setErrorMSG('Internal server error');
          setPassword('');
          setErrorMSGHidden(false);
          setDisable(false);
        } else {
          console.log(err.response.status);
          console.log('Error in Adding Item!');
        }
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const setTokenCookie = (name, token, expiration) => {
    // Set a cookie with name 'token' and the provided token value
    Cookies.set(name, token, { expires: expiration });
  };

  useEffect(() => {
    if (role) {
      axios({
        url:
          `${AUTH_LINK}/api/roles/get_role?role_id=` +
          role,
        method: "GET",
        headers: {
          "x-api-key": AUTH_API_KEY,
        },
      })
        .then((response) => {
          setPermissionsData(response.data.role_data.access_data);
          // console.log("permissions", permissionsData);
          // Redirect to another page, show a success message, etc.
        })
        .catch((error) => {
          console.error(error);
          setError("Failed to fetch role");
        });
    }
  }, [role]);

  useEffect(() => {
    if (permissionsData) {
      const encryptedPermissionsData = encryptData(
        permissionsData,
        ENCRYPTION_KEY
      );
      localStorage.setItem("p", encryptedPermissionsData);
      // console.log("Permissions", encryptedPermissionsData);
      setDisable(false);
      if (isTemp) {
        // navigate("/reset_password");
      } else {
        navigate("/dashboard");
      }

      // notification.open({
      //   message: "Success",
      //   description: `Successfully logged in.`,
      //   duration: 2,
      //   type: "success",
      //   style: {
      //     backgroundColor: "#ccffcc",
      //   },
      // });
    }
  }, [permissionsData]);

  useEffect(() => {
    const temporary = getTemp();
    if (temporary) {
      setIsTemp(true);
    } else {
      setIsTemp(false);
    }

    const name = getName();
    if (name) {
      navigate('/dashboard')
    }
  }, [])

  const showForgotPassUIHandler = () => {
    setToggleUI(true);
  }

  return (
    <>
      {
        isTemp ? (
          <ResetPassword />
        ) : (
          <div>
            <Alert variant='danger' hidden={errorMSGHidden}>
              {errorMSG}
            </Alert>
            <form onSubmit={handleLogin}>
              <div className={style.loginFormText}>
                <p>Login</p>
              </div>

              <div className={style.inputContainer}>
                <label htmlFor="email">Email Address</label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className={style.inputContainer}>
                <label htmlFor="password">Password</label>
                <Input.Password
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              {error && (
                <div className={style.loginError}>
                  <p>{error}</p>
                </div>
              )}
              <div className={style.forgotPassContainer}>
                <Link to="" onClick={showForgotPassUIHandler}>
                  <p>Forgot password?</p>
                </Link>
              </div>
              <div>
                <button
                  disabled={disable}
                  className={style.loginBtn}
                  type="submit"
                >
                  {disable ? "Logging in..." : "Login"}
                </button>
              </div>
            </form>
          </div>
        )
      }
    </>
  );
};

export default LoginPage;
