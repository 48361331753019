import style from "./LoginPage.module.css";
import logo from "../../../static/img/barapido_logo_square_blue.jpg";
import LoginPage from "./LoginPage";
import { useState } from "react";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  const [toggleUI, setToggleUI] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.loginContent}>
        <div className={style.loginText}>
          <div className={style.imgContainer}>
            <img src={logo} alt="BEST Home"></img>
          </div>
          <div className={style.textContainer}>
            <p>Barapido Enterprise System and Technologies</p>
          </div>
        </div>
        <div className={style.loginForm}>
          {
            toggleUI ? <ForgotPassword setToggleUI={setToggleUI} /> : <LoginPage setToggleUI={setToggleUI} />
          }
        </div>
      </div>
    </div>
  );
};

export default Login;
