import React from "react";
import { Link } from "react-router-dom";
import style from "./ItemCenterHome.module.css";

function ItemCenterHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Item Center Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/item_center/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View All Items
          </div>
        </Link>
        <Link to="/item_center/add_item" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Add Item
          </div>
        </Link>
        {/* <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Item
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default ItemCenterHome;
