import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Col, Row, InputGroup, Stack, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { amountToWords, toTitleCase, Required, hasItems, NumberToPhp, TwoDecimalNum, swalWithBootstrapButtons } from '../../../utils/functions';
import Select from 'react-select';
import { FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import CurrencyInput from 'react-currency-input-field';

const MySwal = withReactContent(Swal)

const CVForm = ({ editView, getOneCV, cvID }) => {

  const allSigA = [
    {
      name: 'Sulficio O. Tagud Jr.',
      limit: false,
    },
    {
      name: 'Spencer Ryss R. Tagud',
      limit: false,
    },
    {
      name: 'Genica G. Pena',
      limit: '2M',
    },
    {
      name: 'Benny S. Sabidong',
      limit: '2M',
    },
  ]

  const allSigB = [
    {
      name: 'Zenaida R. Cabral',
      limit: false,
    },
    {
      name: 'Ophelia C. Yanga',
      limit: '2M',
    },
    {
      name: 'Wilfredo D. Barona Jr.',
      limit: '2M',
    },
    {
      name: 'Eric Paul D. Serrano',
      limit: '2M',
    },
  ]

  const [accountName, setAccountName] = useState('');
  const [editText, setEditText] = useState('Create Cash Voucher');
  const [editMode, setEditMode] = useState(false);
  const [cvType, setCVType] = useState('');
  const [paymentRR, setPaymentRR] = useState('');
  const [paymentType, setPaymentType] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [oneCVDebitData, setOneCVDebitData] = useState([]);
  const [allAPV, setAllAPV] = useState([]);
  const [allAPVRFP, setAllAPVRFP] = useState([]);
  const [allAPVSelected, setAllAPVSelected] = useState([]);
  const [allSelectedAPVData, setAllSelectedAPVData] = useState([{}]);
  const [totalAmmountDue, setTotalAmmountDue] = useState('');
  const [totalCurrentBalance, setTotalCurrentBalance] = useState('');
  const [totalAmmountToPay, setTotalAmmountToPay] = useState('');
  const [totalAmmountPaid, setTotalAmmountPaid] = useState('');
  const [totalCurrentBalanceView, setTotalCurrentBalanceView] = useState([]);
  const [dropDownValue, setdropDownValue] = useState('Click to Select Account');
  const [allRFP, setAllRFP] = useState([]);
  const [allAccount, setAllAccounts] = useState([]);
  const [structuredAccountData, setStructuredAccountData] = useState([]);
  const [showAccountList, setShowAccountList] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [validated, setValidated] = useState(false);
  const [signatoryA, setSignatoryA] = useState(() => allSigA);
  const [signatoryB, setSignatoryB] = useState(() => allSigB);

  const [apvSelect, setAPVSelect] = useState([{ value: '', label: 'Select APV' }]);

  const selectAPVRef = useRef();

  const defaultSubmitData = {
    supplier_id: '',
    cv_type: '',
    cv_date: new Date().toISOString().split("T")[0],
    due_date: new Date().toISOString().split("T")[0],
    transaction_date: new Date().toISOString().split("T")[0],
    reference_no: '',
    payee: '',
    total_amount_paid: 0,
    amount_in_words: '',
    account_number: '',
    particulars: '',
    account_code: '',
    audited_by: 'WD Barona Jr',
    signatory_a: '',
    signatory_b: '',
    cv_debit_breakdown: [],
  };

  const defaultArr = {
    supplier_id: '',
    cv_type: '',
    cv_date: new Date().toISOString().split("T")[0],
    due_date: new Date().toISOString().split("T")[0],
    transaction_date: new Date().toISOString().split("T")[0],
    reference_no: '',
    payee: '',
    total_amount_paid: 0,
    amount_in_words: '',
    account_number: '',
    particulars: '',
    account_code: '',
    audited_by: 'WD Barona Jr',
    signatory_a: '',
    signatory_b: '',
    cv_debit_breakdown: [],
  };

  const [submitData, setSubmitData] = useState(defaultSubmitData);

  async function getAllActiveSuppliers() {
    await axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data['suppliers_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  async function getallAPV() {
    await axios({
      url: `${FINANCE_LINK}/api/apv/get_all_apv`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    })
      .then((response) => {
        setAllAPV(response.data['apv_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  async function getAllRFP() {
    await axios({
      url: `${FINANCE_LINK}/api/rfp/get_all`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    })
      .then((response) => {
        setAllRFP(response.data['all_rfp_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All RFP Data!');
      });
  };

  async function getAllAccounts() {
    await axios({
      url: `${FINANCE_LINK}/api/accounts_center/get_all_accounts`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    })
      .then((response) => {
        setAllAccounts(response.data['all_accounts_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All RFP Data!');
      });
  };

  async function fetchStructuredAccountData() {
    await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts_structured`, {
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setStructuredAccountData(data.all_accounts_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const PaddingIcon = (e) => {
    if (e > 0) {
      return (<i className="bi bi-arrow-return-right">&nbsp;&nbsp;</i>)
    } else {
      return ''
    }
  }

  const AccountList = ({ accounts, level = 0 }) => {
    return (
      <div>
        {accounts.sort((val1, val2) => val1.account_code - val2.account_code).map((account) => (
          <ul key={account.account_id} style={{ cursor: 'pointer' }}>
            <li>
              <Stack direction='horizontal' gap={4} onClick={() => { onAccountsChange(account.account_name, account.account_id, account.account_code) }}>
                <span style={{ width: '55%', paddingLeft: level * 10 }}>{PaddingIcon(level)}<span className='fw-bolder'>{account.account_code}</span> - {account.account_name}</span>
                <span style={{ width: '20%' }}>{account.account_type}</span>
                <span style={{ width: '5%' }}>{account.currency}</span>
                <span style={{ width: '20%', textAlign: 'right' }}>{NumberToPhp(account.current_balance)}</span>
              </Stack>
            </li>
            {account.sub_accounts && (
              <AccountList accounts={account.sub_accounts} level={level + 1} />
            )}
          </ul>
        ))}
      </div>
    );
  };

  const showModal = () => {
    if (hasItems(structuredAccountData)) {
      setShowAccountList(true)
    } else {
      alert('No Data')
    }
  }

  const onAccountsChange = (account_name, account_id, account_code) => {
    setSubmitData({ ...submitData, account_code: account_code, account_number: account_id });
    setdropDownValue(account_code + ' - ' + account_name);
    setShowAccountList(false);
  }

  const handleAPVSelectContent = (supID) => {
    setAPVSelect([]);
    setSubmitData({ ...submitData, apv_select: '' })
    if (hasItems(allAPV)) {
      allAPV.forEach(e => {
        if (e.supplier_id === supID && !e.is_fully_paid) {
          const dataArray1 = {
            value: e.apv_id,
            label: e.apv_id,
          }
          setAPVSelect(apvSelect => [...apvSelect, dataArray1])
        }
      });
    }
  }

  const handleSelectedAPVData = (apvID) => {
    if (hasItems(allAPV)) {
      allAPV.forEach(e => {
        if (e.apv_id === apvID) {
          var AccCode = '';
          var AccName = '';
          allAccount.forEach(element => {
            if (e.chart_of_accounts_entry === element.account_id) {
              AccCode = element.account_code;
              AccName = element.account_name;
            }
          });
          const localArr = {
            apv_id: e.apv_id,
            amount_due: e.total_amount_due,
            current_balance: e.current_balance,
            account_name: AccCode + ' - ' + AccName,
            amount_paid: TwoDecimalNum(e.current_balance),
            account_code: AccCode,
          };
          setAllSelectedAPVData(allSelectedAPVData => [...allSelectedAPVData, localArr]);

        }
      });
    }
  }

  const onChangeCV = (e) => {
    setSubmitData({ ...submitData, cv_type: e.target.value });
  }

  const onChange = (e) => {
    setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    if (e.target.name === 'cv_type' && e.target.value === 'Debit') {
      setSubmitData({ ...submitData, cv_type: 'Debit' });
      setCVType(e.target.value);
      setPaymentType(false);
      setAllAPVSelected([]);
      setSubmitData(defaultArr);
    } else if (e.target.name === 'cv_type' && e.target.value === 'Credit') {
      setSubmitData({ ...submitData, cv_type: 'Credit' });
      setCVType(e.target.value);
      setPaymentType(true);
      setShowContent(false);
      setAllAPVSelected([]);
      setSubmitData(defaultArr);
    } else if (e.target.name === 'cv_type' && e.target.value === '') {
      setSubmitData({ ...submitData, cv_type: '' });
      setCVType(e.target.value);
      setPaymentType(false);
      setAllAPVSelected([]);
      setSubmitData(defaultArr);
    }
  };

  const onChangePayment = (e) => {
    if (e.target.name === 'payment_type' && e.target.value === 'RR') {
      setAllAPVSelected([]);
      setPaymentRR(e.target.value);
      setPaymentType(true);
    } else if (e.target.name === 'payment_type' && e.target.value === 'RFP') {
      setAllAPVSelected([]);
      setPaymentRR('RFP');
      setShowContent(false);
      setPaymentType(true);
    } else {
      setAllAPVSelected([]);
      setPaymentType(false);
      setShowContent(true);
    }
  };

  const onChangeCheque = (e) => {
    setSubmitData({ ...submitData, total_amount_paid: e, amount_in_words: toTitleCase(amountToWords(TwoDecimalNum(e))) })
  }

  const onChangeNumber = (e, index) => {
    const curr = 'current_balance';
    const name = 'amount_paid';
    const list = [...allSelectedAPVData];
    if (!e) {
      const value = 1;
      list[index][name] = value;
      setAllSelectedAPVData(list);
    } else if (parseFloat(e) > parseFloat(allSelectedAPVData[index][curr])) {
      const value = allSelectedAPVData[index][curr];
      list[index][name] = value;
      setAllSelectedAPVData(list);
    } else if (parseFloat(e) < 1) {
      const value = 1;
      list[index][name] = value;
      setAllSelectedAPVData(list);
    } else {
      const value = e;
      list[index][name] = value;
      setAllSelectedAPVData(list);
    }
  };

  const onAPVSelect = (e) => {
    // console.log(e);
    setAllAPVSelected([]);
    if (hasItems(e)) {
      e.forEach(element => {
        const apvID = element.value;
        // console.log(element.value);
        setAllAPVSelected(allAPVSelected => [...allAPVSelected, apvID])
      });
    }
  };

  const onSupplierSelect = (e) => {
    // setIsReturn(false);
    handleAPVSelectContent(e.target.value);
    setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    setAllAPVSelected([]);
  };

  const CreateCashVoucherPost = (e) => {

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'All updates will be saved, continue?',
        footer: "Click anywhere outside the box to cancel",
        confirmButtonText: 'Continue',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: false
      }).then((res) => {
        if (res.isConfirmed) {
          MySwal.fire({
            title: <p>Creating Cash Voucher</p>,
            allowOutsideClick: false,
            didOpen: () => {
              // `MySwal` is a subclass of `Swal` with all the same instance & static methods
              MySwal.showLoading()
            },
          })
          axios({
            url: `${FINANCE_LINK}/api/cash_voucher/create_cv`,
            data: submitData,
            method: 'POST',
            headers: {
              'x-api-key': FINANCE_API_KEY,
              'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            },
          }).then((res) => {
            // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
            console.log(res.data)
            return MySwal.fire({
              title: <strong>Cash Voucher Created Successfully</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'success'
            }).then(() => {
              window.location.reload();
            })
          })
            .catch((err) => {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            });

        }
      })
    } else {
      setValidated(true);
      MySwal.fire({
        title: 'Please Fill out Required Fields',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }
  }

  useEffect(() => {
    getAllRFP();
    getAllActiveSuppliers();
    getallAPV();
    getAllAccounts();
    fetchStructuredAccountData();
  }, []);

  useEffect(() => {
    if (editView) {
      setEditText('View Cash Voucher')
    }
  }, [editView])

  useEffect(() => {
    // console.log('allSelectedAPVData',allSelectedAPVData);
    if (hasItems(allSelectedAPVData)) {
      const AmmountDue = (allSelectedAPVData.reduce((total, { amount_due }) => total + parseFloat(amount_due), 0));
      const CurrentBal = (allSelectedAPVData.reduce((total, { current_balance }) => total + parseFloat(current_balance), 0));
      const AmountToPay = (allSelectedAPVData.reduce((total, { amount_paid }) => total + parseFloat(amount_paid), 0));
      //   console.log(AmmountDue);
      setTotalAmmountDue(AmmountDue);
      setTotalCurrentBalance(CurrentBal);
      setTotalAmmountToPay(AmountToPay);
    } else {
      setTotalAmmountDue(1);
      setTotalCurrentBalance(1);
      setTotalAmmountToPay(1);
    }
    setSubmitData({ ...submitData, cv_debit_breakdown: allSelectedAPVData, });
  }, [allSelectedAPVData])

  useEffect(() => {
    // console.log('allRFP',allRFP);
  }, [allRFP])

  useEffect(() => {
    // console.log('allAPVSelected',allAPVSelected);
    // setSubmitData({...submitData, apv_select: allAPVSelected});
    setAllSelectedAPVData([]);
    if (hasItems(allAPVSelected)) {
      allAPVSelected.forEach(e => {
        handleSelectedAPVData(e);
      });
    }
  }, [allAPVSelected])

  useEffect(() => {
    const limit = 2000000;
    // console.log('totalAmmountToPay',totalAmmountToPay);
    setSubmitData({ ...submitData, total_amount_paid: TwoDecimalNum(totalAmmountToPay), amount_in_words: toTitleCase(amountToWords(TwoDecimalNum(totalAmmountToPay))) });
    if (parseFloat(totalAmmountToPay) > limit) {
      setSignatoryA([]);
      setSignatoryB([]);
      allSigA.forEach(e => {
        if (!e.limit) {
          const localArr = {
            name: e.name,
            limit: e.limit,
          };
          setSignatoryA(signatoryA => [...signatoryA, localArr]);
        }
      })
      allSigB.forEach(e => {
        if (!e.limit) {
          const localArr = {
            name: e.name,
            limit: e.limit,
          };
          setSignatoryB(signatoryB => [...signatoryB, localArr]);
        }
      })
    } else {
      setSignatoryA(allSigA);
      setSignatoryB(allSigB);
    }
  }, [totalAmmountToPay])

  useEffect(() => {
    // console.log('allSuppliers',allSuppliers);
  }, [allSuppliers])

  useEffect(() => {
    // console.log('apvSelect',apvSelect);
  }, [apvSelect])

  useEffect(() => {
    // console.log('allAPV',allAPV);
    if (hasItems(allAPV)) {
      setAllAPVRFP([]);
      allAPV.forEach(e => {
        if (e.payment_type === 'RFP' && !e.is_fully_paid) {
          const dataArray1 = {
            value: e.apv_id,
            label: e.apv_id,
          }
          setAllAPVRFP(allAPVRFP => [...allAPVRFP, dataArray1])
        }
      });
    }
  }, [allAPV])

  useEffect(() => {
    // console.log('submitData',submitData);
  }, [submitData])

  useEffect(() => {
    console.log('getOneCV', getOneCV);
    if (hasItems(getOneCV)) {
      setSubmitData(getOneCV);
      if (getOneCV.cv_type === 'Debit' || getOneCV.cv_type === null) {
        setOneCVDebitData(getOneCV.cv_debit_data);
        setCVType('Debit');
        setPaymentType(true);
        setShowContent(false);
      } else if (getOneCV.cv_type === 'Credit') {
        setCVType('Credit');
        setPaymentType(true);
        setShowContent(false);
      } else if (getOneCV.cv_type === '') {
        setCVType(getOneCV.cv_type);
        setPaymentType(false);
      }
      const AmountPaid = (getOneCV.cv_debit_data.reduce((total, { amount_paid }) => total + parseFloat(amount_paid), 0));
      setTotalAmmountPaid(AmountPaid);
      getOneCV.cv_debit_data.forEach(e => {
        const localArr = {
          current_balance: e.current_balance
        }
        setTotalCurrentBalanceView(totalCurrentBalanceView => [...totalCurrentBalanceView, localArr]);
      });
      allAccount.forEach(e => {
        if (e.account_code === getOneCV.account_code) {
          setAccountName(e.account_code + ' - ' + e.account_name);
        }
      });
    }
  }, [getOneCV])

  useEffect(() => {
    if (hasItems(totalCurrentBalanceView)) {
      console.log('totalCurrentBalanceView', totalCurrentBalanceView);
    }
  }, [totalCurrentBalanceView])


  useEffect(() => {
    if (hasItems(oneCVDebitData)) {
      oneCVDebitData.forEach(element => {
        handleSelectedAPVData(element.apv_id);
      });
    }
  }, [oneCVDebitData])

  useEffect(() => {
    if (submitData.apv_select === '') {
      setShowContent(true);
    }
  }, [submitData.apv_select])

  useEffect(() => {
    if (submitData.supplier_id === '') {
      setShowContent(true);
    } else {
      setShowContent(false)
    }
  }, [submitData.supplier_id])

  return (
    <FormContent>
      <Modal show={showAccountList} scrollable={true} onHide={() => { setShowAccountList(false) }} size="xl" >
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <AccountList accounts={structuredAccountData} />
            </div>
          </Stack>
        </Modal.Body>
      </Modal>
      <FormTitle><p className='text-uppercase'>{editText}</p></FormTitle>
      <FormInfoFull>
        <Form noValidate validated={validated} onSubmit={CreateCashVoucherPost} id="addCVForm" name='addCVForm'>
          {
            editView && (
              <Stack className="mb-3">
                <div>
                  <span style={{ fontWeight: 'bold' }}>CV ID: {cvID}</span>
                </div>
              </Stack>
            )
          }
          <Row>
            <Col className="col-md-3">
              <Form.Group className="mb-3" controlId="cv_type">
                <Form.Label><b>Select CV Type</b></Form.Label>
                <InputGroup hasValidation>
                  {
                    editView ? (
                      <div>
                        {
                          getOneCV.cv_type === null ? 'Debit' : getOneCV.cv_type
                        }
                      </div>
                    ) : (
                      <Form.Select value={submitData.cv_type} name="cv_type" onChange={(e) => { onChange(e); onChangeCV(e); }} disabled={editView}>
                        <option value="" label="Select CV Type" />
                        <option value="Debit" label="Debit" />
                        <option value="Credit" label="Credit" />
                      </Form.Select>
                    )
                  }
                </InputGroup>
              </Form.Group>
            </Col>
            {
              cvType === 'Debit' && (
                <Col className="col-md-3">
                  <Form.Group className="mb-3" controlId="payment_type">
                    <Form.Label><b>Payment Type</b></Form.Label>
                    {
                      hasItems(getOneCV) ? (
                        <Form.Control type="text" value={submitData.supplier_id ? 'RR' : 'RFP'} name="payment_type" plaintext disabled />
                      ) : (
                        <InputGroup hasValidation>
                          <Form.Select name="payment_type" onChange={onChangePayment} disabled={editView} >
                            <option value="" label="Select Payment Type" />
                            <option value="RR" label="RR" />
                            <option value="RFP" label="RFP" />
                          </Form.Select>
                        </InputGroup>
                      )
                    }
                  </Form.Group>
                </Col>
              )
            }
          </Row>
          {
            paymentType && (
              <>
                {
                  cvType === 'Debit' && (
                    paymentRR === 'RR' ? (
                      <Row className="col-md-12" hidden={editView}>
                        <Col className="col-md-3">
                          <Form.Group className="mb-3" controlId="supplier_id">
                            <Form.Label><b>Select Supplier</b></Form.Label>
                            <InputGroup hasValidation>
                              <Form.Select name="supplier_id" value={submitData.supplier_id} onChange={onSupplierSelect}>
                                <option value="" label="Select Supplier" />
                                {
                                  allSuppliers.sort((val1, val2) => val2.supplier_id - val1.supplier_id).map((listArr, i) => (
                                    <option key={i} value={listArr.supplier_id}>{listArr.supplier_name}</option>
                                  ))
                                }
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </Col>

                        {
                          !showContent && (
                            <Col className="col-md-9" hidden={editView}>
                              <Form.Group className="mb-3" controlId="apv_select">
                                <Form.Label><b>Select APV</b><Required /></Form.Label>
                                <InputGroup hasValidation>
                                  <Select name='apv_select' ref={selectAPVRef} onChange={(e) => onAPVSelect(e)} options={apvSelect} className="col-md-12 basic-multi-select" classNamePrefix="select" isMulti required />
                                  <Form.Control.Feedback tooltip type="invalid">
                                    Please select APV.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          )
                        }
                      </Row>
                    ) : (
                      <Row className="col-md-12" hidden={editView}>
                        <Col>
                          <Form.Group className="mb-3" controlId="apv_select">
                            <Form.Label><b>Select APV</b><Required /></Form.Label>
                            <InputGroup hasValidation>
                              <Select name='apv_select' ref={selectAPVRef} onChange={(e) => onAPVSelect(e)} options={allAPVRFP} className="col-md-12 basic-multi-select" classNamePrefix="select" isMulti required />
                              <Form.Control.Feedback tooltip type="invalid">
                                Please select APV.
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    )
                  )
                }
                {
                  !showContent ? (
                    <Stack>
                      <div>
                        <Row>
                          <Col className='col-md-4'>
                            <Form.Group className="mb-3" controlId="cv_date">
                              <Form.Label><b>CV Date</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="date" value={submitData.cv_date} name="cv_date" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up CV Date.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col className='col-md-4'>
                            <Form.Group className="mb-3" controlId="due_date">
                              <Form.Label><b>Due Date</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="date" value={submitData.due_date} name="due_date" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Due Date.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col className='col-md-4'>
                            <Form.Group className="mb-3" controlId="transaction_date">
                              <Form.Label><b>Cheque Date</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="date" value={submitData.transaction_date} name="transaction_date" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Cheque Date.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col className="mb-3">
                            <Form.Group controlId="reference_no">
                              <Form.Label><b>Cheque number</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={submitData.reference_no} name="reference_no" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Cheque number
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col className="mb-3">
                            <Form.Group controlId="payee">
                              <Form.Label><b>Payee</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={submitData.payee} name="payee" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Payee.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      {
                        cvType === 'Debit' ? (
                          <>
                            <div>
                              <Row>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  <p className='fw-bolder'>APV ID</p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  <p className='fw-bolder'>Amount Due</p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  {
                                    editView ? (
                                      <p className='fw-bolder'>Amount Paid</p>
                                    ) : (
                                      <p className='fw-bolder'>Current Balance</p>
                                    )
                                  }
                                </Col>
                                <Col className='col-md-2' hidden={editView} >
                                  <p className='fw-bolder'>Amount to Pay</p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-4'}>
                                  <p className='fw-bolder'>Account</p>
                                </Col>
                              </Row>
                              {
                                allSelectedAPVData.map((e, i) => (
                                  <Row key={i}>
                                    <Col className={editView ? 'mb-3 col-md-3' : 'mb-3 col-md-2'}>
                                      <a target='_blank' href={'/apv/view_apv/' + e.apv_id} className='text-break'>{e.apv_id}</a>
                                    </Col>
                                    <Col className={editView ? 'mb-3 col-md-3' : 'mb-3 col-md-2'}>
                                      {
                                        editView ? (
                                          <p className='text-break'>{NumberToPhp(totalCurrentBalanceView[i].current_balance)}</p>
                                        ) : (
                                          <p className='text-break'>{NumberToPhp(e.amount_due)}</p>
                                        )
                                      }
                                    </Col>
                                    <Col className={editView ? 'mb-3 col-md-3' : 'mb-3 col-md-2'}>
                                      {
                                        editView ? (
                                          <p className='text-break'>{NumberToPhp(getOneCV.cv_debit_data[i].amount_paid)}</p>
                                        ) : (
                                          <p className='text-break'>{NumberToPhp(e.current_balance)}</p>
                                        )
                                      }
                                    </Col>
                                    <Col className='mb-3 col-md-2' hidden={editView}>
                                      <Form.Group size="sm" as={Row} className="mb-3" controlId="amount_paid">
                                        <InputGroup hasValidation>
                                          <Form.Control as={CurrencyInput} decimalsLimit={2} value={e.amount_paid} allowNegativeValue={false} prefix={'₱ '} min={0} max={e.current_balance} step={0} aria-describedby="inputGroupPrepend" placeholder="0.00" name="amount_paid" onValueChange={(e) => onChangeNumber(e, i)} />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                    <Col className={editView ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}>
                                      <p className='text-break'>{e.account_name}</p>
                                    </Col>
                                  </Row>
                                ))
                              }
                              <Row>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  <p className='fw-bolder'>Total: </p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  <p className='fw-bolder'>{NumberToPhp(totalAmmountDue)}</p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-2'}>
                                  {
                                    editView ? (
                                      <p className='fw-bolder'>{NumberToPhp(totalAmmountPaid)}</p>
                                    ) : (
                                      <p className='fw-bolder'>{NumberToPhp(totalCurrentBalance)}</p>
                                    )
                                  }
                                </Col>
                                <Col className='col-md-2' hidden={editView}>
                                  <p className='fw-bolder'>{NumberToPhp(totalAmmountToPay)}</p>
                                </Col>
                                <Col className={editView ? 'col-md-3' : 'col-md-4'}>
                                  <p className='fw-bolder'></p>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Col className="mb-3 col-md-3">
                                  <Form.Group controlId="particulars">
                                    <Form.Label><b>Total Cheque Amount</b></Form.Label>
                                    {
                                      editView ? (
                                        <div>{NumberToPhp(getOneCV.total_amount_paid)}</div>
                                      ) : (
                                        <InputGroup hasValidation>
                                          <Form.Control plaintext type="text" value={NumberToPhp(totalAmmountToPay)} name="particulars" disabled />
                                        </InputGroup>
                                      )
                                    }
                                  </Form.Group>
                                </Col>
                                <Col className="mb-3">
                                  <Form.Group controlId="particulars">
                                    <Form.Label><b>Total Cheque Amount in Words</b></Form.Label>
                                    {
                                      editView ? (
                                        <div>{toTitleCase(amountToWords(TwoDecimalNum(getOneCV.total_amount_paid)))}</div>
                                      ) : (
                                        <InputGroup hasValidation>
                                          <Form.Control plaintext type="text" value={toTitleCase(amountToWords(TwoDecimalNum(totalAmmountToPay)))} name="particulars" disabled />
                                        </InputGroup>
                                      )
                                    }

                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Row>
                              <Col className="mb-3 col-md-3">
                                <Form.Group controlId="particulars">
                                  <Form.Label><b>Total Cheque Amount</b></Form.Label>
                                  <InputGroup hasValidation>
                                    <Form.Control as={CurrencyInput} decimalsLimit={2} value={submitData.total_amount_paid} allowNegativeValue={false} prefix={'₱ '} min={0} step={0} aria-describedby="inputGroupPrepend" placeholder="0.00" name="total_amount_paid" onValueChange={(e) => onChangeCheque(e)} disabled={editView} />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <Form.Group controlId="particulars">
                                  <Form.Label><b>Total Cheque Amount in Words</b></Form.Label>
                                  <InputGroup hasValidation>
                                    <Form.Control plaintext type="text" value={toTitleCase(amountToWords(TwoDecimalNum(submitData.total_amount_paid)))} name="particulars" disabled />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        )
                      }
                      <div>
                        <Row>
                          <Col className="mb-3">
                            <Form.Group controlId="particulars">
                              <Form.Label><b>Particulars</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={submitData.particulars} name="particulars" onChange={onChange} required disabled={editView} plaintext={editView} />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Payee.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col className="col-md-4 mb-3">
                            <Form.Group controlId="account_code">
                              <Form.Label><b>Chart of Accounts</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                {
                                  editView ? (
                                    // <a className='btn btn-sm btn-outline-secondary' onClick={showModal} disabled={!editMode}>{dropDownValue} </a>
                                    <Form.Control type="text" value={accountName} name="account_code" onClick={showModal} required readOnly disabled={editView} plaintext={editView} />
                                  ) : (
                                    // <a className='btn btn-sm btn-outline-secondary' onClick={showModal} >{dropDownValue} </a>
                                    <Form.Control type="text" value={dropDownValue} name="account_code" onClick={showModal} required readOnly />
                                  )
                                }
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Account Code.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col className="col-md-4 mb-3">
                            <Form.Group controlId="audited_by">
                              <Form.Label><b>Audited By</b><Required /></Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={submitData.audited_by} name="audited_by" onChange={onChange} required disabled plaintext />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please fill up Audited By.
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          {
                            cvType === 'Debit' && (
                              <>
                                <Col className="col-md-4 mb-3">
                                  <Form.Group controlId="signatory_a">
                                    <Form.Label><b>Signatory A</b><Required /></Form.Label>
                                    {
                                      editView ? (
                                        <Form.Control type="text" value={submitData.signatory_a} name="signatory_a" disabled plaintext />
                                      ) : (
                                        <InputGroup hasValidation>
                                          <Form.Select value={submitData.signatory_a} name="signatory_a" onChange={onChange} required >
                                            <option value="" label="Select Signatory" />
                                            {
                                              signatoryA.map((e, i) => (
                                                <option key={i} value={e.name}>{e.name}</option>
                                              ))
                                            }
                                          </Form.Select>
                                          <Form.Control.Feedback tooltip type="invalid">
                                            Please select Signatory.
                                          </Form.Control.Feedback>
                                        </InputGroup>
                                      )
                                    }
                                  </Form.Group>
                                </Col>
                                <Col className="col-md-4 mb-3">
                                  <Form.Group controlId="signatory_b">
                                    <Form.Label><b>Signatory B</b><Required /></Form.Label>
                                    {
                                      editView ? (
                                        <Form.Control type="text" value={submitData.signatory_b} name="signatory_b" disabled plaintext />
                                      ) : (
                                        <InputGroup hasValidation>
                                          <Form.Select value={submitData.signatory_b} name="signatory_b" onChange={onChange} required >
                                            <option value="" label="Select Signatory" />
                                            {
                                              signatoryB.map((e, i) => (
                                                <option key={i} value={e.name}>{e.name}</option>
                                              ))
                                            }
                                          </Form.Select>
                                          <Form.Control.Feedback tooltip type="invalid">
                                            Please select Signatory.
                                          </Form.Control.Feedback>
                                        </InputGroup>
                                      )
                                    }
                                  </Form.Group>
                                </Col>
                              </>
                            )
                          }
                        </Row>
                      </div>
                      {
                        !editView && (
                          <div className='ms-auto'>
                            <button className="btn btn-success btn-sm" type="submit" form='addCVForm' >
                              Submit
                            </button>
                          </div>
                        )
                      }
                    </Stack>
                  ) : ""
                }
              </>
            )
          }
        </Form>
      </FormInfoFull>
    </FormContent>
  );
};

export default CVForm;