// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
// import BootstrapTable from "react-bootstrap-table-next";
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


// import { useNavigate } from 'react-router-dom';

// const ViewAllClients = (props) => {
//   // Define the state with useState hook
//   const navigate = useNavigate();

// 	const [allClients, setAllClients] = useState('');
// 	const columns = [
// 		{
// 			dataField: "client_id",
// 			text: "Client ID"
// 		},
// 		{
// 			dataField: "client_name",
// 			text: "Invoice Name"
// 		},
// 		{
// 			dataField: "created_date",
// 			text: "Created Date"
// 		}
// 	];
// 	const getallClients = () => {
// 		axios({
// 			url: 'http://localhost:8080/api/client_center/get_all_clients',
// 			method: 'GET',
// 		})
// 		.then((response) => {
// 			// console.log(response)
// 			setAllClients(response.data['client_data']);
// 			// allClients = response.data['invoice_data'];		
// 		})
// 		.catch((err) => {
// 			console.log(err)
// 			console.log('Error in Fetching All Clients!');
// 		});
// 	};

// 	useEffect(() => {
// 		getallClients();
// 		console.log(allClients);
// 	}, []);

// 	const tableRowEvents = {
// 		onClick: (e, row, rowIndex) => {
// 			console.log(row)
// 			// your link creation
// 			// const newTo = { 
// 			// 	pathname: `/view_invoice/${row['invoice_id']}`, 
// 			// 	param1: "Par1" 
// 			// };
// 			navigate(`/clients/view_client/${row['client_id']}`);
// 			// link to the "location"
// 			// see (https://reacttraining.com/react-router/web/api/location)
// 			// <Link to={newTo}><button name="viewInvoiceBtn">View</button></Link>

// 			// console.log(`clicked on row with index: ${rowIndex}`);
// 		},
// 		// onMouseEnter: (e, row, rowIndex) => {
// 		// 	console.log(row)
// 		// 	console.log(`enter on row with index: ${rowIndex}`);
// 		// }
//  }

//   return (
// 	<div className='CreateBook'>
// 	  <div className='container'>
// 		<div className='row'>
// 		  {/* <div className='col-md-8 m-auto'>
// 			<br />
// 			<Link to='/' className='btn btn-outline-warning float-left'>
// 			  Show BooK List
// 			</Link>
// 		  </div> */}

// 			<a
// 			className="display-4"
// 			href="/invoice/home"
// 			rel="noopener noreferrer"
// 			>
// 				{/* <Button variant="primary" className='lead text-center'>Back to Invoicing</Button> */}
// 				<i className="fa-solid fa-arrow-left"></i>
// 			</a>

// 		  <div className='col-md-8 m-auto'>
// 			<h1 className='display-4 text-center'>Client List</h1>
// 				<div>
// 					<BootstrapTable striped bordered hover variant="dark" keyField="client_id" data={allClients} columns={columns} bootstrap4 rowEvents={ tableRowEvents } />
// 					{/* <BootstrapTable theaddata={columns} tbodydata={allClients} /> */}
// 				</div>
// 		  </div>
// 		</div>
// 	  </div>
// 	</div>
//   );
// };

// export default ViewAllClients;

import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const ViewAllClients = () => {
  const navigate = useNavigate();

  const [allClients, setAllClients] = useState();
  //   const [tableData, setTableData] = useState([]);

  const columns = [
    {
      data: "client_id",
      text: "Supplier ID",
      render: function (data, type, row) {
        return `<a href='/clients/view_client/${row['client_id']}'>${data}</a>`;
      }
    },
    {
      data: "client_name",
      text: "Supplier Name"
    },
    {
      data: "business_unit",
      text: "Business Unit"
    }
  ];

  const getallClients = () => {
    axios({
      url: 'http://localhost:8080/api/client_center/get_all_clients',
      method: 'GET',
    })
      .then((response) => {
        setAllClients(response.data['client_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Clients!');
      });
  };

  useEffect(() => {
    getallClients();
  }, []);

  useEffect(() => {
    // console.log(allClients)
    if (!$.fn.DataTable.isDataTable("#example")) {
      // Initialize the DataTable plugin
      $("#example").DataTable({
        data: allClients,
        columns: columns,
      });
    } else {
      $("#example").DataTable().destroy();
      $("#example").DataTable({
        data: allClients,
        columns: columns,
      });
    }
  }, [allClients]);



  return (
    <div className="container" style={{ marginTop: "50px" }}>
      <h1 className="">Client List</h1>
      <table id="example" className="display">
        <thead>
          {<tr>
            <th>Client ID</th>
            <th>Client Name</th>
            <th>Business Unit</th>
          </tr>}
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default ViewAllClients;