import React, { useState, useEffect, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import AgingMRTable from './AgingMRTable';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { hasItems } from '../../../utils/functions';

const APAging = (props) => {

  const [SupData, setSupData] = useState([{}])
  const [allSupData, setAllSupData] = useState([{}])
  const [allAgingData, setAllAgingData] = useState([])
  const [mergeData, setMergeData] = useState([{}])
  const [agingGetData, setAgingGetData] = useState({
    current_date: new Date().toISOString().split("T")[0],
    days: "30",
    period: "3",
  });

  async function fetchAgingData() {
    // setAllAgingData([]);
    allSupData.forEach((e) => {
      // console.log(e.supplier_id)
      if (e.supplier_id) {
        fetch(`${FINANCE_LINK}/api/apv/supplier_apv_aging?supplier_id=` + e.supplier_id + '&payment_type=RR&' + new URLSearchParams(agingGetData), {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
            'x-api-key': FINANCE_API_KEY,
          },
        })
          .then(response => response.json())
          .then(data => {
            const supplier_name = e.supplier_name;
            var apv_total_amount_due = data.apv_total_amount_due;
            var arrayData = data.apv_aging_data;
            var arraySub = data.apv_subsequent_data;
            var supplier_id = e.supplier_id;
            setAllAgingData(allAgingData => [...allAgingData, {
              supplier_id,
              supplier_name,
              apv_total_amount_due,
              arraySub,
              arrayData
            }])
            // allAgingData.shift();
          })
          .catch(error => {
            console.log('Error in fetching Data!');
            console.log(error);
          });

      }
    })
  }

  async function fetchRFPAPV() {
    setAllAgingData([]);
    await fetch(`${FINANCE_LINK}/api/apv/supplier_apv_aging?supplier_id=&payment_type=RFP&` + new URLSearchParams(agingGetData), {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        var supplier_name = 'Miscellaneous';
        var apv_total_amount_due = data.apv_total_amount_due;
        var arrayData = data.apv_aging_data;
        var arraySub = data.apv_subsequent_data;
        var supplier_id = 'RFP';
        setAllAgingData([{
          supplier_id,
          supplier_name,
          apv_total_amount_due,
          arraySub,
          arrayData
        }])
        // allAgingData.shift();
        fetchAgingData();
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  async function fetchAllSupplierData() {
    await fetch(`${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAllSupData(data.suppliers_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const [columnState, setColumnState] = useState([
    "current",
    "1-30",
    "31-60",
    "61-90",
    ">90"
  ])

  useEffect(() => {
    fetchAllSupplierData();
  }, []);

  useEffect(() => {
    if (hasItems(allAgingData)) {
      console.log('allAgingData', allAgingData);
    }
    if (allAgingData.length === 1) {
      if (typeof (allAgingData[0].arrayData) === 'object') {
        setColumnState(Object.keys(allAgingData[0].arrayData))
      }
    } else if (allAgingData.length > 1) {
      setColumnState(Object.keys(allAgingData[1].arrayData))
    }
  }, [allAgingData]);

  useEffect(() => {
    // console.log(SupData)
  }, [SupData])

  useEffect(() => {
    // console.log('columnState',columnState)
  }, [columnState]);

  useEffect(() => {
    // console.log('agingGetData',agingGetData);
  }, [agingGetData]);

  useEffect(() => {
    // console.log('mergeData',mergeData);
  }, [mergeData]);

  useEffect(() => {
    fetchRFPAPV();
    // fetchAgingData();
  }, [allSupData]);

  const onChangeAgingGet = (e) => {
    setAgingGetData({ ...agingGetData, [e.target.name]: e.target.value });
  }

  function resetGet() {
    return new Promise(resolve => {
      setAllAgingData([]);
      setTimeout(resolve, 1000);
      fetchRFPAPV();
      // fetchAgingData();
    });
  }

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: 'AP Aging',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Accounts Payable Voucher (APV) Aging</p></FormTitle>
        <FormInfoFull>
          <Stack direction='horizontal' gap={4}>
            <div className='mb-3 input-group input-group-sm flex-nowrap'>
              <span className="input-group-text" id="date_addon">Date: </span>
              <input className='form-control' aria-describedby="date_addon" type='date' defaultValue={new Date().toISOString().split("T")[0]} name="current_date" id="current_date" onChange={onChangeAgingGet} />
            </div>
            <div className='mb-3 input-group input-group-sm flex-nowrap'>
              <span className="input-group-text" id="days_addon">Interval: </span>
              <input className='form-control' aria-describedby="days_addon" min={1} max={250} type='number' name="days" id="days" placeholder='30' onChange={onChangeAgingGet} />
            </div>
            <div className='mb-3 input-group input-group-sm flex-nowrap'>
              <span className="input-group-text" id="period_addon">Period: </span>
              <input className='form-control' aria-describedby="period_addon" type='number' min={1} max={20} name="period" id="period" placeholder='3' onChange={onChangeAgingGet} />
            </div>
            <div className='mb-3 input-group flex-nowrap'>
              <button className='btn btn-primary btn-sm ms-auto' onClick={resetGet}>Generate AP Aging</button>
            </div>
          </Stack>
          <Stack>
            {/* <AgingTable agingData={allAgingData} /> */}
            {/* <AgingTable agingData={allAgingData} agingColumns={columnState} /> */}
            <AgingMRTable agingData={allAgingData} agingColumns={columnState} />
          </Stack>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );

};


export default APAging;