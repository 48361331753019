import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Button, Modal, Stack } from "react-bootstrap";
import { toTitleCase, mmddyyWord, uniq, hasItems, NumberToPhp } from '../../../utils/functions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from "export-to-csv";
import { Link } from "react-router-dom";

const data = [];

const AgingMRTable = ({ agingData, agingColumns }) => {

  const columns = [
    {
      header: "Supplier",
      accessorKey: 'supplier_name',
      Footer: () => <div>Total Amount Due: </div>,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          // alert(cell.getValue());
          onClickCellTotal(cell['row'].id);
          setSupAPVTitle(cell['row'].original.supplier_name);
          // console.log(cell.getValue(), cell['row'].id);
        },
      }),
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} >{renderedCellValue}</span>
      ) //optional custom cell render onClick={e => {e.stopPropagation(); onClickCellHeader(renderedCellValue)}} 
    }
  ];

  const [tableData, setTableData] = useState(() => data);
  const [tableColumn, setTableColumn] = useState(() => columns);
  const [columnSet, setColumnSet] = useState([]);
  const [totalAmountDue, setTotalAmountDue] = useState([])
  const [totalAmountPaid, setTotalAmountPaid] = useState([])
  const [totalAmountPaidAll, setTotalAmountPaidAll] = useState([])
  const [totalAmountPaidData, setTotalAmountPaidData] = useState([])
  const [allTotalAmountDue, setAllTotalAmmountDue] = useState([])
  const [allAPVSupplier, setAllAPVSupplier] = useState([])
  const [mergeAPVSupplier, setMergeAPVSupplier] = useState([])
  const [mergeAPVColumn, setMergeAPVColumn] = useState([])
  const [allAPVColumn, setAllAPVColumn] = useState([])
  const [allSupplier, setAllSupplier] = useState([])
  const [totalADData, setTotalADData] = useState([
    // apvs: {},
    // total_amount_due: 0,
  ])
  const [SAPV, setSAPV] = useState([{
    apv_id: '',
    transaction_date: '',
    due_date: '',
    amount_due: '',
    age: '',
  }])
  const [SAPVTitle, setSAPVTitle] = useState();
  const [supAPVTitle, setSupAPVTitle] = useState();

  const onClickCellTotal = (index) => {
    // setSAPVTitle(e);
    setTotalAmountDue([])
    agingColumns.forEach((key) => {
      // console.table(agingData[index].arrayData[key]['apvs'])
      setTotalAmountDue(totalAmountDue => [...totalAmountDue, agingData[index].arrayData[key]['apvs']])
    });
    setShowTAD(true)
    // setTotalAmountDue(totalData)
  }

  const onClickCellSubsequentTotal = (index) => {
    // console.log(agingData[index].arraySub['subsequent']['apvs']);
    setTotalAmountPaid([])
    setTotalAmountPaid(totalAmountPaid => [...totalAmountPaid, agingData[index].arraySub['subsequent']['apvs']]);
    setShowSub(true)
    // setTotalAmountDue(totalData)
  }

  const onClickColumnTotal = (e) => {
    // console.log('e',e);
    setSAPVTitle(e)
    setMergeAPVColumn([])
    agingData.forEach(element => {
      Array(element.arrayData[e]['apvs']).forEach(innerElem => {
        if (innerElem.length > 0) {
          setMergeAPVColumn(mergeAPVColumn => [...mergeAPVColumn, innerElem])
        }
      })
    })
    setShowAPVColumn(true)
  }

  const populateTAD = () => {
    populateAllAPV();
    setAllTotalAmmountDue([])
    agingData.forEach((element, index) => {
      agingColumns.forEach((key, i) => {
        const allarrayData = element.arrayData[key].apvs;
        if (allarrayData.length > 0) {
          allarrayData.forEach((innerArrayData) => {
            // allTotalAmountDue.push(innerArrayData)
            setAllTotalAmmountDue(allTotalAmountDue => [...allTotalAmountDue, innerArrayData])
          })
        }
      });
    })
    // console.table(allTotalAmountDue);
    setShowALLAPV(true);
  }

  const populateSubsequent = () => {
    // populateAllAPV();
    setTotalAmountPaidAll([])
    agingData.forEach((element) => {
      if (element.arraySub.subsequent.total_amount_paid !== 0) {
        // console.log(index);
        // console.log(element.arraySub.subsequent.apvs);
        setTotalAmountPaidAll(totalAmountPaidAll => [...totalAmountPaidAll, element.arraySub.subsequent.apvs]);
      }
    })
    // console.table(allTotalAmountDue);
    setShowAllSub(true)
  }

  const populateAllAPV = () => {
    setAllAPVSupplier([])
    setAllSupplier([])
    agingData.forEach((element) => {
      const supplierName = element['supplier_name'];
      // console.log(supplierName);
      setAllSupplier(allSupplier => [...allSupplier, supplierName])
      agingColumns.forEach((key) => {
        // console.log(element);
        if (element.supplier_name === supplierName) {
          const allarrayData = element.arrayData[key].apvs;
          // const APVarrayData = {};
          // console.log(allarrayData);
          if (hasItems(allarrayData)) {
            if (allarrayData.length > 1) {
              allarrayData.forEach(element => {
                const APVarrayData = {
                  supplier_name: supplierName,
                  apv_id: element.apv_id,
                  transaction_date: element.transaction_date,
                  due_date: element.due_date,
                  amount_due: element.amount_due,
                  age: element.age,
                };
                if (allarrayData.length > 0) {
                  allarrayData.forEach((innerArrayData) => {
                    // setAllAPVSupplier(allAPVSupplier => [...allAPVSupplier, supplierName, innerArrayData]);
                    setAllAPVSupplier(allAPVSupplier => [...allAPVSupplier, APVarrayData]);
                  })
                }
              });
            } else {
              const APVarrayData = {
                supplier_name: supplierName,
                apv_id: allarrayData[0].apv_id,
                transaction_date: allarrayData[0].transaction_date,
                due_date: allarrayData[0].due_date,
                amount_due: allarrayData[0].amount_due,
                age: allarrayData[0].age,
              };
              if (allarrayData.length > 0) {
                allarrayData.forEach((innerArrayData) => {
                  // setAllAPVSupplier(allAPVSupplier => [...allAPVSupplier, supplierName, innerArrayData]);
                  setAllAPVSupplier(allAPVSupplier => [...allAPVSupplier, APVarrayData]);
                })
              }
            }
            // const APVarrayData = {
            //   supplier_name: supplierName,
            //   supplier_apv: allarrayData
            // };
            // console.log('yes',element.supplier_name);

          }
        }
      });
    })
    console.log(uniq(allAPVSupplier));
    // restructure(allAPVSupplier)
    // console.table(allAPVSupplier);
  }

  const onClickCell = (e, i) => {
    setSAPVTitle(e)
    const cellData = agingData[i]['arrayData'][e]['apvs']
    setSAPV(cellData)
    setShowSpecificAPV(true)
  }

  const [isSAPV, setIsSAPV] = useState(true);

  useEffect(() => {
    setAllAPVColumn(mergeAPVColumn.flat());
  }, [mergeAPVColumn])

  useEffect(() => {
    // console.table(allAPVColumn);
  }, [allAPVColumn])

  useEffect(() => {
    // console.log('totalAmountPaid',totalAmountPaid);
    // console.table(totalAmountPaid);
  }, [totalAmountPaid])

  useEffect(() => {
    // console.log('SAPV')
    // console.table(SAPV)
    if (SAPV.length === 1 || SAPV.length > 0) {
      setIsSAPV(false)
    } else {
      setIsSAPV(true)
    }
  }, [SAPV])

  const [isTotalADData, setIsTotalADData] = useState(true)

  useEffect(() => {
    // console.log('totalADData')
    // console.table(totalADData)
    if (totalADData.length === 1 || totalADData.length > 0) {
      setIsTotalADData(false)
    } else {
      setIsTotalADData(true)
    }
  }, [totalADData])

  useEffect(() => {
    // console.log('allSupplier')
    // console.table(allSupplier)
  }, [allSupplier])

  useEffect(() => {
    // console.log('mergeAPVSupplier')
    // console.table(mergeAPVSupplier)
  }, [mergeAPVSupplier])

  useEffect(() => {
    // console.log(uniq(allAPVSupplier))
    const uniqArray = uniq(allAPVSupplier)
    // console.log('allAPVSupplier')
    // console.table(allAPVSupplier)
    const result = []
    const obj = {}
    uniqArray.map(item => {
      Object.keys(item).map(key => {
        if (obj[key] && obj[key] !== item[key]) {
          return obj[key] = [obj[key], item[key]].flat()
        } else {
          return obj[key] = item[key]
        }
      })
    })
    result.push(obj)
    // console.log(result)
    setMergeAPVSupplier(result)
  }, [allAPVSupplier])

  useEffect(() => {
    // console.log('allTotalAmountDue')
    // console.table(allTotalAmountDue)
  }, [allTotalAmountDue])

  useEffect(() => {
    // console.log('totalAmountDue')
    // console.table(totalAmountDue)
    setTotalADData([])
    totalAmountDue.forEach(element => {
      element.forEach(elem => {
        setTotalADData(totalADData => [...totalADData, elem])
      });
    });
  }, [totalAmountDue])

  useEffect(() => {
    // console.log('totalAmountDue')
    // console.table(totalAmountDue)
    if (hasItems(totalAmountPaidAll)) {
      setTotalAmountPaidData([]);
      totalAmountPaidAll.forEach(element => {
        element.forEach(elem => {
          setTotalAmountPaidData(totalAmountPaidData => [...totalAmountPaidData, elem])
        });
      });
    }
  }, [totalAmountPaidAll])

  useEffect(() => {
    // console.log('columnSet',columnSet);
  }, [columnSet])

  useEffect(() => {
    // setGrandTotalSum(columns)
    // console.log('MRT agingData')
    // console.table(agingData)
    if (agingData.length === 1) {
      if (agingData[0].supplier_id === undefined) {
        // console.log('undefined 1');
        agingData.shift();
      }
      // agingData.shift()
      setTableData(agingData)
    } else if (agingData.length > 1) {
      if (agingData[0].supplier_id === undefined) {
        // console.log('undefined 2');
        agingData.shift();
      }
      // agingData.shift()
      setTableData(agingData)
    }
  }, [agingData]);

  useEffect(() => {
    // console.log('MRT agingColumns')
    // console.table(agingColumns);
    var grandTotalSum = 0;
    setColumnSet(columns);
    // console.log(agingColumns)
    agingColumns.forEach((key) => {
      // console.log('key',key)
      columnSet.push({
        header: toTitleCase(key),
        accessorKey: 'arrayData.' + key + '.total_amount_due',
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Footer: () => <span style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => onClickColumnTotal(key)}>{NumberToPhp(getTableTotals(key))}</span>,
        // aggregationFn: 'sum',
        // AggregatedCell: ({ cell }) => <div>Total Score: {cell.getValue()}</div>,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            onClickCell(key, cell['row'].id);
            setSupAPVTitle(cell['row'].original.supplier_name);
            // console.log(cell.getValue(), cell.id);
          },
        }),
        Cell: ({ renderedCellValue }) => (
          <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={key} id={key} >
            {NumberToPhp(renderedCellValue)}
          </span>
        )
      })
      // cellTotalSum = cellTotalSum + cellSum;
      // console.log('cellTotalSum',cellTotalSum)
    });
    columnSet.push({
      header: "Total Amount Due",
      accessorKey: 'apv_total_amount_due',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      Footer: () => <span style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={populateTAD}>{NumberToPhp(grandTotalSum)}</span>,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          onClickCellTotal(cell['row'].id);
          setSupAPVTitle(cell['row'].original.supplier_name);
          // console.log(cell.getValue(), cell['row'].original.supplier_name);
        },
      }),
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black', fontWeight: 'bold' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      )
    });

    columnSet.push({
      header: "Subsequent",
      accessorKey: 'arraySub.subsequent.total_amount_paid',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      Footer: () => <span style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={populateSubsequent}>{NumberToPhp(getSubsequentTotals())}</span>,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          onClickCellSubsequentTotal(cell['row'].id);
          setSupAPVTitle(cell['row'].original.supplier_name);
          // console.log(cell.getValue(), cell['row'].original.supplier_name);
        },
      }),
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black', fontWeight: 'bold' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      )
    });
    setTableColumn(columnSet)
    // console.table('MRT columnSet',columnSet)
    agingData.forEach(total => {
      var grandSum = total.apv_total_amount_due;
      grandTotalSum = grandTotalSum + grandSum;
      // console.log(grandTotalSum)
    });
  }, [agingColumns])

  useEffect(() => {
    // console.log('MRT tableColumn',tableColumn)
  }, [tableColumn])

  useEffect(() => {
    // console.log('MRT tableData')
    // console.table(tableData)
  }, [tableData])

  const [showTAD, setShowTAD] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showAllSub, setShowAllSub] = useState(false);
  const [showALLAPV, setShowALLAPV] = useState(false);
  const [showAPVColumn, setShowAPVColumn] = useState(false);
  const [showSpecificAPV, setShowSpecificAPV] = useState(false);

  const handleClose = () => { setShowTAD(false); setShowSpecificAPV(false); setShowALLAPV(false); setShowAPVColumn(false); setShowSub(false); setShowAllSub(false); };

  const getTableTotals = (key) => {
    let total = 0;
    agingData.forEach(item => {
      // console.table(item.arrayData[key]['total_amount_due']);
      total += parseFloat(item.arrayData[key]['total_amount_due']);
    });
    return total;
  };

  const getSubsequentTotals = () => {
    let total = 0;
    agingData.forEach(item => {
      // console.table(item.arraySub['subsequent']['total_amount_paid']);
      total += parseFloat(item.arraySub['subsequent']['total_amount_paid']);
    });
    return total;
  };

  const getTotals = (data, key) => {
    let total = 0;
    data.forEach(item => {
      total += parseFloat(item[key]);
    });
    return total;
  };

  const SubsequentColumns = [
    {
      accessorKey: 'apv_id',
      header: 'APV ID',
      Footer: () => <div>Total Amount Paid: </div>,
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/apv/view_apv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
    },
    {
      accessorKey: 'cv_id',
      header: 'Cash Voucher',
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/cv/view_cv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
    },
    {
      accessorKey: 'transaction_date',
      header: 'Transaction Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      ),
      AggregatedCell: ({ row }) => (
        <>
          Total Amount Paid:
        </>
      ),
    },
    {
      accessorKey: 'amount_paid',
      header: 'Amount Paid',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      ),
      AggregatedCell: ({ cell }) => (
        <>
          <div style={{ fontWeight: 'bold' }}>{NumberToPhp(cell.getValue())}</div>
        </>
      ),
      Footer: () => <div>{NumberToPhp(getSubsequentTotals(totalAmountPaid[0], "amount_paid"))}</div>,
    },
  ];

  const GTotalColumns = [
    {
      accessorKey: 'apv_id',
      header: 'APV ID',
      Footer: () => <div>Total Amount Due: </div>,
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/apv/view_apv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
    },
    {
      accessorKey: 'transaction_date',
      header: 'Transaction Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'due_date',
      header: 'Due Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'amount_due',
      header: 'Amount Due',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      ),
      Footer: () => <div>{NumberToPhp(getTotals(totalADData, "amount_due"))}</div>,
    },
    {
      accessorKey: 'age',
      header: 'Age',
    },
  ];

  const CellColumns = [
    {
      accessorKey: 'apv_id',
      header: 'APV ID',
      Footer: () => <div>Total Amount Due: </div>,
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/apv/view_apv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
    },
    {
      accessorKey: 'transaction_date',
      header: 'Transaction Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'due_date',
      header: 'Due Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'amount_due',
      header: 'Amount Due',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      ),
      Footer: () => <div>{NumberToPhp(getTotals(SAPV, "amount_due"))}</div>,
    },
    {
      accessorKey: 'age',
      header: 'Age',
    },
  ];

  const AllAPVColumns = [
    {
      accessorKey: 'apv_id',
      header: 'APV ID',
      Footer: () => <div>Total Amount Due: </div>,
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/apv/view_apv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
    },
    {
      accessorKey: 'transaction_date',
      header: 'Transaction Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'due_date',
      header: 'Due Date',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'amount_due',
      header: 'Amount Due',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      ),
      Footer: () => <div>{NumberToPhp(getTotals(allAPVColumn, "amount_due"))}</div>,
    },
    {
      accessorKey: 'age',
      header: 'Age',
    },
  ];

  const StructuredAPVColumns = [
    {
      accessorKey: 'supplier_name',
      header: 'Supplier',
      Footer: () => <div>Total Amount Due: </div>,
    },
    {
      accessorKey: 'apv_id',
      header: 'APV ID',
      enableGrouping: false,
      Cell: ({ renderedCellValue }) => (
        <Link target="_blank" to={'/apv/view_apv/' + renderedCellValue}>{renderedCellValue}</Link>
      ),
      // Footer: () => <div>Total Amount Due: </div>,
    },
    {
      accessorKey: 'transaction_date',
      header: 'Transaction Date',
      enableGrouping: false,
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      )
    },
    {
      accessorKey: 'due_date',
      header: 'Due Date',
      enableGrouping: false,
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {mmddyyWord(renderedCellValue)}
        </span>
      ),
      AggregatedCell: ({ row }) => (
        <>
          Total Amount Due for {row.original.supplier_name}
        </>
      ),
    },
    {
      accessorKey: 'amount_due',
      header: 'Amount Due',
      enableGrouping: false,
      // aggregationFn: 'mean',
      Cell: ({ renderedCellValue }) => (
        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }} name={renderedCellValue} >
          {NumberToPhp(renderedCellValue)}
        </span>
      ),
      AggregatedCell: ({ cell }) => (
        <>
          <div style={{ fontWeight: 'bold' }}>{NumberToPhp(cell.getValue())}</div>
        </>
      ),
      Footer: () => <div>{NumberToPhp(getTotals(allTotalAmountDue, "amount_due"))}</div>,
    },
    {
      accessorKey: 'age',
      header: 'Age',
      enableGrouping: false,
    },
  ];

  const handleExportDataGrandTotal = () => {
    // csvExporter.generateCsv(agingData);
    csvExporterGrandTotalTable.generateCsv(totalADData);
  };

  const handleExportAPVData = () => {
    csvExporterAPVTable.generateCsv(allTotalAmountDue);
  };

  const handleExportAllAPVData = () => {
    csvExporterAllAPVTable.generateCsv(allAPVColumn);
  };

  const handleExportDataTotal = () => {
    csvExporterCellTable.generateCsv(SAPV);
  };

  const optionsAPV = {
    filename: "All APV" + new Date(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'ALL APV',
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ['APV ID', 'Transaction Date', 'Due Date', 'Amount Due', 'Age']
  };

  const optionsAllAPV = {
    filename: `APV for ${SAPVTitle} Column` + new Date(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: `APV for ${SAPVTitle} Column`,
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ['APV ID', 'Transaction Date', 'Due Date', 'Amount Due', 'Age']
  };

  const optionsGrandTotal = {
    filename: `Total Amount Due for ${supAPVTitle}` + new Date(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: `Total Amount Due for ${supAPVTitle}`,
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ['APV ID', 'Transaction Date', 'Due Date', 'Amount Due', 'Age']
  };

  const optionsCellTotal = {
    filename: `Amount Due for ${SAPVTitle} Days for ${supAPVTitle}` + new Date(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: `Amount Due for ${SAPVTitle} Days for ${supAPVTitle}`,
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ['APV ID', 'Transaction Date', 'Due Date', 'Amount Due', 'Age']
  };

  const csvExporterAPVTable = new ExportToCsv(optionsAPV);
  const csvExporterAllAPVTable = new ExportToCsv(optionsAllAPV);
  const csvExporterGrandTotalTable = new ExportToCsv(optionsGrandTotal);
  const csvExporterCellTable = new ExportToCsv(optionsCellTotal);

  return (
    <div>
      <Modal
        show={showALLAPV}
        onHide={handleClose}
        backdrop="static"
        fullscreen
      // size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>All APV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={StructuredAPVColumns}
                data={uniq(allAPVSupplier)}
                // columns={APVColumns}
                // data={allTotalAmountDue}
                enableGrouping
                enableStickyHeader
                enableStickyFooter
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact', grouping: ['supplier_name'], expanded: false, sorting: [{ id: 'amount_due', desc: true, }], }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="primary" onClick={() => { handleExportAPVData(); handleClose() }} >
            <FileDownloadIcon /> Export All Data
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAPVColumn}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>APV for {SAPVTitle} Column</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={AllAPVColumns}
                data={allAPVColumn}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact' }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="primary" onClick={() => { handleExportAllAPVData(); handleClose() }} >
            <FileDownloadIcon /> Export All Data
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSub}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Subsequent Data for {supAPVTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={SubsequentColumns}
                data={totalAmountPaid[0]}
                enableGrouping
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact', grouping: ['apv_id'], }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {hasItems(totalAmountPaid[0]) ? (
            <Button variant="primary" onClick={() => { handleExportDataGrandTotal(); handleClose() }} >
              <FileDownloadIcon /> Export All Data
            </Button>
          ) : (
            <span>No Data to Export</span>
          )
          }
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAllSub}
        onHide={handleClose}
        backdrop="static"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Subsequent Data for All APV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={SubsequentColumns}
                data={totalAmountPaidData}
                enableGrouping
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact', grouping: ['apv_id'], }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {hasItems(totalAmountPaidData) ? (
            <Button variant="primary" onClick={() => { handleExportDataGrandTotal(); handleClose() }} >
              <FileDownloadIcon /> Export All Data
            </Button>
          ) : (
            <span>No Data to Export</span>
          )
          }
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTAD}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Total Amount Due for {supAPVTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={GTotalColumns}
                data={totalADData}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact' }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {!isTotalADData ? (
            <Button variant="primary" onClick={() => { handleExportDataGrandTotal(); handleClose() }} >
              <FileDownloadIcon /> Export All Data
            </Button>
          ) : (
            <span>No Data to Export</span>
          )
          }
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSpecificAPV}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Amount Due for {SAPVTitle} Days for {supAPVTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <MaterialReactTable
                columns={CellColumns}
                data={SAPV}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                // enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: true }}
                initialState={{ density: 'compact' }}
              />
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {!isSAPV ? (
            <Button variant="primary" onClick={() => { handleExportDataTotal(); handleClose() }} >
              <FileDownloadIcon /> Export All Data
            </Button>
          ) : (
            <span>No Data to Export</span>
          )
          }

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <MaterialReactTable
        columns={tableColumn}
        data={agingData}
        initialState={{ density: 'compact', sorting: [{ id: 'apv_total_amount_due', desc: true, }] }}
        // enableSorting={false}
        enableColumnActions={false}
        enableHiding={false}
        enableDensityToggle={false}
        enablePagination={false}
      // enableGrouping
      // enableRowActions
      // renderRowActions={() => <a onClick={e => e.stopPropagation()}>Test</a>}   
      />
      {/* <button className="btn" onClick={populateAllAPV}>Console Log Data</button> */}
      {/* <button className="btn" onClick={handleExportFullTable}>Console Log Data</button> */}
    </div>
  );
};

export default AgingMRTable;