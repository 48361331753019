import React from "react";
import { Link } from "react-router-dom";
import style from "./SupplierCenterHome.module.css";

function SupplierCenterHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Suppliers Center Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/suppliers/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fa-solid fa-dolly"></i></p>
            View All Suppliers
          </div>
        </Link>
        <Link to="/suppliers/add_supplier" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Add Supplier
          </div>
        </Link>
        {/* <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Suppliers
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default SupplierCenterHome;
