import React from "react";
import { Link } from "react-router-dom";
import style from "./APVHome.module.css";

function APVHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>APV Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/apv/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View APVs
          </div>
        </Link>
        <Link to="/apv/add_apv" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Create APV
          </div>
        </Link>
      </div>
    </div>
  );
}

export default APVHome;
