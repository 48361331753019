import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ENCRYPTION_KEY, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, amountToWords, Required, swalWithBootstrapButtons } from '../../../utils/functions';
import PRTable from './PRTable';
import Select from 'react-select';
import { FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Switch } from 'antd';
import { decryptData } from '../../../utils/encryption';

const MySwal = withReactContent(Swal)

const PRForm = ({ viewEdit, onePRData, prInfoData, prItemsInfoData, prID }) => {

  const [editMode, setEditMode] = useState(false);
  const [editModeText, setEditModeText] = useState('CREATE PURCHASE REQUEST');

  const [poInfo, setPOInfo] = useState({});
  const [prInfo, setPRInfo] = useState({
    supplier_id: '',
    payment_method: '',
    pr_date: '',
    deliver_to: '',
    receiver: '',
    receiver_contact_number: '',
    required_delivery_date: '',
    revenue_center: '',
    is_payment_first: '',
    notes: '',
    total_vat_inc: '',
    grand_total: '',
    amount_in_words: '',
    deleted_items: [],
    pr_items_data: [],
  });

  const supplierListOptions = [
    // { value: 'BECOM', label: 'BECOM' },
    // { value: 'BMART', label: 'BMART' },
    // { value: 'BRET', label: 'BRET' },
  ];

  const [nameReq, setNameReq] = useState("");
  const [supplierID, setSupplierID] = useState("");
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [suppliersItems, setSuppliersItems] = useState([]);
  const [itemsInfoCimbined, setitemsInfoCimbined] = useState([]);
  const [isApproved, setisApproved] = useState(false);
  const [approveStatus, setapproveStatus] = useState('');
  const [PRListData, setPRListData] = useState([]);
  const [isActive, setIsActive] = useState(null);

  const totalVatIncRef = useRef(null);
  const grandTotalRef = useRef(null);
  const amountInWordsRef = useRef(null);

  const getName = () => {
    // getting stored value
    const saved = localStorage.getItem("n");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  const onChange = (e) => {
    if (e.target.name === 'supplier_id') {
      getSupplierInfo(e.target.value);
      setSupplierID(e.target.value);
      setPRInfo({ ...prInfo, [e.target.name]: e.target.value });
    }
    if (e.hasOwnProperty('target')) {
      if (e.target.type === 'file') {
        setPRInfo({ ...prInfo, [e.target.name]: e.target.files[0] });
      } else {
        setPRInfo({ ...prInfo, [e.target.name]: e.target.value });
      }
    } else { // For supplier_id field only
      getSupplierInfo(e.value);
      setSupplierID(e.value)
    }

  };

  // Main submit handler
  const CreatePurchaseRequestsPost = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Creating Purchase Request</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_request/create_pr`,
          data: prInfo,
          method: 'POST',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then((res) => {
          // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
          console.log(res.data)
          return MySwal.fire({
            title: <strong>Purchase Request Created Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch((err) => {
            console.log(err.response.data.message)
            return MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            }).then(() => {
              MySwal.close();
            })
          });

      }
    })
  }

  const UpdatePurchaseRequestsPost = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Purchase Request</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_request/update_pr`,
          data: prInfo,
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then((res) => {
          // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
          console.log(res.data)
          return MySwal.fire({
            title: <strong>Purchase Request Updated Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch((err) => {
            console.log(err.response.data.message)
            return MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            }).then(() => {
              MySwal.close();
            })
          });

      }
    })
  }

  const getActiveSuppliers = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data.suppliers_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  const getSupplierInfo = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_supplier`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setSupplierInfo(response.data.supplier_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  }

  function setSupplierListOptions() {
    allSuppliers.forEach(item => {
      const supplierOption = {
        value: item.supplier_id,
        label: item.supplier_name
      }
      supplierListOptions.push(supplierOption);
    });
    setPRListData(supplierListOptions);
  }

  const getSuppliersItems = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_suppliers_items`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setSuppliersItems(response.data.suppliers_items);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log(`Error in Fetching All Suppliers's Items!`);
      });
  }

  // Contains data from Purchase Request Items Table
  const handlePRItemsTableFormSubmit = (data, deletedItems) => {
    let curr_amounts_list = [];
    // Access the data from PRTable.jsx and perform necessary actions
    // console.log("Submitted data from PRTable:", data);
    data.forEach((item) => {
      // console.log(item.total_amount)
      curr_amounts_list.push(parseFloat(item.total_amount));
    })
    setOrderSummaryData(curr_amounts_list)
    return new Promise(resolve => {
      // console.log('deletedItems',deletedItems);
      let total = 0;
      curr_amounts_list.forEach((amount) => {
        total += amount;
      });
      // console.log("curr_amounts_list", total);
      setPRInfo({
        ...prInfo,
        total_vat_inc: total,
        grand_total: total,
        amount_in_words: amountInWordsRef.current.value,
        pr_date: new Date().toISOString().split('T')[0],
        supplier_id: supplierID,
        deleted_items: deletedItems,
        pr_items_data: data
      }, resolve);
    });

    // setPRInfo({ ...prInfo, pr_items_data: data });

  };

  const setOrderSummaryData = async (amounts_list) => {
    let total = 0;
    amounts_list.forEach((amount) => {
      total += amount;
    });
    // console.log(total);

    // Set form field values using refs
    totalVatIncRef.current.value = NumberToPhp(total);
    grandTotalRef.current.value = NumberToPhp(total);
    amountInWordsRef.current.value = amountToWords(total);
  };

  async function fetchItemData(ItemID, order_quantity, total_amount, cost_center, pr_items_id) {
    await fetch(`${PROCUREMENT_LINK}/api/item_center/get_item?item_id=` + ItemID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        var CostCenter = '';
        if (cost_center === null) {
          CostCenter = 'None';
        } else {
          CostCenter = cost_center;
        }
        const getItems = data.invoice_item_data;
        const dataArray1 = {
          pr_items_id: pr_items_id,
          item_id: getItems.item_id,
          item_name: getItems.item_name,
          sku_code: getItems.sku_code,
          uom: getItems.uom,
          qty_per_uom: getItems.qty_per_uom,
          price_per_uom: getItems.price_per_uom,
          cost_center: CostCenter,
          order_quantity: order_quantity,
          total_amount: total_amount
        }
        // combinePls.push(dataArray1)
        // console.log('dataArray1',dataArray1);
        setitemsInfoCimbined(itemsInfoCimbined => [...itemsInfoCimbined, dataArray1])
        // return getItems
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onClickEdit = _ => {
    setEditMode(true);
  }

  const clickApprove = () => {
    swalWithBootstrapButtons.fire({
      title: 'PR will be Approved, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Approving PR</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_request/approve_pr`,
          data: poInfo,
          method: 'POST',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY,
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Approving PR</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Approving PR</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  }

  const clickReject = () => {
    swalWithBootstrapButtons.fire({
      title: 'PR will be Reject, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Rejecting PR</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_request/reject_pr`,
          params: { pr_id: prID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Rejecting PR</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Rejecting PR</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this PR, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_request/change_pr_active_status`,
          params: { pr_id: prID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Updating Active/Inactive Status!</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  useEffect(() => {
    try {
      if (onePRData.pr_info.is_approved === true) {
        setisApproved(true);
        setapproveStatus('PR is already Approved');
      } else if (onePRData.pr_info.is_approved === false) {
        setisApproved(true);
        setapproveStatus('PR is already Rejected');
      } else {
        setisApproved(false);
      }
    } catch (err) { }
  }, [onePRData]);

  useEffect(() => {
    getActiveSuppliers();

    const name = getName();
    if (name) {
      const decryptName = decryptData(name, ENCRYPTION_KEY);
      setNameReq(decryptName);
    } else {
      setNameReq('No User');
    }
  }, []);

  useEffect(() => {
    // console.log('prInfo',prInfo);
    if (viewEdit) {
      setPOInfo({
        pr_id: prInfo.pr_id,
        supplier_id: prInfo.supplier_id,
        payment_method: prInfo.payment_method,
        pr_date: prInfo.pr_date,
        deliver_to: prInfo.deliver_to,
        receiver: prInfo.receiver,
        receiver_contact_number: prInfo.receiver_contact_number,
        required_delivery_date: prInfo.required_delivery_date,
        revenue_center: prInfo.revenue_center,
        is_payment_first: prInfo.is_payment_first,
        notes: prInfo.notes,
        total_vat_inc: prInfo.total_vat_inc,
        grand_total: prInfo.grand_total,
        amount_in_words: prInfo.amount_in_words,
        deleted_items: prInfo.deleted_items,
        po_items_data: prInfo.pr_items_data,
      })
    }
  }, [prInfo]);

  useEffect(() => {
    // console.log('poInfo',poInfo);
  }, [poInfo]);

  useEffect(() => {
    // getActiveSuppliers();
  }, [supplierID]);

  useEffect(() => {
    setSupplierListOptions();
  }, [allSuppliers]);

  useEffect(() => {
    // supplierDataSet();
    // console.log('supplierInfo',supplierInfo);
  }, [supplierInfo]);

  useEffect(() => {
    if (viewEdit) {
      setEditModeText('VIEW PURCHASE REQUEST')
    }
  }, [viewEdit])

  useEffect(() => {
    try {
      if (prInfoData.is_active) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
      if (prInfoData.supplier_id) {
        getSuppliersItems(prInfoData.supplier_id);
        getSupplierInfo(prInfoData.supplier_id);
        setSupplierID(prInfoData.supplier_id);
        // setPRInfo(prInfoData)
        setPRInfo(
          {
            ...prInfo,
            pr_id: prID,
            supplier_id: prInfoData.supplier_id,
            payment_method: prInfoData.payment_method,
            pr_date: prInfoData.pr_date,
            deliver_to: prInfoData.deliver_to,
            receiver: prInfoData.receiver,
            receiver_contact_number: prInfoData.receiver_contact_number,
            required_delivery_date: prInfoData.required_delivery_date,
            revenue_center: prInfoData.revenue_center,
            is_payment_first: prInfoData.is_payment_first,
            notes: prInfoData.notes,
            total_vat_inc: prInfoData.total_vat_inc,
            grand_total: prInfoData.grand_total,
            amount_in_words: prInfoData.amount_in_words,
            deleted_items: [],
            pr_items_data: prItemsInfoData,
          }
        )
        // console.log(prInfo);
      }
    } catch (err) {
      if (viewEdit) {
        console.log(err);
      }
    }
  }, [prInfoData])

  useEffect(() => {
    try {
      if (prItemsInfoData[0].pr_items_id) {
        setitemsInfoCimbined([])
        prItemsInfoData.forEach(e => {
          fetchItemData(e.item_id, e.order_quantity, e.total_amount, e.cost_center, e.pr_items_id);
        });
      }
    } catch (err) { }
  }, [prItemsInfoData])

  useEffect(() => {

  }, [suppliersItems])

  useEffect(() => {
    // console.log(PRListData);
  }, [PRListData])

  useEffect(() => {
    // console.log('itemsInfoCimbined',itemsInfoCimbined);
  }, [itemsInfoCimbined])

  const formRequired = false;

  return (
    <FormContent>
      <FormTitle>
        <Stack direction='horizontal' gap={2}>
          <div>
            <p className='text-uppercase'>{editModeText}</p>
          </div>
          <div className='ms-auto' style={{ paddingRight: '10px' }}>
            <p className='text-upppercase'>{approveStatus}</p>
          </div>
        </Stack>
      </FormTitle>
      <FormInfoFull>
        <Form onSubmit={!editMode ? CreatePurchaseRequestsPost : UpdatePurchaseRequestsPost} name="PurchaseRequestsForm" id='PurchaseRequestsForm'>
          {
            viewEdit && (
              <Stack gap={2}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>PR ID: {prID}</span>
                </div>
                <div>
                  Status: &nbsp;
                  <Switch
                    style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                    checkedChildren={'Active'}
                    unCheckedChildren={'Inactive'}
                    checked={isActive}
                    onClick={handleIsActive}
                    size="small"
                  />
                </div>
              </Stack>
            )
          }
          <div className="mt-3" style={{ display: "flex" }}>
            <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
              <h4 className="mb-3">Supplier Information</h4>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                  <Form.Label>Supplier Name</Form.Label><Required />
                  {
                    viewEdit ? (
                      <>
                        {/* <Select options={PRListData} defaultValue={supplierInfo.supplier_id} name="supplier_id" onChange={onChange} required={formRequired} isSearchable /> */}
                        <Form.Select name="supplier_id" value={supplierInfo.supplier_id} aria-label="supplier_id" required={formRequired} disabled={!editMode} onChange={onChange}>
                          <option value=''>Select Supplier</option>
                          {
                            PRListData.map((e, i) => (
                              <option key={i} value={e.value} >{e.label}</option>
                            ))
                          }
                        </Form.Select>
                      </>
                    ) : (
                      // <Select options={supplierListOptions} name="supplier_id" onChange={onChange} required={formRequired} isSearchable />
                      <Form.Select name="supplier_id" value={supplierInfo.supplier_id} aria-label="supplier_id" required={formRequired} onChange={onChange}>
                        <option value='' >Select Supplier</option>
                        {
                          PRListData.map((e, i) => (
                            <option key={i} value={e.value} >{e.label}</option>
                          ))
                        }
                      </Form.Select>
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="attention">
                  <Form.Label>Attention</Form.Label>
                  {/* {
                                    viewEdit ? (
                                        <Form.Control type="text" defaultValue={supplierInfo.attention} name="attention" disabled />
                                    ) : (
                                        <Form.Control type="text" defaultValue={supplierInfo.attention} placeholder="Attention" name="attention" disabled />
                                    )
                                } */}
                  <Form.Control type="text" defaultValue={supplierInfo.attention} placeholder="Attention" name="attention" disabled />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="email_address">
                  <Form.Label>Email Address</Form.Label>
                  {/* {
                                    viewEdit ? (
                                        <Form.Control type="email" defaultValue={supplierInfo.email_address} name="email_address" disabled />
                                    ) : (
                                        <Form.Control type="email" defaultValue={supplierInfo.email_address} placeholder="Email Address" name="email_address" disabled />
                                    )
                                } */}
                  <Form.Control type="email" defaultValue={supplierInfo.email_address} placeholder="Email Address" name="email_address" disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="contact_person">
                  <Form.Label>Contact Person</Form.Label>
                  {/* {
                                    viewEdit ? (
                                        <Form.Control type="text" defaultValue={supplierInfo.contact_person} name="contact_person" disabled />
                                    ) : (
                                        <Form.Control type="text" defaultValue={supplierInfo.contact_person} placeholder="Contact Person" name="contact_person" disabled />
                                    )
                                } */}
                  <Form.Control type="text" defaultValue={supplierInfo.contact_person} placeholder="Contact Person" name="contact_person" disabled />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="mobile_number">
                  <Form.Label>Contact Number</Form.Label>
                  {/* {
                                    viewEdit ? (
                                        <Form.Control type="text" defaultValue={supplierInfo.mobile_number} name="mobile_number" disabled />
                                    ) : (
                                        <Form.Control type="text" defaultValue={supplierInfo.mobile_number} placeholder="Contact Number" name="mobile_number" disabled />
                                    )
                                } */}
                  <Form.Control type="text" defaultValue={supplierInfo.mobile_number} placeholder="Contact Number" name="mobile_number" disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="address">
                  <Form.Label>Address</Form.Label>
                  {/* {
                                    viewEdit ? (
                                        <Form.Control type="text" defaultValue={supplierInfo.address} name="address" disabled />
                                    ) : (
                                        <Form.Control type="text" defaultValue={supplierInfo.address} placeholder="Address" name="address" disabled />
                                    )
                                } */}
                  <Form.Control type="text" defaultValue={supplierInfo.address} placeholder="Address" name="address" disabled />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="payment_method">
                <Form.Label>Payment Method</Form.Label><Required />
                {
                  viewEdit ? (
                    <Form.Control type="text" defaultValue={prInfoData.payment_method} name="payment_method" onChange={onChange} disabled={!editMode} required={formRequired} />
                  ) : (
                    <Form.Control type="text" placeholder="Enter Payment Method" name="payment_method" onChange={onChange} required={formRequired} />
                  )
                }
              </Form.Group>
            </div>
            <div className="rounded border p-3 mb-4" style={{ flex: "1" }}>
              <h4 className="mb-3">Purchase Request Details</h4>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="pr_date">
                  <Form.Label>PR Date</Form.Label>
                  {
                    viewEdit ? (
                      <Form.Control type="date" defaultValue={prInfoData.pr_date} name="pr_date" disabled />
                    ) : (
                      <Form.Control type="date" placeholder="Enter Payment Method" name="pr_date" value={new Date().toISOString().split('T')[0]} onChange={onChange} disabled />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="requestor" className="mb-3">
                  <Form.Label>Requestor</Form.Label>
                  <Form.Control type="text" name="requestor" value={nameReq} onChange={onChange} disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="deliver_to" className="mb-3">
                  <Form.Label>Deliver To</Form.Label><Required />
                  {
                    viewEdit ? (
                      <Form.Control type="text" defaultValue={prInfoData.deliver_to} name="deliver_to" onChange={onChange} disabled={!editMode} required={formRequired} />
                    ) : (
                      <Form.Control type="text" name="deliver_to" onChange={onChange} required={formRequired} />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="receiver" className="mb-3">
                  <Form.Label>Receiver</Form.Label><Required />
                  {
                    viewEdit ? (
                      <Form.Control type="text" defaultValue={prInfoData.receiver} name="receiver" onChange={onChange} disabled={!editMode} required={formRequired} />
                    ) : (
                      <Form.Control type="text" name="receiver" onChange={onChange} required={formRequired} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="receiver_contact_number" className="mb-3">
                  <Form.Label>Receiver Contact Number</Form.Label><Required />
                  {
                    viewEdit ? (
                      <Form.Control type="text" defaultValue={prInfoData.receiver_contact_number} name="receiver_contact_number" onChange={onChange} disabled={!editMode} required={formRequired} />
                    ) : (
                      <Form.Control type="text" name="receiver_contact_number" onChange={onChange} required={formRequired} />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="required_delivery_date" className="mb-3">
                  <Form.Label>Required Delivery Date</Form.Label><Required />
                  {
                    viewEdit ? (
                      <Form.Control type="date" defaultValue={prInfoData.required_delivery_date} min={new Date().toLocaleString("sv-SE", { timeZone: "Asia/Manila" }).split(' ')[0]} name="required_delivery_date" onChange={onChange} disabled={!editMode} required={formRequired} />
                    ) : (
                      <Form.Control type="date" name="required_delivery_date" onChange={onChange} min={new Date().toLocaleString("sv-SE", { timeZone: "Asia/Manila" }).split(' ')[0]} required={formRequired} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="revenue_center" className="mb-3">
                  <Form.Label>Revenue Center</Form.Label><Required />
                  {
                    viewEdit ? (
                      !editMode ? (
                        <Form.Control type="text" defaultValue={prInfoData.revenue_center} name="revenue_center" disabled />
                      ) : (
                        <Form.Select defaultValue={prInfoData.revenue_center} name="revenue_center" onChange={onChange}>
                          <option value="">Select Revenue Center</option>
                          <option value="BECOM">BECOM</option>
                          <option value="BMART">BMART</option>
                          <option value="BRET">BRET</option>
                        </Form.Select>
                      )
                    ) : (
                      <Form.Select name="revenue_center" onChange={onChange} required={formRequired} >
                        <option value="">Select Revenue Center</option>
                        <option value="BECOM">BECOM</option>
                        <option value="BMART">BMART</option>
                        <option value="BRET">BRET</option>
                      </Form.Select>
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="is_payment_first" className="mb-3">
                  <Form.Label>Payment First?</Form.Label><Required />
                  {
                    viewEdit ? (
                      !editMode ? (
                        <Form.Select value={prInfoData.is_payment_first} name="is_payment_first" disabled >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      ) : (
                        <Form.Select defaultValue={prInfoData.is_payment_first} name="is_payment_first" onChange={onChange} >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      )
                    ) : (
                      <Form.Select name="is_payment_first" onChange={onChange} required={formRequired} >
                        <option value="">Does this PR require payment first?</option>
                        <option value="true">YES</option>
                        <option value="false">NO</option>
                      </Form.Select>
                    )
                  }
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="mt-3" style={{ width: "100%", display: "flex" }}>
            <div className="rounded border p-3 mb-4" style={{ width: "100%", flex: "1", marginRight: "10px" }}>
              <h4 className="mb-3">Purchase Request Items</h4>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                  <PRTable supplier_id={supplierID} onSubmit={handlePRItemsTableFormSubmit} items_info={itemsInfoCimbined} editMode={viewEdit ? editMode : true} proc_page={'PR'} />
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="mt-3" style={{ display: "flex" }}>
            <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
              <h4 className="mb-3">Order Summary</h4>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  {
                    viewEdit ? (
                      <Form.Control type="text" defaultValue={prInfoData.notes} name="notes" onChange={onChange} disabled={!editMode} />
                    ) : (
                      <Form.Control type="text" placeholder="Enter Notes" name="notes" onChange={onChange} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="total_vat_inc">
                  <Form.Label>Total (Vat Inc)</Form.Label>
                  <Form.Control type="text" placeholder="Total (Vat Inc)" name="total_vat_inc" ref={totalVatIncRef} onChange={onChange} disabled />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="grand_total">
                  <Form.Label>Grand Total</Form.Label>
                  <Form.Control type="email" placeholder="Grand Total" name="grand_total" ref={grandTotalRef} onChange={onChange} disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="amount_in_words">
                  <Form.Label>Amount in Words</Form.Label>
                  <Form.Control className='text-capitalize' type="text" placeholder="Amount in Words" name="amount_in_words" ref={amountInWordsRef} onChange={onChange} disabled />
                </Form.Group>
              </Row>

            </div>
          </div>
        </Form>
        {
          viewEdit ? (
            !editMode ? (
              !isApproved ? (
                <Stack direction='horizontal' gap={3}>
                  <div>
                    <button className='btn btn-primary btn-sm' type="button" onClick={onClickEdit}>Edit</button>
                  </div>
                  <div className='ms-auto'>
                    <button className='btn btn-success btn-sm' type="button" onClick={clickApprove}>Approve</button>
                  </div>
                  <div>
                    <button className='btn btn-danger btn-sm' type="button" onClick={clickReject}>Reject</button>
                  </div>
                </Stack>
              ) : (
                <div>
                  <button className='btn btn-sm btn-success' type='button' disabled>{approveStatus}</button>
                </div>
              )
            ) : (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <button className='btn btn-danger btn-sm' type="button" onClick={handleClickRefresh}>Cancel</button>
                </div>
                <div className='ms-auto'>
                  <button className='btn btn-primary btn-sm' type="submit" form='PurchaseRequestsForm'>Submit</button>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div>
                <button className='btn btn-primary btn-sm' type="submit" form='PurchaseRequestsForm'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default PRForm;