import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, Form, Col, Row, InputGroup, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfo } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';

const Required = _ => {
  return (
    <span style={{ color: "red" }}>*</span>
  )
}


const MySwal = withReactContent(Swal)

const AddBank = (props) => {


  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [counter, setCounter] = useState(false);

  const [bankArr, setBankArr] = useState({
    account_name: '',
    account_nickname: '',
    account_code: '',
    bank_name: '',
    bank_type: '',
    bank_account_number: '',
    starting_balance: '',
    current_balance: '',
  });

  const onChange = (e) => {
    if (e.target.name === 'starting_balance') {
      setBankArr({ ...bankArr, starting_balance: e.target.value, current_balance: e.target.value });
    } else {
      setBankArr({ ...bankArr, [e.target.name]: e.target.value });
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const addBankPost = (e) => {

    const form = e.currentTarget;
    console.log(bankArr)

    // e.preventDefault();


    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'Please wait',
        text: "Populating data",
        // footer: "Click anywhere outside the box to exit",
        // confirmButtonText: 'OK',
        // cancelButtonText: 'Cancel',
        timer: 1000,
        timerProgressBar: true,
        // showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false,
        allowOutsideClick: false
      }).then(_ => {
        swalWithBootstrapButtons.fire({
          title: 'Are you sure you want to Submit?',
          text: "You won't be able to revert this!",
          footer: "Click anywhere outside the box to cancel",
          confirmButtonText: 'Submit',
          // cancelButtonText: 'Cancel',
          timer: 5000,
          timerProgressBar: true,
          // showCancelButton: true,
          showConfirmButton: true,
          showCloseButton: false
        }).then((result) => {
          if (result.isConfirmed) {
            MySwal.fire({
              title: <p>Creating Bank Account</p>,
              allowOutsideClick: false,
              didOpen: () => {
                MySwal.showLoading()
              },
            })
            axios({
              url: 'https://finance-test.fly.dev/api/bank_center/add_bank',
              data: bankArr,
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
              },
            }).then((res) => {
              console.log(res.data)
              return MySwal.fire({
                title: <strong>Bank Account successfully added</strong>,
                icon: 'success'
              }).then(() => {
                window.location.reload();
              })


              // Push to /
              // navigate('/');
            })
              .catch((err) => {
                console.log(err)
                console.log('Error in Adding Item!');
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'Cancelled'
            )
          }
        })
      })

    }
    e.preventDefault();
  }

  const handleSubmit = (event) => {

    setBankArr({
      ...bankArr,
      account_name: event.target.elements.account_name.value, //Account Name
      account_nickname: event.target.elements.account_nickname.value, //Account Nickname
      account_code: event.target.elements.account_code.value, //Account Code
      bank_name: event.target.elements.bank_name.value, //Bank Name
      bank_type: event.target.elements.bank_type.value, //Bank type
      bank_account_number: event.target.elements.bank_account_number.value, //Bank Account Number
      starting_balance: event.target.elements.starting_balance.value, //Starting Balance
      current_balance: event.target.elements.starting_balance.value, //Current Balance
    });
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      // event.stopPropagation();
      MySwal.fire({
        title: 'Please Fill out Required Fields',
        icon: 'warning',
        cancelButtonText: 'OK',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        showCloseButton: false
      })
    }

    if (form.checkValidity() === true) {
      // event.preventDefault();
      // event.stopPropagation();
      setCounter(true);
      addBankPost(event);
    } else {

      setValidated(true);
      // console.log(rfp)
      addBankPost(event);

    }

  };

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal">
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location',
              },
              {
                type: 'separator',
                separator: ':',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../bank_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../bank_center/view_all_bank">Bank List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New Bank Account',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Add New Bank</p></FormTitle>
        <Form noValidate validated={validated} onSubmit={handleSubmit} name="addBankForm">
          <FormInfo>
            <Row>
              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_name">
                  <Form.Label column sm="5">Account Name<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" placeholder="Enter Account Name" name="account_name" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_nickname">
                  <Form.Label column sm="5">Account Nickname<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" placeholder="Enter Account Nickname" name="account_nickname" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Nickname.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="account_code">
                  <Form.Label column sm="5">Account Code<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" placeholder="Enter Account Code" name="account_code" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Code.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="bank_name">
                  <Form.Label column sm="5">Bank Name<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" placeholder="Enter Bank Name" name="bank_name" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Bank Name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </FormInfo>
          <FormInfo>
            <Row>
              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="bank_type">
                  <Form.Label column sm="5">Bank type<Required />: </Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Select name="bank_type" aria-label="bank_type" required onChange={onChange}>
                        <option value="">Select Bank Type</option>
                        <option name="bank_type" value="Savings Account">Savings Account</option>
                        <option name="bank_type" value="Checking Account">Checking Account</option>
                      </Form.Select>
                      <Form.Control.Feedback tooltip type="invalid">
                        Please select Bank Type.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="bank_account_number">
                  <Form.Label column sm="5">Bank Account Number<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <Form.Control type="text" placeholder="Enter Bank Account Number" name="bank_account_number" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Bank Account Number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='col-md-12'>
                <Form.Group size="sm" as={Row} className="mb-3" controlId="starting_balance">
                  <Form.Label column sm="5">Starting Balance<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">₱</InputGroup.Text>
                      <Form.Control type="number" step="0.01" min="0" aria-describedby="inputGroupPrepend" placeholder="0.00" name="starting_balance" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Starting Balance.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col className='mb-3'></Col>
              <Col lg={2}>
                <Button variant="success" className='btn-sm' type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </FormInfo>
        </Form>
      </FormContent>
    </FormContainer>
  );
};

export default AddBank;