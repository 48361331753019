import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, TwoDecimalNum, hasItems } from '../../../utils/functions';


const ViewAllCV = () => {

  const [allCVData, setAllCVData] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [combine, setCombine] = useState([]);

  async function getallCV() {
    await axios({
      url: `${FINANCE_LINK}/api/cash_voucher/get_all_cv`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then((response) => {
        setAllCVData(response.data['all_cv']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  async function getallSuppliers() {
    await axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_all_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data['suppliers_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  const columns = [
    {
      data: 'cv_id',
      text: 'CV ID',
      render: function (data, type) {
        return `<a href='/cv/view_cv/${data}'>${data}</a>`;
      }
    },
    {
      data: 'cv_date',
      text: 'CV Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: 'due_date',
      text: 'Due Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: 'supplier_id',
      text: 'Supplier ID',
      render: function (data, type, row) {
        // console.log(row['supplier_id'],data);
        if (data === null || data === '') {
          if (row['cv_type'] === 'Credit') {
            return 'Credit (No Supplier)';
          } else {
            return 'RFP (No Supplier)';
          }
        } else {
          return `<a target='_blank' href='/suppliers/view_supplier/${data}'>${data}</a>`;
        }
      }
    },
    {
      data: 'particulars',
      text: 'Memo',
    },
    {
      data: 'total_amount_paid',
      text: 'Amount',
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00';
        } else {
          return NumberToPhp(data);
        }
      }
    },
  ];

  useEffect(() => {
    getallSuppliers();
    getallCV();
  }, [])

  useEffect(() => {
    // console.log('allSuppliers',allSuppliers);
  }, [allSuppliers])

  useEffect(() => {
    // console.log('combine',combine);
  }, [combine])


  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#TableAPVData")) {
      $("#TableAPVData").DataTable({
        responsive: true,
        data: allCVData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -1,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    } else {
      $("#TableAPVData").DataTable().destroy();
      $("#TableAPVData").DataTable({
        responsive: true,
        data: allCVData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -1,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    }
  }, [allCVData]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal' gap={3}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="/cv/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'CV List'
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Cash Voucher (CV)</p></FormTitle>
        <FormInfoFull>
          <div className="container-fluid" rel="noopener noreferrer">
            <table id="TableAPVData" className="display">
              <thead>
                <tr>
                  <th>CV ID</th>
                  <th>CV Date</th>
                  <th>Due Date</th>
                  <th>Supplier ID</th>
                  <th>Memo</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <Link className='btn btn-success btn-sm' to='/cv/create_cv' >Add CV</Link>
          </div>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllCV;
