import { NavLink } from "react-router-dom";
import style from "./sidebar.module.css";
import React, { useState, useRef, useEffect } from "react";
import classes from "./Content.module.css";
import { useLocation } from "react-router-dom";
import usePermission from "../../usePermission/usePermission";
import { hasItems } from "../../../utils/functions";

const Content = () => {
  const activeNav = ({ isActive }) => (isActive ? style.active : undefined);
  const { getPermissionByModule } = usePermission();
  const permission = getPermissionByModule("MOD00000046");

  const [openAF, setOpenAF] = useState(false);
  const [openPro, setOpenPro] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("apv") ||
      location.pathname.includes("ap_aging") ||
      location.pathname.includes("cv") ||
      location.pathname.includes("rfp") ||
      location.pathname.includes("account_center")
    ) {
      setOpenAF(true);
      setOpenPro(false);
      setOpenUser(false);
    } else if (location.pathname.includes("sysad")) {
      setOpenPro(false);
      setOpenAF(false);
      setOpenUser(true);
    } else if (
      location.pathname.includes("purchase_request") ||
      location.pathname.includes("purchase_order") ||
      location.pathname.includes("recieving_receipt") ||
      location.pathname.includes("suppliers") ||
      location.pathname.includes("item_center")
    ) {
      setOpenUser(false);
      setOpenAF(false);
      setOpenPro(true);
    }
  }, [location]);

  useEffect(() => {
    if (hasItems(permission)) {
      // console.log('permission',permission);
      // console.log('module_id',permission['module_id']);
      // console.log('hasView',permission['hasView']);
    }
  }, [permission])


  const onOpenAFHandler = () => {
    setOpenAF(!openAF);
  };

  const onOpenProHandler = () => {
    setOpenPro(!openPro);
  };

  const onOpenUserHandler = () => {
    setOpenUser(!openUser);
  };

  return (
    <nav>
      <>
        <ul>
          <li>
            <NavLink to="/dashboard" className={activeNav}>
              <span className={style.fontawesome_icon}>
                <i className="fa-solid fa-house"></i>
              </span>
              Home
            </NavLink>
          </li>
        </ul>
        {
          hasItems(permission) && (
            <ul>
              <li>
                <button onClick={onOpenAFHandler}>
                  <span className={style.fontawesome_icon}>
                    <i className="fa-solid fa-calculator"></i>
                  </span>{" "}
                  Accounting and Finance
                  {!openAF ? (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-down"></i>
                    </i>
                  ) : (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-up"></i>
                    </i>
                  )}
                </button>
              </li>
              {openAF && (
                <ul className={classes.dropdown_content}>

                  <li>
                    <NavLink to="/account_center" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-money-bill-transfer"></i>
                      </span>
                      Account Center
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/apv/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-money-bill-wave"></i>
                      </span>
                      Accounts Payable Voucher
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/ap_aging" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-sign-hanging"></i>
                      </span>
                      AP Aging
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/cv/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      Cash Voucher
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/rfp/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-money-check-dollar"></i>
                      </span>
                      Request for Payment
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/journal_voucher/view_all" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-money-check-dollar"></i>
                      </span>
                      Journal Voucher
                    </NavLink>
                  </li>

                </ul>
              )}

              <li>
                <button onClick={onOpenUserHandler}>
                  <span className={style.fontawesome_icon}>
                    <i className="fa-solid fa-user-pen"></i>
                  </span>
                  Identity Management
                  {!openUser ? (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-down"></i>
                    </i>
                  ) : (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-up"></i>
                    </i>
                  )}
                </button>
              </li>

              {openUser && (
                <div className={classes.dropdown_content}>
                  <li>
                    <NavLink
                      to="/sysad/role_management/view_all"
                      className={activeNav}
                    >
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-user-shield"></i>
                      </span>
                      Role Management
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/sysad/module_management/view_all"
                      className={activeNav}
                    >
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-cubes"></i>
                      </span>
                      Module Management
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/sysad/permissions_management/view_all"
                      className={activeNav}
                    >
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-user-lock"></i>
                      </span>
                      Permission Management
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/sysad/user_management" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-users-gear"></i>
                      </span>
                      User Management
                    </NavLink>
                  </li>
                </div>
              )}

              <li>
                <button onClick={onOpenProHandler}>
                  <span className={style.fontawesome_icon}>
                    <i className="fa-solid fa-cart-flatbed"></i>
                  </span>
                  Procurement
                  {!openPro ? (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-down"></i>
                    </i>
                  ) : (
                    <i className={classes.i_icon}>
                      <i className="fa-solid fa-caret-up"></i>
                    </i>
                  )}
                </button>
              </li>

              {openPro && (
                <div className={classes.dropdown_content}>

                  <li>
                    <NavLink to="/suppliers/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-truck-ramp-box"></i>
                      </span>
                      Supplier Center
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/item_center/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-boxes-packing"></i>
                      </span>
                      Item Center
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/purchase_requests/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-file-invoice-dollar"></i>
                      </span>
                      Purchase Request
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/purchase_order/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-hand-holding-dollar"></i>
                      </span>
                      Purchase Order
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/recieving_receipt/home" className={activeNav}>
                      <span
                        className={`${style.fontawesome_icon} ${style.fi_margin}`}
                      >
                        <i className="fa-solid fa-file-invoice"></i>
                      </span>
                      Receiving Receipt
                    </NavLink>
                  </li>

                </div>
              )}
            </ul>
          )
        }
      </>
    </nav>
  );
};

export default React.memo(Content);
