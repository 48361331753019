import React, { useState, useEffect } from 'react';
import UserForm from './UserForm';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Breadcrumb } from 'antd';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { decryptData } from '../../../../utils/encryption';
import { ENCRYPTION_KEY } from '../../../../utils/env_config';
// import Cookies from 'js-cookie';

const CreateUser = (props) => {

  var editView = false;
  const [token, setToken] = useState();

  const getToken = () => {
    // getting stored value
    const saved = localStorage.getItem("t");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, "ABC"));
  };

  useEffect(() => {
    const initialValue = getToken();
    if (initialValue) {
      const DataToken = decryptData(initialValue, ENCRYPTION_KEY);
      setToken(DataToken);
    }
  }, [])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/user_management">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/user_management/user_list">User List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New User',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      {/* User Management Form */}
      <UserForm editView={editView} UserToken={token} />

    </FormContainer>
  );
};

export default CreateUser;