import React from "react";
import { Link } from "react-router-dom";
import style from "./ClientCenterHome.module.css";

function ClientCenterHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Client Center Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/clients/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-people-arrows"></i></p>
            View All Clients
          </div>
        </Link>
        <Link to="/clients/add_client" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Add Client
          </div>
        </Link>
        <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Client
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ClientCenterHome;
