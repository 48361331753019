import React, { useState, useEffect, useRef } from 'react';
import { Link, json } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { useParams } from 'react-router-dom';
import PRTable from '../purchaseRequests/PRTable';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb, Switch } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { NumberToPhp, amountToWords, hasItems, swalWithBootstrapButtons } from '../../../utils/functions';

const MySwal = withReactContent(Swal)

const Required = _ => {
  return (
    <span style={{ color: "red" }}>*</span>
  )
}

const ViewPO = (props) => {

  const { poID } = useParams();

  const [editMode, setEditMode] = useState(false);

  const [poInfo, setPOInfo] = useState({
    supplier_id: '',
    payment_method: '',
    pr_date: '',
    deliver_to: '',
    receiver: '',
    receiver_contact_number: '',
    required_delivery_date: '',
    revenue_center: '',
    is_payment_first: '',
    notes: '',
    total_vat_inc: '',
    grand_total: '',
    amount_in_words: '',
    deleted_items: [],
    rr_items_data: [],
    pr_items_data: [],
  });

  const supplierListOptions = [
    // { value: 'BECOM', label: 'BECOM' },
    // { value: 'BMART', label: 'BMART' },
    // { value: 'BRET', label: 'BRET' },
  ];

  const [supplierID, setSupplierID] = useState("");
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [suppliersItems, setSuppliersItems] = useState([]);
  const [itemsInfoCimbined, setitemsInfoCimbined] = useState([]);
  const [isApproved, setisApproved] = useState(false);
  const [isGeneratedRR, setIsGeneratedRR] = useState(false);
  const [approveStatus, setapproveStatus] = useState('');
  const [POListData, setPOListData] = useState([]);
  const [onePOData, setOnePOData] = useState([]);
  const [poInfoData, setPOInfoData] = useState([]);
  const [poItemsInfoData, setPOItemsInfoData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [isApproveStatus, setIsApproveStatus] = useState(false);

  const totalVatIncRef = useRef(null);
  const grandTotalRef = useRef(null);
  const amountInWordsRef = useRef(null);

  async function fetchOnePOData() {
    await fetch(`${PROCUREMENT_LINK}/api/purchase_order/get_po?po_id=` + poID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setOnePOData(data.po_data);
        setPOInfoData(data.po_data.po_info);
        setPOItemsInfoData(data.po_data.po_items_info);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onChange = (e) => {
    if (e.target.name === 'supplier_id') {
      getSupplierInfo(e.target.value);
      setSupplierID(e.target.value);
      setPOInfo({ ...poInfo, [e.target.name]: e.target.value });
    }
    if (e.hasOwnProperty('target')) {
      if (e.target.type === 'file') {
        setPOInfo({ ...poInfo, [e.target.name]: e.target.files[0] });
      } else {
        setPOInfo({ ...poInfo, [e.target.name]: e.target.value });
      }
    } else { // For supplier_id field only
      getSupplierInfo(e.value);
      setSupplierID(e.value)
    }

  };

  const UpdatePOPost = (e) => {
    // console.log(poInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Purchase Order</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_order/update_po`,
          data: poInfo,
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then((res) => {
          // ReactDOM.findDOMNode(this.RRForm).reset();
          //   console.log(res.data)
          return MySwal.fire({
            title: <strong>Purchase Order Updated Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch((err) => {
            console.log(err)
            return MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            }).then(() => {
              MySwal.close();
            })
          });

      }
    })
  }

  const getActiveSuppliers = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data.suppliers_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  const getSupplierInfo = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_supplier`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setSupplierInfo(response.data.supplier_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  }

  function setSupplierListOptions() {
    allSuppliers.forEach(item => {
      const supplierOption = {
        value: item.supplier_id,
        label: item.supplier_name
      }
      supplierListOptions.push(supplierOption);
    });
    setPOListData(supplierListOptions);
  }

  const getSuppliersItems = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_suppliers_items`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setSuppliersItems(response.data.suppliers_items);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log(`Error in Fetching All Suppliers's Items!`);
      });
  }

  // Contains data from Purchase Order Items Table
  const handlePRItemsTableFormSubmit = (data, deletedItems) => {
    let curr_amounts_list = [];
    // Access the data from PRTable.jsx and perform necessary actions
    // console.log("Submitted data from PRTable:", data);
    data.forEach((item) => {
      // console.log(item.total_amount)
      curr_amounts_list.push(parseFloat(item.total_amount));
    })
    setOrderSummaryData(curr_amounts_list)
    return new Promise(resolve => {
      //   console.log('deletedItems',deletedItems);
      let total = 0;
      curr_amounts_list.forEach((amount) => {
        total += amount;
      });
      //   console.log("data", data);
      setPOInfo({
        ...poInfo,
        total_vat_inc: total,
        grand_total: total,
        amount_in_words: amountInWordsRef.current.value,
        pr_date: new Date().toISOString().split('T')[0],
        supplier_id: supplierID,
        deleted_items: deletedItems,
        po_items_info: data
      }, resolve);
    });

    // setPOInfo({ ...poInfo, po_items_data: data });

  };

  const setOrderSummaryData = async (amounts_list) => {
    let total = 0;
    amounts_list.forEach((amount) => {
      total += amount;
    });
    // console.log(total);

    // Set form field values using refs
    totalVatIncRef.current.value = NumberToPhp(total);
    grandTotalRef.current.value = NumberToPhp(total);
    amountInWordsRef.current.value = amountToWords(total);
  };

  async function fetchItemData(ItemID, order_quantity, total_amount, cost_center, po_items_id) {
    await fetch(`${PROCUREMENT_LINK}/api/item_center/get_item?item_id=` + ItemID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        var CostCenter = '';
        if (cost_center === null) {
          CostCenter = 'None';
        } else {
          CostCenter = cost_center;
        }
        const getItems = data.invoice_item_data;
        const dataArray1 = {
          po_items_id: po_items_id,
          item_id: getItems.item_id,
          item_name: getItems.item_name,
          sku_code: getItems.sku_code,
          uom: getItems.uom,
          qty_per_uom: getItems.qty_per_uom,
          price_per_uom: getItems.price_per_uom,
          cost_center: CostCenter,
          order_quantity: order_quantity,
          total_amount: total_amount,
        }
        // combinePls.push(dataArray1)
        setitemsInfoCimbined(itemsInfoCimbined => [...itemsInfoCimbined, dataArray1])
        // return getItems
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onClickEdit = _ => {
    setEditMode(true);
  }

  const clickApprove = () => {
    swalWithBootstrapButtons.fire({
      title: 'PO will be Approved, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Approving PO</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_order/approve_po`,
          params: { po_id: poID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY,
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Approving PO</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Approving PO</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  }

  const clickReject = () => {
    swalWithBootstrapButtons.fire({
      title: 'PO will be Reject, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Rejecting PO</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_order/reject_po`,
          params: { po_id: poID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Rejecting PO</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Rejecting PO</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this PO, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/purchase_order/change_po_active_status`,
          params: { po_id: poID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in updating Active/Inactive Status</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const clickGRR = () => {
    // console.log('onePOData', onePOData)
    // console.log('approveData', approveData)
    swalWithBootstrapButtons.fire({
      title: 'RR will be Generated, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Generating Receiving Receipt</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/receiving_receipt/generate_rr`,
          data: poInfo,
          method: 'POST',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY,
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Generating Receiving Receipt</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Generating Receiving Receipt</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  }

  useEffect(() => {
    if (hasItems(onePOData)) {
      if (onePOData.po_info.is_approved === true) {
        // console.log('onePOData',onePOData);
        setisApproved(true);
        setapproveStatus('PO is Already Approved');
        setIsApproveStatus(true);
      } else if (onePOData.po_info.is_approved === false) {
        setisApproved(true);
        setapproveStatus('PO is already Rejected');
        setIsApproveStatus(true);
      } else {
        setisApproved(false);
        setIsApproveStatus(false);
      }
    }
  }, [onePOData]);

  useEffect(() => {
    fetchOnePOData();
    getActiveSuppliers();
  }, []);

  useEffect(() => {
    console.log('poInfo', poInfo);
  }, [poInfo]);

  useEffect(() => {
    // getActiveSuppliers();
  }, [supplierID]);

  useEffect(() => {
    setSupplierListOptions();
  }, [allSuppliers]);

  useEffect(() => {
    // supplierDataSet();
    // console.log('supplierInfo',supplierInfo);
  }, [supplierInfo]);

  useEffect(() => {
    try {
      if (poInfoData.is_active) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
      if (poInfoData.is_rr_generated) {
        setIsGeneratedRR(true)
      } else {
        setIsGeneratedRR(false)
      }
      if (poInfoData.supplier_id) {
        getSuppliersItems(poInfoData.supplier_id);
        getSupplierInfo(poInfoData.supplier_id);
        setSupplierID(poInfoData.supplier_id);
        // setPOInfo(poInfoData)
        setPOInfo(
          {
            ...poInfo,
            pr_id: poInfoData.pr_id,
            po_id: poID,
            supplier_id: poInfoData.supplier_id,
            payment_method: poInfoData.payment_method,
            pr_date: poInfoData.pr_date,
            deliver_to: poInfoData.deliver_to,
            receiver: poInfoData.receiver,
            receiver_contact_number: poInfoData.receiver_contact_number,
            required_delivery_date: poInfoData.required_delivery_date,
            revenue_center: poInfoData.revenue_center,
            is_payment_first: poInfoData.is_payment_first,
            notes: poInfoData.notes,
            total_vat_inc: poInfoData.total_vat_inc,
            grand_total: poInfoData.grand_total,
            amount_in_words: poInfoData.amount_in_words,
            deleted_items: [],
            rr_items_data: poItemsInfoData,
            pr_items_data: poItemsInfoData,
          }
        )
        // console.log(poInfo);
      }
    } catch (err) { }
  }, [poInfoData])

  useEffect(() => {
    try {
      if (poItemsInfoData[0].po_items_id) {
        setitemsInfoCimbined([])
        poItemsInfoData.forEach(e => {
          fetchItemData(e.item_id, e.order_quantity, e.total_amount, e.cost_center, e.po_items_id);
        });
      }
    } catch (err) { }
  }, [poItemsInfoData])

  useEffect(() => {

  }, [suppliersItems])

  useEffect(() => {
    // console.log(POListData);
  }, [POListData])

  const formRequired = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../purchase_order/home">Purchase Order List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'PO Details',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className="text-uppercase">Purchase Order Details</p></FormTitle>
        <FormInfoFull>
          <Form onSubmit={UpdatePOPost} name="RRForm" id='RRForm'>
            <Stack gap={2}>
              <div>
                <span style={{ fontWeight: 'bold' }}>PO ID: {poID}</span>
              </div>
              <div>
                Status: &nbsp;
                <Switch
                  style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                  checkedChildren={'Active'}
                  unCheckedChildren={'Inactive'}
                  checked={isActive}
                  onClick={handleIsActive}
                  size="small"
                />
              </div>
            </Stack>
            <div className="mt-3" style={{ display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Supplier Information</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                    <Form.Label>Supplier Name</Form.Label><Required />
                    <Form.Select name="supplier_id" value={supplierInfo.supplier_id} aria-label="supplier_id" required={formRequired} disabled onChange={onChange}>
                      {
                        POListData.map((e, i) => (
                          <option key={i} value={e.value} >{e.label}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="attention">
                    <Form.Label>Attention</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.attention} name="attention" disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="email_address">
                    <Form.Label>Email Address</Form.Label><Form.Control type="email" defaultValue={supplierInfo.email_address} name="email_address" disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="contact_person">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.contact_person} name="contact_person" disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="mobile_number">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.mobile_number} name="mobile_number" disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.address} name="address" disabled />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="payment_method">
                  <Form.Label>Payment Method</Form.Label><Required />
                  <Form.Control type="text" defaultValue={poInfoData.payment_method} name="payment_method" onChange={onChange} disabled={!editMode} required={formRequired} />
                </Form.Group>
              </div>
              <div className="rounded border p-3 mb-4" style={{ flex: "1" }}>
                <h4 className="mb-3">Purchase Order Details</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="pr_date">
                    <Form.Label>PR Date</Form.Label>
                    <Form.Control type="date" defaultValue={poInfoData.pr_date} name="pr_date" disabled />
                  </Form.Group>
                  <Form.Group as={Col} controlId="requestor" className="mb-3">
                    <Form.Label>Requestor</Form.Label>
                    <Form.Control type="text" name="requestor" value="Test Requestor" onChange={onChange} disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="deliver_to" className="mb-3">
                    <Form.Label>Deliver To</Form.Label><Required />
                    <Form.Control type="text" defaultValue={poInfoData.deliver_to} name="deliver_to" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="receiver" className="mb-3">
                    <Form.Label>Receiver</Form.Label><Required />
                    <Form.Control type="text" defaultValue={poInfoData.receiver} name="receiver" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="receiver_contact_number" className="mb-3">
                    <Form.Label>Receiver Contact Number</Form.Label><Required />
                    <Form.Control type="text" defaultValue={poInfoData.receiver_contact_number} name="receiver_contact_number" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="required_delivery_date" className="mb-3">
                    <Form.Label>Required Delivery Date</Form.Label><Required />
                    <Form.Control type="date" defaultValue={poInfoData.required_delivery_date} min={new Date().toLocaleString("sv-SE", { timeZone: "Asia/Manila" }).split(' ')[0]} name="required_delivery_date" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="revenue_center" className="mb-3">
                    <Form.Label>Revenue Center</Form.Label><Required />
                    {
                      !editMode ? (
                        <Form.Control type="text" defaultValue={poInfoData.revenue_center} name="revenue_center" disabled />
                      ) : (
                        <Form.Select defaultValue={poInfoData.revenue_center} name="revenue_center" onChange={onChange}>
                          <option value="">Select Revenue Center</option>
                          <option value="BECOM">BECOM</option>
                          <option value="BMART">BMART</option>
                          <option value="BRET">BRET</option>
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                  <Form.Group as={Col} controlId="is_payment_first" className="mb-3">
                    <Form.Label>Payment First?</Form.Label><Required />
                    {
                      !editMode ? (
                        <Form.Select value={poInfoData.is_payment_first} name="is_payment_first" disabled >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      ) : (
                        <Form.Select defaultValue={poInfoData.is_payment_first} name="is_payment_first" onChange={onChange} >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="mt-3" style={{ width: "100%", display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ width: "100%", flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Purchase Order Items</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                    <PRTable supplier_id={supplierID} onSubmit={handlePRItemsTableFormSubmit} items_info={itemsInfoCimbined} editMode={editMode} proc_page={'PO'} />
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="mt-3" style={{ display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Order Summary</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control type="text" defaultValue={poInfoData.notes} name="notes" onChange={onChange} disabled={!editMode} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="total_vat_inc">
                    <Form.Label>Total (Vat Inc)</Form.Label>
                    <Form.Control type="text" placeholder="Total (Vat Inc)" name="total_vat_inc" ref={totalVatIncRef} onChange={onChange} disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="grand_total">
                    <Form.Label>Grand Total</Form.Label>
                    <Form.Control type="email" placeholder="Grand Total" name="grand_total" ref={grandTotalRef} onChange={onChange} disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="amount_in_words">
                    <Form.Label>Amount in Words</Form.Label>
                    <Form.Control className='text-capitalize' type="text" placeholder="Amount in Words" name="amount_in_words" ref={amountInWordsRef} onChange={onChange} disabled />
                  </Form.Group>
                </Row>

              </div>
            </div>
          </Form>
          {
            !editMode ? (
              !isApproveStatus ? (
                <Stack direction='horizontal' gap={3}>
                  {/* <div>
                                        <button type='button' onClick={onClickEdit} className='btn btn-sm btn-primary'>Edit</button>
                                    </div> */}
                  <div className='ms-auto'>
                    <button type='button' onClick={clickApprove} className='btn btn-sm btn-success'>Approve</button>
                  </div>
                  <div>
                    <button type='button' onClick={clickReject} className='btn btn-sm btn-danger'>Reject</button>
                  </div>
                </Stack>
              ) : (
                <Stack direction='horizontal' gap={3}>
                  <div>
                    <button type='button' onClick={clickGRR} className='btn btn-sm btn-primary'>Generate Receiving Receipt</button>
                  </div>
                  <div className='ms-auto'>
                    <button type='button' className='btn btn-sm btn-primary'><PrinterOutlined style={{ verticalAlign: 'middle' }} /> Print Purchase Order</button>
                  </div>
                  <div>
                    <a className='btn btn-sm btn-primary' >Send to Supplier</a>
                  </div>
                </Stack>
              )
            ) : (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <button type='button' onClick={handleClickRefresh} className='btn btn-sm btn-danger'>Cancel</button>
                </div>
                <div className='ms-auto'>
                  <button type='submit' form='RRForm' className='btn btn-sm btn-primary'>Submit</button>
                </div>
              </Stack>
            )
          }
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewPO;