import React, { useEffect, useState } from 'react';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import RoleForm from './RoleForm';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';

const CreateRole = (props) => {

  var editView = false;

  const [allRoleAccessData, setAllRoleAccessData] = useState([]);

  async function fetchOneRoleData() {
    await fetch(`${AUTH_LINK}/api/roles/get_current_access_data_structure`, {
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAllRoleAccessData(data.access_data_structure);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOneRoleData();
  }, [])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/role_management/view_all">Role List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Role Permissions',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <RoleForm allRoleAccessData={allRoleAccessData} editView={editView} />
    </FormContainer>
  );
};

export default CreateRole;