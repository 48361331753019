import React from "react";
import { Link } from "react-router-dom";
import style from "./PurchaseRequestHome.module.css";

function PurchaseRequestHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Purchase Request Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/purchase_requests/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View Purchase Requests
          </div>
        </Link>
        <Link to="/purchase_requests/create" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Make Purchase Request
          </div>
        </Link>
        {/* <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Purchase Request
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default PurchaseRequestHome;
