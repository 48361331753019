import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import { FINANCE_LINK, FINANCE_API_KEY } from '../../../utils/env_config';
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { Button, Row, Col, Stack } from 'react-bootstrap';
import { FormContainer, FormAction, FormContent, FormTitle } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import { NumberToPhp, currencyFormat, numberFormating } from '../../../utils/functions';


const ViewAllAccount = () => {
  const [oneAccountData, setAllAccountData] = useState({});

  const columns = [
    {
      data: 'account_code',
      text: 'Account Code',
      render: function (data, type, row) {
        return `<a href='/account_center/view_account/${row['account_id']}'>${data}</a>`;
      },
      sort: true
    },
    {
      data: 'sub_account_of',
      text: 'Subaccount of',
      render: function (data, type, row) {
        var counter = 0;
        var code = '';
        if (row['is_sub_account']) {
          oneAccountData.forEach(element => {
            if (element.account_id === row['sub_account_of'] && counter === 0) {
              code = element.account_code
              counter = 1;
            }
          });
          return code
        } else {
          return `Main Account`
        }
      },
      sort: true
    },
    {
      data: 'account_name',
      text: 'Account Name',
      sort: true
    },
    {
      data: 'account_type',
      text: 'Account Type',
      sort: true
    },
    {
      data: 'currency',
      text: 'Currency',
      sort: true
    },
    {
      data: 'current_balance',
      text: 'Current Balance',
      render: function (data, type, row) {
        if (data === '' || data === 0 || data === '0.00') {
          // console.log(data);
          return '₱ 0.00'
        } else {
          return NumberToPhp(data);
        }
      },
      sort: true
    },
    {
      data: 'is_active',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      },
      sort: true
    },
  ];

  const getAllBankCenter = () => {
    axios({
      url: `${FINANCE_LINK}/api/accounts_center/get_all_accounts`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      }
    })
      .then((response) => {
        setAllAccountData(response.data['all_accounts_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching Account Center Data!');
      });
  };

  useEffect(() => {
    getAllBankCenter();
    // fetchRFPData();
  }, []);

  useEffect(() => {
    // console.log(oneAccountData);		

    // try {
    // 	oneAccountData.filter(active => active.is_active === true)
    // } catch(err) {
    // 	// console.log(err)
    // }


    if (!$.fn.DataTable.isDataTable("#TableAccountData")) {
      // Initialize the DataTable plugin
      $("#TableAccountData").DataTable({
        responsive: true,
        data: oneAccountData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -2,
            className: 'dt-right'
          }
        ]
      });
    } else {
      $("#TableAccountData").DataTable().destroy();
      $("#TableAccountData").DataTable({
        responsive: true,
        data: oneAccountData,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -2,
            className: 'dt-right'
          }
        ]
      });
    }

  }, [oneAccountData]);

  return (
    <FormContainer rel="noopener noreferrer" >
      <FormAction>
        <Stack direction="horizontal" gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../account_center">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Account List',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Account Center View List</p></FormTitle>
        <div className="container-fluid">
          <div style={{ marginTop: '1rem' }}></div>
          <table id="TableAccountData" className="display">
            <thead>
              <tr>
                <th>Account Code</th>
                <th>Subaccount of</th>
                <th>Account Name</th>
                <th>Account Type</th>
                <th>Currency</th>
                <th>Current Balanace</th>
                <th>Active Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div>
            <Link className='btn btn-success btn-sm' to='/account_center/add_account' >ADD</Link>
          </div>
        </div>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllAccount;
