import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import ItemForm from './ItemForm';

const AddItem = (props) => {

  const { supID } = useParams();

  const formRequired = true;
  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: <span>Location:</span>,
              },
              {
                title: <Link style={{ color: '#909090' }} to="../item_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../item_center/view_all">Item List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New Item',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <ItemForm editView={false} supID={supID} />

    </FormContainer>
  );
};

export default AddItem;