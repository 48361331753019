let PROCUREMENT_LINK;
let FINANCE_LINK;
let FINANCE_API_KEY;
let PROCUREMENT_API_KEY;
let AUTH_LINK;
let AUTH_API_KEY;
let ENCRYPTION_KEY;

if (process.env.REACT_APP_DEPLOYMENT_ENV === 'development') {

    AUTH_LINK = process.env.REACT_APP_AUTH_MICROSVC_DEV_LINK;
    AUTH_API_KEY = process.env.REACT_APP_AUTH_MICROSVC_DEV_API_KEY;

    PROCUREMENT_LINK = process.env.REACT_APP_PROCUREMENT_MICROSVC_DEV_LINK;
    PROCUREMENT_API_KEY = process.env.REACT_APP_PROCUREMENT_MICROSVC_DEV_API_KEY;

    FINANCE_LINK = process.env.REACT_APP_FINANCE_MICROSVC_DEV_LINK;
    FINANCE_API_KEY = process.env.REACT_APP_FINANCE_MICROSVC_DEV_API_KEY;

    ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

} else if (process.env.REACT_APP_DEPLOYMENT_ENV === 'testing') {

    AUTH_LINK = process.env.REACT_APP_AUTH_MICROSVC_TEST_LINK;
    AUTH_API_KEY = process.env.REACT_APP_AUTH_MICROSVC_TEST_API_KEY;

    PROCUREMENT_LINK = process.env.REACT_APP_PROCUREMENT_MICROSVC_TEST_LINK;
    PROCUREMENT_API_KEY = process.env.REACT_APP_PROCUREMENT_MICROSVC_TEST_API_KEY;

    FINANCE_LINK = process.env.REACT_APP_FINANCE_MICROSVC_TEST_LINK;
    FINANCE_API_KEY = process.env.REACT_APP_FINANCE_MICROSVC_TEST_API_KEY;

    ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

} else if (process.env.REACT_APP_DEPLOYMENT_ENV === 'production') {

    AUTH_LINK = process.env.REACT_APP_AUTH_MICROSVC_PROD_LINK;
    AUTH_API_KEY = process.env.REACT_APP_AUTH_MICROSVC_PROD_API_KEY;

    PROCUREMENT_LINK = process.env.REACT_APP_PROCUREMENT_MICROSVC_PROD_LINK;
    PROCUREMENT_API_KEY = process.env.REACT_APP_PROCUREMENT_MICROSVC_PROD_API_KEY;

    FINANCE_LINK = process.env.REACT_APP_FINANCE_MICROSVC_PROD_LINK;
    FINANCE_API_KEY = process.env.REACT_APP_FINANCE_MICROSVC_PROD_API_KEY;

    ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

};

export { PROCUREMENT_LINK, FINANCE_LINK, FINANCE_API_KEY, PROCUREMENT_API_KEY, AUTH_LINK, AUTH_API_KEY, ENCRYPTION_KEY };
