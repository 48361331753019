// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
// import BootstrapTable from "react-bootstrap-table-next";
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


// import { useNavigate } from 'react-router-dom';

// const ViewAllInvoices = (props) => {
//   // Define the state with useState hook
//   const navigate = useNavigate();

// 	const [allInvoices, setInvoice] = useState('');
// 	const columns = [
// 		{
// 			dataField: "invoice_id",
// 			text: "Invoice ID"
// 		},
// 		{
// 			dataField: "invoice_number",
// 			text: "Invoice Number"
// 		},
// 		{
// 			dataField: "amount_due",
// 			text: "Amount Due"
// 		}
// 	];
// 	const getAllInvoices = () => {
// 		axios({
// 			url: 'http://localhost:8080/api/invoice/get_all_invoices',
// 			method: 'GET',
// 		})
// 		.then((response) => {
// 			// console.log(response)
// 			setInvoice(JSON.parse(response.data['invoice_data']));
// 			// allInvoices = response.data['invoice_data'];		
// 		})
// 		.catch((err) => {
// 			console.log(err)
// 			console.log('Error in Fetching All Invoices!');
// 		});
// 	};

// 	useEffect(() => {
// 		getAllInvoices();
// 		console.log(allInvoices);
// 	}, []);

// 	const tableRowEvents = {
// 		onClick: (e, row, rowIndex) => {
// 			console.log(row)
// 			// your link creation
// 			// const newTo = { 
// 			// 	pathname: `/view_invoice/${row['invoice_id']}`, 
// 			// 	param1: "Par1" 
// 			// };
// 			navigate(`/invoice/view_invoice/${row['invoice_id']}`);
// 			// link to the "location"
// 			// see (https://reacttraining.com/react-router/web/api/location)
// 			// <Link to={newTo}><button name="viewInvoiceBtn">View</button></Link>

// 			// console.log(`clicked on row with index: ${rowIndex}`);
// 		},
// 		// onMouseEnter: (e, row, rowIndex) => {
// 		// 	console.log(row)
// 		// 	console.log(`enter on row with index: ${rowIndex}`);
// 		// }
//  }

//   return (
// 	<div className='CreateBook'>
// 	  <div className='container'>
// 		<div className='row'>
// 		  {/* <div className='col-md-8 m-auto'>
// 			<br />
// 			<Link to='/' className='btn btn-outline-warning float-left'>
// 			  Show BooK List
// 			</Link>
// 		  </div> */}

// 			<a
// 			className="display-4"
// 			href="/invoice/home"
// 			rel="noopener noreferrer"
// 			>
// 				{/* <Button variant="primary" className='lead text-center'>Back to Invoicing</Button> */}
// 				<i className="fa-solid fa-arrow-left"></i>
// 			</a>

// 		  <div className='col-md-8 m-auto'>
// 			<h1 className='display-4 text-center'>View All Invoices</h1>
// 				<div>
// 					<BootstrapTable striped bordered hover variant="dark" keyField="invoice_id" data={allInvoices} columns={columns} bootstrap4 rowEvents={ tableRowEvents } />
// 					{/* <BootstrapTable theaddata={columns} tbodydata={allInvoices} /> */}
// 				</div>
// 		  </div>
// 		</div>
// 	  </div>
// 	</div>
//   );
// };

// export default ViewAllInvoices;


import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import style from './ViewAllInvoices.module.css'

const ViewAllInvoices = () => {
  const [allInvoices, setInvoice] = useState();
  const [allClients, setAllClients] = useState([]);


  const columns = [
    {
      data: "invoice_id",
      text: "Invoice ID",
      render: function (data, type, row) {
        return `<a href='/invoice/view_invoice/${row['invoice_id']}'>${data}</a>`;
      }
    },
    {
      data: "invoice_number",
      text: "Invoice Number"
    },
    {
      data: "client_data.business_unit",
      text: "Business Unit"
    },
    {
      data: "client_data.client_name",
      text: "Client Name"
    },
    {
      data: "amount_due",
      text: "Amount Due"
    }
  ];

  const getAllInvoices = () => {
    axios({
      url: 'http://localhost:8080/api/invoice/get_all_invoices',
      method: 'GET',
    })
      .then((response) => {
        var invoiceData = response.data['invoice_data'];

        invoiceData.forEach(element => {
          // console.log(allClients);
          const currClient = allClients.find(object => object.client_id === element.client_id);
          // console.log(currClient);
          element.client_data = currClient;
        });
        setInvoice(invoiceData);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Invoices!');
      });
  };

  const getAllClients = _ => {
    axios({
      url: 'http://localhost:8080/api/client_center/get_all_clients',
      method: 'GET',
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setAllClients(response.data.client_data);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Clients!');
      });
  }

  useEffect(() => {
    getAllClients();
    // getAllInvoices();
  }, []);

  useEffect(() => {
    if (allClients.length > 0) {
      getAllInvoices();
    }
  }, [allClients]);

  useEffect(() => {
    // console.log(allInvoices)
    if (!$.fn.DataTable.isDataTable("#example")) {
      // Initialize the DataTable plugin
      $("#example").DataTable({
        data: allInvoices,
        columns: columns,
      });
    } else {
      console.log(allInvoices)
      $("#example").DataTable().destroy();
      $("#example").DataTable({
        data: allInvoices,
        columns: columns,
      });
    }
  }, [allInvoices]);



  return (
    <div className="container" style={{ marginTop: "50px" }}>
      <div className={style.pageTitle}>Invoice List</div>
      <table id="example" className="display">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Invoice Number</th>
            <th>Business Unit</th>
            <th>Client Name</th>
            <th>Amount Due</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default ViewAllInvoices;
