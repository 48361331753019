import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../static/img/barapido_logo_square_blue.jpg';
import style from './ForgotPassword.module.css';
import { Input } from 'antd';

function ResetPassword() {

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disable, setDisable] = useState(false);

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      alert('Password do not match');
    } else {
      setDisable(true);
    }
  }

  return (
    <div>
      <div className={style.forgotPassFormText}>
        <p>Reset Password</p>
      </div>
      <div className={style.inputContainer}>
        <label htmlFor="newpassword">New Password</label>
        <Input.Password
          type="newpassword"
          placeholder="New Password"
          value={newPassword}
          onChange={handleNewPassword}
        />
      </div>
      <div className={style.inputContainer}>
        <label htmlFor="confirmpassword">Confirm Password</label>
        <Input.Password
          type="confirmpassword"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleConfirmPassword}
        />
      </div>
      <div>
        <button
          disabled={disable}
          className={style.submitBtn}
          onClick={handleSubmit}
        // type="submit"
        >
          {disable ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
