import React from "react";
import style from "./navbar.module.css";
import user from "./user.png";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// import Cookies from 'js-cookie';
// import { notification } from 'antd';
import { ENCRYPTION_KEY } from "../../../utils/env_config";
import { decryptData } from "../../../utils/encryption";

const NavigationBar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [userName, setUserName] = useState('');
  const containerRef = useRef(null);
  
  const getPermission = () => {
    const json = localStorage.getItem("p");
    return json || false;
  };

  const getName = () => {
    // getting stored value
    const saved = localStorage.getItem("n");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('userName',decryptData(initialValue, ENCRYPTION_KEY));
  };

  const navigate = useNavigate();

  const handleClickOutside = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    const name = getName();
    const permission = getPermission();
    if (name) {
      const decryptName = decryptData(name, ENCRYPTION_KEY);
      setUserName(decryptName);
    } else {
      setUserName('No User');
    }
    if (permission) {
      // const decryptPermission = decryptData(permission, ENCRYPTION_KEY);
      // console.log('decryptPermission');
      // console.table(JSON.parse(decryptPermission));
    } else {
      // console.log('No Data');
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownHandler = () => {
    setOpenDropdown(prevOpen => !prevOpen);
  };

  const logoutHandler = () => {
    // localStorage.removeItem("p");
    localStorage.clear();
    // Cookies.remove('token', { path: '/' });
    // Cookies.remove('temp', { path: '/' });
    // Cookies.remove('name', { path: '/' });
    navigate('/login')

    // notification.open({
    //   message: "Success",
    //   description: `Successfully logged out.`,
    //   duration: 2,
    //   type: "success",
    //   style: {
    //     backgroundColor: '#ccffcc',
    //   },
    // });

  }

  return (
    <nav className={style.navbar}>
      <div className={style.profileContainer}>
        <div className={style.profileContainerDropdown}>
          <div className={style.container} ref={containerRef}>
            <button className={style.button} onClick={dropdownHandler}>
              <span className={style.fontawesome_icon}>
                <i className="fa-solid fa-caret-down fa-xl"></i>
              </span>
            </button>
            {openDropdown && (
              <div className={style.dropdown}>
                <ul>
                  <li>View Profile</li>
                  <li>Change Password</li>
                  <li onClick={logoutHandler}>Logout</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={style.profileContainerName}>
          <p>{userName}</p>
        </div>
        <div className={style.profileContainerImg}>
          <img src={user} alt="profile" className={style.logo} />
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
