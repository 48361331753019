import React from "react";
import { Link } from "react-router-dom";
import style from "./BankCenterHome.module.css";

function BankCenterHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Bank Center Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/bank_center/view_all_bank" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View All Banks
          </div>
        </Link>
        <Link to="/bank_center/add_bank" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Create Bank Account
          </div>
        </Link>
      </div>
    </div>
  );
}

export default BankCenterHome;
