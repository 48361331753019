import React from 'react';
import { Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import RFPForm from './RFPForm';

const AddRFP = () => {

  const editView = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal" gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../rfp/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New RFP',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      {/* RFP Form Content */}
      <RFPForm editView={editView} />

    </FormContainer>


  );

};


export default AddRFP;