import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { Button, Row, Col, Stack } from 'react-bootstrap';
import { FormContainer, FormAction, FormContent, FormTitle } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';


const ViewAllBank = () => {
  const [allBankData, setAllBank] = useState({});

  const columns = [
    {
      data: 'bank_center_id',
      text: 'Bank Center Number',
      render: function (data, type, row) {
        return `<a href='/bank_center/view_bank/${row['bank_center_id']}'>${data}</a>`;
      },
      sort: true
    },
    {
      data: 'account_code',
      text: 'Account Code',
      sort: true
    },
    {
      data: 'account_name',
      text: 'Account Name',
      sort: true
    },
    {
      data: 'bank_type',
      text: 'Bank Type',
      sort: true
    },
    {
      data: 'is_active',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      },
      sort: true
    },
  ];

  const getAllBankCenter = () => {
    axios({
      url: `https://finance-test.fly.dev/api/bank_center/get_all_accounts`,
      method: 'GET',
    })
      .then((response) => {
        setAllBank(response.data['all_bank_center_accounts_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching Bank Center Data!');
      });
  };

  useEffect(() => {
    getAllBankCenter();
    // fetchRFPData();
  }, []);

  useEffect(() => {
    // console.log(allBankData);		

    try {
      allBankData.filter(active => active.is_active === true)
    } catch (err) {
      console.log(err)
    }


    if (!$.fn.DataTable.isDataTable("#TableBankData")) {
      // Initialize the DataTable plugin
      $("#TableBankData").DataTable({
        responsive: true,
        data: allBankData,
        columns: columns,
      });
    } else {
      $("#TableBankData").DataTable().destroy();
      $("#TableBankData").DataTable({
        responsive: true,
        data: allBankData,
        columns: columns,
      });
    }

  }, [allBankData]);

  return (
    <FormContainer rel="noopener noreferrer" >
      <FormAction>
        <Stack direction="horizontal" gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location',
              },
              {
                type: 'separator',
                separator: ':',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../bank_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Bank List',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Bank Center View List</p></FormTitle>
        <div className="container-fluid">
          <div style={{ marginTop: '1rem' }}></div>
          <table id="TableBankData" className="display">
            <thead>
              <tr>
                <th>Bank Center Number</th>
                <th>Account Code</th>
                <th>Account Name</th>
                <th>Bank Type</th>
                <th>Active Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div>
            <Link className='btn btn-success btn-sm' to='/bank_center/add_bank' >ADD</Link>
          </div>
        </div>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllBank;
