import React from 'react';
import style from './layout.module.css';
import Sidebar from '../_components/sidebar/Sidebar.jsx';
import NavigationBar from '../_components/navbar/NavigationBar';

// import React from 'react';
// import ReactDOM from 'react-dom';
// import MetisMenu from 'react-metismenu';
import '../../static/fontawesome-free-6.2.1-web/css/fontawesome.css';
import '../../static/fontawesome-free-6.2.1-web/css/brands.css';
import '../../static/fontawesome-free-6.2.1-web/css/solid.css';
import '../../static/fontawesome-free-6.2.1-web/css/all.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';

// import './metismenu.css'
const Layout = props => {
  const { children } = props;

  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  var loggedIn = localStorage.getItem('time');

  if (loggedIn) {
    if (setupTime == null) {
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > loggedIn) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
      }
    }
  }

  return (
    <div className={style.layout}>
      <header className={style.header}>
        <NavigationBar />
      </header>
      <aside className={style.aside}>
        <Sidebar />
        {/* <MetisMenu /> */}
      </aside>
      <main className={style.main}>{children}</main>
      {/* <footer className={style.footer}></footer> */}
    </div>
  );
};

export default Layout;