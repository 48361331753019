import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Stack, Col, Row, InputGroup } from 'react-bootstrap';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Required, hasItems, swalWithBootstrapButtons } from '../../../utils/functions';
import { Switch } from 'antd';

const MySwal = withReactContent(Swal)

const SupplierForm = ({ editView, supplierID, oneSupplierData }) => {

  const formRequired = true;
  const defaultSupData = {
    address: '',
    address2: '',
    attention: '',
    bir_cor: '',
    company_profile: '',
    contact_person: '',
    email_address: '',
    latest_fin_stmt: '',
    mayors_permit: '',
    mobile_number: '',
    photocopy_or: '',
    sec_dti: '',
    supplier_name: '',
    terms: '',
    middle_name: '',
    tin: '',
    valid_id: '',
    industry: '',
    bank_name: '',
    bank_name2: '',
    bank_name3: '',
    account_name: '',
    account_name2: '',
    account_name3: '',
    account_number: '',
    account_number2: '',
    account_number3: '',
    account_type: '',
    account_type2: '',
    account_type3: '',
  }

  const [viewText, setViewText] = useState('Add New Supplier');
  const [submitData, setSubmitData] = useState(defaultSupData);
  const [isActiveTrue, setIsActiveTrue] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const onChange = (e) => {
    if (e.target.type === 'file') {
      setSubmitData({ ...submitData, [e.target.name]: e.target.files[0] });
    } else {
      setSubmitData({ ...submitData, [e.target.name]: e.target.value });
    }
  };

  const addSupplierPost = (e) => {
    // console.log(submitData)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Creating Supplier</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/supplier_center/add_supplier`,
          data: submitData,
          method: 'POST',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY,
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }).then((res) => {
          // ReactDOM.findDOMNode(this.addsupplierForm).reset();
          console.log(res.data)
          return MySwal.fire({
            title: <strong>Supplier successfully created</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch((err) => {
            if (err.response.status === 450) {
              MySwal.fire({
                title: 'TIN must be Unique',
                icon: 'warning',
                cancelButtonText: 'OK',
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: false
              })
            } else {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            }
          });

      }
    })
  }

  const updateSupplierPost = (e) => {
    e.preventDefault();
    MySwal.fire({
      title: <p>Updating supplier profile</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/update_supplier`,
      data: submitData,
      method: 'PUT',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
    }).then((res) => {
      // ReactDOM.findDOMNode(this.addsupplierForm).reset();
      // console.log(res.data)
      return MySwal.fire({
        title: <strong>Supplier successfully updated</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })
    })
      .catch((err) => {
        if (err.response.status === 450) {
          MySwal.fire({
            title: 'TIN must be Unique',
            icon: 'warning',
            cancelButtonText: 'OK',
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: false
          })
        } else {
          console.log(err)
          return MySwal.fire({
            title: <strong>{err.response.data.message}</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'error'
          }).then(() => {
            MySwal.close();
          })
        }
      });
  }


  function getFileFromS3(e) {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_file_from_s3`,
      params: {
        supplier_id: supplierID,
        file_field: e
      },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
        'Content-Type': 'multipart/form-data',
      },
    }
    ).then((res) => {
      // console.log(res.data.fileData.mimeType)
      const fileType = res.data.fileData.mimeType
      const buffer = new Uint8Array(res.data.fileData.Body.data); // Replace [...] with your buffer data
      const blob = new Blob([buffer], { type: fileType });
      // const blob = new Blob([buffer], { type: 'image/jpeg' }); // Replace 'image/jpeg' with the appropriate MIME type for your image
      const url = URL.createObjectURL(blob);
      window.open(url);
    })
      .catch((err) => {
        if (err.response.status === 450) {
          MySwal.fire({
            title: 'No File Uploaded',
            icon: 'info',
            cancelButtonText: 'OK',
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: false
          })
        } else {
          console.log(err.response.status)
        }
      });
  }

  const updateActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this Account, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/supplier_center/change_supplier_active_status`,
          params: { supplier_id: supplierID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            MySwal.fire({
              title: 'Error in Updating Account',
              text: "Please try again",
              icon: 'warning',
              cancelButtonText: 'OK',
              footer: "Click anywhere outside the box to cancel",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              showCloseButton: false
            })
            console.log(err)
            // console.log('Error in Updating Account!');
          });
      }
    })
  }

  const onClickEdit = () => {
    setEditMode(true);
    setViewText('Edit Supplier');
  }

  const onClickCancel = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        setEditMode(false);
        setSubmitData(oneSupplierData);
        setViewText('Supplier Information');
        // window.location.reload();
      }
    })
  }

  useEffect(() => {
    if (editView) {
      setViewText('Supplier Information');
    }
  }, [editView])

  useEffect(() => {
    console.log('submitData', submitData);
  }, [submitData])

  useEffect(() => {
    if (hasItems(oneSupplierData)) {
      console.log('oneSupplierData', oneSupplierData);
      setSubmitData(oneSupplierData);
      if (oneSupplierData.is_active) {
        setIsActiveTrue(true)
      } else {
        setIsActiveTrue(false)
      }
    }
  }, [oneSupplierData])


  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{viewText}</p></FormTitle>
      <FormInfoFull>
        <Form onSubmit={editView ? updateSupplierPost : addSupplierPost} name="SupplierFormPost" id='SupplierFormPost'>
          {
            editView && (
              <Stack gap={2}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Supplier ID: {supplierID}</span>
                </div>
                <div>
                  <Stack direction='horizontal' gap={2}>
                    <div>
                      Status: &nbsp;
                      <Switch
                        style={{ backgroundColor: isActiveTrue ? '#237804' : '#f5222d' }}
                        checkedChildren={'Active'}
                        unCheckedChildren={'Inactive'}
                        checked={isActiveTrue}
                        onClick={updateActive}
                        size="small"
                      />
                    </div>
                    {
                      !editMode && (
                        <div className='ms-auto'>
                          <a className='btn btn-sm btn-primary' target='_blank' href={'/item_center/add_item/' + supplierID}>Add Item</a>
                        </div>
                      )
                    }
                  </Stack>
                </div>
              </Stack>
            )
          }
          <div className="mt-3" style={{ display: "flex" }}>
            <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
              <h4 className="mb-3">Supplier Information</h4>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="supplier_name">
                  <Form.Label>Supplier Name</Form.Label><Required />
                  <Form.Control type="text" value={submitData.supplier_name} placeholder="Enter Supplier Name" name="supplier_name" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="email_address">
                  <Form.Label>Email Address</Form.Label><Required />
                  <Form.Control type="email" value={submitData.email_address} placeholder="Enter Email Address" name="email_address" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="attention">
                  <Form.Label>Attention</Form.Label><Required />
                  <Form.Control type="text" value={submitData.attention} placeholder="Enter Attention" name="attention" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="tin">
                  <Form.Label>TIN</Form.Label><Required />
                  <Form.Control type="text" value={submitData.tin} placeholder="Enter TIN" name="tin" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="contact_person">
                  <Form.Label>Contact Person</Form.Label><Required />
                  <Form.Control type="text" value={submitData.contact_person} placeholder="Enter Contact Person" name="contact_person" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="mobile_number">
                  <Form.Label>Mobile Number</Form.Label><Required />
                  <Form.Control type="text" value={submitData.mobile_number} placeholder="Enter Mobile Number" name="mobile_number" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="industry">
                  <Form.Label>Industry</Form.Label><Required />
                  <Form.Control type="text" value={submitData.industry} placeholder="Enter Industry" name="industry" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="terms">
                  <Form.Label>Terms</Form.Label><Required />
                  <Form.Control type="number" value={submitData.terms} min={1} placeholder="Enter Terms" name="terms" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="address">
                  <Form.Label>Office Address</Form.Label><Required />
                  <Form.Control type="text" value={submitData.address} placeholder="Enter Address" name="address" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="address2">
                  <Form.Label>Warehouse Address</Form.Label>
                  <Form.Control type="text" value={submitData.address2} placeholder="Enter Warehouse Address" name="address2" onChange={onChange} disabled={editView ? !editMode : false} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="vat_type">
                  <Form.Label size='sm' className='fw-bolder'>VAT type - VAT inc</Form.Label>
                  <InputGroup size='sm' hasValidation>
                    <Form.Check inline label="12%" value={'12%'} name="vat_type" type='radio' id="vat_type" onChange={onChange} defaultChecked required />
                    <Form.Check inline label="Vat Exempt" value={'Vat Exempt'} name="vat_type" type='radio' id="vat_type" onChange={onChange} required />
                    <Form.Control.Feedback tooltip type="invalid">
                      Please choose one
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
            </div>
            <div className="rounded border p-3 mb-4" style={{ flex: "1" }}>
              <h4 className="mb-3">Supplier Documents</h4>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="sec_dti" className="mb-3">
                  <Form.Label>SEC/DTI</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.sec_dti ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('sec_dti') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="sec_dti" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.sec_dti ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('sec_dti') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="sec_dti" onChange={onChange} />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="bir_cor" className="mb-3">
                  <Form.Label>BIR Certificate of Registration</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.bir_cor ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('bir_cor') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="bir_cor" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.bir_cor ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('bir_cor') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="bir_cor" onChange={onChange} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="mayors_permit" className="mb-3">
                  <Form.Label>Mayor's Permit</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.mayors_permit ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('mayors_permit') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="mayors_permit" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.mayors_permit ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('mayors_permit') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="mayors_permit" onChange={onChange} />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="latest_fin_stmt" className="mb-3">
                  <Form.Label>Latest Financial Statement</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.latest_fin_stmt ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('latest_fin_stmt') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="latest_fin_stmt" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.latest_fin_stmt ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('latest_fin_stmt') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="latest_fin_stmt" onChange={onChange} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="company_profile" className="mb-3">
                  <Form.Label>Company Profile</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.company_profile ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('company_profile') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="company_profile" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.company_profile ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('company_profile') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="company_profile" onChange={onChange} />
                    )
                  }
                </Form.Group>
                <Form.Group as={Col} controlId="photocopy_or" className="mb-3">
                  <Form.Label>Photocopy of Official Receipt</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.photocopy_or ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('photocopy_or') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="photocopy_or" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.photocopy_or ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('photocopy_or') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="photocopy_or" onChange={onChange} />
                    )
                  }
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="valid_id" className="mb-3">
                  <Form.Label>Valid ID</Form.Label>
                  {
                    editView ? (
                      editMode ? (
                        <Stack gap={2}>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.valid_id ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('valid_id') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                          <div>
                            <Form.Control type="file" name="valid_id" size="sm" onChange={onChange} />
                          </div>
                        </Stack>
                      ) : (
                        <Stack>
                          {
                            hasItems(oneSupplierData) ? (
                              oneSupplierData.valid_id ? (
                                <div>
                                  <a target="_bank" className='btn btn-primary btn-sm' onClick={(e) => { getFileFromS3('valid_id') }}>Preview</a>
                                </div>
                              ) : (
                                <div>
                                  <span>No File Uploaded</span>
                                </div>
                              )
                            ) : (
                              <div>
                                <span>No File Uploaded</span>
                              </div>
                            )
                          }
                        </Stack>
                      )
                    ) : (
                      <Form.Control type="file" name="valid_id" onChange={onChange} />
                    )
                  }
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="mt-3" style={{ display: "flex" }}>
            <div className="rounded border p-3 mb-3" style={{ flex: "1" }}>
              <h4 className="mb-3">Supplier Banking Info</h4>
              <div className="rounded border p-3 mb-3" style={{ flex: "1" }}>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="bank_name">
                    <Form.Label>Bank Name <Required /></Form.Label>
                    <Form.Control type="text" value={submitData.bank_name} placeholder="Enter Bank Name" name="bank_name" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_name">
                    <Form.Label>Account Name <Required /></Form.Label>
                    <Form.Control type="text" value={submitData.account_name} placeholder="Enter Account Name" name="account_name" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_number">
                    <Form.Label>Account Number <Required /></Form.Label>
                    <Form.Control type="text" value={submitData.account_number} placeholder="Enter Account Number" name="account_number" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_type">
                    <Form.Label>Type of Account <Required /></Form.Label>
                    <Form.Control type="text" value={submitData.account_type} placeholder="Enter Account Type" name="account_type" onChange={onChange} required={formRequired} disabled={editView ? !editMode : false} />
                  </Form.Group>
                </Row>
              </div>
              <div className="rounded border p-3 mb-3" style={{ flex: "1" }}>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="bank_name2">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" value={submitData.bank_name2} placeholder="Enter Bank Name" name="bank_name2" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_name2">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control type="text" value={submitData.account_name2} placeholder="Enter Account Name" name="account_name2" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_number2">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control type="text" value={submitData.account_number2} placeholder="Enter Account Number" name="account_number2" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_type2">
                    <Form.Label>Type of Account</Form.Label>
                    <Form.Control type="text" value={submitData.account_type2} placeholder="Enter Account Type" name="account_type2" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                </Row>
              </div>
              <div className="rounded border p-3 mb-3" style={{ flex: "1" }}>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="bank_name3">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" value={submitData.bank_name3} placeholder="Enter Bank Name" name="bank_name3" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_name3">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control type="text" value={submitData.account_name3} placeholder="Enter Account Name" name="account_name3" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_number3">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control type="text" value={submitData.account_number3} placeholder="Enter Account Number" name="account_number3" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="account_type3">
                    <Form.Label>Type of Account</Form.Label>
                    <Form.Control type="text" value={submitData.account_type3} placeholder="Enter Account Type" name="account_type3" onChange={onChange} disabled={editView ? !editMode : false} />
                  </Form.Group>
                </Row>
              </div>
            </div>
          </div>
        </Form>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <a className='btn btn-sm btn-danger' onClick={onClickCancel} >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button className='btn btn-sm btn-primary' form='SupplierFormPost' type="submit">Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal'>
                <div >
                  <a className='btn btn-sm btn-primary' onClick={onClickEdit} >Edit</a>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button className='btn btn-sm btn-primary' form='SupplierFormPost' type="submit">Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default SupplierForm;