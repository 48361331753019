import React, { useEffect, useState } from 'react';
import { FormContent, FormInfoFull, FormTitle } from '../../../formLayout/FormContainer';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { Required, hasItems, swalWithBootstrapButtons } from '../../../../utils/functions';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Switch } from 'antd';

const MySwal = withReactContent(Swal);

const PermissionForm = (props, { permissionID, oneAccountData, editView }) => {

  const [permissionData, setPermissionData] = useState({
    permission_name: '',
    module_id: '',
  })

  const [selectedModule, setSelectedModule] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState('Create Permission');
  const [isActive, setIsActive] = useState(null);

  const getAllModules = () => {
    axios({
      url: `${AUTH_LINK}/api/modules/get_all_modules`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    })
      .then((response) => {
        setModuleData(response.data['all_modules_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Modules!');
      });
  };

  const fetchPostSubmit = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Creating Permission</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/permissions/add_permission`,
          data: permissionData,
          method: 'POST',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async (res) => {
          // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
          console.log(res.data)
          await MySwal.fire({
            title: <strong>Permission Created Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            if (err.response.status === 450) {
              MySwal.fire({
                title: 'Permission Name must be Unique',
                icon: 'warning',
                cancelButtonText: 'OK',
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: false
              })
            } else {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            }
          });

      }
    })
  }

  const fetchPutUpdate = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    MySwal.fire({
      title: <p>Updating Permission</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${AUTH_LINK}/api/permissions/update_permission`,
      data: permissionData,
      method: 'PUT',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    }).then(async (res) => {
      // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
      console.log(res.data)
      await MySwal.fire({
        title: <strong>Permission Updated Successfully</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      });
      window.location.reload();
    })
      .catch((err) => {
        if (err.response.status === 450) {
          MySwal.fire({
            title: 'Permission Name must be Unique',
            icon: 'warning',
            cancelButtonText: 'OK',
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: false
          })
        } else {
          console.log(err)
          return MySwal.fire({
            title: <strong>{err.response.data.message}</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'error'
          }).then(() => {
            MySwal.close();
          })
        }
      });
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this Permission, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/permissions/change_permission_active_status`,
          params: { permission_id: permissionID },
          method: 'PUT',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Updating Active/Inactive Status!</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const onChangeModule = (e) => {
    if (e.target.value) {
      setSelectedModule(true);
      setPermissionData({
        ...permissionData,
        [e.target.name]: e.target.value,
      })
    } else {
      setSelectedModule(false);
      setPermissionData({
        ...permissionData,
        [e.target.name]: '',
        permission_name: '',
      })
    }
  }

  const onChange = (e) => {
    setPermissionData({
      ...permissionData,
      [e.target.name]: e.target.value,
    })
  }

  const handleEditMode = () => {
    setEditMode(true);
    setEditText('Edit Permission');
  }

  useEffect(() => {
    getAllModules();
  }, [])

  useEffect(() => {
    // console.log(permissionID);
  }, [permissionID])

  useEffect(() => {
    // console.log(oneAccountData);
    try {
      if (oneAccountData.permission_id) {
        if (oneAccountData.is_active) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
        setPermissionData({
          ...permissionData,
          permission_id: oneAccountData.permission_id,
          permission_name: oneAccountData.permission_name,
        })
      }
    } catch (err) { }
  }, [oneAccountData])

  useEffect(() => {
    // console.log(editMode);
  }, [editMode])

  useEffect(() => {
    // console.log('permissionData', permissionData);
  }, [permissionData])

  useEffect(() => {
    // console.log('moduleData', moduleData);
  }, [moduleData])

  useEffect(() => {
    // console.log(editView);
    if (editView) {
      setEditText('View Permission');
    }
  }, [editView])

  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{editText}</p></FormTitle>
      <FormInfoFull>
        <Form onSubmit={!editView ? fetchPostSubmit : fetchPutUpdate} name="PermissionPostForm" id='PermissionPostForm'>
          {
            editView && (
              <Stack className="mb-3" hidden={editMode}>
                <div>
                  Status: &nbsp;
                  <Switch
                    style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                    checkedChildren={'Active'}
                    unCheckedChildren={'Inactive'}
                    checked={isActive}
                    onClick={handleIsActive}
                    size="small"
                  />
                </div>
              </Stack>
            )
          }
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="module_id">
              <Form.Label>Select Module<Required /></Form.Label>
              <Form.Select value={permissionData.module_id} name="module_id" onChange={onChangeModule} required disabled={editView ? !editMode : false} >
                <option value='' >Select Module</option>
                {
                  hasItems(moduleData) && (
                    moduleData.map((e, i) => (
                      <option key={i} value={e.module_id}>{e.module_name}</option>
                    ))
                  )
                }
              </Form.Select>
            </Form.Group>
          </Row>
          {
            selectedModule && (
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="permission_name">
                  <Form.Label>Permission Name <Required /></Form.Label>
                  {
                    !editView ? (
                      <Form.Control type="text" placeholder='Permission Name' value={permissionData.permission_name} name="permission_name" onChange={onChange} required />
                    ) : (
                      <Form.Control type="text" placeholder='Permission Name' value={permissionData.permission_name} name="permission_name" onChange={onChange} disabled={!editMode} required />
                    )
                  }
                </Form.Group>
              </Row>
            )
          }
        </Form>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-danger' onClick={handleClickRefresh} >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button type='submit' className='btn btn-sm btn-primary' form='PermissionPostForm'>Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-primary' onClick={handleEditMode} >Edit</a>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button type='submit' className='btn btn-sm btn-primary' form='PermissionPostForm'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default PermissionForm;