import React from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import CVForm from './CVForm';

const CreateCV = () => {

  var editView = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal" gap={3}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../cv/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../cv/view_all">CV List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New CV',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>

      {/* Cash Voucher Form */}
      <CVForm editView={editView} />

    </FormContainer>
  );
};

export default CreateCV;