import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../utils/env_config';
import RFPForm from './RFPForm';

const EditViewRFP = () => {

  const editView = true;

  const { rfpID } = useParams();
  const [rfpOneData, setRFPOneData] = useState([]);

  const getOneRFPData = _ => {
    axios({
      url: `${FINANCE_LINK}/api/rfp/get_rfp?rfp_id=` + rfpID,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    }).then((res) => {
      setRFPOneData(res.data['rfp_data']);
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting RFP Data!');
    });
  }

  useEffect(() => {
    getOneRFPData()
  }, []);


  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal" gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../rfp/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View RFP',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <RFPForm editView={editView} rfpID={rfpID} rfpOneData={rfpOneData} />

    </FormContainer>
  );

};


export default EditViewRFP;