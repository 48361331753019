import React from "react";
import { Link } from "react-router-dom";
import style from "./AccountCenterHome.module.css";

function AccountCenterHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>Accounts Center Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/account_center/view_all_account" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View All Accounts
          </div>
        </Link>
        <Link to="/account_center/add_account" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Create New Account
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AccountCenterHome;
