import React from "react";
import { Button } from "react-bootstrap";
import styles from "./Dashboard.module.css";

function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <header className={styles.header}>BEST Dashboard</header>
      <div className={styles.container}>
        <Button className={styles.button} href="/invoice/home">Invoicing</Button>
        <Button className={styles.button} href="/clients/home">Client Center</Button>
        <Button className={styles.button} href="/suppliers/home">Supplier Center</Button>
        <Button className={styles.button} href="/rfp/home">RFP</Button>
      </div>
    </div>
  );
}

export default Dashboard;
