import React, { useEffect, useState } from 'react';
import UserForm from './UserForm';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Breadcrumb } from 'antd';
import { Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AUTH_API_KEY, AUTH_LINK, ENCRYPTION_KEY } from '../../../../utils/env_config';
import { decryptData } from '../../../../utils/encryption';
// import Cookies from 'js-cookie';

const ViewUser = (props) => {

  const { userID } = useParams();
  var editView = true;

  const [oneUserData, setOneUserData] = useState([]);

  const [token, setToken] = useState();

  const getToken = () => {
    // getting stored value
    const saved = localStorage.getItem("t");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  useEffect(() => {
    const initialValue = getToken();
    if (initialValue) {
      const DataToken = decryptData(initialValue, ENCRYPTION_KEY);
      setToken(DataToken);
    }
  }, [])

  async function fetchOneUserData() {
    await fetch(`${AUTH_LINK}/api/users/get_user?user_id=${userID}`, {
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setOneUserData(data.all_users_data);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOneUserData();
  }, [])

  useEffect(() => {
    // console.log('oneUserData',oneUserData);
  }, [oneUserData])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/user_management">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/user_management/user_list">User List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View User',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      {/* User Management Form */}
      <UserForm oneUserData={oneUserData} editView={editView} userID={userID} UserToken={token} />

    </FormContainer>
  );
};

export default ViewUser;