// import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

// import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
// import BootstrapTable from "react-bootstrap-table-next";
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// import { useParams, useNavigate } from 'react-router-dom';

// const MySwal = withReactContent(Swal)

// const InvoicePage = (props) => {
// 	const navigate = useNavigate();

// 	// const { state } = useLocation();
// 	// Define the state with useState hook
// 	const {invoice_id} = useParams();
// 	const [invoiceData, setInvoiceData] = useState([]);

// 	// let columns = [];
// 	// Object.keys(invoiceData[0]).forEach((key) => {
// 	// 	columns.push({
// 	// 		dataField: key,
// 	// 		text: key
// 	// 	});
// 	// });

// 	const columns = [
// 		{
// 			dataField: "invoice_id",
// 			text: "Invoice ID"
// 		},
// 		{
// 			dataField: "invoice_number",
// 			text: "Invoice Number"
// 		},
// 		{
// 			dataField: "amount_due",
// 			text: "Amount Due"
// 		}
// 	];
// 	const invoice_req_params = {
// 		invoice_id: invoice_id,
// 	}

// 	const getInvoice = async() => {
// 		// var form_data = new FormData();
// 		// form_data.append('invoice_id', invoice_id);

// 		// console.log(invoice);
// 		axios({
// 			url: 'http://localhost:8080/api/invoice/get_invoice',
// 			params: invoice_req_params,
// 			method: 'GET',
// 			headers: {
// 				'Content-Type': 'multipart/form-data',
// 			},
// 		}).then((res) => {
// 			// console.log(res.data);
// 			// const data = new Array(res.data['invoice_data']);
// 			// console.log(data);
// 			setInvoiceData([JSON.parse(res.data['invoice_data'])]);

// 			// Push to /
// 			// navigate('/');
// 		})
// 		.catch((err) => {
// 			console.log(err)
// 			console.log('Error in Getting Invoice!');
// 		});
// 		// alert(invoice_id);
// 	};

// 	useEffect(() => {
// 		getInvoice();
// 		console.log(invoiceData)
// 		// alert(allInvoices);
// 	}, []);

// 	async function deleteInvoice(){
// 		MySwal.fire({
// 			title: <p>Deleting Invoice</p>,
// 			didOpen: () => {
// 				// `MySwal` is a subclass of `Swal` with all the same instance & static methods
// 				MySwal.showLoading()
// 			},
// 		})
// 		// await new Promise(r => setTimeout(r, 5000));
// 		axios({
// 			url: 'http://localhost:8080/api/invoice/delete_invoice',
// 			params: invoice_req_params,
// 			method: 'DELETE',
// 			headers: {
// 				'Content-Type': 'multipart/form-data',
// 			},
// 		}
// 		).then((res) => {
// 			return MySwal.fire({
// 				title: <strong>Invoice successfully deleted</strong>,
// 				// html: <i>You clicked the button!</i>,
// 				icon: 'success'
// 			}).then(() => {
// 				navigate(`/invoice/view_all`);
// 			})
// 			// navigate(`/invoice/view_all`);
// 		})
// 		.catch((err) => {
// 			console.log(err)
// 			console.log('Error in Getting Invoice!');
// 			return MySwal.fire({
// 				title: <strong>Invoice deletion failed!</strong>,
// 				html: <i>Please try again</i>,
// 				icon: 'error'
// 			})
// 		});
// 	}

// 	return (
// 		<div className='CreateBook'>
// 			<div className='container'>
// 				<div className='row'>
// 					{/* <div className='col-md-8 m-auto'>
// 						<br />
// 						<Link to='/' className='btn btn-outline-warning float-left'>
// 							Show BooK List
// 						</Link>
// 					</div> */}
// 					<div className='col-md-8 m-auto'>

// 						<a
// 						className="App-link display-4 text-center"
// 						href="/invoice/home"
// 						rel="noopener noreferrer"
// 						>
// 							<Button variant="primary" className='lead text-center mt-2'>Back to Invoicing</Button>
// 						</a>

// 						<br></br>

// 						<a
// 						className="App-link display-4 text-center"
// 						href="/invoice/view_all"
// 						rel="noopener noreferrer"
// 						>
// 							<Button variant="primary" className='lead text-center mt-2'>Back to All Invoices</Button>
// 						</a>

// 						<h1 className='display-4 text-center'>View Invoice</h1>
// 						<h1 className='display-4 text-center'>Invoice ID: {invoice_id}</h1>

// 						<div>
// 							<BootstrapTable striped bordered hover variant="dark" keyField="invoice_id" data={invoiceData} columns={columns} bootstrap4 />
// 						</div>


// 							<Button variant="danger" className='lead text-center mt-2' name='deleteInvoiceBtn' onClick={deleteInvoice}>Delete Invoice</Button>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default InvoicePage;








import React, { useState, useEffect } from 'react';
import styles from './invoice.module.css';

import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import BootstrapTable from "react-bootstrap-table-next";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { useParams, useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)

const InvoicePage = () => {

  const navigate = useNavigate();

  // const { state } = useLocation();
  // Define the state with useState hook
  const { invoice_id } = useParams();
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceItemsData, setInvoiceItemsData] = useState([]);


  // let columns = [];
  // Object.keys(invoiceData[0]).forEach((key) => {
  // 	columns.push({
  // 		dataField: key,
  // 		text: key
  // 	});
  // });

  const columns = [
    {
      dataField: "invoice_id",
      text: "Invoice ID"
    },
    {
      dataField: "invoice_number",
      text: "Invoice Number"
    },
    {
      dataField: "amount_due",
      text: "Amount Due"
    }
  ];
  const invoice_req_params = {
    invoice_id: invoice_id,
  }

  const getInvoice = _ => {
    axios({
      url: 'http://localhost:8080/api/invoice/get_invoice',
      params: invoice_req_params,
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      // console.log(res.data);
      // const data = new Array(res.data['invoice_data']);
      // console.log(data);
      setInvoiceData([JSON.parse(res.data['invoice_data'])]);
      // setInvoiceData(invoiceData[0].client_data, JSON.parse(invoiceData[0].client_data));

      // Push to /
      // navigate('/');
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting Invoice!');
    });
    // alert(invoice_id);
  };

  const getInvoiceItems = _ => {
    // alert(invoice_req_params)
    axios({
      url: 'http://localhost:8080/api/invoice_itemization/get_invoice_items',
      params: invoice_req_params,
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      // console.log(res.data);
      // const data = new Array(res.data['invoice_data']);
      // console.log(data);
      setInvoiceItemsData(res.data['invoice_item_data']);
      // setInvoiceData(invoiceData[0].client_data, JSON.parse(invoiceData[0].client_data));

      // Push to /
      // navigate('/');
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting Invoice!');
    });
    // alert(invoice_id);
  };

  useEffect(() => {
    getInvoice();
    getInvoiceItems();
    // console.log(invoiceData)
    // alert(allInvoices);
  }, []);

  async function deleteInvoice() {
    MySwal.fire({
      title: 'Are you sure you want to delete this invoice?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: <p>Deleting Invoice</p>,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        // await new Promise(r => setTimeout(r, 5000));
        axios({
          url: 'http://localhost:8080/api/invoice/delete_invoice',
          params: invoice_req_params,
          method: 'DELETE',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        ).then((res) => {
          return MySwal.fire({
            title: <strong>Invoice successfully deleted</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            navigate(`/invoice/view_all`);
          })
          // navigate(`/invoice/view_all`);
        })
          .catch((err) => {
            console.log(err)
            console.log('Error in Getting Invoice!');
            return MySwal.fire({
              title: <strong>Invoice deletion failed!</strong>,
              html: <i>Please try again</i>,
              icon: 'error'
            })
          });
      }
    })
  }
  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.invoiceHeader}>
        <a
          className="display-4"
          href="javascript:history.back()"
          rel="noopener noreferrer"
        >
          {/* <Button variant="primary" className='lead text-center'>Back to All Invoices</Button> */}
          <i className="fa-solid fa-arrow-left"></i>
        </a>

        {/* <Button variant="danger" className={styles.deleteInvoiceBtn} name='deleteInvoiceBtn' onClick={deleteInvoice}><i className="fa-solid fa-trash"></i></Button> */}

        <BootstrapSwitchButton
          checked={true}
          onlabel='Active'
          offlabel='Inactive'
          width={100}
          onChange={(checked) => this.setState({ isUserAdmin: checked })}
        />
        <h1 className={styles.invoiceTitle}>Invoice</h1>
        <div className={styles.invoiceInfo}>
          {/* <p>Date: {new Date(invoiceData[0]['created_date']).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
					<p>Invoice ID: {invoiceData[0]['invoice_id']}</p>
					<p>Invoice Number: {invoiceData[0]['invoice_number']}</p> */}
          <p className={styles.invoiceInfoText}>Date Created: {new Date(invoiceData[0]?.created_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? "Loading..."}</p>
          <p className={styles.invoiceInfoText}>Invoice ID: {invoiceData[0]?.invoice_id ?? "Loading..."}</p>
          <p className={styles.invoiceInfoText}>Invoice Number: {invoiceData[0]?.invoice_number ?? "Loading..."}</p>
          <p className={styles.invoiceInfoText}>Tax Date: {new Date(invoiceData[0]?.tax_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? "Loading..."}</p>
        </div>
      </div>
      <div className={styles.invoiceContent}>
        <div className={styles.clientInfoMain}>
          <h2 className={styles.clientTitle}>Client Information</h2>
          <div className={styles.clientInfo}>
            {/* <p className={styles.clientName}>Name: {JSON.parse(invoiceData[0].client_data)?.client_name ?? "Loading..."}</p> */}
            <p className={styles.clientInfoText}>Name: {
              typeof invoiceData[0]?.client_data === "string"
                ? (JSON.parse(invoiceData[0].client_data)?.client_name ?? "Loading...")
                : "Loading..."
            }</p>
            <p className={styles.clientInfoText}>Address: {
              typeof invoiceData[0]?.client_data === "string"
                ? (JSON.parse(invoiceData[0].client_data)?.shipping_address ?? "Loading...")
                : "Loading..."
            }</p>
            <p className={styles.clientInfoText}>Mobile Number: {
              typeof invoiceData[0]?.client_data === "string"
                ? (JSON.parse(invoiceData[0].client_data)?.mobile_number ?? "Loading...")
                : "Loading..."
            }</p>
            {/* <p className={styles.clientAddress}>Address:</p>
						<p className={styles.clientEmail}>Email: [Insert Client Email Here]</p> */}
          </div>
        </div>
        <div className={styles.invoiceDetails}>
          <h2 className={styles.detailsTitle}>Invoice Details</h2>
          <table className={styles.invoiceTable}>
            <thead className={styles.invoiceTableHeading}>
              <tr>
                <th className={styles.tableHeader}>Item</th>
                <th className={styles.tableHeader}>Quantity</th>
                <th className={styles.tableHeader}>Price</th>
                <th className={styles.tableHeader}>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItemsData.length > 0 &&
                invoiceItemsData.map(item => (
                  <tr>
                    <td className={styles.tableData}>{item.item_id}</td>
                    <td className={styles.tableData}>{item.quantity}</td>
                    <td className={styles.tableData}>{item.rate}</td>
                    <td className={styles.tableData}>{item.rate}</td>
                  </tr>
                ))
              }
              {/* <tr>
								<td className={styles.tableData}>[Insert Item Here]</td>
								<td className={styles.tableData}>[Insert Quantity Here]</td>
								<td className={styles.tableData}>[Insert Price Here]</td>
								<td className={styles.tableData}>[Insert Total Here]</td>
							</tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.invoiceFooter}>
        <p className={styles.total}>Total: Php {invoiceData[0]?.total ?? "Loading..."}</p>
      </div>
    </div>
  );
};

export default InvoicePage;