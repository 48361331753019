import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SweetAlert from 'react-bootstrap-sweetalert'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)

// const InvoiceItemizationTable = (props) => {
// 	const [allSupplierItems, setAllSupplierItems] = useState([]);

// 	function getAllSupplierItems(){
// 		axios({
// 			url: 'http://localhost:8080/api/supplier_center/supplier_items',
// 			method: 'GET',
// 		})
// 		.then((response) => {
// 			// console.log(response)
// 			// console.log(response.data.client_data)
// 			setAllSupplierItems(response.data.supplier_items_data);
// 			// allInvoices = response.data['invoice_data'];		
// 		})
// 		.catch((err) => {
// 			console.log(err)
// 			console.log('Error in Fetching All Supplier Items!');
// 		});
// 	}

// 	useEffect(() => {
// 		getAllSupplierItems();
// 		// console.log(allSupplierItems);
// 	}, []);

// 	function TableRows({rowsData, deleteTableRows, handleChange}) {
// 		return(

// 			rowsData.map((data, index)=>{
// 				const {fullName, emailAddress, salary}= data;
// 				return(
// 					<tr key={index}>
// 					<td>
// 						<input type="text" value={fullName} onChange={(evnt)=>(handleChange(index, evnt))} name="fullName" className="form-control"/>
// 					</td>
// 					<td><input type="text" value={emailAddress}  onChange={(evnt)=>(handleChange(index, evnt))} name="emailAddress" className="form-control"/> </td>
// 					<td><input type="text" value={salary}  onChange={(evnt)=>(handleChange(index, evnt))} name="salary" className="form-control" /> </td>
// 					<td><input type="text" value={salary}  onChange={(evnt)=>(handleChange(index, evnt))} name="salary" className="form-control" /> </td>
// 					<td><button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
// 				</tr>
// 				)
// 			})

// 		)

// 	}

// 	function AddDeleteTableRows(){
// 		const [rowsData, setRowsData] = useState([]);

// 		const addTableRows = ()=>{

// 			const rowsInput={
// 				fullName:'',
// 				emailAddress:'',
// 				salary:''  
// 			} 
// 			setRowsData([...rowsData, rowsInput])

// 		}
// 	const deleteTableRows = (index)=>{
// 			const rows = [...rowsData];
// 			rows.splice(index, 1);
// 			setRowsData(rows);
// 	}

// 	const handleChange = (index, evnt)=>{

// 		const { name, value } = evnt.target;
// 		const rowsInput = [...rowsData];
// 		rowsInput[index][name] = value;
// 		setRowsData(rowsInput);



// 	}
// 		return(
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-sm-8">
// 					<table className="table">
// 						<thead>
// 						<tr>
// 							<th>Item</th>
// 							<th>Rate</th>
// 							<th>Quantity</th>
// 							<th>Notes</th>
// 							<th><button className="btn btn-outline-success" onClick={addTableRows} >Add Item</button></th>
// 						</tr>
// 						</thead>
// 					<tbody>
// 					<TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
// 					</tbody> 
// 					</table>
// 					</div>
// 					<div className="col-sm-4">
// 					</div>
// 				</div>
// 			</div>
// 		)
// 	}
// 	return <AddDeleteTableRows />;
// }



const AddInvoice = (props) => {
  const inputRef = useRef(null);
  const [allSupplierItems, setAllSupplierItems] = useState([]);

  function getAllSupplierItems() {
    axios({
      url: 'http://localhost:8080/api/supplier_center/supplier_items',
      method: 'GET',
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setAllSupplierItems(response.data.supplier_items_data);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Supplier Items!');
      });
  }

  useEffect(() => {
    getAllSupplierItems();
    // console.log(allSupplierItems);
  }, []);

  function TableRows({ rowsData, deleteTableRows, handleChange }) {
    return (

      rowsData.map((data, index) => {
        const { item_id, rate, quantity, description } = data;
        return (
          <tr key={index}>
            <td>
              <select onChange={(evnt) => (handleChange(index, evnt))} name="item_id" className="form-control" value={rowsData[index].item_id || ""}>
                <option value="" selected>Select Item</option>
                {allSupplierItems.length > 0 &&
                  allSupplierItems.map(item => (
                    // <optgroup key={item.supplier_id} value={item.supplier_id}>
                    <optgroup label={item.supplier_name}>
                      {/* {item.supplier_name} */}
                      {
                        item.item_data.map(subItem => (
                          <option key={subItem.item_id} value={subItem.item_id}>
                            {subItem.item_name}
                          </option>
                        ))
                      }
                    </optgroup>
                  ))
                }
              </select>

              {/* <input type="text" value={fullName} onChange={(evnt)=>(handleChange(index, evnt))} name="fullName" className="form-control"/> */}
            </td>
            <td><input type="text" value={rate} onChange={(evnt) => (handleChange(index, evnt))} name="rate" className="form-control" /> </td>
            <td><input type="text" value={quantity} onChange={(evnt) => (handleChange(index, evnt))} name="quantity" className="form-control" /> </td>
            <td><input type="text" value={description} onChange={(evnt) => (handleChange(index, evnt))} name="description" className="form-control" /> </td>
            <td><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(index))}>x</button></td>
          </tr>
        )
      })

    )

  }
  const [rowsData, setRowsData] = useState([]);
  function AddDeleteTableRows() {

    const addTableRows = () => {

      const rowsInput = {
        item_id: '',
        rate: '',
        quantity: '',
        description: '',
      }
      setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index) => {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
      console.log(rowsData);
    }

    const handleChange = (index, evnt) => {
      // evnt.persist();
      const { name, value } = evnt.target;
      // console.log(value)
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
      // setRowsData(rowsInput, () => {
      // 	evnt.target.focus();
      // });
      // console.log(evnt.target)
      evnt.target.focus();

    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Notes</th>
                  <th><button type="button" className="btn btn-outline-success" onClick={addTableRows} >Add Item</button></th>
                </tr>
              </thead>
              <tbody>
                <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
              </tbody>
            </table>
          </div>
          <div className="col-sm-4">
          </div>
        </div>
      </div>
    )
  }

  const navigate = useNavigate();


  const [invoice, setInvoice] = useState({
    address: '',
    amount_due: 0,
    business_style: '',
    client_id: '',
    customer_message: '',
    fob: '',
    invoice_id: '',
    invoice_number: '',
    invoice_to: '',
    memo: '',
    osca_pwd_id: '',
    payments_applied: 0,
    po_number: '',
    sales_rep: '',
    ship_date: '',
    ship_to: '',
    ship_via: '',
    tax_date: '',
    terms: '',
    tin: '',
  });

  const [allClients, setAllClients] = useState([]);

  const onChange = (e) => {
    setInvoice({ ...invoice, [e.target.name]: e.target.value });
  };

  const addInvoicePost = (e) => {
    console.log(invoice)
    invoice.invoice_itemization = rowsData;
    e.preventDefault();
    MySwal.fire({
      title: <p>Creating Invoice</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: 'http://localhost:8080/api/invoice/add_invoice',
      data: invoice,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
    }).then((res) => {
      // ReactDOM.findDOMNode(this.addInvoiceForm).reset();
      console.log(res.data)
      return MySwal.fire({
        title: <strong>Invoice successfully created</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      }).then(() => {
        window.location.reload();
      })


      // Push to /
      // navigate('/');
    })
      .catch((err) => {
        console.log(err)
        console.log('Error in Adding Invoice!');
      });
  }

  const getAllClients = _ => {
    axios({
      url: 'http://localhost:8080/api/client_center/get_all_clients',
      method: 'GET',
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setAllClients(response.data.client_data);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Clients!');
      });
  }

  useEffect(() => {
    getAllClients();
    // console.log(allClients);
  }, []);

  return (
    <div className='CreateBook'>
      <div className='container'>
        <a
          className="display-4"
          href="/invoice/home"
          rel="noopener noreferrer"
        >
          {/* <Button variant="primary" className='lead text-center'>Back to Invoicing</Button> */}
          <i className="fa-solid fa-arrow-left"></i>
        </a>
        <div className='row'>

          <div className='col-md-8 m-auto'>
            <h1 className='display-6 text-center'>Add New Invoice</h1>
            <Form onSubmit={addInvoicePost} name="addInvoiceForm">

              <Form.Group className="mb-3" controlId="invoice_id">
                <Form.Label>Invoice ID</Form.Label>
                <Form.Control type="text" placeholder="Enter Invoice ID" name="invoice_id" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="invoice_number">
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control type="text" placeholder="Enter Invoice Number" name="invoice_number" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="client_id">
                <Form.Label>Client</Form.Label>
                <Form.Select aria-label="Default select example" name="client_id" onChange={onChange} >
                  <option>Select Client</option>
                  {allClients.length > 0 &&
                    allClients.map(item => (
                      <option key={item.client_id} value={item.client_id}>
                        {item.client_name}
                      </option>
                    ))
                  }
                  {/* <option value="1">One</option>
						<option value="2">Two</option>
						<option value="3">Three</option> */}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" placeholder="Enter Address" name="address" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="amount_due">
                <Form.Label>Amount Due</Form.Label>
                <Form.Control type="number" placeholder="Enter Amount Due" name="amount_due" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="business_style">
                <Form.Label>Business Style</Form.Label>
                <Form.Control type="text" placeholder="Enter Business Style" name="business_style" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="customer_message">
                <Form.Label>Customer Message</Form.Label>
                <Form.Control type="text" placeholder="Enter Customer Message" name="customer_message" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="fob">
                <Form.Label>FOB</Form.Label>
                <Form.Control type="text" placeholder="Enter FOB" name="fob" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="invoice_to">
                <Form.Label>Invoice To</Form.Label>
                <Form.Control type="text" placeholder="Enter Invoice To" name="invoice_to" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="memo">
                <Form.Label>Memo</Form.Label>
                <Form.Control type="text" placeholder="Enter Memo" name="memo" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="osca_pwd_id">
                <Form.Label>OSCA/PWD ID</Form.Label>
                <Form.Control type="text" placeholder="Enter OSCA/PWD ID" name="osca_pwd_id" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="payments_applied">
                <Form.Label>Payments Applied</Form.Label>
                <Form.Control type="number" placeholder="Enter Payments Applied" name="payments_applied" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="po_number">
                <Form.Label>P.O. Number</Form.Label>
                <Form.Control type="text" placeholder="Enter P.O. Number" name="po_number" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="sales_rep">
                <Form.Label>Sales Rep</Form.Label>
                <Form.Control type="text" placeholder="Enter Sales Rep" name="sales_rep" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="ship_date">
                <Form.Label>Ship Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Ship Date" name="ship_date" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="ship_to">
                <Form.Label>Ship To</Form.Label>
                <Form.Control type="text" placeholder="Enter Ship To" name="ship_to" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="ship_via">
                <Form.Label>Ship Via</Form.Label>
                <Form.Control type="text" placeholder="Enter Ship Via" name="ship_via" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="tax_date">
                <Form.Label>Tax Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Tax Date" name="tax_date" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="terms">
                <Form.Label>Terms</Form.Label>
                <Form.Control type="text" placeholder="Enter Terms" name="terms" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="tin">
                <Form.Label>TIN</Form.Label>
                <Form.Control type="text" placeholder="Enter TIN" name="tin" onChange={onChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="invoice_itemization">
                <Form.Label><strong>Invoice Itemization</strong></Form.Label>
                <AddDeleteTableRows />
              </Form.Group>


              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInvoice;