import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { AUTH_API_KEY, AUTH_LINK, ENCRYPTION_KEY } from "../../../../utils/env_config";
import { FormAction, FormContainer, FormContent, FormInfoFull, FormTitle } from "../../../formLayout/FormContainer";
import { Stack } from "react-bootstrap";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { decryptData } from "../../../../utils/encryption";
import { hasItems } from "../../../../utils/functions";
// import { useNavigate } from 'react-router-dom';



const ViewAllusers = () => {
  // const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState();
  const [roleData, setRoleData] = useState();
  const [token, setToken] = useState();

  const columns = [
    {
      data: "id",
      text: "User ID",
      // render: function(data, type, row) {
      // 	return `<a href='/clients/view_client/${row['client_id']}'>${data}</a>`;
      // }
    },
    {
      data: "firstname",
      text: "Full Name",
      render: function (data, type, row) {
        // return data+' '+row['lastname'];
        return `<a href='/sysad/user_management/view_user/${row['id']}'>${data + ' ' + row['lastname']}</a>`
      }
    },
    {
      data: "mobileNumber",
      text: "Mobile Number"
    },
    {
      data: "email",
      text: "Email"
    },
    {
      data: "roleId",
      text: "Role Title",
      render: function (data, type, row) {
        if (data !== null) {
          return `<a target='_blank' href='/sysad/role_management/view_role/${row['roleId']}'>${data}</a>`;
        } else {
          return 'N/A';
        }
      }
    },
    {
      data: 'isEnabled',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    },
  ];

  const getAllUsers = () => {
    axios({
      url: `${AUTH_LINK}/api/users/get_all_users`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${token}`
      },
    })
      .then((response) => {
        setAllUsers(response.data['all_users_data']['users']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Users!');
      });
  };

  const getAllRoles = () => {
    axios({
      url: `${AUTH_LINK}/api/roles/get_all_roles`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    })
      .then((response) => {
        setRoleData(response.data['all_roles_data']);
        getAllUsers();
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  const getToken = () => {
    // getting stored value
    const saved = localStorage.getItem("t");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
    // console.log('name',decryptData(initialValue, ENCRYPTION_KEY));
  };

  useEffect(() => {
    // getAllRoles();
    const initialValue = getToken();
    if (initialValue) {
      const DataToken = decryptData(initialValue, ENCRYPTION_KEY);
      setToken(DataToken);
    }
  }, []);

  useEffect(() => {
    console.log(allUsers)
    if (!$.fn.DataTable.isDataTable("#DataTableUsers")) {
      // Initialize the DataTable plugin
      $("#DataTableUsers").DataTable({
        data: allUsers,
        columns: columns,
      });
    } else {
      $("#DataTableUsers").DataTable().destroy();
      $("#DataTableUsers").DataTable({
        data: allUsers,
        columns: columns,
      });
    }
  }, [allUsers]);

  useEffect(() => {
    // console.log(token);
    getAllRoles();
  }, [token]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/user_management">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'User List',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>BEST Users List</p></FormTitle>
        <FormInfoFull>
          <Stack gap={2}>
            <div>
              <table id="DataTableUsers" className="display">
                <thead>
                  {<tr>
                    <th>User ID</th>
                    <th>Full Name</th>
                    <th>Mobile Number</th>
                    <th>Email</th>
                    <th>Role Title</th>
                    <th>Active Status</th>
                  </tr>}
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div>
              <Link className="btn btn-sm btn-success" to="/sysad/user_management/create_user">Add</Link>
            </div>
          </Stack>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllusers;