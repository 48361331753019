import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, Form, Col, Row, InputGroup, Stack, Modal, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfo, FormInfoFull } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import CurrencyInput from 'react-currency-input-field';
import { MinusCircleOutlined, PlusOutlined, CaretDownFilled } from '@ant-design/icons';
import { NumberToPhp, Required, TwoDecimalNum, hasItems, swalWithBootstrapButtons } from '../../../utils/functions';

const MySwal = withReactContent(Swal)

const JVForm = ({ oneJVData, viewOnly, jvID }) => {

  const [viewMode, setViewmode] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allAccount, setAllAccounts] = useState([]);
  const [structuredAccountData, setStructuredAccountData] = useState([]);
  const [showAccountList, setShowAccountList] = useState(false);
  const [showSupplierList, setShowSupplierList] = useState(false);
  const [titleText, setTitleText] = useState('Add Journal Voucher');
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const [accValue, setAccValue] = useState();
  const [accIndex, setAccIndex] = useState(0);

  const noDataRow = {
    entry_type: 'No Data',
    name: 'No Data',
    memo: 'No Data',
    account_code: 'No Data',
    account_name: 'No Data',
    supplier_name: 'No Data',
    supplier_id: 'No Data',
    credit_amount: 0,
    debit_amount: 0,
  }

  const [rowAddJV, setRowAddJV] = useState([
    {
      entry_type: '',
      name: '',
      memo: '',
      account_code: '',
      account_name: 'Click to Select Account',
      supplier_name: '',
      supplier_id: '',
      credit_amount: 0,
      debit_amount: 0,
    },
    {
      entry_type: '',
      name: '',
      memo: '',
      account_code: '',
      account_name: 'Click to Select Account',
      supplier_name: '',
      supplier_id: '',
      credit_amount: 0,
      debit_amount: 0,
    },
  ]);

  const [submitData, setSubmitData] = useState({
    jv_date: new Date().toISOString().split("T")[0],
    total_amount: 0,
    jv_entries: [],
  });

  async function fetchAllSupplier() {
    await fetch(`${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`, {
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAllSuppliers(data.suppliers_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  async function getAllAccounts() {
    await axios({
      url: `${FINANCE_LINK}/api/accounts_center/get_all_accounts`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    })
      .then((response) => {
        setAllAccounts(response.data['all_accounts_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All RFP Data!');
      });
  };

  async function fetchStructuredAccountData() {
    await fetch(`${FINANCE_LINK}/api/accounts_center/get_all_accounts_structured`, {
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setStructuredAccountData(data.all_accounts_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const PaddingIcon = (e) => {
    if (e > 0) {
      return (<i className="bi bi-arrow-return-right">&nbsp;&nbsp;</i>)
    } else {
      return ''
    }
  }

  const SupplierList = ({ suppliers }) => {
    return (
      <div>
        <ul style={{ cursor: 'pointer' }}>
          <li>
            <Stack direction='horizontal' gap={4} >
              <span style={{ width: '25%', fontWeight: 'bold' }}>Supplier Name</span>
              <span style={{ width: '25%', fontWeight: 'bold' }}>Contact Person</span>
              <span style={{ width: '25%', fontWeight: 'bold' }}>Email</span>
              <span style={{ width: '25%', fontWeight: 'bold' }}>Contact Number</span>
            </Stack>
          </li>
        </ul>
        {suppliers.map((sup) => (
          <ul key={sup.account_id} style={{ cursor: 'pointer' }}>
            <li>
              <Stack direction='horizontal' gap={4} onClick={() => { onSupplierChange(sup.supplier_name, sup.supplier_id) }}>
                <span style={{ width: '25%' }}>{sup.supplier_name}</span>
                <span style={{ width: '25%' }}>{sup.contact_person}</span>
                <span style={{ width: '25%' }}>{sup.email_address}</span>
                <span style={{ width: '25%' }}>{sup.mobile_number}</span>
              </Stack>
            </li>
          </ul>
        ))}
      </div>
    );
  }

  const AccountList = ({ accounts, level = 0 }) => {
    return (
      <div>
        {accounts.sort((val1, val2) => val1.account_code - val2.account_code).map((account) => (
          <ul key={account.account_id} style={{ cursor: 'pointer' }}>
            <li>
              <Stack direction='horizontal' gap={4} onClick={() => { onAccountsChange(account.account_name, account.account_id, account.account_code) }}>
                <span style={{ width: '55%', paddingLeft: level * 10 }}>{PaddingIcon(level)}<span className='fw-bolder'>{account.account_code}</span> - {account.account_name}</span>
                <span style={{ width: '20%' }}>{account.account_type}</span>
                <span style={{ width: '5%' }}>{account.currency}</span>
                <span style={{ width: '20%', textAlign: 'right' }}>{NumberToPhp(account.current_balance)}</span>
              </Stack>
            </li>
            {account.sub_accounts && (
              <AccountList accounts={account.sub_accounts} level={level + 1} />
            )}
          </ul>
        ))}
      </div>
    );
  }

  const showModal = (e, i) => {
    setAccIndex(i);
    if (hasItems(structuredAccountData)) {
      setShowAccountList(true);
    } else {
      // alert('No Data')
    }
  }

  const showModalSup = (e, i) => {
    setAccIndex(i);
    if (hasItems(allSuppliers)) {
      setShowSupplierList(true);
    } else {
      // alert('No Data')
    }
  }

  const onAccountsChange = (account_name, account_id, account_code) => {
    const list = [...rowAddJV];
    list[accIndex]['account_code'] = account_code;
    list[accIndex]['account_name'] = account_code + ' - ' + account_name;
    setRowAddJV(list);
    setShowAccountList(false);

  }

  const onSupplierChange = (supplier_name, supplier_id) => {
    const list = [...rowAddJV];
    list[accIndex]['supplier_id'] = supplier_id;
    list[accIndex]['supplier_name'] = supplier_name;
    list[accIndex]['name'] = supplier_name;
    setRowAddJV(list);
    setShowSupplierList(false);

  }

  const addJournalVoucherPost = (e) => {
    e.preventDefault();
    if (debitTotal !== 0 && creditTotal !== 0) {
      if (debitTotal === creditTotal) {
        swalWithBootstrapButtons.fire({
          title: 'All updates will be saved, continue?',
          footer: "Click anywhere outside the box to cancel",
          confirmButtonText: 'Continue',
          timer: 5000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: true,
          showCloseButton: false
        }).then((res) => {
          if (res.isConfirmed) {
            MySwal.fire({
              title: <p>Creating Journal Voucher</p>,
              allowOutsideClick: false,
              didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                MySwal.showLoading()
              },
            })
            axios({
              url: `${FINANCE_LINK}/api/journal_voucher/create_jv`,
              data: submitData,
              method: 'POST',
              headers: {
                'x-api-key': FINANCE_API_KEY,
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
              },
            }).then(async (res) => {
              // ReactDOM.findDOMNode(this.addsupplierForm).reset();
              console.log(res.data)
              return MySwal.fire({
                title: <strong>Journal Voucher Created Successfully</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'success'
              }).then(() => {
                window.location.reload();
              })
            })
              .catch(async (err) => {
                console.log(err)
                await MySwal.fire({
                  title: <strong>{err.response.data.message}</strong>,
                  // html: <i>You clicked the button!</i>,
                  icon: 'error'
                });
                MySwal.close();
              });

          }
        })
      } else {
        MySwal.fire({
          title: <strong>Debit and Credit Total must be Equal</strong>,
          // html: <i>You clicked the button!</i>,
          icon: 'warning'
        }).then(() => {
          MySwal.close();
        })
      }
    } else {
      MySwal.fire({
        title: <strong>Please check Debit and Credit Amount</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'warning'
      }).then(() => {
        MySwal.close();
      })
    }
  }

  const handleRowAdd = () => {
    setRowAddJV([...rowAddJV, { entry_type: "", name: "", memo: "", account_code: '', account_name: 'Click to Select Account', supplier_name: '', supplier_id: '', credit_amount: 0, debit_amount: 0, }]);
  };

  const handleRowDelete = (index) => {
    const list = [...rowAddJV];
    list.splice(index, 1);
    setRowAddJV(list);
  };

  const onChange = (e) => {
    setSubmitData({ ...submitData, [e.target.name]: e.target.value });
  }

  const onChangeRow = (e, index) => {
    const { name, value } = e.target;
    const list = [...rowAddJV];
    list[index][name] = value;
    setRowAddJV(list);
  }

  const onChangeDebit = (e, index) => {
    const list = [...rowAddJV];
    if (!e) {
      list[index]['debit_amount'] = 0;
    } else {
      if ([index]['credit_amount'] !== 0) {
        list[index]['credit_amount'] = 0;
      }
      list[index]['debit_amount'] = e;
      list[index]['entry_type'] = 'Debit';
    }
    setRowAddJV(list);
    totalCompute();
  }

  const onChangeCredit = (e, index) => {
    console.log();
    const list = [...rowAddJV];
    if (!e) {
      list[index]['credit_amount'] = 0;
    } else {
      if (list[index]['debit_amount'] !== 0) {
        list[index]['debit_amount'] = 0;
      }
      list[index]['credit_amount'] = e;
      list[index]['entry_type'] = 'Credit';
    }
    setRowAddJV(list);
    totalCompute();
  }

  function totalCompute() {

    const credit = (rowAddJV.reduce((total, { credit_amount }) => total + parseFloat(credit_amount), 0));
    const debit = (rowAddJV.reduce((total, { debit_amount }) => total + parseFloat(debit_amount), 0));

    setCreditTotal(TwoDecimalNum(credit));
    setDebitTotal(TwoDecimalNum(debit));
  }

  useEffect(() => {
    fetchAllSupplier();
    fetchStructuredAccountData();
    getAllAccounts();
  }, [])

  useEffect(() => {
    // console.log('rowAddJV',rowAddJV);
    totalCompute();
    if (hasItems(oneJVData) !== true) {
      const stringify = JSON.stringify(rowAddJV);
      setSubmitData(
        {
          ...submitData,
          jv_entries: stringify,
        }
      );
    }
  }, [rowAddJV])

  useEffect(() => {
    // console.log('debitTotal',debitTotal);
    if (isNaN(debitTotal)) {
      setDebitTotal(0);
    } else if (debitTotal === creditTotal) {
      setSubmitData({ ...submitData, total_amount: parseFloat(debitTotal), });
    } else {
      setSubmitData({ ...submitData, total_amount: 0, });
    }
  }, [debitTotal])

  useEffect(() => {
    // console.log('debitTotal',debitTotal);
    if (isNaN(creditTotal)) {
      setCreditTotal(0);
    } else if (debitTotal === creditTotal) {
      setSubmitData({ ...submitData, total_amount: parseFloat(creditTotal), })
    } else {
      setSubmitData({ ...submitData, total_amount: 0, });
    }
  }, [creditTotal])

  useEffect(() => {
    // console.log('accValue',accValue);
  }, [accValue])

  useEffect(() => {
    if (hasItems(oneJVData)) {
      setTitleText('View Journal Voucher')
      console.log('oneJVData', oneJVData);
      setViewmode(true);
      setSubmitData(oneJVData);
      if (hasItems(oneJVData.jv_entries)) {
        console.log(oneJVData.jv_entries);
        setRowAddJV(oneJVData.jv_entries);
      } else {
        setRowAddJV([noDataRow]);
      }
    }
  }, [oneJVData])

  useEffect(() => {
    // console.log('submitData',submitData);
    if (hasItems(oneJVData)) {
      rowAddJV.forEach((rowJV, index) => {
        allAccount.forEach((acc) => {
          if (rowJV.account_code === acc.account_code) {
            const list = [...rowAddJV];
            list[index]['account_name'] = acc.account_name;
            setRowAddJV(list);
          }
        })
      })
    }
  }, [submitData])



  return (
    <FormContent>
      <Modal show={showAccountList} scrollable={true} onHide={() => { setShowAccountList(false) }} size="xl" >
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <AccountList accounts={structuredAccountData} />
            </div>
          </Stack>
        </Modal.Body>
      </Modal>
      <Modal show={showSupplierList} scrollable={true} onHide={() => { setShowSupplierList(false) }} size="xl" >
        <Modal.Body>
          <Stack>
            <div className="mb-3">
              <SupplierList suppliers={allSuppliers} />
            </div>
          </Stack>
        </Modal.Body>
      </Modal>
      <FormTitle><p className='text-uppercase'>{titleText}</p></FormTitle>
      <FormInfoFull>
        <Form noValidate onSubmit={addJournalVoucherPost} name="addJournalVoucherForm" id="addJournalVoucherForm">
          <Row className="col-md-12">
            {
              viewMode && (
                <Col className="col-md-4">
                  <Form.Group className="mb-3" controlId="jv_date">
                    <Form.Label><b>JV ID</b></Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control type="text" value={jvID} plaintext={viewOnly} disabled={viewOnly} />
                    </InputGroup>
                  </Form.Group>
                </Col>
              )
            }
            <Col className="col-md-4">
              <Form.Group className="mb-3" controlId="jv_date">
                <Form.Label><b>Transaction Date</b> <Required /></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control type="transaction_date" value={new Date(submitData.jv_date).toISOString().split("T")[0]} name="jv_date" onChange={onChange} required plaintext={viewOnly} disabled={viewOnly} />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col className="col-md-4">
              <Form.Group className="mb-3" controlId="jv_date">
                <Form.Label><b>Date</b> <Required /></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control type="date" value={new Date(submitData.jv_date).toISOString().split("T")[0]} name="jv_date" onChange={onChange} required plaintext={viewOnly} disabled={viewOnly} />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Table responsive>
            <thead>
              <tr className="col-md-12">
                <th className="col-md-2">Name</th>
                <th className="col-md-2">Memo</th>
                <th className="col-md-3">Account</th>
                <th className="col-md-2">Credit</th>
                <th className="col-md-2">Debit</th>
                {
                  !viewMode && (
                    <th className="col-md-1">
                      <button type="button" onClick={handleRowAdd} className='btn btn-success btn-sm' hidden={viewOnly}>
                        <PlusOutlined style={{ verticalAlign: 'middle' }} />
                      </button>
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                rowAddJV.map((e, i) => (
                  <tr key={i}>
                    <td className="col-md-2">
                      {
                        !viewMode ? (
                          <>
                            <Form.Group className="mb-3" controlId="name">
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={e.name} name="name" onChange={(e) => { onChangeRow(e, i) }} required disabled={viewOnly} aria-describedby="dropDownSup" />
                                <Button variant="outline-secondary" id="dropDownSup" onClick={(e) => { showModalSup(e, i) }}>
                                  <CaretDownFilled style={{ verticalAlign: 'middle' }} />
                                </Button>
                              </InputGroup>
                            </Form.Group>
                            {/* <input className="col-md-11" type='text' placeholder='Name...' value={e.name} name='name' id='name' onChange={(e) => {onChangeRow(e,i)}} disabled={viewOnly} /> */}
                          </>
                        ) : (
                          <>
                            {e.name}
                          </>
                        )
                      }
                    </td>
                    <td>
                      {
                        !viewMode ? (
                          <>
                            <Form.Group className="mb-3" controlId="memo">
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={e.memo} name="memo" onChange={(e) => { onChangeRow(e, i) }} required disabled={viewOnly} />
                              </InputGroup>
                            </Form.Group>
                            {/* <input type='text' placeholder='Memo...' value={e.memo} name='memo' id='memo' onChange={(e) => {onChangeRow(e,i)}} disabled={viewOnly} /> */}
                          </>
                        ) : (
                          <>
                            {e.memo}
                          </>
                        )
                      }
                    </td>
                    <td>
                      {
                        !viewMode ? (
                          <>
                            <Form.Group className="mb-3" controlId="memo">
                              <InputGroup hasValidation>
                                <Form.Control type="text" value={e.account_name} onClick={(e) => { showModal(e, i) }} hidden={viewOnly} readOnly />
                              </InputGroup>
                            </Form.Group>
                            {/* <a className='btn btn-sm btn-outline-secondary' onClick={(e) => {showModal(e,i)}} hidden={viewOnly}>{e.account_name}</a> */}
                          </>
                        ) : (
                          <>
                            {e.account_code + ' - ' + e.account_name}
                          </>
                        )
                      }
                    </td>
                    <td>
                      {
                        !viewMode ? (
                          <>
                            <Form.Group className="mb-3" controlId="credit_amount">
                              <InputGroup hasValidation>
                                <Form.Control as={CurrencyInput} decimalsLimit={2} allowNegativeValue={false} prefix={'₱ '} min={0} value={e.credit_amount} name="credit_amount" onValueChange={(e) => { onChangeCredit(e, i) }} disabled={viewOnly} />
                              </InputGroup>
                            </Form.Group>
                            {/* <CurrencyInput decimalsLimit={2} value={e.credit_amount} step={0} allowNegativeValue={false} prefix={'₱ '} min={0} name='credit_amount' id='credit_amount' onValueChange={(e) => {onChangeCredit(e,i)}} disabled={viewOnly} /> */}
                          </>
                        ) : (
                          <>
                            {NumberToPhp(e.credit_amount)}
                          </>
                        )
                      }
                    </td>
                    <td>
                      {
                        !viewMode ? (
                          <>
                            <Form.Group className="mb-3" controlId="debit_amount">
                              <InputGroup hasValidation>
                                <Form.Control as={CurrencyInput} decimalsLimit={2} allowNegativeValue={false} prefix={'₱ '} min={0} value={e.debit_amount} name="debit_amount" onValueChange={(e) => { onChangeDebit(e, i) }} disabled={viewOnly} />
                              </InputGroup>
                            </Form.Group>
                            {/* <CurrencyInput decimalsLimit={2} value={e.debit_amount} allowNegativeValue={false} prefix={'₱ '} min={0} name='debit_amount' id='debit_amount' onValueChange={(e) => {onChangeDebit(e,i)}} disabled={viewOnly} /> */}
                          </>
                        ) : (
                          <>
                            {NumberToPhp(e.debit_amount)}
                          </>
                        )
                      }
                    </td>
                    {
                      !viewMode && (
                        <td>
                          <Form.Group className="mb-3">
                            {rowAddJV.length !== 2 && (
                              <button type="button" onClick={() => handleRowDelete(i)} className='btn btn-danger btn-sm' >
                                <MinusCircleOutlined style={{ verticalAlign: 'middle' }} />
                              </button>
                            )}
                          </Form.Group>
                        </td>
                      )
                    }
                  </tr>
                ))
              }
              <tr>
                <td></td>
                <td></td>
                <td style={{ fontWeight: 'bold' }}>Total:</td>
                <td style={{ fontWeight: 'bold' }}>{NumberToPhp(creditTotal)}</td>
                <td style={{ fontWeight: 'bold' }}>{NumberToPhp(debitTotal)}</td>
              </tr>
            </tbody>
          </Table>
        </Form>
        {
          !viewMode && (
            <Stack hidden={viewOnly}>
              <div className='ms-auto'>
                <button type='submit' form='addJournalVoucherForm' className='btn btn-sm btn-primary'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default JVForm;