import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';


const ViewAllRole = () => {
  const [roleData, setRoleData] = useState();

  const columns = [
    {
      data: "role_id",
      text: "Role ID",
      render: function (data, type, row) {
        return `<a href='/sysad/role_management/view_role/${row['role_id']}'>${data}</a>`;
      }
    },
    {
      data: "role_name",
      text: "Role Name",
    },
    {
      data: "created_by",
      text: "Created By",
    },
    {
      data: "created_date",
      text: "Created Date",
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }

      }
    },
    {
      data: "last_updated_by",
      text: "Last Updated By"
    },
    {
      data: 'is_active',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    },
  ];

  const getAllRoles = () => {
    axios({
      url: `${AUTH_LINK}/api/roles/get_all_roles`,
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    })
      .then((response) => {
        setRoleData(response.data['all_roles_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    // console.log(roleData)
    if (!$.fn.DataTable.isDataTable("#RoleTable")) {
      // Initialize the DataTable plugin
      $("#RoleTable").DataTable({
        data: roleData,
        columns: columns,
      });
    } else {
      $("#RoleTable").DataTable().destroy();
      $("#RoleTable").DataTable({
        data: roleData,
        columns: columns,
      });
    }
  }, [roleData]);



  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: 'Role List',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className="text-uppercase">Role List</p></FormTitle>
        <FormInfoFull>
          <Stack gap={2}>
            <div>
              <table id="RoleTable" className="display" >
                <thead>
                  <tr>
                    <th>Role ID</th>
                    <th>Role Name</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>Last Updated By</th>
                    <th>Active Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div>
              <Link to={'/sysad/role_management/create_role'} className="btn btn-sm btn-success">ADD</Link>
            </div>
          </Stack>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllRole;