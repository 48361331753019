import React from 'react';
import { Link } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import SupplierForm from './SupplierForm';

const AddSupplier = (props) => {

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../suppliers/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../suppliers/view_all">Supplier List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New Supplier',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <SupplierForm editView={false} />

    </FormContainer>
  );
};

export default AddSupplier;