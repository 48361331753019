import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK, PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';


const ViewAllAPV = () => {
  const [allAPVData, setAPVData] = useState({});
  const [allSupData, setSupData] = useState({});

  async function fetchRRData() {
    await fetch(`${FINANCE_LINK}/api/apv/get_all_apv`, {
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAPVData(data.apv_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  async function fetchSupData() {
    await fetch(`${PROCUREMENT_LINK}/api/supplier_center/get_all_suppliers`, {
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setSupData(data.suppliers_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchRRData();
    fetchSupData();
  }, [])

  useEffect(() => {
    // console.table(allSupData)
  }, [allSupData])


  const columns = [
    {
      data: 'apv_id',
      text: 'APV ID',
      render: function (data, type, row) {
        return `<a href='/apv/view_apv/${row['apv_id']}'>${data}</a>`;
      }
    },
    {
      data: 'transaction_date',
      text: 'Transaction Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: 'due_date',
      text: 'Due Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: 'supplier_id',
      text: 'Supplier',
      render: function (data) {
        var name = '';
        var supID = '';
        try {
          allSupData.forEach(element => {
            if (element.supplier_id === data) {
              name = element.supplier_name
              supID = element.supplier_id
            }
          });
        } catch (err) { }
        return `<a target='_blank' href='/suppliers/view_supplier/${supID}'>${name}</a>`
      }
    },
    {
      data: 'created_date',
      text: 'Created Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }
      }
    },
    {
      data: 'is_fully_paid',
      text: 'Payment Status',
      render: function (data, type, row) {
        if (data === true) {
          return 'Fully Paid';
        } else if (row['is_partially_paid']) {
          return 'Partially Paid';
        } else {
          return 'Not Paid';
        }
      }
    },
    {
      data: 'is_active',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    },
  ];

  useEffect(() => {

    if (!$.fn.DataTable.isDataTable("#TableAPVData")) {
      $("#TableAPVData").DataTable({
        responsive: true,
        data: allAPVData,
        columns: columns,
        order: [[0, 'desc']],
      });
    } else {
      $("#TableAPVData").DataTable().destroy();
      $("#TableAPVData").DataTable({
        responsive: true,
        data: allAPVData,
        columns: columns,
        order: [[0, 'desc']],
      });
    }

  }, [allAPVData]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal' gap={3}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../apv/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'APV List',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className='text-uppercase'>Accounts Payable Voucher (APV)</p></FormTitle>
        <FormInfoFull>
          <div className="container-fluid" rel="noopener noreferrer">
            <table id="TableAPVData" className="display">
              <thead>
                <tr>
                  <th>APV ID</th>
                  <th>Transaction Date</th>
                  <th>Due Date</th>
                  <th>Supplier</th>
                  <th>Created Date</th>
                  <th>Payment Status</th>
                  <th>Active Status</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <Link className='btn btn-success btn-sm' to='/apv/add_apv' >Add APV</Link>
          </div>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllAPV;
