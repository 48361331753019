import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Col, Row, InputGroup, Stack } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfo } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import { numberFormating } from '../../../utils/functions';

const Required = _ => {
  return (
    <span style={{ color: "red" }}>*</span>
  )
}


const MySwal = withReactContent(Swal)

const EditBank = () => {

  const { bankID } = useParams();
  const [oneBankData, setOneBankData] = useState({});
  const [isActiveTrue, setIsActiveTrue] = useState(null);
  const [isReadonly, setIsReadonly] = useState(true);
  const [editMode, setEditMode] = useState(true);
  const [editMode2, setEditMode2] = useState(false);
  const [show, toggleShow] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const getOneBankData = _ => {
    axios({
      url: 'https://finance-test.fly.dev/api/bank_center/get_bank_account?bank_center_id=' + bankID,
      method: 'GET'
    }).then((res) => {
      setOneBankData(res.data['bank_account_data']);
      // console.log(rfpOneData)
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting Bank Center Data!');
    });
  }

  const handleChange = event => {
    // console.log(event.target.value);
    setSelectedType(event.target.value);
  };

  const logResult = useCallback(() => {
    return 0;
  }, []);

  useEffect(() => {

    try {

      if (oneBankData.is_active === true) {
        setIsActiveTrue(true)
      } else {
        setIsActiveTrue(false)
      }

      if (oneBankData.bank_type === 'Savings Account') {
        setSelectedType('Savings Account')
      } else {
        setSelectedType('Checking Account')
      }

    } catch (err) {
      console.log(err)
    }

  }, [oneBankData, logResult]);

  useEffect(() => {
    // getBankData();
    getOneBankData();
  }, []);

  const [validated, setValidated] = useState(false);
  const [counter, setCounter] = useState(false);

  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(!active);
  };
  const handleClickRefresh = () => {

    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      // cancelButtonText: 'Cancel',
      buttonsStyling: 'right-margin: 0.5rem',
      timer: 5000,
      timerProgressBar: true,
      // showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const onChange = (e) => {
    // setBankArr({ ...bankArr, [e.target.name]: e.target.value });
    setOneBankData({ ...oneBankData, [e.target.name]: e.target.value });
    // console.log(oneBankData)
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const updateBankPost = (e) => {

    const form = e.currentTarget;
    // console.log(bankArr)

    e.preventDefault();
    if (form.checkValidity() === true) {
      swalWithBootstrapButtons.fire({
        title: 'Please Wait...',
        text: 'Populating Data',
        timer: 1500,
        allowOutsideClick: false,
        showConfirmButton: false,
      }).then(_ => {
        swalWithBootstrapButtons.fire({
          title: 'Are you sure you want to Update?',
          text: "You won't be able to revert this!",
          footer: "Click anywhere outside the box to cancel",
          confirmButtonText: 'Update',
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: true,
          showCloseButton: false
        }).then((result) => {
          if (result.isConfirmed) {
            MySwal.fire({
              title: <p>Updating Bank Center Data</p>,
              allowOutsideClick: false,
              didOpen: () => {
                MySwal.showLoading()
              },
            })
            axios({
              url: 'https://finance-test.fly.dev/api/bank_center/update_bank_account',
              data: oneBankData,
              method: 'PUT',
              headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
              },
            }).then(async (res) => {
              // console.log(res.data)
              await MySwal.fire({
                title: <strong>Bank successfully updated</strong>,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
              });
              window.location.reload();
              // console.log(bankArr);
              // e.preventDefault();
            }).catch((err) => {
              console.log(err)
              console.log('Error in Updating RFP!');
            });
          } else {
            setCounter(false)
            // console.log(counter)
          }
        })
      })

    }

  }

  const handleSubmit = (event) => {

    try {

      setOneBankData({
        ...oneBankData,
        account_name: event.target.elements.account_name.value, //Account Name
        account_nickname: event.target.elements.account_nickname.value, //Account Nickname
        account_code: event.target.elements.account_code.value, //Account Code
        bank_name: event.target.elements.bank_name.value, //Bank Name
        bank_type: event.target.elements.bank_type.value, //Bank type
        bank_account_number: event.target.elements.bank_account_number.value, //Bank Account Number
      });

      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        MySwal.fire({
          title: 'Please Fill out Required Fields',
          icon: 'warning',
          cancelButtonText: 'OK',
          timer: 2000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          showCloseButton: false
        })
      }

      if (form.checkValidity() === true) {
        event.preventDefault();
        event.stopPropagation();
        updateBankPost(event);
        setCounter(true);
      } else {

        setValidated(true);
        // console.log(rfp)
        updateBankPost(event);

      }
    } catch (err) {
      console.log(err)
    }

  };

  const updateActive = (e) => {
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to Update Active Status?',
      // text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Update',
      timer: 4000,
      timerProgressBar: true,
      showConfirmButton: true,
      showCloseButton: false
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: 'https://finance-test.fly.dev/api/bank_center/change_bank_center_account_active_status?bank_center_id=' + bankID,
          params: { bank_center_id: bankID },
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }).then(async (res) => {
          // console.log(res.data)
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            console.log(err)
            console.log('Error in Updating Active Status!');
          });
      } else {
        console.log(result)
      }
    })

  }

  const onClickEdit = _ => {
    setIsReadonly(prevState => !prevState);
    setEditMode(prevState => !prevState);
    setEditMode2(prevState => !prevState);
    toggleShow(!show);
    handleClick();
  }

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal">
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location',
              },
              {
                type: 'separator',
                separator: ':',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../bank_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../bank_center/view_all_bank">Bank List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View Bank Account',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle>
          <p>BANK DETAILS</p>
        </FormTitle>
        <Form noValidate validated={validated} onSubmit={handleSubmit} id="addBankForm">
          <FormInfo>
            <Stack>
              {
                !editMode2 ? (
                  <div>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label size="sm" column sm="5" className='fw-bolder'>Bank ID</Form.Label>
                      <Col sm="7">
                        <Form.Control plaintext disabled type="text" value={bankID} />
                      </Col>
                    </Form.Group>
                  </div>
                ) : ""
              }
              <div>
                <Form.Group as={Row} className="mb-3" controlId="account_name">
                  <Form.Label size="sm" column sm="5" className='fw-bolder'>Account Name<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup size="sm" hasValidation>
                      <Form.Control plaintext={isReadonly} disabled={isReadonly} type="text" value={oneBankData.account_name} name="account_name" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </div>
              <div>
                <Form.Group as={Row} className="mb-3" controlId="account_nickname">
                  <Form.Label size="sm" column sm="5" className='fw-bolder'>Account Nickname<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup size="sm" hasValidation>
                      <Form.Control plaintext={isReadonly} disabled={isReadonly} type="text" value={oneBankData.account_nickname} name="account_nickname" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Nickname.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </div>
              <div>
                <Form.Group as={Row} className="mb-3" controlId="account_code">
                  <Form.Label size="sm" column sm="5" className='fw-bolder'>Account Code<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup size="sm" hasValidation>
                      <Form.Control plaintext={isReadonly} disabled={isReadonly} type="text" value={oneBankData.account_code} name="account_code" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Account Code.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </div>
              <div>
                {!editMode ? (
                  <button type='button' className='btn btn-danger btn-sm' onClick={handleClickRefresh}>
                    Cancel
                  </button>
                ) : (
                  <button onClick={onClickEdit} className='btn btn-primary btn-sm'>
                    Edit
                  </button>
                )}
              </div>
            </Stack>
          </FormInfo>
          <FormInfo>
            <Stack>
              <div>
                <Form.Group as={Row} className="mb-3" controlId="bank_name">
                  <Form.Label size="sm" column sm="5" className='fw-bolder'>Bank Name<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup size="sm" hasValidation>
                      <Form.Control plaintext={isReadonly} disabled={isReadonly} type="text" value={oneBankData.bank_name} name="bank_name" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Bank Name.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </div>
              {
                !editMode ? (
                  <div>
                    <Form.Group as={Row} className="mb-3" controlId="bank_type">
                      <Form.Label size="sm" column sm="5" className='fw-bolder'>Bank type<Required />: </Form.Label>
                      <Col sm="7">
                        <Form.Select size="sm" name="bank_type" aria-label="bank_type" required onChange={onChange}>
                          <option name="bank_type" value="Savings Account" selected={selectedType === 'Savings Account'}>Savings Account</option>
                          <option name="bank_type" value="Checking Account" selected={selectedType === 'Checking Account'}>Checking Account</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                ) : (
                  <div>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label size="sm" column sm="5" className='fw-bolder'>Bank type<Required /></Form.Label>
                      <Col sm="7">
                        <Form.Control plaintext disabled type="text" value={oneBankData.bank_type} />
                      </Col>
                    </Form.Group>
                  </div>
                )
              }
              <div>
                <Form.Group as={Row} className="mb-3" controlId="bank_account_number">
                  <Form.Label size="sm" column sm="5" className='fw-bolder'>Bank Account Number<Required /></Form.Label>
                  <Col sm="7">
                    <InputGroup size="sm" hasValidation>
                      <Form.Control plaintext={isReadonly} disabled={isReadonly} type="text" value={oneBankData.bank_account_number} name="bank_account_number" onChange={onChange} required />
                      <Form.Control.Feedback tooltip type="invalid">
                        Please fill up Bank Account Number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </div>
              {
                !editMode2 ? (
                  <div>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label size="sm" column sm="5" className='fw-bolder'>Current Balance<Required /></Form.Label>
                      <Col sm="7">
                        <Form.Control plaintext disabled type="text" value={"₱ " + numberFormating(oneBankData.current_balance)} />
                      </Col>
                    </Form.Group>
                  </div>
                ) : (
                  <div className='ms-auto'>
                    <button type='submit' form="addBankForm" className='btn btn-success btn-sm' onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                )
              }
            </Stack>
          </FormInfo>
        </Form>
      </FormContent>
    </FormContainer>
  );
};

export default EditBank;