import React, { useEffect, useState } from 'react';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link, useParams } from 'react-router-dom';
import ModuleForm from './ModuleForm';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';

const ViewModule = (props) => {

  const { moduleID } = useParams();
  var editView = true;

  const [oneModuleData, setOneModuleData] = useState([])

  async function fetchOneModuleData() {
    await fetch(`${AUTH_LINK}/api/modules/get_module?module_id=${moduleID}`, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': AUTH_API_KEY, // Example header
        // 'Authorization': 'Bearer your-access-token' // Example header
        // Add more headers as needed
      },
    })
      .then(response => response.json())
      .then(data => {
        setOneModuleData(data.module_data)
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOneModuleData();
  }, [])


  useEffect(() => {
    // console.log(oneModuleData);
  }, [oneModuleData])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/module_management/view_all">Module List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View Module',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <ModuleForm moduleID={moduleID} oneModuleData={oneModuleData} editView={editView} />
    </FormContainer>
  );
};

export default ViewModule;