import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net/js/jquery.dataTables";
import axios from 'axios';
// import style from './rfpHome.module.css'
import { NumberToPhp } from '../../../utils/functions';
import { Stack } from 'react-bootstrap';
import { FormContainer, FormContent, FormTitle, FormInfoFull, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../utils/env_config';

const ViewAllRFP = () => {

  const [allRFP, setAllRFP] = useState({});

  const columns = [
    {
      data: 'rfp_id',
      text: 'RFP Number',
      render: function (data, type, row) {
        return `<a href='/rfp/view_rfp/${row['rfp_id']}'>${data}</a>`;
      }
    },
    {
      data: 'payee',
      text: 'Payee'
    },
    {
      data: 'date_requested',
      text: 'Date',
      render: function (data, type) {
        if (data === '' || data === null) {
          return ''
        } else {
          var dateNeed = new Date(data).toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
          return dateNeed // `${data}`
        }

      }
    },
    {
      data: 'particulars_total_amount',
      text: 'Total Amount',
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00'
        } else {
          return NumberToPhp(data)
          // return data
        }
      }
    },
    {
      data: 'is_active',
      text: 'Active Status',
      render: function (data, type) {
        if (data === true) {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    },
    {
      data: 'is_approved',
      text: 'Approval Status',
      render: function (data, type) {
        if (data === true) {
          return 'Approved'
        } else if (data === false) {
          return 'Rejected'
        } else {
          return 'Pending'
        }
      }
    }
  ];

  const getAllRFP = () => {
    axios({
      url: `${FINANCE_LINK}/api/rfp/get_all`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY
      },
    })
      .then((response) => {
        setAllRFP(response.data['all_rfp_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All RFP Data!');
      });
  };

  useEffect(() => {
    getAllRFP();
    // fetchRFPData();
  }, []);

  useEffect(() => {

    if (!$.fn.DataTable.isDataTable("#TableRFPData")) {
      // Initialize the DataTable plugin
      $("#TableRFPData").DataTable({
        data: allRFP,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -3,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    } else {
      $("#TableRFPData").DataTable().destroy();
      $("#TableRFPData").DataTable({
        data: allRFP,
        columns: columns,
        order: [[0, 'desc']],
        columnDefs: [
          {
            targets: -3,
            className: 'dt-right',
            // className: 'dt-body-right'
          }
        ]
      });
    }

  }, [allRFP]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal" gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: 'Home',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p>REQUEST FOR PAYMENT (RFP)</p></FormTitle>
        <FormInfoFull>
          <table id="TableRFPData" className="display">
            <thead>
              <tr>
                <th>RFP Number</th>
                <th>Payee</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Active Status</th>
                <th>Approval Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div>
            <Link className='btn btn-success btn-sm' to='/rfp/add_rfp' >ADD</Link>
          </div>
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewAllRFP;
