import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import ReactDOM from 'react-dom';
import { Form, Button, Col, Row } from 'react-bootstrap';
// import SweetAlert from 'react-bootstrap-sweetalert';
import RequiredMarker from '../../_components/misc/RequiredMarker'
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp } from '../../../utils/functions.jsx';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb, Switch } from 'antd';
import ItemForm from './ItemForm';

const MySwal = withReactContent(Swal)

const ViewItem = (props) => {

  const { itemID } = useParams();

  const [oneItem, setoneItem] = useState([]);

  const getOneItem = _ => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_item?item_id=` + itemID,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setoneItem(response.data['invoice_item_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching Item!');
      });
  }

  useEffect(() => {
    getOneItem();
  }, []);

  const formRequired = true;
  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal' gap={2}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: <span>Location:</span>,
              },
              {
                title: <Link style={{ color: '#909090' }} to="../item_center/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../item_center/view_all">Item List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Supplier Item',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <ItemForm editView={true} oneItemData={oneItem} itemID={itemID} />

    </FormContainer>
  );
};

export default ViewItem;