import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Layout Components
import Layout from './components/layout/Layout';
import AuthLayout from './components/layout/AuthLayout';

// Auth Components
// import LandingPage from './components/app_views/auth/LandingPage';
import Dashboard from './components/app_views/auth/Dashboard';
import Login from './components/app_views/auth/Login';
import ForgotPassword from './components/app_views/auth/ForgotPassword';
import ResetPassword from './components/app_views/auth/ResetPassword';

// import LoginPage from './components/app_views/auth/LoginPage';

// System Admin - User Management Components
import UserManagementHome from './components/app_views/systemAdmin/userManagement/UserManagementHome';
import CreateUser from './components/app_views/systemAdmin/userManagement/CreateUser';
import ViewUser from './components/app_views/systemAdmin/userManagement/ViewUser';
import UsersList from './components/app_views/systemAdmin/userManagement/UsersList';
import SysAdViewUserProfile from './components/app_views/systemAdmin/userManagement/SysAdViewUserProfile';

// System Admin - Role Management Components
import ViewAllRole from './components/app_views/systemAdmin/roleManagement/ViewAllRoles';
import CreateRole from './components/app_views/systemAdmin/roleManagement/CreateRole';
import ViewRole from './components/app_views/systemAdmin/roleManagement/ViewRole';

// System Admin - Module Management Components
import ViewAllModule from './components/app_views/systemAdmin/moduleManagement/ViewAllModules';
import CreateModule from './components/app_views/systemAdmin/moduleManagement/CreateModules';
import ViewModule from './components/app_views/systemAdmin/moduleManagement/ViewModule';

// System Admin - Permissions Management Components
import ViewAllPermissions from './components/app_views/systemAdmin/permissionsManagement/ViewAllPermissions';
import CreatePermissions from './components/app_views/systemAdmin/permissionsManagement/CreatePermissions';
import ViewPermissions from './components/app_views/systemAdmin/permissionsManagement/ViewPermissions';

// Invoice Components
import InvoiceHome from './components/app_views/invoice/InvoiceHome';
import AddInvoice from './components/app_views/invoice/AddInvoice';
import ViewAllInvoices from './components/app_views/invoice/ViewAllInvoices';
import InvoicePage from './components/app_views/invoice/InvoicePage';
import SearchInvoice from './components/app_views/invoice/SearchInvoice';

// Client Center Components
import ClientCenterHome from './components/app_views/clientCenter/ClientCenterHome';
import ViewAllClients from './components/app_views/clientCenter/ViewAllClients';
import ClientInformationPage from './components/app_views/clientCenter/ClientInformationPage';
import AddClient from './components/app_views/clientCenter/AddClient';

// Supplier Center Components
import SupplierCenterHome from './components/app_views/supplierCenter/SupplierCenterHome';
import ViewallSuppliers from './components/app_views/supplierCenter/ViewAllSuppliers';
import AddSupplier from './components/app_views/supplierCenter/AddSupplier';
import SupplierInformationPage from './components/app_views/supplierCenter/SupplierInformationPage';

// Reports Components
import ReportsHome from './components/app_views/reports/ReportsHome';
import AccountsReceivable from './components/app_views/reports/AccountsReceivable'

// Item Center Components
import ItemCenterHome from './components/app_views/itemCenter/ItemCenterHome';
import ViewAllItems from './components/app_views/itemCenter/ViewAllItems';
import AddItem from './components/app_views/itemCenter/AddItem';
import ViewItem from './components/app_views/itemCenter/ViewItem';
// import SearchItem from './components/app_views/itemCenter/SearchItem';

// Purchase Requests Components
import CreatePurchaseRequests from './components/app_views/purchaseRequests/CreatePurchaseRequests';
import PurchaseRequestHome from './components/app_views/purchaseRequests/PurchaseRequestHome';
import ViewAllPR from './components/app_views/purchaseRequests/ViewAllPR';
import ViewPR from './components/app_views/purchaseRequests/ViewPR';

// Purchase Order Components
import ViewAllPO from './components/app_views/purchaseOrder/ViewAllPO';
import ViewPO from './components/app_views/purchaseOrder/ViewPO';

// Receiving Report Components
import ViewAllRR from './components/app_views/receivingReceipt/ViewAllRR';
import ViewRR from './components/app_views/receivingReceipt/ViewRR';

// JV Components
import AddJV from './components/app_views/jv/AddJV';
import ViewAllJV from './components/app_views/jv/ViewAllJV';
import ViewJV from './components/app_views/jv/ViewJV';

// APV Components
import APVHome from './components/app_views/apv/APVHome';
import AddAPV from './components/app_views/apv/AddAPV';
import ViewAllAPV from './components/app_views/apv/ViewAllAPV';
import ViewAPV from './components/app_views/apv/ViewAPV';

// APV Aging Components
import APAging from './components/app_views/apv_aging/APAging';

// CV Components
import CVHome from './components/app_views/cv/CVHome';
import ViewAllCV from './components/app_views/cv/ViewAllCV';
import CreateCV from './components/app_views/cv/CreateCV';
import ViewCV from './components/app_views/cv/ViewCV';

// RFP Components
import RFPHome from './components/app_views/rfp/RFPHome';
import AddRFP from './components/app_views/rfp/AddRFP';
import ViewRFP from './components/app_views/rfp/ViewRFP';

// Account Center Components
import AccountCenterHome from './components/app_views/accountCenter/AccountCenterHome';
import AddAccounts from './components/app_views/accountCenter/AddAccount';
import ViewAllAccount from './components/app_views/accountCenter/ViewAllAccounts';
import ViewAccount from './components/app_views/accountCenter/ViewAccount';

// Bank Center Components
import BankCenterHome from './components/app_views/bankCenter/BankCenterHome';
import AddBank from './components/app_views/bankCenter/AddBanks';
import ViewAllBank from './components/app_views/bankCenter/ViewAllBanks';
import EditBank from './components/app_views/bankCenter/ViewBank';


const App = () => {
  return (
    // <React.Fragment>
    //   <Router>
    //     <NavigationBar />
    //   </Router>
    // </React.Fragment>

    <Router>
      {/* <NavigationBar /> */}
      <div>
        <Routes>
          {/* Auth Routes */}
          {/* <Route exact path='/' element={<Layout><LandingPage /></Layout>} /> */}
          <Route exact path='/' element={<AuthLayout><Login /></AuthLayout>} />
          <Route exact path='/login' element={<AuthLayout><Login /></AuthLayout>} />
          {/* <Route exact path='/' element={<AuthLayout><LoginPage /></AuthLayout>} />
            <Route exact path='/login' element={<AuthLayout><LoginPage /></AuthLayout>} /> */}
          <Route exact path='/dashboard' element={<Layout><Dashboard /></Layout>} />
          <Route exact path='/forgot_password' element={<AuthLayout><ForgotPassword /></AuthLayout>} />
          <Route exact path='/reset_password' element={<AuthLayout><ResetPassword /></AuthLayout>} />

          {/* System Admin - User Management Routes */}
          <Route exact path='/sysad/user_management' element={<Layout><UserManagementHome /></Layout>} />
          <Route exact path='/sysad/user_management/create_user' element={<Layout><CreateUser /></Layout>} />
          <Route exact path='/sysad/user_management/view_user/:userID' element={<Layout><ViewUser /></Layout>} />
          <Route exact path='/sysad/user_management/user_list' element={<Layout><UsersList /></Layout>} />
          <Route exact path='/sysad/user_management/view_user' element={<Layout><SysAdViewUserProfile /></Layout>} />

          {/* System Admin - Role Management Routes */}
          <Route exact path='/sysad/role_management/view_all' element={<Layout><ViewAllRole /></Layout>} />
          <Route exact path='/sysad/role_management/create_role' element={<Layout><CreateRole /></Layout>} />
          <Route exact path='/sysad/role_management/view_role/:roleID' element={<Layout><ViewRole /></Layout>} />

          {/* System Admin - Module Management Routes */}
          <Route exact path='/sysad/module_management/view_all' element={<Layout><ViewAllModule /></Layout>} />
          <Route exact path='/sysad/module_management/create_module' element={<Layout><CreateModule /></Layout>} />
          <Route exact path='/sysad/module_management/view_module/:moduleID' element={<Layout><ViewModule /></Layout>} />

          {/* System Admin - Permissions Management Routes */}
          <Route exact path='/sysad/permissions_management/view_all' element={<Layout><ViewAllPermissions /></Layout>} />
          <Route exact path='/sysad/permissions_management/create_permissions' element={<Layout><CreatePermissions /></Layout>} />
          <Route exact path='/sysad/permissions_management/view_permissions/:permissionID' element={<Layout><ViewPermissions /></Layout>} />

          {/* Invoice Routes */}
          <Route exact path='/invoice/home' element={<Layout><InvoiceHome /></Layout>} />
          <Route exact path='/invoice/create_invoice' element={<Layout><AddInvoice /></Layout>} />
          <Route exact path='/invoice/view_all' element={<Layout><ViewAllInvoices /></Layout>} />
          <Route exact path='/invoice/search' element={<Layout><SearchInvoice /></Layout>} />
          <Route exact path="/invoice/view_invoice/:invoice_id" element={<Layout><InvoicePage /></Layout>} />

          {/* Client Center Routes */}
          <Route exact path='/clients/home' element={<Layout><ClientCenterHome /></Layout>} />
          <Route exact path='/clients/view_all' element={<Layout><ViewAllClients /></Layout>} />
          <Route exact path='/clients/view_client/:clientID' element={<Layout><ClientInformationPage /></Layout>} />
          <Route exact path='/clients/add_client' element={<Layout><AddClient /></Layout>} />

          {/* Supplier Center Routes */}
          <Route exact path='/suppliers/home' element={<Layout><SupplierCenterHome /></Layout>} />
          <Route exact path='/suppliers/view_all' element={<Layout><ViewallSuppliers /></Layout>} />
          <Route exact path='/suppliers/add_supplier' element={<Layout><AddSupplier /></Layout>} />
          <Route exact path='/suppliers/view_supplier/:supplierID' element={<Layout><SupplierInformationPage /></Layout>} />

          {/* Reporting Routes */}
          <Route exact path='/reports/home' element={<Layout><ReportsHome /></Layout>} />
          <Route exact path='/reports/accounts_receivable' element={<Layout><AccountsReceivable /></Layout>} />

          {/* Item Center Routes */}
          <Route exact path='/item_center/home' element={<Layout><ItemCenterHome /></Layout>} />
          <Route exact path='/item_center/add_item' element={<Layout><AddItem /></Layout>} />
          <Route exact path='/item_center/add_item/:supID' element={<Layout><AddItem /></Layout>} />
          <Route exact path='/item_center/view_all' element={<Layout><ViewAllItems /></Layout>} />
          <Route exact path='/item_center/view_item/:itemID' element={<Layout><ViewItem /></Layout>} />

          {/* Purchase Requests Routes */}
          <Route exact path='/purchase_requests/home' element={<Layout><PurchaseRequestHome /></Layout>} />
          <Route exact path='/purchase_requests/create' element={<Layout><CreatePurchaseRequests /></Layout>} />
          <Route exact path='/purchase_requests/view_all' element={<Layout><ViewAllPR /></Layout>} />
          <Route exact path='/purchase_requests/view_pr/:prID' element={<Layout><ViewPR /></Layout>} />

          {/* Purchase Order Routes */}
          <Route exact path='/purchase_order/home' element={<Layout><ViewAllPO /></Layout>} />
          <Route exact path='/purchase_order/view_po/:poID' element={<Layout><ViewPO /></Layout>} />

          {/* Receiving Receipt Routes */}
          <Route exact path='/recieving_receipt/home' element={<Layout><ViewAllRR /></Layout>} />
          <Route exact path='/recieving_receipt/view_rr/:rrID' element={<Layout><ViewRR /></Layout>} />

          {/* JV Routes */}
          <Route exact path='/journal_voucher/addjv' element={<Layout><AddJV /></Layout>} />
          <Route exact path='/journal_voucher/view_all' element={<Layout><ViewAllJV /></Layout>} />
          <Route exact path='/journal_voucher/view_jv/:jvID' element={<Layout><ViewJV /></Layout>} />

          {/* APV Routes */}
          <Route exact path='/apv/home' element={<Layout><APVHome /></Layout>} />
          <Route exact path='/apv/add_apv' element={<Layout><AddAPV /></Layout>} />
          <Route exact path='/apv/view_all' element={<Layout><ViewAllAPV /></Layout>} />
          <Route exact path='/apv/view_apv/:apvID' element={<Layout><ViewAPV /></Layout>} />
          <Route exact path='/apv/create_cv/:apvID' element={<Layout><CreateCV /></Layout>} />

          {/* APV Routes */}
          <Route exact path='/ap_aging/' element={<Layout><APAging /></Layout>} />

          {/* CV Routes */}
          <Route exact path='/cv/home' element={<Layout><CVHome /></Layout>} />
          <Route exact path='/cv/view_all' element={<Layout><ViewAllCV /></Layout>} />
          <Route exact path='/cv/create_cv' element={<Layout><CreateCV /></Layout>} />
          <Route exact path='/cv/view_cv/:cvID' element={<Layout><ViewCV /></Layout>} />

          {/* RFP Routes */}
          <Route exact path='/rfp/home' element={<Layout><RFPHome /></Layout>} />
          <Route exact path='/rfp/add_rfp' element={<Layout><AddRFP /></Layout>} />
          <Route exact path='/rfp/view_rfp/:rfpID' element={<Layout><ViewRFP /></Layout>} />

          {/* Account Center Routes */}
          <Route exact path='/account_center/' element={<Layout><AccountCenterHome /></Layout>} />
          <Route exact path='/account_center/add_account' element={<Layout><AddAccounts /></Layout>} />
          <Route exact path='/account_center/view_all_account' element={<Layout><ViewAllAccount /></Layout>} />
          <Route exact path='/account_center/view_account/:accountID' element={<Layout><ViewAccount /></Layout>} />

          {/* Bank Center Routes */}
          <Route exact path='/bank_center/home' element={<Layout><BankCenterHome /></Layout>} />
          <Route exact path='/bank_center/add_bank' element={<Layout><AddBank /></Layout>} />
          <Route exact path='/bank_center/view_all_bank' element={<Layout><ViewAllBank /></Layout>} />
          <Route exact path='/bank_center/view_bank/:bankID' element={<Layout><EditBank /></Layout>} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;