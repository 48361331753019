import React, { useEffect, useState } from 'react';
import { FormAction, FormContainer } from '../../../formLayout/FormContainer';
import { Stack } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { Link, useParams } from 'react-router-dom';
import RoleForm from './RoleForm';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import { hasItems } from '../../../../utils/functions';

const ViewRole = (props) => {

  const { roleID } = useParams();
  var editView = true;

  const [oneRoleData, setOneRoleData] = useState([]);
  const [oneRoleAccessData, setOneRoleAccessData] = useState([]);
  const [oneAccessDataKey, setOneAccessDataKey] = useState(["module_name"]);
  const [allRoleAccessData, setAllRoleAccessData] = useState([]);

  async function fetchallRoleAccessData() {
    await fetch(`${AUTH_LINK}/api/roles/get_current_access_data_structure`, {
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setAllRoleAccessData(data.access_data_structure);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  async function fetchOneRoleData() {
    await fetch(`${AUTH_LINK}/api/roles/get_role?role_id=${roleID}`, {
      method: 'GET',
      headers: {
        'x-api-key': AUTH_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setOneRoleData(data.role_data);
        const arrayJson = JSON.parse(data.role_data.access_data);
        setOneRoleAccessData(arrayJson);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOneRoleData();
    fetchallRoleAccessData();
  }, [])

  useEffect(() => {
    // console.log('oneRoleAccessData',oneRoleAccessData);
    if (hasItems(oneRoleAccessData)) {
      setOneAccessDataKey(Object.keys(oneRoleAccessData[0]));
    }
  }, [oneRoleAccessData])

  useEffect(() => {
    // console.log(oneRoleData);
  }, [oneRoleData])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../sysad/role_management/view_all">Role List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View Role',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <RoleForm roleID={roleID} oneRoleData={oneRoleData} oneRoleAccessData={oneRoleAccessData} oneAccessDataKey={oneAccessDataKey} allRoleAccessData={allRoleAccessData} editView={editView} />
    </FormContainer>
  );
};

export default ViewRole;