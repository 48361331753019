import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import CVForm from './CVForm';
import { FINANCE_API_KEY, FINANCE_LINK } from '../../../utils/env_config';

const ViewCV = () => {

  var editView = true;
  const { cvID } = useParams();

  const [getOneCV, setGetOneCV] = useState([]);

  async function getallCV() {
    await axios({
      url: `${FINANCE_LINK}/api/cash_voucher/get_cv?cv_id=${cvID}`,
      method: 'GET',
      headers: {
        'x-api-key': FINANCE_API_KEY,
      },
    })
      .then((response) => {
        setGetOneCV(response.data['cv_data']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  useEffect(() => {
    getallCV();
  }, [])


  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal" gap={3}>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../cv/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../cv/view_all">CV List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'View CV',
              }
            ]} />
          </div>
        </Stack>
      </FormAction>

      {/* Cash Voucher Form */}
      <CVForm editView={editView} getOneCV={getOneCV} cvID={cvID} />

    </FormContainer>
  );
};

export default ViewCV;