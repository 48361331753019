import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormContainer, FormAction } from "../../formLayout/FormContainer";
import { Breadcrumb } from 'antd';
import JVForm from './JVForm';


const MySwal = withReactContent(Swal)

const AddJV = (props) => {

  const viewOnly = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction="horizontal">
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../journal_voucher/view_all">Journal Voucher List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'New Journal Voucher',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <JVForm viewOnly={viewOnly} />
    </FormContainer>
  );
};

export default AddJV;