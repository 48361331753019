import React, { useState, useEffect } from 'react';
// import './SupplierInformationPage.module.css';
import $ from "jquery";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Form, Stack, Col, Row } from 'react-bootstrap';
import { Breadcrumb, Switch } from 'antd';
import { Link } from "react-router-dom";
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { NumberToPhp, Required, swalWithBootstrapButtons } from '../../../utils/functions';
import SupplierForm from './SupplierForm';

const SupplierInformationPage = () => {

  const { supplierID } = useParams();
  const [supplierData, setSupplierData] = useState({});
  const [allItems, setAllItems] = useState();

  const columns = [
    {
      data: "item_name",
      text: "Item Name",
      render: function (data, type, row) {
        return `<a target="_blank" href='/item_center/view_item/${row['item_id']}'>${data}</a>`;
      },
    },
    {
      data: "item_description",
      text: "Item Description",
    },
    {
      data: "case_barcode",
      text: "Case Barcode",
    },
    {
      data: "item_barcode",
      text: "Item Barcode",
    },
    {
      data: "sku_code",
      text: "SKU",
    },
    {
      data: "uom",
      text: "UOM",
    },
    {
      data: "qty_per_uom",
      text: "QTY / UOM",
    },
    {
      data: "price_per_piece",
      text: "Price Per Piece",
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00'
        } else {
          return NumberToPhp(data)
          // return data
        }
      }
    },
    {
      data: "price_per_uom",
      text: "Price Per UOM",
      render: function (data, type) {
        if (data === null || isNaN(data)) {
          return '₱ 0.00'
        } else {
          return NumberToPhp(data)
          // return data
        }
      }
    },
    // {
    //   data: "is_active",
    //   text: "Active Status",
    //   render: function(data, type) {
    // 			if (data === true) {
    // 				return 'Active'
    // 			} else {
    // 				return 'Inactive'
    // 			}
    // 		}
    // },
  ];

  const getSupplierData = _ => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_supplier`,
      params: { supplier_id: supplierID },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    }).then((res) => {
      setSupplierData(res.data['supplier_data']);
      getAllItems();
    }).catch((err) => {
      console.log(err)
      console.log('Error in Getting Supplier!');
    });
  };

  const getAllItems = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_suppliers_items?supplier_id=${supplierID}`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllItems(response.data['suppliers_items']);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Items!');
      });
  };

  function SetDataTable() {
    $("#DataTableItems").DataTable({
      data: allItems,
      columns: columns,
      order: [[0, 'asc']],
      columnDefs: [
        {
          targets: -1,
          className: 'dt-right',
          // className: 'dt-body-right'
        },
        {
          targets: -2,
          className: 'dt-right',
          // className: 'dt-body-right'
        },
      ],
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'csv',
          text: 'Export to CSV', // Customize the button text here
          className: 'btn-primary btn btn-sm', // Customize the button CSS class here
          filename: `${supplierData.supplier_name}-items-${new Date().toISOString().split("T")[0]}`, // Set the desired filename here
        }
      ]
    });
  }

  useEffect(() => {
    getSupplierData();
  }, []);

  useEffect(() => {
    // console.log(allItems)
    if (!$.fn.DataTable.isDataTable("#DataTableItems")) {
      // Initialize the DataTable plugin
      SetDataTable();
    } else {
      $("#DataTableItems").DataTable().destroy();
      SetDataTable();
    }
  }, [allItems]);

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../suppliers/home">Home</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../suppliers/view_all">Supplier List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'Supplier Info',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>

      <SupplierForm editView={true} supplierID={supplierID} oneSupplierData={supplierData} />

      <FormContent>
        <FormTitle><p className='text-uppercase'>Supplier Items</p></FormTitle>
        <Stack>
          <div>
            <div className="mt-3" style={{ display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                <table id="DataTableItems" className="display" >
                  <thead>
                    {<tr>
                      <th>Item Name</th>
                      <th>Item Description</th>
                      <th>Case Barcode</th>
                      <th>Item Barcode</th>
                      <th>SKU</th>
                      <th>UOM</th>
                      <th>QTY / UOM</th>
                      <th>Price / Piece</th>
                      <th>Price / UOM</th>
                      {/* <th>Active Status</th> */}
                    </tr>}
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </Stack>
      </FormContent>
    </FormContainer>

  );
};

export default SupplierInformationPage;
