import React, { useEffect, useMemo, useState } from 'react';
import { FormContent, FormInfoFull, FormTitle } from '../../../formLayout/FormContainer';
import { Accordion, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { Required, hasItems, swalWithBootstrapButtons, toTitleCase } from '../../../../utils/functions';
import { AUTH_API_KEY, AUTH_LINK } from '../../../../utils/env_config';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Checkbox, Switch } from 'antd';

const MySwal = withReactContent(Swal);

const RoleForm = ({ roleID, oneRoleData, oneRoleAccessData, oneAccessDataKey, allRoleAccessData, editView }) => {

  const sampleDataArr = [
    {
      "permission_id": "PRM00000041",
      "permission_name": "Import",
      "module_id": "MOD00000047",
    },
    {
      "permission_id": "PRM00000038",
      "permission_name": "Update",
      "module_id": "MOD00000047",
    },
    {
      "permission_id": "PRM00000039",
      "permission_name": "View",
      "module_id": "MOD00000048",
    },
    {
      "permission_id": "PRM00000040",
      "permission_name": "Print",
      "module_id": "MOD00000046",
    },
    {
      "permission_id": "PRM00000042",
      "permission_name": "Send",
      "module_id": "MOD00000046",
    },
    {
      "permission_id": "PRM00000037",
      "permission_name": "Create",
      "module_id": "MOD00000048",
    }
  ]

  const sampleModules = [
    {
      "module_id": "MOD00000047",
      "module_name": "Purchase Order",
    },
    {
      "module_id": "MOD00000048",
      "module_name": "Purchase Request",
    },
    {
      "module_id": "MOD00000046",
      "module_name": "Receiving Receipt",
    }
  ]

  const [roleData, setRoleData] = useState({
    role_name: '',
  })

  const [roleAccessData, setRoleAccessData] = useState([
    {
      module_id: '',
    }
  ]);

  const [roleAccessDataName, setRoleAccessDataName] = useState([
    'Module Name'
  ]);

  const [roleAccessModuleName, setRoleAccessModuleName] = useState([
    'Module Name'
  ]);

  const [roleAccessDataKey, setRoleAccessDataKey] = useState(["module_id"]);
  const [validated, setValidated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState('Create Role');
  const [isActive, setIsActive] = useState(null);

  async function fetchPermissionNameEdit() {
    setRoleAccessDataName([
      {
        module_id: 'Module Name',
      }
    ]);
    Object.keys(oneRoleAccessData[0]).map(key => {
      if (key !== 'module_id') {
        fetch(`${AUTH_LINK}/api/permissions/get_permission?permission_id=` + key, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
            'x-api-key': AUTH_API_KEY,
          },
        })
          .then(response => response.json())
          .then(data => {
            var perName = data.permission_data.permission_name;
            const perNameArr = {
              [key]: data.permission_data.permission_name,
            };
            setRoleAccessDataName(roleAccessDataName => [
              ...roleAccessDataName,
              perNameArr,
            ])
          })
          .catch(error => {
            console.log('Error in fetching Data!');
            console.log(error);
          });
      }
    })
  }

  async function fetchPermissionNameCreate() {
    setRoleAccessDataName([
      {
        module_id: 'Module Name',
      }
    ]);
    Object.keys(allRoleAccessData[0]).map(key => {
      if (key !== 'module_id') {
        fetch(`${AUTH_LINK}/api/permissions/get_permission?permission_id=` + key, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
            'x-api-key': AUTH_API_KEY,
          },
        })
          .then(response => response.json())
          .then(data => {
            var perName = data.permission_data.permission_name;
            const perNameArr = {
              [key]: data.permission_data.permission_name,
            };
            setRoleAccessDataName(roleAccessDataName => [
              ...roleAccessDataName,
              perNameArr,
            ])
          })
          .catch(error => {
            console.log('Error in fetching Data!');
            console.log(error);
          });
      }
    })
  }

  async function fetchModuleNameEdit() {
    setRoleAccessModuleName([]);
    oneRoleAccessData.forEach(element => {
      fetch(`${AUTH_LINK}/api/modules/get_module?module_id=` + element.module_id, {
        method: 'GET', // or 'POST', 'PUT', etc.
        headers: {
          'x-api-key': AUTH_API_KEY,
        },
      })
        .then(response => response.json())
        .then(data => {
          var modName = data.module_data.module_name;
          const modNameArr = {
            [element.module_id]: data.module_data.module_name,
          };
          // roleAccessModuleName.push(modName)
          setRoleAccessModuleName(roleAccessModuleName =>
            [
              ...roleAccessModuleName,
              modNameArr,
            ]
          )
        })
        .catch(error => {
          console.log('Error in fetching Data!');
          console.log(error);
        });
    })
  }

  async function fetchModuleNameCreate() {
    setRoleAccessModuleName([]);
    allRoleAccessData.forEach(element => {
      fetch(`${AUTH_LINK}/api/modules/get_module?module_id=` + element.module_id, {
        method: 'GET', // or 'POST', 'PUT', etc.
        headers: {
          'x-api-key': AUTH_API_KEY,
        },
      })
        .then(response => response.json())
        .then(data => {
          var modName = data.module_data.module_name;
          const modNameArr = {
            [element.module_id]: data.module_data.module_name,
          };
          // roleAccessModuleName.push(modName)
          setRoleAccessModuleName(roleAccessModuleName =>
            [
              ...roleAccessModuleName,
              modNameArr,
            ]
          )
        })
        .catch(error => {
          console.log('Error in fetching Data!');
          console.log(error);
        });
    })
  }

  const fetchPostSubmit = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Creating Role</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/roles/add_role`,
          data: roleData,
          method: 'POST',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async (res) => {
          // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
          console.log(res.data)
          await MySwal.fire({
            title: <strong>Role Created Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch((err) => {
            if (err.response.status === 450) {
              MySwal.fire({
                title: 'Role Name must be Unique',
                icon: 'warning',
                cancelButtonText: 'OK',
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: false
              })
            } else {
              console.log(err)
              return MySwal.fire({
                title: <strong>{err.response.data.message}</strong>,
                // html: <i>You clicked the button!</i>,
                icon: 'error'
              }).then(() => {
                MySwal.close();
              })
            }
          });
      }
    })
  }

  const fetchPutUpdate = (e) => {
    // console.log(prInfo)
    e.preventDefault();
    MySwal.fire({
      title: <p>Updating Role</p>,
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    axios({
      url: `${AUTH_LINK}/api/roles/update_role`,
      data: roleData,
      method: 'PUT',
      headers: {
        'x-api-key': AUTH_API_KEY
      },
    }).then(async (res) => {
      // ReactDOM.findDOMNode(this.PurchaseRequestsForm).reset();
      console.log(res.data)
      await MySwal.fire({
        title: <strong>Role Updated Successfully</strong>,
        // html: <i>You clicked the button!</i>,
        icon: 'success'
      });
      window.location.reload();
    })
      .catch((err) => {
        if (err.response.status === 450) {
          MySwal.fire({
            title: 'Role Name must be Unique',
            icon: 'warning',
            cancelButtonText: 'OK',
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: false
          })
        } else {
          console.log(err)
          return MySwal.fire({
            title: <strong>{err.response.data.message}</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'error'
          }).then(() => {
            MySwal.close();
          })
        }
      });
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this Role, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${AUTH_LINK}/api/roles/change_role_active_status`,
          params: { role_id: roleID },
          method: 'PUT',
          headers: {
            'x-api-key': AUTH_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Updating Active/Inactive Status!</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err)
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  const onChange = (e) => {
    setRoleData({
      ...roleData,
      [e.target.name]: e.target.value
    })
  }

  const handleEditMode = () => {
    setEditMode(true);
    setEditText('Edit Role');
  }

  const handleCheckboxChange = (moduleName, permissionName) => {
    setRoleAccessData(prevPermissions => {
      return prevPermissions.map(module => {
        if (module.module_id === moduleName) {
          return {
            ...module,
            [permissionName]: !module[permissionName]
          };
        }
        return module;
      });
    });
  };

  const onCheckChange = (e) => {
    console.log(e);
  }

  useEffect(() => {
    // console.log(roleID);
  }, [roleID])

  useEffect(() => {
    // console.log(oneRoleData);
    if (hasItems(oneRoleData)) {
      if (oneRoleData.is_active) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
      setRoleData({
        ...roleData,
        role_id: oneRoleData.role_id,
        role_name: oneRoleData.role_name,
        access_data: oneRoleData.access_data,
      })
      // setRoleData(oneRoleData);
    }
  }, [oneRoleData])

  useEffect(() => {
    // console.log(editMode);
  }, [editMode])

  useEffect(() => {
    // console.log('roleAccessData', roleAccessData);
    var accessData = JSON.stringify(roleAccessData)
    setRoleData({
      ...roleData,
      access_data: accessData,
    });
  }, [roleAccessData])

  useEffect(() => {
    // console.log('roleData',roleData);
  }, [roleData])

  useEffect(() => {
    // console.log('oneRoleAccessData', oneRoleAccessData);
    if (hasItems(oneRoleAccessData)) {
      if (editView) {
        setRoleAccessData(oneRoleAccessData);
        fetchPermissionNameEdit();
        fetchModuleNameEdit();
      }
    }
  }, [oneRoleAccessData])

  useEffect(() => {
    // console.log('oneRoleAccessData',oneRoleAccessData);
    if (hasItems(allRoleAccessData)) {
      if (!editView) {
        setRoleAccessData(allRoleAccessData);
        fetchPermissionNameCreate();
        fetchModuleNameCreate();
      }
    }
  }, [allRoleAccessData])

  useEffect(() => {
    // console.log('roleAccessDataKey',roleAccessDataKey);
  }, [roleAccessDataKey])

  useEffect(() => {
    // console.log('roleAccessDataName',roleAccessDataName);
  }, [roleAccessDataName])

  useEffect(() => {
    // console.log('roleAccessModuleName',roleAccessModuleName);
  }, [roleAccessModuleName])

  useEffect(() => {
    // console.log(oneAccessDataKey);
    if (hasItems(oneAccessDataKey)) {
      setRoleAccessDataKey(oneAccessDataKey);
    }
  }, [oneAccessDataKey])

  useEffect(() => {
    // console.log(editView);
    if (editView) {
      setEditText('View Role');
    }
  }, [editView])

  return (
    <FormContent>
      <FormTitle><p className='text-uppercase'>{editText}</p></FormTitle>
      <FormInfoFull>
        <Form onSubmit={!editView ? fetchPostSubmit : fetchPutUpdate} name="RolePostForm" id='RolePostForm'>
          {
            editView && (
              <Stack className="mb-3" hidden={editMode}>
                <div>
                  Status: &nbsp;
                  <Switch
                    style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                    checkedChildren={'Active'}
                    unCheckedChildren={'Inactive'}
                    checked={isActive}
                    onClick={handleIsActive}
                    size="small"
                  />
                </div>
              </Stack>
            )
          }
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3" controlId="role_name">
              <Form.Label>Role Name <Required /></Form.Label>
              {
                !editView ? (
                  <Form.Control type="text" placeholder='Role Name' value={roleData.role_name} name="role_name" onChange={onChange} required />
                ) : (
                  <Form.Control type="text" placeholder='Role Name' defaultValue={roleData.role_name} name="role_name" onChange={onChange} disabled={!editMode} required />
                )
              }
            </Form.Group>
          </Row>

          <Row className="mb-3" md={3}>
            <Accordion alwaysOpen>
              {
                sampleModules.map((modules, i) => (
                  <Accordion.Item eventKey={i+'_'+modules.module_id}>
                    <Accordion.Header>
                      <Col>{modules.module_name}</Col>
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        sampleDataArr.map((e, i) => (
                          modules.module_id === e.module_id && (
                            <Row key={i}>
                              <Col>{e.permission_name}</Col>
                              <Col>
                                <Checkbox
                                  type="checkbox"
                                  onChange={() => { onCheckChange(e.permission_id) }}
                                />
                              </Col>
                            </Row>
                          )
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </Row>

          <Row className="mb-3" style={{ width: '100%' }}>
            {
              !editView ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(roleAccessData[0]).map(key => {
                        if (key !== 'module_id') {
                          var name = "";
                          roleAccessDataName.map((e) => {
                            if (e[key]) {
                              // console.log(e[key]);
                              name = e[key]
                            }
                          })
                          return <th key={key}>{name}</th>;
                        } else {
                          return <th key={key}>Module Name</th>;
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {roleAccessData.map((module) => (
                      <tr key={module.module_id}>

                        {
                          roleAccessModuleName.map((e) => {
                            var name = module.module_id;
                            if (e[name]) {
                              return <td key={name}>{e[name]}</td>
                            }
                          })
                        }

                        {Object.keys(module).map(key => {
                          if (key !== 'module_id') {
                            return (
                              <td key={key}>
                                <Checkbox
                                  type="checkbox"
                                  checked={module[key]}
                                  onChange={() => handleCheckboxChange(module.module_id, key)}
                                />
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      {
                        Object.keys(roleAccessData[0]).map(key => {
                          if (key !== 'module_id') {
                            var name = "";
                            roleAccessDataName.map((e) => {
                              if (e[key]) {
                                // console.log(e[key]);
                                name = e[key]
                              }
                            })
                            return <th key={key}>{name}</th>;
                          } else {
                            return <th key={key}>Module Name</th>;
                          }
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      roleAccessData.map((module) => (
                        <tr key={module.module_id}>

                          {
                            roleAccessModuleName.map((e) => {
                              var name = module.module_id;
                              if (e[name]) {
                                return <td key={name}>{e[name]}</td>
                              }
                            })
                          }

                          {Object.keys(module).map(key => {
                            if (key !== 'module_id') {
                              return (
                                <td key={key}>
                                  <Checkbox
                                    disabled={!editMode}
                                    type="checkbox"
                                    checked={module[key]}
                                    onChange={() => handleCheckboxChange(module.module_id, key)}
                                  />
                                </td>
                              );
                            }
                            return null;
                          })}
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              )
            }
          </Row>

        </Form>
        {
          editView ? (
            editMode ? (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-danger' onClick={handleClickRefresh} >Cancel</a>
                </div>
                <div className='ms-auto'>
                  <button type='submit' className='btn btn-sm btn-primary' form='RolePostForm'>Submit</button>
                </div>
              </Stack>
            ) : (
              <Stack direction='horizontal'>
                <div>
                  <a type='button' className='btn btn-sm btn-primary' onClick={handleEditMode} >Edit</a>
                </div>
              </Stack>
            )
          ) : (
            <Stack direction='horizontal'>
              <div className='ms-auto'>
                <button type='submit' className='btn btn-sm btn-primary' form='RolePostForm'>Submit</button>
              </div>
            </Stack>
          )
        }
      </FormInfoFull>
    </FormContent>
  );
};

export default RoleForm;