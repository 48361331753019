import React from "react";
import { Link } from "react-router-dom";
import style from "./UserManagementHome.module.css";

function UserManagementHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>User Management Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/sysad/user_management/user_list" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fa-solid fa-users"></i></p>
            View All Users
          </div>
        </Link>
        <Link to="/sysad/user_management/create_user" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Add User
          </div>
        </Link>
        {/* <Link to="/invoice/search" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-search"></i></p>
            Search Suppliers
          </div>
        </Link>
        <Link to="/page4" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-hand-paper"></i></p>
            Button 4
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default UserManagementHome;
