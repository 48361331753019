import React, { useCallback, useMemo, useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from "../../../utils/env_config";
import Select from "react-select";
import { NumberToPhp, amountToWords, hasItems } from "../../../utils/functions";
// import { data, costCenterList } from './makeData';

const data = [
  {
    // item_id: '',
    // item_name: '',
    // sku_code: '',
    // uom: '',
    // qty_per_uom: '',
    // price_per_uom: '',
    // order_quantity: '',
    // total_amount: NumberToPhp(''),
  },
];
const costCenterList = [
  // {
  //   value: "None",
  //   label: "None",
  // },
  {
    value: "Procurement",
    label: "Procurement",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "IT",
    label: "IT",
  },
];

const suppliersItemsListOptions = [];
let suppliersItemsList = [];
const PRTable = (props) => {
  //  const [ showEditForm, setShowEditForm ] = useState('false')

  //  const editFormHandler = () => {
  //     setShowEditForm(true);
  //     console.log(showEditForm);
  //  }

  const [data, setData] = useState([
    //   {
    //     // id: {item_id:'ITM0000000X', item_name:'Boy Bawang'},
    //     // item_id:'ITM0000000X',
    //     // item_name:'Boy Bawang',
    //     // sku_code: 'ITM00000001',
    //     // uom: '423423423423',
    //     // qty_per_uom: '234234234',
    //     // price_per_uom: 234234234,
    //     // order_quantity: '100',
    //     // total_amount: '100'
    //   }
  ])

  const [suppliersItems, setSuppliersItems] = useState([]);
  const getSuppliersItems = () => {
    suppliersItemsListOptions.length = 0;
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_suppliers_items`,
      params: { supplier_id: props.supplier_id },
      method: "GET",
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        suppliersItemsList = response.data.suppliers_items;
        setSuppliersItems(response.data.suppliers_items);
        // allInvoices = response.data['invoice_data'];
      })
      .catch((err) => {
        console.log(err);
        console.log(`Error in Fetching All Suppliers's Items!`);
      });
  };

  useEffect(() => {
    getSuppliersItems();
  }, [props.supplier_id]);

  function setItemListOptions() {
    suppliersItems.forEach((item) => {
      const supplierOption = {
        value: item.item_id,
        label: item.item_name,
      };
      suppliersItemsListOptions.push(supplierOption);
      // console.log(suppliersItemsListOptions);
    });
  }

  useEffect(() => {
    setItemListOptions();
  }, [suppliersItems]);

  useEffect(() => {
    // console.log('props.editMode',props.editMode);
  }, [props.editMode]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => data);
  const [validationErrors, setValidationErrors] = useState({});
  const [deletedItems, setDeletedItems] = useState([])

  // console.log(tableData);

  useEffect(() => {
    try {
      if (hasItems(props.items_info)) {
        setTableData(props.items_info);
      } else {
        console.log("no PR Data");
      }
    } catch (err) { }
  }, [props.items_info]);

  useEffect(() => {
    // console.log("data", data);
  }, [data]);

  const handleCreateNewRow = (values) => {
    // values.total_amount = NumberToPhp(values.total_amount)
    tableData.push(values);
    setTableData([...tableData]);
    props.onSubmit(tableData, deletedItems); // Passing back curr contents of Table to CreatePurchaseRequests.jsx
    // console.log(tableData);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      // values.total_amount = NumberToPhp(values.total_amount)
      if (tableData[row.index].pr_items_id) {
        var pr_items_id = tableData[row.index].pr_items_id;
        const addPRItemsID = {
          pr_items_id,
          item_name: values.item_name,
          item_id: values.item_id,
          sku_code: values.sku_code,
          uom: values.uom,
          qty_per_uom: values.qty_per_uom,
          price_per_uom: values.price_per_uom,
          cost_center: values.cost_center,
          order_quantity: values.order_quantity,
          total_amount: values.total_amount,
        }
        // console.log('addPRItemsID',addPRItemsID);
        tableData[row.index] = addPRItemsID
      } else if (tableData[row.index].po_items_id) {
        var po_items_id = tableData[row.index].po_items_id;
        const addPRItemsID = {
          po_items_id,
          item_name: values.item_name,
          item_id: values.item_id,
          sku_code: values.sku_code,
          uom: values.uom,
          qty_per_uom: values.qty_per_uom,
          price_per_uom: values.price_per_uom,
          cost_center: values.cost_center,
          order_quantity: values.order_quantity,
          total_amount: values.total_amount,
        }
        // console.log('addPRItemsID',addPRItemsID);
        tableData[row.index] = addPRItemsID
      } else if (tableData[row.index].rr_items_id) {
        var rr_items_id = tableData[row.index].rr_items_id;
        const addPRItemsID = {
          rr_items_id,
          item_name: values.item_name,
          item_id: values.item_id,
          sku_code: values.sku_code,
          uom: values.uom,
          qty_per_uom: values.qty_per_uom,
          price_per_uom: values.price_per_uom,
          cost_center: values.cost_center,
          order_quantity: values.order_quantity,
          total_amount: values.total_amount,
        }
        // console.log('addPRItemsID',addPRItemsID);
        tableData[row.index] = addPRItemsID
      } else {
        tableData[row.index] = values
      }
      // tableData.forEach((tableItem, idx) => {
      //   tableData[idx].total_amount = NumberToPhp(tableItem.total_amount)
      // })
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
      props.onSubmit(tableData, deletedItems);
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };


  const handleDeleteRow = useCallback(
    (row) => {
      if (
        // !confirm(`Are you sure you want to delete ${row.getValue('sku_code')}`)
        console.log()
      ) {
        return;
      }
      if (tableData[row.index].pr_items_id) {
        var pr_items_id = tableData[row.index].pr_items_id;
        deletedItems.push(pr_items_id)
        tableData.splice(row.index, 1);
      } else if (tableData[row.index].po_items_id) {
        var po_items_id = tableData[row.index].po_items_id;
        deletedItems.push(po_items_id)
        tableData.splice(row.index, 1);
      } else if (tableData[row.index].rr_items_id) {
        var rr_items_id = tableData[row.index].rr_items_id;
        deletedItems.push(rr_items_id)
        tableData.splice(row.index, 1);
      } else {
        tableData.splice(row.index, 1);
      }
      //send api delete request here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      // console.log('deletedItems',deletedItems);
      props.onSubmit(tableData, deletedItems);
      // props.onSubmit(tableData);
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
                ? validateAge(+event.target.value)
                : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const [currAmount, setCurrAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Calculate the total amount when the table data changes
    const total = tableData.reduce(
      (sum, row) => sum + Number(row.total_amount || 0),
      0
    );
    setTotalAmount(total);
    // props.updatedGrandTotal(total);
    // console.log('tableData',tableData);
  }, [tableData]);

  useEffect(() => {
    document.getElementById('grand_total').value = NumberToPhp(totalAmount)
    document.getElementById('total_vat_inc').value = NumberToPhp(totalAmount)
    document.getElementById('amount_in_words').value = amountToWords(totalAmount)
  }, [totalAmount])

  // console.log(currAmount, totalAmount);

  const columns = useMemo(
    () => [
      {
        accessorKey: "item_name",
        header: "Item Name",
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "item_id",
        header: "Item ID",
        enableEditing: false,
      },
      {
        accessorKey: "sku_code",
        header: "SKU Code",
        size: 80,
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "uom",
        header: "UOM",
        enableEditing: false,
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "qty_per_uom",
        header: "Quantity per UOM",
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "text",
        }),
      },
      {
        accessorKey: "price_per_uom",
        header: "Price per UOM",
        enableEditing: false,
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "text",
        }),
        Cell: ({ renderedCellValue }) => (
          <span>
            {NumberToPhp(renderedCellValue)}
          </span>
        )
      },
      {
        accessorKey: 'cost_center',
        header: 'Cost Center',
        enableEditing: true,
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          select: true, //change to select for a dropdown
          children: costCenterList.map((costCenter) => (
            <MenuItem key={costCenter.value} value={costCenter.value}>
              {costCenter.label}
            </MenuItem>
          )),
        }),
      },
      // {
      //   accessorKey: "cost_center",
      //   header: "Cost Center",
      //   enableEditing: false,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: "text",
      //   }),
      // },
      {
        accessorKey: "order_quantity",
        header: "Order Quantity",
        muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
          inputProps: {
            min: 1,
          },
          onChange: (event) => {
            const updatedValue = event.target.value;
            const pricePerUom = row.original.price_per_uom;
            const totalAmount = pricePerUom * updatedValue;
            setCurrAmount(totalAmount);
          },
        }),
      },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "text",
          value: currAmount ? currAmount : row.original.total_amount,
        }),
        Cell: ({ renderedCellValue }) => (
          <span>
            {NumberToPhp(renderedCellValue)}
          </span>
        )
      },
    ],
    [getCommonEditTextFieldProps, currAmount]
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          props.editMode ? (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          ) : ''
        )}
        initialState={props.proc_page === 'RR' ? { density: 'compact', columnVisibility: { total_amount: false, price_per_uom: false } } : { density: 'compact' }}
        // enableTopToolbar={props.proc_page === 'RR' ? false : true}
        enableHiding={props.proc_page === 'RR' ? false : true}
        renderTopToolbarCustomActions={() => (
          props.proc_page === 'PR' ? (
            props.editMode ? (
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              // disabled={}
              >
                Add Item
              </Button>
            ) : (
              <div>&nbsp;</div>
            )
          ) : (
            <div>&nbsp;</div>
          )
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        suppliersItemsListOptions={suppliersItemsListOptions}
        costCenterList={costCenterList}
        suppliersItemsList={suppliersItemsList}
      />
    </>
  );
};

export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  suppliersItemsList,
  suppliersItemsListOptions,
  costCenterList,
}) => {
  // const [currItemID, setCurrItemID] = useState("");
  const [values, setValues] = useState(() =>
    // console.log(columns),
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onClose();
    onSubmit(values);
    setCurrItemID("");
  };

  const handleClose = () => {
    //put your validation logic here
    setCurrItemID("");
    onClose();
  };

  const [costCenterID, setCostCenterID] = useState("");
  const [currItemID, setCurrItemID] = useState("");
  const [currItemInfo, setCurrItemInfo] = useState("");
  const [currItemTotal, setCurrItemTotal] = useState("");

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Add Item</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) =>
              // Check if the column's accessor key is equal to 'id'
              // If it is, render a Select component with the column's header as the label
              // If not, check if the column's accessor key is equal to 'name'
              // If it is, render a TextArea component with the column's header as the label
              // Otherwise, render a TextField component with the column's header as the label
              column.accessorKey === "item_name" ? (
                <Select
                  options={suppliersItemsListOptions}
                  key={column.accessorKey}
                  placeholder="Select Item"
                  name={column.accessorKey}
                  onChange={(e) => {
                    // setSelectedValue(e.value); // Set the selected value in state
                    // console.log(e);
                    // setValues({ ...values, "item_id": e.value }); // Update the other values in state
                    // console.log(values)
                    setCurrItemID(e.value); // Update the other values in state

                    const itemInfo = suppliersItemsList.find(
                      (obj) => obj.item_id === e.value
                    );
                    setValues({
                      ...values,
                      item_name: e.label,
                      item_id: e.value,
                      sku_code: itemInfo.sku_code,
                      uom: itemInfo.uom,
                      qty_per_uom: itemInfo.qty_per_uom,
                      price_per_uom: itemInfo.price_per_uom,
                    }); // Update the other values in state
                    setCurrItemInfo(itemInfo);
                    // console.log(itemInfo.item_name);
                  }}
                  isSearchable
                  style={{ opacity: 0.5 }}
                  menuPortalTarget={document.body}
                  menuPlacement={"bottom"}
                  menuPosition={"fixed"}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (base) => ({
                      ...base,
                      opacity: 1,
                    }),
                  }}
                />
              ) : column.accessorKey === "cost_center" ? (
                <Select
                  options={costCenterList}
                  key={column.accessorKey}
                  placeholder="Procurement"
                  name={column.accessorKey}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      cost_center: e.value,
                    });
                    setCostCenterID(e.value); // Update the other values in state
                  }}
                  isSearchable
                  style={{ opacity: 0.5 }}
                  menuPortalTarget={document.body}
                  menuPlacement={"bottom"}
                  menuPosition={"fixed"}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (base) => ({
                      ...base,
                      opacity: 1,
                    }),
                  }}
                />
              ) : column.accessorKey === "item_id" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemID}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : column.accessorKey === "sku_code" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemInfo ? currItemInfo.sku_code : ""}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : column.accessorKey === "uom" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemInfo ? currItemInfo.uom : ""}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : column.accessorKey === "qty_per_uom" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemInfo ? currItemInfo.qty_per_uom : ""}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : column.accessorKey === "price_per_uom" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemInfo ? currItemInfo.price_per_uom : ""}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : column.accessorKey === "order_quantity" ? (
                <TextField
                  type="number"
                  inputProps={{ inputMode: "numeric", min: 0 }} // Set the minimum value to 0
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) => {
                    // setSelectedValue(e.value); // Set the selected value in state
                    let currTotal = e.target.value * currItemInfo.price_per_uom;
                    setValues({
                      ...values,
                      order_quantity: e.target.value,
                      total_amount: currTotal,
                    }); // Update the other values in state
                    setCurrItemTotal(currTotal);
                  }}
                />
              ) : column.accessorKey === "total_amount" ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={currItemTotal ? NumberToPhp(currItemTotal) : ""}
                  // onChange={(e) => {
                  //   // setSelectedValue(e.value); // Set the selected value in state
                  //   setValues({ ...values, 'id': e.value }); // Update the other values in state
                  // }}
                  disabled={true}
                />
              ) : (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              )
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default PRTable;
