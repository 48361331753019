import React, { useState, useEffect, useRef } from 'react';
import { Link, json } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { PROCUREMENT_API_KEY, PROCUREMENT_LINK } from '../../../utils/env_config';
import { useParams } from 'react-router-dom';
import PRTable from '../purchaseRequests/PRTable';
import { FormContainer, FormAction, FormContent, FormTitle, FormInfoFull } from "../../formLayout/FormContainer";
import { Stack } from 'react-bootstrap';
import { Breadcrumb, Switch } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { NumberToPhp, amountToWords, hasItems, swalWithBootstrapButtons } from '../../../utils/functions';

const MySwal = withReactContent(Swal)

const Required = _ => {
  return (
    <span style={{ color: "red" }}>*</span>
  )
}

const ViewRR = (props) => {

  const { rrID } = useParams();

  const [editMode, setEditMode] = useState(false);

  const [rrInfo, setRRInfo] = useState({
    supplier_id: '',
    payment_method: '',
    pr_date: '',
    deliver_to: '',
    receiver: '',
    receiver_contact_number: '',
    required_delivery_date: '',
    revenue_center: '',
    is_payment_first: '',
    notes: '',
    total_vat_inc: '',
    grand_total: '',
    amount_in_words: '',
    deleted_items: [],
    pr_items_data: '',
  });

  const supplierListOptions = [
    // { value: 'BECOM', label: 'BECOM' },
    // { value: 'BMART', label: 'BMART' },
    // { value: 'BRET', label: 'BRET' },
  ];

  const [supplierID, setSupplierID] = useState("");
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [suppliersItems, setSuppliersItems] = useState([]);
  const [itemsInfoCimbined, setitemsInfoCimbined] = useState([]);
  const [isApproved, setisApproved] = useState(false);
  const [isDelivered, setisDelivered] = useState(false);
  const [approveStatus, setapproveStatus] = useState('');
  const [PRListData, setPRListData] = useState([]);
  const [oneRRData, setOneRRData] = useState([]);
  const [rrInfoData, setRRInfoData] = useState([]);
  const [rrItemsInfoData, setRRItemsInfoData] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [isApproveStatus, setIsApproveStatus] = useState(false);

  const totalVatIncRef = useRef(null);
  const grandTotalRef = useRef(null);
  const amountInWordsRef = useRef(null);

  async function fetchOneRRData() {
    await fetch(`${PROCUREMENT_LINK}/api/receiving_receipt/get_one_rr?rr_id=` + rrID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        setOneRRData(data.rr_data);
        setRRInfoData(data.rr_data.rr_info);
        setRRItemsInfoData(data.rr_data.rr_items_info);
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onChange = (e) => {
    if (e.target.name === 'supplier_id') {
      getSupplierInfo(e.target.value);
      setSupplierID(e.target.value);
      setRRInfo({ ...rrInfo, [e.target.name]: e.target.value });
    }
    if (e.hasOwnProperty('target')) {
      if (e.target.type === 'file') {
        setRRInfo({ ...rrInfo, [e.target.name]: e.target.files[0] });
      } else {
        setRRInfo({ ...rrInfo, [e.target.name]: e.target.value });
      }
    } else { // For supplier_id field only
      getSupplierInfo(e.value);
      setSupplierID(e.value)
    }

  };

  const UpdateRRPost = (e) => {
    // console.log(rrInfo)
    e.preventDefault();
    swalWithBootstrapButtons.fire({
      title: 'All updates will be saved, continue?',
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Receiving Receipt</p>,
          allowOutsideClick: false,
          didOpen: () => {
            // `MySwal` is a subclass of `Swal` with all the same instance & static methods
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/receiving_receipt/update_rr`,
          data: rrInfo,
          method: 'POST',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY,
          },
        }).then((res) => {
          // ReactDOM.findDOMNode(this.RRForm).reset();
          console.log(res.data)
          return MySwal.fire({
            title: <strong>Receiving Receipt Updated Successfully</strong>,
            // html: <i>You clicked the button!</i>,
            icon: 'success'
          }).then(() => {
            window.location.reload();
          })
        })
          .catch((err) => {
            console.log(err.response.data.message)
            return MySwal.fire({
              title: <strong>{err.response.data.message}</strong>,
              // html: <i>You clicked the button!</i>,
              icon: 'error'
            }).then(() => {
              MySwal.close();
            })
          });

      }
    })
  }

  const getActiveSuppliers = () => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_active_suppliers`,
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setAllSuppliers(response.data.suppliers_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  };

  const getSupplierInfo = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/supplier_center/get_supplier`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        setSupplierInfo(response.data.supplier_data);
      })
      .catch((err) => {
        console.log(err)
        console.log('Error in Fetching All Suppliers!');
      });
  }

  function setSupplierListOptions() {
    allSuppliers.forEach(item => {
      const supplierOption = {
        value: item.supplier_id,
        label: item.supplier_name
      }
      supplierListOptions.push(supplierOption);
    });
    setPRListData(supplierListOptions);
  }

  const getSuppliersItems = (supplier_id) => {
    axios({
      url: `${PROCUREMENT_LINK}/api/item_center/get_suppliers_items`,
      params: { supplier_id: supplier_id },
      method: 'GET',
      headers: {
        'x-api-key': PROCUREMENT_API_KEY
      },
    })
      .then((response) => {
        // console.log(response)
        // console.log(response.data.client_data)
        setSuppliersItems(response.data.suppliers_items);
        // allInvoices = response.data['invoice_data'];		
      })
      .catch((err) => {
        console.log(err)
        console.log(`Error in Fetching All Suppliers's Items!`);
      });
  }

  // Contains data from Receiving Receipt Items Table
  const handlePRItemsTableFormSubmit = (data, deletedItems) => {
    let curr_amounts_list = [];
    // Access the data from PRTable.jsx and perform necessary actions
    // console.log("Submitted data from PRTable:", data);
    data.forEach((item) => {
      // console.log(item.total_amount)
      curr_amounts_list.push(parseFloat(item.total_amount));
    })
    setOrderSummaryData(curr_amounts_list)
    return new Promise(resolve => {
      console.log('deletedItems', deletedItems);
      let total = 0;
      curr_amounts_list.forEach((amount) => {
        total += amount;
      });
      // console.log("curr_amounts_list", total);
      setRRInfo({
        ...rrInfo,
        total_vat_inc: total,
        grand_total: total,
        amount_in_words: amountInWordsRef.current.value,
        pr_date: new Date().toISOString().split('T')[0],
        supplier_id: supplierID,
        deleted_items: deletedItems,
        rr_items_data: data
      }, resolve);
    });

    // setRRInfo({ ...rrInfo, pr_items_data: data });

  };

  const setOrderSummaryData = async (amounts_list) => {
    let total = 0;
    amounts_list.forEach((amount) => {
      total += amount;
    });
    // console.log(total);

    // Set form field values using refs
    totalVatIncRef.current.value = NumberToPhp(total);
    grandTotalRef.current.value = NumberToPhp(total);
    amountInWordsRef.current.value = amountToWords(total);
  };

  async function fetchItemData(ItemID, order_quantity, total_amount, cost_center, rr_items_id) {
    await fetch(`${PROCUREMENT_LINK}/api/item_center/get_item?item_id=` + ItemID, {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'x-api-key': PROCUREMENT_API_KEY,
      },
    })
      .then(response => response.json())
      .then(data => {
        var CostCenter = '';
        if (cost_center === null) {
          CostCenter = 'None';
        } else {
          CostCenter = cost_center;
        }
        const getItems = data.invoice_item_data;
        const dataArray1 = {
          rr_items_id: rr_items_id,
          item_id: getItems.item_id,
          item_name: getItems.item_name,
          sku_code: getItems.sku_code,
          uom: getItems.uom,
          qty_per_uom: getItems.qty_per_uom,
          price_per_uom: getItems.price_per_uom,
          cost_center: CostCenter,
          order_quantity: order_quantity,
          total_amount: total_amount
        }
        // combinePls.push(dataArray1)
        setitemsInfoCimbined(itemsInfoCimbined => [...itemsInfoCimbined, dataArray1])
        // return getItems
      })
      .catch(error => {
        console.log('Error in fetching Data!');
        console.log(error);
      });
  }

  const onClickEdit = _ => {
    setEditMode(true);
  }

  const clickApprove = () => {
    swalWithBootstrapButtons.fire({
      title: 'RR will be Approved, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Approving RR</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/receiving_receipt/approve_rr`,
          params: { rr_id: rrID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Approving RR</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Approving RR</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  }

  const clickReject = () => {
    swalWithBootstrapButtons.fire({
      title: 'RR will be Reject, Continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Rejecting RR</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/receiving_receipt/reject_rr`,
          params: { rr_id: rrID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async (res) => {
          await MySwal.fire({
            title: <strong>Success in Rejecting RR</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in Rejecting RR</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  }

  const handleIsActive = () => {
    swalWithBootstrapButtons.fire({
      title: 'You are changing the Active Status of this RR, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          title: <p>Updating Active Status</p>,
          didOpen: () => {
            MySwal.showLoading()
          },
        })
        axios({
          url: `${PROCUREMENT_LINK}/api/receiving_receipt/change_rr_active_status`,
          params: { rr_id: rrID },
          method: 'PUT',
          headers: {
            'x-api-key': PROCUREMENT_API_KEY
          },
        }).then(async () => {
          await MySwal.fire({
            title: <strong>Success in updating Active/Inactive Status</strong>,
            icon: 'success'
          });
          window.location.reload();
        })
          .catch(async (err) => {
            await MySwal.fire({
              title: <strong>Error in updating Active/Inactive Status</strong>,
              text: "Please Try Again",
              icon: 'error'
            });
            console.log(err);
          });
      }
    })
  };

  const handleClickRefresh = () => {
    swalWithBootstrapButtons.fire({
      title: 'All updates will not be saved, continue?',
      text: "You won't be able to revert this!",
      footer: "Click anywhere outside the box to cancel",
      confirmButtonText: 'Continue',
      timer: 5000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: false
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.reload();
      }
    })
  };

  useEffect(() => {
    if (hasItems(oneRRData)) {
      if (oneRRData.rr_info.is_approved === true) {
        console.log('oneRRData', oneRRData);
        setisApproved(true);
        setapproveStatus('RR is already Approved');
        setIsApproveStatus(true);
      } else if (oneRRData.rr_info.is_approved === false) {
        setisApproved(true);
        setapproveStatus('RR is already Rejected');
        setIsApproveStatus(true);
      } else {
        setisApproved(false);
        setIsApproveStatus(false);
      }
    }
  }, [oneRRData]);

  useEffect(() => {
    fetchOneRRData();
    getActiveSuppliers();
  }, []);

  useEffect(() => {
    console.log('rrInfo', rrInfo);
  }, [rrInfo]);

  useEffect(() => {
    // getActiveSuppliers();
  }, [supplierID]);

  useEffect(() => {
    setSupplierListOptions();
  }, [allSuppliers]);

  useEffect(() => {
    // supplierDataSet();
    // console.log('supplierInfo',supplierInfo);
  }, [supplierInfo]);

  useEffect(() => {
    try {
      if (rrInfoData.is_active && rrInfoData.delivery_status) {
        // console.log('rrInfoData',rrInfoData);
        setIsActive(true);
        setisDelivered(true);
      } else {
        setIsActive(false);
        setisDelivered(false);
      }
      if (rrInfoData.supplier_id) {
        getSuppliersItems(rrInfoData.supplier_id);
        getSupplierInfo(rrInfoData.supplier_id);
        setSupplierID(rrInfoData.supplier_id);
        // setRRInfo(rrInfoData)
        setRRInfo(
          {
            ...rrInfo,
            rr_id: rrInfoData.rr_id,
            pr_id: rrID,
            supplier_id: rrInfoData.supplier_id,
            payment_method: rrInfoData.payment_method,
            pr_date: rrInfoData.pr_date,
            deliver_to: rrInfoData.deliver_to,
            receiver: rrInfoData.receiver,
            receiver_contact_number: rrInfoData.receiver_contact_number,
            required_delivery_date: rrInfoData.required_delivery_date,
            revenue_center: rrInfoData.revenue_center,
            is_payment_first: rrInfoData.is_payment_first,
            notes: rrInfoData.notes,
            total_vat_inc: rrInfoData.total_vat_inc,
            grand_total: rrInfoData.grand_total,
            amount_in_words: rrInfoData.amount_in_words,
            deleted_items: [],
            rr_items_data: rrItemsInfoData,
          }
        )
        // console.log(rrInfo);
      }
    } catch (err) { }
  }, [rrInfoData])

  useEffect(() => {
    try {
      if (rrItemsInfoData[0].rr_items_id) {
        setitemsInfoCimbined([])
        rrItemsInfoData.forEach(e => {
          fetchItemData(e.item_id, e.order_quantity, e.total_amount, e.cost_center, e.rr_items_id);
        });
      }
    } catch (err) { }
  }, [rrItemsInfoData])

  useEffect(() => {

  }, [suppliersItems])

  useEffect(() => {
    // console.log(PRListData);
  }, [PRListData])

  const formRequired = false;

  return (
    <FormContainer>
      <FormAction>
        <Stack direction='horizontal'>
          <div>
            <Breadcrumb separator="" items={[
              {
                title: 'Location:',
              },
              {
                title: <Link style={{ color: '#909090' }} to="../recieving_receipt/home">Receiving Receipt List</Link>,
              },
              {
                type: 'separator',
              },
              {
                title: 'RR Details',
              },
            ]} />
          </div>
        </Stack>
      </FormAction>
      <FormContent>
        <FormTitle><p className="text-uppercase">Receiving Receipt Details</p></FormTitle>
        <FormInfoFull>
          <Form onSubmit={UpdateRRPost} name="RRForm" id='RRForm'>
            <Stack gap={2}>
              <div>
                <span style={{ fontWeight: 'bold' }}>RR ID: {rrID}</span>
              </div>
              <div>
                Status: &nbsp;
                <Switch
                  style={{ backgroundColor: isActive ? '#237804' : '#f5222d' }}
                  checkedChildren={'Active'}
                  unCheckedChildren={'Inactive'}
                  checked={isActive}
                  onClick={handleIsActive}
                  size="small"
                />
              </div>
            </Stack>
            <div className="mt-3" style={{ display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Supplier Information</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                    <Form.Label>Supplier Name</Form.Label><Required />
                    <Form.Select name="supplier_id" value={supplierInfo.supplier_id} aria-label="supplier_id" required={formRequired} disabled onChange={onChange}>
                      {
                        PRListData.map((e, i) => (
                          <option key={i} value={e.value} >{e.label}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="attention">
                    <Form.Label>Attention</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.attention} name="attention" disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="email_address">
                    <Form.Label>Email Address</Form.Label><Form.Control type="email" defaultValue={supplierInfo.email_address} name="email_address" disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="contact_person">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.contact_person} name="contact_person" disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="mobile_number">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.mobile_number} name="mobile_number" disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" defaultValue={supplierInfo.address} name="address" disabled />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="payment_method">
                  <Form.Label>Payment Method</Form.Label><Required />
                  <Form.Control type="text" defaultValue={rrInfoData.payment_method} name="payment_method" onChange={onChange} disabled={!editMode} required={formRequired} />
                </Form.Group>
              </div>
              <div className="rounded border p-3 mb-4" style={{ flex: "1" }}>
                <h4 className="mb-3">Receiving Receipt Details</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="pr_date">
                    <Form.Label>PR Date</Form.Label>
                    <Form.Control type="date" defaultValue={rrInfoData.pr_date} name="pr_date" disabled />
                  </Form.Group>
                  <Form.Group as={Col} controlId="requestor" className="mb-3">
                    <Form.Label>Requestor</Form.Label>
                    <Form.Control type="text" name="requestor" value="Test Requestor" onChange={onChange} disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="deliver_to" className="mb-3">
                    <Form.Label>Deliver To</Form.Label><Required />
                    <Form.Control type="text" defaultValue={rrInfoData.deliver_to} name="deliver_to" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="receiver" className="mb-3">
                    <Form.Label>Receiver</Form.Label><Required />
                    <Form.Control type="text" defaultValue={rrInfoData.receiver} name="receiver" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="receiver_contact_number" className="mb-3">
                    <Form.Label>Receiver Contact Number</Form.Label><Required />
                    <Form.Control type="text" defaultValue={rrInfoData.receiver_contact_number} name="receiver_contact_number" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="required_delivery_date" className="mb-3">
                    <Form.Label>Required Delivery Date</Form.Label><Required />
                    <Form.Control type="date" defaultValue={rrInfoData.required_delivery_date} min={new Date().toLocaleString("sv-SE", { timeZone: "Asia/Manila" }).split(' ')[0]} name="required_delivery_date" onChange={onChange} disabled={!editMode} required={formRequired} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="revenue_center" className="mb-3">
                    <Form.Label>Revenue Center</Form.Label><Required />
                    {
                      !editMode ? (
                        <Form.Control type="text" defaultValue={rrInfoData.revenue_center} name="revenue_center" disabled />
                      ) : (
                        <Form.Select defaultValue={rrInfoData.revenue_center} name="revenue_center" onChange={onChange}>
                          <option value="">Select Revenue Center</option>
                          <option value="BECOM">BECOM</option>
                          <option value="BMART">BMART</option>
                          <option value="BRET">BRET</option>
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                  <Form.Group as={Col} controlId="is_payment_first" className="mb-3">
                    <Form.Label>Payment First?</Form.Label><Required />
                    {
                      !editMode ? (
                        <Form.Select value={rrInfoData.is_payment_first} name="is_payment_first" disabled >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      ) : (
                        <Form.Select defaultValue={rrInfoData.is_payment_first} name="is_payment_first" onChange={onChange} >
                          <option value="">Does this PR require payment first?</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </Form.Select>
                      )
                    }
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="mt-3" style={{ width: "100%", display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ width: "100%", flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Receiving Receipt Items</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="supplier_id">
                    <PRTable supplier_id={supplierID} onSubmit={handlePRItemsTableFormSubmit} items_info={itemsInfoCimbined} editMode={editMode} proc_page={'RR'} />
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="mt-3" style={{ display: "flex" }}>
              <div className="rounded border p-3 mb-4" style={{ flex: "1", marginRight: "10px" }}>
                <h4 className="mb-3">Order Summary</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control type="text" defaultValue={rrInfoData.notes} name="notes" onChange={onChange} disabled={!editMode} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="total_vat_inc">
                    <Form.Label>Total (Vat Inc)</Form.Label>
                    <Form.Control type="text" placeholder="Total (Vat Inc)" name="total_vat_inc" ref={totalVatIncRef} onChange={onChange} disabled />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="grand_total">
                    <Form.Label>Grand Total</Form.Label>
                    <Form.Control type="email" placeholder="Grand Total" name="grand_total" ref={grandTotalRef} onChange={onChange} disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3" controlId="amount_in_words">
                    <Form.Label>Amount in Words</Form.Label>
                    <Form.Control className='text-capitalize' type="text" placeholder="Amount in Words" name="amount_in_words" ref={amountInWordsRef} onChange={onChange} disabled />
                  </Form.Group>
                </Row>
                <h4 className="mb-3">Supplementary Documents</h4>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="delivery_receipt" className="mb-3">
                    <Form.Label>Delivery Receipt</Form.Label>
                    <Form.Control type="file" name="delivery_receipt" />
                  </Form.Group>
                  <Form.Group as={Col} controlId="sales_invoice" className="mb-3">
                    <Form.Label>Sales Invoice</Form.Label>
                    <Form.Control type="file" name="sales_invoice" />
                  </Form.Group>
                </Row>
              </div>
            </div>
          </Form>
          {
            !editMode ? (
              !isApproveStatus ? (
                <Stack direction='horizontal' gap={3}>
                  <div>
                    <button type='button' onClick={onClickEdit} className='btn btn-sm btn-primary'>Edit</button>
                  </div>
                  <div className='ms-auto'>
                    <button type='button' onClick={clickApprove} className='btn btn-sm btn-success'>Approve</button>
                  </div>
                  <div>
                    <button type='button' onClick={clickReject} className='btn btn-sm btn-danger'>Reject</button>
                  </div>
                </Stack>
              ) : (
                <Stack direction='horizontal' gap={2}>
                  <div>
                    <button type='button' className='btn btn-sm btn-success' disabled>{approveStatus}</button>
                  </div>
                  <div className='ms-auto'>
                    <button type='button' className='btn btn-sm btn-primary'><PrinterOutlined style={{ verticalAlign: 'middle' }} /> Print Receiving Receipt</button>
                  </div>
                </Stack>
              )
            ) : (
              <Stack direction='horizontal' gap={2}>
                <div>
                  <button type='button' onClick={handleClickRefresh} className='btn btn-sm btn-danger'>Cancel</button>
                </div>
                <div className='ms-auto'>
                  <button type='submit' form='RRForm' className='btn btn-sm btn-primary'>Submit</button>
                </div>
              </Stack>
            )
          }
        </FormInfoFull>
      </FormContent>
    </FormContainer>
  );
};

export default ViewRR;