import React from "react";
import { Link } from "react-router-dom";
import style from "./CVHome.module.css";

function CVHome() {
  return (
    <div className={style.homeContainer}>
      <h1 className={style.title}>CV Homepage</h1>
      <div className={style.buttonsContainer}>
        <Link to="/cv/view_all" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-file-invoice-dollar"></i></p>
            View CV
          </div>
        </Link>
        <Link to="/cv/create_cv" className={style.link}>
          <div className={style.button}>
            <p className={style.faContainer}><i className="fas fa-plus-square"></i></p>
            Create CV
          </div>
        </Link>
      </div>
    </div>
  );
}

export default CVHome;
